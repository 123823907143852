import { AxiosError } from 'axios'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Spinner, Icon } from '@lib-components'
import { Spacer, Typography } from '@prometeoapi/afrodita-react-component-lib'

import { LIST_ALL_WIDGETS_QUERY_KEY } from 'src/config'
import { Widget } from 'src/domain'
import { useWidgetUseCases } from 'src/service-locator'
import { WidgetListItemProps } from 'src/presentation'

import styles from './widget-list.module.scss'

export const WidgetListItem: FC<WidgetListItemProps> = ({ widget, index }) => {
  const navigate = useNavigate()

  return (
    <div key={index} className={styles['list-item']}>
      {index >= 1 && <div className={styles['separator-h']} />}
      <div
        className={styles['list-item-container']}
        onClick={() => navigate(`${widget.id}`)}
      >
        <div className={styles['list-item-left']}>
          <Typography tag="p" variant="emphasized-1" typographyColor="gray-8">
            {widget.name}
          </Typography>
          <Spacer size={'1x'} />
          <Typography tag="p" variant="body-2" typographyColor="gray-6">
            {widget.description}
          </Typography>
        </div>
        <div className={styles['list-item-right']}>
          <Icon name={'navigate_next'} size={'xl'} />
        </div>
      </div>
    </div>
  )
}

export const WidgetList: FC = () => {
  const { t } = useTranslation(['widgets'])
  const { listAllWidgets } = useWidgetUseCases()

  const query = useQuery<Widget[], AxiosError>(
    LIST_ALL_WIDGETS_QUERY_KEY,
    listAllWidgets
  )

  return (
    <div className={styles['card']}>
      {query.isLoading && <Spinner />}

      {query.isError && (
        <Typography tag="p" variant="body-2" color="red-5">
          {t('widgets:listPage.error')}
        </Typography>
      )}

      {query.isSuccess &&
        (query.data.length ? (
          query.data.map((widget, index) => (
            <WidgetListItem key={widget.id} widget={widget} index={index} />
          ))
        ) : (
          <Typography tag="p" variant="body-2">
            {t('widgets:listPage.empty')}
          </Typography>
        ))}
    </div>
  )
}
