import { FC } from 'react'
import classNames from 'classnames'

import { AnchorProps } from '@lib-types'
import { getStyledElementProps } from '@lib-utils'
import { Typography } from '@lib-components'

import styles from './anchor.module.scss'

export const Anchor: FC<AnchorProps> = ({
  children,
  underlineOnHover = true,
  typographyColor = 'brand',
  typographyVariant = 'body-1',
  typographyTag = 'span',
  typographyProps = {},
  ...props
}) => {
  const styledProps = getStyledElementProps(
    props,
    classNames(styles['default'], {
      [styles['underline-on-hover']]: underlineOnHover,
    })
  )
  return (
    <a {...styledProps}>
      <Typography
        variant={typographyVariant}
        tag={typographyTag}
        typographyColor={typographyColor}
        {...typographyProps}
      >
        {children}
      </Typography>
    </a>
  )
}
