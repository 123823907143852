import React, { FC, useState } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useAuthStorageServiceAdapter } from 'src/adapters'
import { MARKETPLACE_HOST } from 'src/config'
import { useDownloadFile } from './hooks'
import { ButtonState } from './button-csv/button-csv.d'
import { ButtonCsv } from './button-csv'
import { TableProps } from '../../table/table-component.d'
import styles from './download-button.module.scss'

export const DownloadButton: FC<TableProps> = ({
  startDate,
  endDate,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  currency,
  amountMin,
  amountMax,
  search,
  statusFilter,
  payment,
  widgetIds,
}) => {
  const { t } = useTranslation(['payments'])
  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.Primary
  )
  const { getAccessToken } = useAuthStorageServiceAdapter()
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const preDownloading = () => setButtonState(ButtonState.Loading)
  const postDownloading = () => setButtonState(ButtonState.Primary)

  const onErrorDownloadFile = () => {
    setButtonState(ButtonState.Primary)
    setShowAlert(true)
    setTimeout(() => {
      setShowAlert(false)
    }, 3000)
  }

  const getFileName = () => {
    const current = new Date()
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`

    return date + '.csv'
  }

  const downloadCsvFile = () => {
    const token = getAccessToken()
    return axios.post(
      `${MARKETPLACE_HOST}/dashboard/api/payment-link-gateway/download?search=${search}&status=${statusFilter}&created_at__gte=${startDate}&created_at__lte=${endDate}&amount__gte=${amountMin}&amount__lte=${amountMax}&paid=${payment}`,
      { widget_ids: widgetIds },
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }

  const { ref, url, download, name } = useDownloadFile({
    apiDefinition: downloadCsvFile,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  })

  return (
    <div>
      {showAlert && (
        <span className={styles['download-error']}>
          {t('payments:paymentHistory.downloadError')}
        </span>
      )}
      <a href={url} download={name} className="hidden" ref={ref} />
      <ButtonCsv
        label={t('payments:paymentHistory.downloadLabel')}
        buttonState={buttonState}
        onClick={download}
      />
    </div>
  )
}
