import {
  WidgetPayment,
  PaymentLink,
  CreateLinkArgs,
  CreateLinkValidationErrors,
  CancelLinkArgs,
  WidgetIdLinkArgs,
  PaymentLinkTable,
  CreateBatchArgs,
} from 'src/domain'
import { PaymentsLinkService } from 'src/infrastructure'
import { removeEmptyErrors } from 'src/utils'
import { MapToPaymentLinks } from '../definition'

export const mapToWidgetPaymentLink = (
  dto: PaymentsLinkService.Widget
): WidgetPayment => ({
  id: dto.id,
  name: dto.name,
  description: dto.description,
})

export const mapToCreateLinkPayload = (
  args: CreateLinkArgs
): PaymentsLinkService.CreateLinkPayload => {
  return {
    product_id: args.product_id,
    concept: args.concept,
    amount: args.amount,
    currency: args.currency,
    external_id: args.external_id,
    expires_at: args.expires_at,
    email: args.email,
  }
}

export const mapToCreateBatchPayload = (
  args: CreateBatchArgs
): PaymentsLinkService.CreateBatchPayload => {
  return {
    file: args.file,
    format: args.format,
  }
}

export const mapToPaymentLink = (
  dto: PaymentsLinkService.PaymentLink
): PaymentLink => ({
  product_id: dto.product_id,
  concept: dto.concept,
  amount: dto.amount,
  currency: dto.concept,
  external_id: dto.external_id,
  expires_at: dto.expires_at,
  email: dto.email,
  url: dto.url,
})

//Get Payment Links table
export const mapToPaymentLinksArray = (
  data: PaymentsLinkService.PaymentLinkTableResponse
): PaymentLinkTable[] => data.results.map(mapToPaymentLinks)

export const mapToPaymentLinks: MapToPaymentLinks = (
  response: PaymentsLinkService.PaymentLinkObject
): PaymentLinkTable => ({
  id: response.id,
  productId: response.product_id,
  url: response.url,
  status: response.status,
  createdAt: response.created_at,
  expiresAt: response.expires_at,
  qrCode: response.url,
  email: response.email,
  reusable: response.reusable,
  intents: response.intents,
  paymentData: response.payment_data,
})

export const mapToWidgetPaymentLinkArray = (
  data: PaymentsLinkService.Widget[]
): WidgetPayment[] => data.map(mapToWidgetPaymentLink)

export const mapToCreatLinkValidationErrors = (
  responseErrors: PaymentsLinkService.CreateLinkResponseErrors
): CreateLinkValidationErrors => {
  const mappedErrors = {
    name: responseErrors.name,
    description: responseErrors.description,
  }
  return removeEmptyErrors<CreateLinkValidationErrors>(mappedErrors)
}

export const mapToCancelLinkPayload = (
  args: CancelLinkArgs
): PaymentsLinkService.CancelLinkPayload => {
  return {
    ids: args.ids,
  }
}

export const mapToWidgetIdLinkPayload = (
  args: WidgetIdLinkArgs
): PaymentsLinkService.WidgetIdLinkPayload => {
  return {
    widget_ids: args.widget_ids,
  }
}
