import * as enNs from './en'
import * as esNs from './es'

const en = { ...enNs }
const es = { ...esNs }

export const defaultNS = 'shared'
export const resources = {
  es,
  en,
} as const
