import { useCallback, useEffect, useState } from 'react'
import { Alert, AlertBody, AlertTitle, Spacer } from '@lib-components'

import {
  UseAlert,
  UseDangerMutationAlert,
  UseAPIDangerMutationAlert,
  UseSuccessMutationAlert,
} from 'src/presentation'

export const useAlert = ({ onAlertClose }: UseAlert) => {
  const [showAlert, setShowAlert] = useState<boolean>(false)

  const handleAlertClose = useCallback(() => {
    setShowAlert(false)
    onAlertClose && onAlertClose()
  }, [onAlertClose])

  return {
    showAlert,
    setShowAlert,
    handleAlertClose,
  }
}

export const useAPIDangerMutationAlert = ({
  isError,
  error,
  onAlertClose,
  spacerSize,
}: UseAPIDangerMutationAlert) => {
  const { showAlert, setShowAlert, handleAlertClose } = useAlert({
    onAlertClose,
  })

  const renderAlert = useCallback(() => {
    return (
      isError &&
      error &&
      showAlert && (
        <>
          <Alert variant={'danger'} onClose={handleAlertClose}>
            {error.messageForUser?.label && (
              <AlertTitle>{error.messageForUser.label}</AlertTitle>
            )}
            <AlertBody>{error.messageForUser.description}</AlertBody>
          </Alert>
          {spacerSize && <Spacer size={spacerSize} />}
        </>
      )
    )
  }, [spacerSize, error, handleAlertClose, isError, showAlert])

  return {
    showAlert,
    setShowAlert,
    renderAlert,
  }
}

export const useSuccessMutationAlert = ({
  title,
  body,
  isSuccess,
  onAlertClose,
  spacerSize = '4x',
}: UseSuccessMutationAlert) => {
  const { showAlert, setShowAlert, handleAlertClose } = useAlert({
    onAlertClose,
  })

  const renderAlert = useCallback(() => {
    return (
      isSuccess &&
      showAlert && (
        <>
          <Alert variant={'success'} onClose={handleAlertClose}>
            {title && <AlertTitle>{title}</AlertTitle>}
            {body && <AlertBody>{body}</AlertBody>}
          </Alert>
          {spacerSize && <Spacer size={spacerSize} />}
        </>
      )
    )
  }, [spacerSize, body, handleAlertClose, isSuccess, showAlert, title])

  return {
    showAlert,
    setShowAlert,
    renderAlert,
  }
}

export const useDangerMutationAlert = ({
  title,
  body,
  isError,
  onAlertClose,
  spacerSize = '4x',
}: UseDangerMutationAlert) => {
  const { showAlert, setShowAlert, handleAlertClose } = useAlert({
    onAlertClose,
  })

  const renderAlert = useCallback(() => {
    return (
      isError &&
      showAlert && (
        <>
          <Alert variant={'danger'} onClose={handleAlertClose}>
            {title && <AlertTitle>{title}</AlertTitle>}
            {body && <AlertBody>{body}</AlertBody>}
          </Alert>
          {spacerSize && <Spacer size={spacerSize} />}
        </>
      )
    )
  }, [spacerSize, body, handleAlertClose, isError, showAlert, title])

  useEffect(() => {
    if (isError) {
      setShowAlert(true)
    } else {
      setShowAlert(false)
    }
  }, [isError, setShowAlert])

  return {
    showAlert,
    setShowAlert,
    renderAlert,
  }
}
