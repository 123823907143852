import { useMutation } from 'react-query'

import { APIError, useMarketplaceLogoutOnQueryError } from 'src/adapters'

export function useMarketplaceFormMutation<
  TRequestFunctionArgs,
  TResponse,
  TServiceErrors,
  TAdapterErrors
>({
  requestFunction,
  errorMapper,
  onErrorCallback,
  onSuccessCallback,
}: {
  requestFunction: (data: TRequestFunctionArgs) => Promise<TResponse>
  errorMapper: (serviceErrors: TServiceErrors) => TAdapterErrors
  onErrorCallback?: (
    error: APIError<TServiceErrors, TAdapterErrors>,
    variables: TRequestFunctionArgs,
    context: unknown
  ) => void | Promise<unknown> | undefined
  onSuccessCallback?: (
    data: TResponse,
    variables: TRequestFunctionArgs,
    context: unknown
  ) => void | Promise<unknown> | undefined
}) {
  const { data, mutate, reset, error, isLoading, isError, isSuccess } =
    useMutation<
      TResponse,
      APIError<TServiceErrors, TAdapterErrors>,
      TRequestFunctionArgs
    >(requestFunction, {
      onError: (apiError, variables, context) => {
        apiError.setAdapterFormFieldErrors(errorMapper)
        onErrorCallback && onErrorCallback(apiError, variables, context)
      },
      onSuccess: onSuccessCallback,
    })

  useMarketplaceLogoutOnQueryError<APIError<TServiceErrors, TAdapterErrors>>({
    isError,
    error,
  })

  return {
    data,
    mutate,
    reset,
    error,
    isLoading,
    isError,
    isSuccess,
  }
}
