export default {
  header: {
    usage: {
      title: 'Historial',
      subtitle:
        'Esta sección te permite contabilizar en detalle la cantidad de requests a través de Prometeo.',
    },
  },
  pageCard: {
    titleCard: 'Vista General del Historial',
    buttonCard: 'Filtrar',
  },
  table: {
    date: 'FECHA',
    requests: 'REQUESTS EXITOSAS',
    error: 'ERRORES',
    users: 'Usuarios: ',
  },
}
