import {
  MapToResetPasswordPayload,
  MapToResetPasswordConfirmPayload,
} from 'src/adapters'

export const mapToResetPasswordPayload: MapToResetPasswordPayload = (args) => ({
  email: args.email,
})

export const mapToResetPasswordConfirmPayload: MapToResetPasswordConfirmPayload =
  (args) => ({
    uuid64: args.uuid64,
    token: args.token,
    newPassword: args.newPassword,
    passwordConfirm: args.confirmPassword,
  })
