export enum ButtonState {
  Primary = 'Primary',
  Loading = 'Loading',
}

export interface ButtonCsvProps {
  readonly buttonState: ButtonState
  readonly onClick: () => void
  readonly label: string
}
