import { FC, useRef } from 'react'
import styles from './modal.module.scss'
import {
  Spacer,
  Typography,
  Button,
  InputCaption,
} from '@prometeoapi/afrodita-react-component-lib'

type ModalType = {
  isOpen: boolean
  onClickSubmit: () => void
  onClickCancel: () => void
  title: string
  message: string
  cancel: string
  submit: string
  messageError: string
}
export const ConfirmationModal: FC<ModalType> = ({
  title,
  message,
  cancel,
  submit,
  isOpen,
  onClickSubmit,
  onClickCancel,
  messageError,
}) => {
  const modalRef = useRef<HTMLDialogElement>(null)

  return (
    <dialog open={isOpen} ref={modalRef} className={styles['modal-container']}>
      <div>
        <Typography tag="h3" variant="headline-3" typographyColor="gray-8">
          {title}
        </Typography>

        <Spacer />

        <Typography tag="p" variant="body-1" typographyColor="gray-6">
          {message}
        </Typography>

        <Spacer size={'8x'} />

        {messageError ? (
          <div>
            <InputCaption
              className={styles['error-text']}
              error
              id="input-caption"
            >
              {messageError}
            </InputCaption>
          </div>
        ) : (
          ''
        )}

        <div className={styles['modal-button-row']}>
          <>
            <Typography
              className={'clickable'}
              variant="body-2"
              typographyColor="red-6"
              onClick={onClickCancel}
            >
              {cancel}
            </Typography>
            <Button size="md" variant="primary-light" onClick={onClickSubmit}>
              {submit}
            </Button>
          </>
        </div>
      </div>
    </dialog>
  )
}
