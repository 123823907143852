import { useParams } from 'react-router-dom'

export const useNonNullableParams = (...paramNames: string[]) => {
  const result: Record<string, string> = {}
  const params = useParams()
  paramNames.forEach((paramName) => {
    if (!params[paramName]) {
      throw new Error('Dont try to get param if it nullable')
    }
    result[paramName] = params[paramName] as string
  })
  return result
}

export const capitalizeWords = (arr: string) => {
  return arr
    .split(' ')
    .map((element) => {
      return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase()
    })
    .join(' ')
}
