import { UsePaymentsLinkUseCases } from 'src/domain'

type InitProps = {
  usePaymentsLinkUseCases: UsePaymentsLinkUseCases
}

export let usePaymentsLinkUseCases: UsePaymentsLinkUseCases

export const init = (props: InitProps) => {
  usePaymentsLinkUseCases = props.usePaymentsLinkUseCases
}
