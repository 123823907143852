import { MarketplaceErrorListFields } from 'src/infrastructure'
// eslint-disable-next-line
import { number } from 'yup'

export namespace PaymentsLinkService {
  export type Widget = {
    id: number
    name: string
    description: string
  }

  export type PaymentLink = {
    product_id: string
    concept: string
    amount: number
    currency: string
    external_id: string
    expires_at: string
    email: string
    url: string
  }

  export type BatchCreate = {
    file: any
    format: string
  }

  export type BatchResponse = {
    id: string
    expires_at: string
    qr_code: string
    url: string
  }

  export type ErrorBatchResponse = {
    error_details: object[]
  }

  export type CancelLinkArgs = {
    ids: string[]
  }

  export type CancelLink = {
    payment_links_canceled: number
  }

  export type WidgetIdLinkArgs = {
    widget_ids: string[]
  }

  export type PaymentData = {
    amount: number
    currency: string
    concept: string
    externalId: string
  }

  export type PaymentIntent = {
    intendId: string
  }

  export type PaymentLinkObject = {
    id: string
    product_id: string
    url: string
    status: string
    created_at: string
    expires_at: string
    url: string
    email: string
    reusable: boolean
    intents: PaymentIntent[]
    payment_data: PaymentData
  }

  export type PaymentLinkTableResponse = {
    results: PaymentLinkObject[]
    next: number
    previous: number
    count: number
  }

  export type PaymentBatch = {
    id: string
    status: string
    created_at: string
    url: string
  }

  export type PaymentBatchTableResponse = {
    results: PaymentBatch[]
    next: number
    previous: number
    count: number
  }

  export type WidgetIdLinkPayload = WidgetIdLinkArgs

  export type GetWidgetResponse = Widget

  export type ListAllWidgetsResponse = Widget[]

  export type CreateLinkPayload = Pick<PaymentLink>

  export type CreateLinkErrorResponseField = keyof CreateLinkPayload

  export type CreateLinkResponseErrors =
    MarketplaceErrorListFields<CreateLinkErrorResponseField>

  export type CreateLinkResponse = PaymentLink

  export type CancelLinkPayload = CancelLinkArgs

  export type CancelLinkResponse = CancelLink

  export type CreateBatchPayload = BatchCreate

  export type CreateBatchResponse = PaymentLink[] | ErrorBatchResponse[]

  export type DownloadResponse = string | ErrorBatchResponse[] | JSON
}
