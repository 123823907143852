import {
  UseUsageServiceAdapter,
  mapToUsage,
  mapToUsageGraph,
} from 'src/adapters'
import { useUsageService, useUsageGraphService } from 'src/infrastructure'

export const useUsageServiceAdapter: UseUsageServiceAdapter = () => {
  const usageService = useUsageService()
  const usageGraphService = useUsageGraphService()

  const getUsage = async (year: number, month: number) => {
    const usageResponse = await usageService.getUsage({ year, month })
    return mapToUsage(usageResponse.data)
  }
  const getUsageGraphData = async (year: number, month: number) => {
    const usageGraphResponse = await usageGraphService.getUsageGraph({
      year,
      month,
    })
    return mapToUsageGraph(usageGraphResponse.data)
  }

  return { getUsage, getUsageGraphData }
}
