import { UseWidgetUseCases } from 'src/domain'

type InitProps = {
  useWidgetUseCases: UseWidgetUseCases
}

export let useWidgetUseCases: UseWidgetUseCases

export const init = (props: InitProps) => {
  useWidgetUseCases = props.useWidgetUseCases
}
