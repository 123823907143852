import {
  MapToRegisterAccountPayload,
  MapToRegisterAccountConfirmPayload,
} from 'src/adapters'
import { Account, RegisterAccountConfirmResponse } from 'src/infrastructure'

export const mapToRegisterAccountPayload: MapToRegisterAccountPayload = (
  args
) => ({
  first_name: args.first_name,
  last_name: args.last_name,
  email: args.email,
  password: args.password,
  terms_conditions: args.terms_conditions,
})

export const mapToAccount = (dto: Account): Account => ({
  first_name: dto.first_name,
  last_name: dto.last_name,
  email: dto.email,
  password: dto.password,
  terms_conditions: dto.terms_conditions,
})

export const mapToRegisterAccountConfirmPayload: MapToRegisterAccountConfirmPayload =
  (args) => ({
    uuid: args.uuid,
    token: args.token,
  })

export const mapToAccountConfirm = (
  dto: RegisterAccountConfirmResponse
): RegisterAccountConfirmResponse => ({
  message: dto.message,
  success: dto.success,
})
