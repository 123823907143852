export enum StageOptions {
  preproduction = 'PP',
  production = 'PR',
  sandbox = 'SA',
}

export enum StatusOptions {
  approved = 'ap',
  blank = 'bl',
  pending = 'pe',
}
