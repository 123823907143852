import { GetConsumptionResponse } from 'src/infrastructure'
import { useMarketplaceClient } from 'src/adapters'

export const useConsumptionService = () => {
  const { privateClient } = useMarketplaceClient()

  return {
    getConsumption: () =>
      privateClient.get<GetConsumptionResponse>(
        'api/dashboard/user/consumption'
      ),
  }
}
