import {
  TypographyTag,
  TypographyVariant,
  TypographyColor,
  TypographyVariantMappingType,
} from '@lib-types'
import { puiColors } from '@lib-utils'

export const defaultVariantMapping: TypographyVariantMappingType = {
  'display-1': 'h1',
  'display-2': 'h2',
  'display-3': 'h3',
  'featured-1': 'h1',
  'featured-2': 'h2',
  'featured-3': 'h3',
  'headline-1': 'h1',
  'headline-2': 'h2',
  'headline-3': 'h3',
  'strong-1': 'p',
  'strong-2': 'p',
  'emphasized-1': 'p',
  'emphasized-2': 'p',
  'body-1': 'p',
  'body-2': 'p',
  'small-1': 'span',
  'small-2': 'span',
  'code-1': 'span',
}

export const isParagraph = (tag: TypographyTag): boolean => {
  return tag === 'p'
}

export const isSpan = (tag: TypographyTag): boolean => {
  return tag === 'span'
}
export const isLabel = (tag: TypographyTag): boolean => {
  return tag === 'label'
}

export const validVariants: TypographyVariant[] = [
  'display-1',
  'display-2',
  'display-3',
  'featured-1',
  'featured-2',
  'featured-3',
  'headline-1',
  'headline-2',
  'headline-3',
  'strong-1',
  'strong-2',
  'emphasized-1',
  'emphasized-2',
  'body-1',
  'body-2',
  'small-1',
  'small-2',
  'code-1',
]

export const validColors: TypographyColor[] = [
  ...puiColors,
  'typography-primary',
  'typography-disabled',
  'typography-tertiary',
  'typography-secondary',
]
