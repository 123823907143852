import { useTranslation } from 'react-i18next'

import { WarningAlert, useHomeQuery } from 'src/presentation'

export const Alert = () => {
  const { t } = useTranslation(['home'])
  const { data } = useHomeQuery()

  if (!data?.daysResetPassword) {
    return null
  }

  return (
    <>
      {data && (
        <WarningAlert>
          {t('home:header.alert', { days: 90 - data.daysResetPassword })}
        </WarningAlert>
      )}
    </>
  )
}
