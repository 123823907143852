import { useEffect, useRef, useState } from 'react'

export function useExternalScript({ url }: any) {
  const [loaded, setLoaded] = useState(false)
  const isMounted = useRef<boolean>(true)

  useEffect(() => {
    function loadScript() {
      const src = url
      const script = document.createElement('script')

      const onError = () => {
        setLoaded(false)
        script.removeEventListener('error', onError)
        script.removeEventListener('load', onLoad)
      }

      const onLoad = () => {
        setLoaded(true)
        script.removeEventListener('error', onError)
        script.removeEventListener('load', onLoad)
      }

      script.src = src
      script.async = true
      script.defer = true
      script.setAttribute('data-testid', 'hs-script-loader')
      script.setAttribute('id', 'hs-script-loader')

      document.body.appendChild(script)

      script.addEventListener('load', onLoad)

      script.addEventListener('error', onError)
    }

    if (isMounted.current) {
      loadScript()
    }

    return () => {
      isMounted.current = false
    }
  }, [url])

  return loaded
}
