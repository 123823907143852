import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { format } from 'date-fns'
import { ColumnDef } from '@tanstack/react-table'
import { usePaymentsUseCases } from 'src/service-locator'
import { capitalizeWords } from 'src/utils'
import { DownloadButton } from './download-button'
import { Table } from './table'
import DatePicker, { registerLocale } from 'react-datepicker'
import {
  Card,
  Typography,
  IconMoon,
} from '@prometeoapi/afrodita-react-component-lib'
import './react-datepicker.css'
import styles from './payment-table.module.scss'
import { es, enUS } from 'date-fns/locale'
// eslint-disable-next-line
import { TableComponent } from 'src/components/table'
import { useAuthWithRoles } from 'src/infrastructure'
import { ACTION_ROLES } from 'src/config'

export const PaymentTable: FC = () => {
  const { t } = useTranslation(['payments'])
  const navigate = useNavigate()

  const { hasPermission: canExportData } = useAuthWithRoles(
    ACTION_ROLES['payments_export_action']
  )
  const { getPaymentsFilters } = usePaymentsUseCases()
  const [searchParams] = useSearchParams()

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [searchDownload, setSearchDownload] = useState('')

  const [formatStartDate, serFormatStartDate] = useState(
    searchParams.get('date-from') || ''
  )
  const [formatEndDate, serFormatEndDate] = useState(
    searchParams.get('date-to') || ''
  )
  const [filterStatus, setFilterStatus] = useState(
    searchParams.get('status-filter') || ''
  )
  const [min, setMin] = useState(searchParams.get('min') || '')
  const [max, setMax] = useState(searchParams.get('max') || '')
  const [isActiveConfirmed, setIsActiveConfirmed] = useState(
    filterStatus === 'c'
  )
  const [isActiveError, setIsActiveError] = useState(filterStatus === '0')
  const [isActiveRejected, setIsActiveRejected] = useState(filterStatus === 'r')

  const [filterPage, setFilterPage] = useState('')

  registerLocale('es', es)
  registerLocale('enUS', enUS)

  const onClickStatus = (e: any) => {
    const typeStatus = e.target?.value
    setFilterStatus(typeStatus)
    setActiveButtons(typeStatus)
  }

  const setActiveButtons = (typeStatus: string) => {
    setFilterPage(typeStatus)
    if (typeStatus === 'c') {
      setIsActiveConfirmed(true)
      setIsActiveError(false)
      setIsActiveRejected(false)
    } else if (typeStatus === 'o') {
      setIsActiveConfirmed(false)
      setIsActiveError(true)
      setIsActiveRejected(false)
    } else if (typeStatus === 'r') {
      setIsActiveConfirmed(false)
      setIsActiveError(false)
      setIsActiveRejected(true)
    }
  }

  const deleteFilters = () => {
    setFilterPage('delete')
    setFilterStatus('')
    setMin('')
    setMax('')
    setStartDate(null)
    setEndDate(null)
    serFormatStartDate('')
    serFormatEndDate('')
    setIsActiveConfirmed(false)
    setIsActiveError(false)
    setIsActiveRejected(false)
    navigate('/payments')
  }

  const onChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    const dateStart = format(new Date(start), 'yyyy-MM-dd')
    const dateEnd = format(new Date(end), 'yyyy-MM-dd')
    serFormatStartDate(dateStart)
    serFormatEndDate(dateEnd)
    setFilterPage('date')
  }

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: t(
          'payments:paymentHistory.tableHeaders.createdAt'
        ).toUpperCase(),
        accessorKey: 'created_at',
        enableSorting: true,
        cell: (c: any) => {
          return format(new Date(c.getValue()), 'dd/MM/yyyy')
        },
      },
      {
        header: t(
          'payments:paymentHistory.tableHeaders.requestId'
        ).toUpperCase(),
        accessorKey: 'request_id',
        enableSorting: false,
        cell: (c: any) => {
          return (
            <code className={styles['tooltip']}>
              {c.getValue().slice(0, 6)}...{c.getValue().slice(-4)}
              <br></br>
              <code className={styles['tooltiptext']}>{c.getValue()}</code>
            </code>
          )
        },
      },
      {
        header: t(
          'payments:paymentHistory.tableHeaders.operationId'
        ).toUpperCase(),
        accessorKey: 'operation_id',
        enableSorting: false,
        cell: (c: any) => {
          return c.getValue() ? `#${c.getValue()}` : ''
        },
      },
      {
        header: t(
          'payments:paymentHistory.tableHeaders.externalId'
        ).toUpperCase(),
        accessorKey: 'external_id',
        enableSorting: false,
        cell: (c: any) => {
          return c.getValue() ? `${c.getValue()}` : ''
        },
      },
      {
        header: t(
          'payments:paymentHistory.tableHeaders.originAccount'
        ).toUpperCase(),
        accessorKey: 'origin_account',
        enableSorting: true,
        cell: (c: any) => {
          const bank = c.row.original.origin_bank_code ?? ''
          return `${bank.toUpperCase()} ${c.row.original.origin_account}`
        },
      },
      {
        header: t(
          'payments:paymentHistory.tableHeaders.destinationAccount'
        ).toUpperCase(),
        accessorKey: 'destination_account',
        enableSorting: true,
        cell: (c: any) => {
          const bank = c.row.original.destination_bank_code ?? ''
          return `${bank.toUpperCase()} ${c.row.original.destination_account}`
        },
      },
      {
        header: t('payments:paymentHistory.tableHeaders.concept').toUpperCase(),
        accessorKey: 'concept',
        enableSorting: true,
        cell: (c: any) => {
          const cut = 35
          const value = c.getValue().slice(0, cut)
          return c.getValue().length > cut && !c.getValue().match(/\s+/)
            ? `${value}...`
            : c.getValue()
        },
      },
      {
        header: t('payments:paymentHistory.tableHeaders.status').toUpperCase(),
        accessorKey: 'status',
        enableSorting: false,
        cell: (c: any) => {
          if (c.getValue() === 'CONFIRMED') {
            return (
              <span className={styles['confirmed']}>
                {capitalizeWords(c.getValue() || '')}
              </span>
            )
          } else if (c.getValue() === 'ERROR') {
            return (
              <span className={styles['error']}>
                {capitalizeWords(c.getValue() || '')}
              </span>
            )
          } else if (c.getValue() === 'REJECTED') {
            return (
              <span className={styles['rejected']}>
                {capitalizeWords(c.getValue() || '')}
              </span>
            )
          } else {
            return capitalizeWords(c.getValue() || '')
          }
        },
      },
      {
        header: t(
          'payments:paymentHistory.tableHeaders.currency'
        ).toUpperCase(),
        accessorKey: 'currency',
        enableSorting: true,
      },
      {
        header: t('payments:paymentHistory.tableHeaders.amount').toUpperCase(),
        accessorKey: 'amount',
        enableSorting: true,
        cell: (c: any) => {
          const amount = new Intl.NumberFormat('en', {
            minimumFractionDigits: 2,
          }).format(c.getValue())
          return <span className={styles['center']}>{amount}</span>
        },
      },
    ],
    [t]
  )
  const checkAmountKey = (key: string) => {
    return (
      (key >= '0' && key <= '9') ||
      ['.', 'ArrowLeft', 'ArrowRight', 'Delete', 'Backspace'].includes(key)
    )
  }
  return (
    <Card>
      <div className={styles['card-header']}>
        <Typography tag="h2" variant="strong-1">
          {t('payments:paymentHistory.title')}
        </Typography>
        {canExportData && (
          <DownloadButton
            startDate={formatStartDate}
            endDate={formatEndDate}
            statusFilter={filterStatus}
            amountMin={min}
            amountMax={max}
            search={searchDownload}
            currency={''}
          />
        )}
      </div>
      <div className={styles['filter-header']}>
        <div className={styles['filter-section-border']}>
          <span className={styles['filter-title']}>
            {t('payments:paymentHistory.filters.status')}
          </span>
          <div className={styles['filter-button']}>
            <button
              value="c"
              className={`${styles.confirmed} ${
                isActiveConfirmed ? styles.selected : ''
              }`}
              onClick={onClickStatus}
            >
              {t('payments:paymentHistory.filters.buttons.confirmed')}
            </button>
            <button
              value="o"
              className={`${styles.error} ${
                isActiveError ? styles.selected : ''
              }`}
              onClick={onClickStatus}
            >
              {t('payments:paymentHistory.filters.buttons.error')}
            </button>
            <button
              value="r"
              className={`${styles.rejected} ${
                isActiveRejected ? styles.selected : ''
              }`}
              onClick={onClickStatus}
            >
              {t('payments:paymentHistory.filters.buttons.rejected')}
            </button>
          </div>
        </div>
        <div className={styles['filter-section-border']}>
          <span className={styles['filter-title']}>
            {t('payments:paymentHistory.filters.date')}
          </span>
          <div className={styles['calendar-section']}>
            <IconMoon
              className={styles['icon']}
              name="icon-calendar-today"
              size="sm"
            ></IconMoon>
            <DatePicker
              dateFormat="dd/MM/yy"
              selected={startDate}
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={onChange}
              locale="es"
              className={styles['calendar-input']}
              disabledKeyboardNavigation
            />
          </div>
        </div>
        <div className={styles['filter-section-border']}>
          <span className={styles['filter-title']}>
            {t('payments:paymentHistory.filters.amount')}
          </span>
          <div className={styles['filter-input']}>
            <input
              name="min"
              type="text"
              placeholder={t('payments:paymentHistory.filters.min')}
              value={min}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setMin(e.currentTarget.value)
                setFilterPage('min')
              }}
              onKeyDown={(e) => {
                if (checkAmountKey(e.key) === false) {
                  e.preventDefault()
                }
              }}
            ></input>
            <span className={styles['filter-hyphen']}>{'-'}</span>
            <input
              name="max"
              type="text"
              placeholder={t('payments:paymentHistory.filters.max')}
              value={max}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setMax(e.currentTarget.value)
                setFilterPage('max')
              }}
              onKeyDown={(e) => {
                if (checkAmountKey(e.key) === false) {
                  e.preventDefault()
                }
              }}
            ></input>
          </div>
        </div>
        <div className={styles['filter-section']}>
          <div className={styles['filter-button']}>
            <button className={styles['delete-filter']} onClick={deleteFilters}>
              {t('payments:paymentHistory.filters.buttons.delete')}
            </button>
          </div>
        </div>
      </div>

      <Table
        columns={columns}
        query={getPaymentsFilters}
        startDate={formatStartDate}
        endDate={formatEndDate}
        statusFilter={filterStatus}
        amountMin={min}
        amountMax={max}
        currency={''}
        filterPage={filterPage}
        setSearchDownload={setSearchDownload}
      />
    </Card>
  )
}
