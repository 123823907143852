import {
  DetailedHTMLProps,
  FC,
  LiHTMLAttributes,
  MouseEventHandler,
} from 'react'
import { callAll } from '@lib-utils'
import { useAuthWithRoles } from 'src/infrastructure'

export const Li: FC<
  DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> & {
    checkRoles: boolean
    allowedRoles: Array<any>
    onLiClick: MouseEventHandler<HTMLLIElement> | undefined
  }
> = ({ onClick, onLiClick, checkRoles, allowedRoles, ...props }) => {
  const handleClick = callAll(onLiClick, onClick)
  const { hasPermission } = useAuthWithRoles(allowedRoles)
  if (!hasPermission && checkRoles) {
    return null
  }
  return <li onClick={handleClick} {...props} />
}
