import { FC } from 'react'
import classNames from 'classnames'

import {
  JSXTypographyHeadingTag,
  TypographyProps,
  HTMLHeadingProps,
  HTMLParagraphProps,
  HTMLLabelProps,
} from '@lib-types'
import { getStyledElementProps } from '@lib-utils'

import { defaultVariantMapping, isSpan, isParagraph, isLabel } from './helpers'

import styles from './typography.module.scss'

export const Typography: FC<TypographyProps> = ({
  tag,
  variant = 'body-1',
  typographyColor = 'black',
  withFontFamily = true,
  error = false,
  ...props
}) => {
  const styledProps = getStyledElementProps(
    props,
    classNames(
      styles['default'],
      styles[variant],
      styles[`color-${typographyColor}`],
      {
        [styles[`${variant}-font-family`]]: withFontFamily,
        [styles['error']]: error,
      }
    )
  )

  const selectedTag = tag ?? defaultVariantMapping[variant]

  if (isSpan(selectedTag)) {
    return <span {...styledProps} />
  }

  if (isLabel(selectedTag)) {
    const labelProps = styledProps as HTMLLabelProps
    return <label {...labelProps} />
  }

  if (isParagraph(selectedTag)) {
    const paragraphProps = styledProps as HTMLParagraphProps
    return <p {...paragraphProps} />
  }

  const Heading = selectedTag as keyof JSXTypographyHeadingTag
  const headingProps = styledProps as HTMLHeadingProps

  return <Heading {...headingProps} />
}
