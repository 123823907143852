import {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  ChangeEvent,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { BlockPicker, ColorResult } from 'react-color'
import {
  Spinner,
  Typography,
  Spacer,
  Button,
  TextField,
  FileField,
} from '@prometeoapi/afrodita-react-component-lib'

import * as settings from 'src/config/settings'
import { useWidgetUseCases } from 'src/service-locator'
import { Home, UpdateWidgetArgs, Widget } from 'src/domain'
import { GET_WIDGET_QUERY_KEY, WIDGET_CLOUDFRONT_URL } from 'src/config'
import {
  useDangerMutationAlert,
  useHomeQuery,
  useHomeQueryDataHelpers,
  useWidgetQuery,
} from 'src/presentation'

import styles from './widget-appearance-page.module.scss'

type ConfigWidgetModulePageProps = {
  widget: Widget
}

interface IFormInputs {
  brandName: string
  brandPrimaryColor: string
}

const postIframeMessage = (id: string, message: Record<string, any>) => {
  const iframe = window.document.querySelector(
    '#widget-preview-iframe'
  ) as HTMLIFrameElement
  iframe.contentWindow &&
    iframe.contentWindow.postMessage({ id, ...message }, WIDGET_CLOUDFRONT_URL)
}

export const WidgetAppearancePage: FC = () => {
  const { t } = useTranslation(['widgets', 'shared'])
  const { updateWidget } = useWidgetUseCases()
  const query = useWidgetQuery()

  const validationSchema = Yup.object({
    brandName: Yup.string().nullable(),
    brandPrimaryColor: Yup.string().nullable(),
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      brandName: query.data?.brandName ?? '',
      brandPrimaryColor: query.data?.brandPrimaryColor ?? '',
      brandLogoUrl: query.data?.brandLogo ?? '',
    },
    resolver: yupResolver(validationSchema),
  })

  const { data, isSuccess } = useHomeQuery()
  const { isInProduction: isProd } = useHomeQueryDataHelpers()
  const shouldRedirect =
    isSuccess &&
    !isProd(data as Home) &&
    !settings.REACT_APP_WIDGET_SHOW_IN_MENU

  const [widget, setWidget] = useState<Widget>()
  // eslint-disable-next-line
  const [brandName, setBrandName] = useState('')
  const [brandNameUrl, setBrandNameUrl] = useState('')
  const [selectedColor, setSelectedColor] = useState('')
  const [selectedColorUrl, setSelectedColorUrl] = useState('')
  const [selectedLogo, setSelectedLogo] = useState<File>()
  const [logoUrl, setLogoUrl] = useState('')
  const [preview, setPreview] = useState<string>('')
  const [errorMessages, setErrorMessage] = useState<string>('')

  const previewRef = useRef<HTMLIFrameElement>(null)
  const isInitialMount = useRef(true)

  const navigate = useNavigate()

  const { state } = useLocation()

  const { widget: widgetHistory } = state as ConfigWidgetModulePageProps
  const { id: widgetId, publicKey } = widgetHistory

  const queryClient = useQueryClient()
  const mutation = useMutation<Widget, AxiosError, UpdateWidgetArgs>(
    updateWidget,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(GET_WIDGET_QUERY_KEY)
        navigate(`/widgets/${widgetId}`)
      },
    }
  )

  const { renderAlert: renderErrorAlert, showAlert: showErrorAlert } =
    useDangerMutationAlert({
      isError: mutation.isError,
      body: t('widgets:modulePage.errors.generic'),
      spacerSize: '6x',
    })

  const checkFileSize = (file: File): boolean => {
    const FILE_SIZE = 500 * 1024
    const checkSize = file.size < FILE_SIZE
    if (!checkSize) {
      const messageSize = `${t(
        'widgets:appearancePage.forms.updateWidget.fields.errors.brandLogoUrl.bySize'
      )}`
      setErrorMessage(messageSize)
      return false
    }
    return true
  }

  const checkFileFormat = (file: File): boolean => {
    const SUPPORTED_FORMATS = [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/webp',
    ]
    const checkType = SUPPORTED_FORMATS.includes(file.type)
    if (!checkType) {
      const messageType = `${t(
        'widgets:appearancePage.forms.updateWidget.fields.errors.brandLogoUrl.byType'
      )}`
      setErrorMessage(messageType)
      return false
    }
    return true
  }

  const onChangeLogoFileInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      const checkSize = checkFileSize(file)
      const checkType = checkFileFormat(file)

      if (checkType && checkSize) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          postIframeMessage('preview-image', { blob: reader.result })
          setPreview(reader.result as string)
          setSelectedLogo(file)
          setErrorMessage('')
        }
      }
    }
  }

  const getWidgetUrl = useCallback(() => {
    let url = `${WIDGET_CLOUDFRONT_URL}?isPreview=true&widgetKey=${publicKey}`

    if (brandNameUrl) {
      const encodedComponent = encodeURIComponent(`${brandNameUrl}`)
      url = `${url}&brandName=${encodedComponent}`
    }

    if (selectedColorUrl) {
      const encodedComponent = encodeURIComponent(`${selectedColorUrl}`)
      url = `${url}&primaryColor=${encodedComponent}`
    }

    if (logoUrl) {
      const encodedComponent = encodeURIComponent(`${logoUrl}`)
      url = `${url}&logoUrl=${encodedComponent}`
    }

    url = `${url}&concept="Concept"`

    return url
  }, [brandNameUrl, logoUrl, selectedColorUrl, publicKey])

  useEffect(() => {
    if (query.data) {
      setWidget(query.data)
      setBrandNameUrl(query.data?.brandName ?? '')
      setSelectedColorUrl(query.data?.brandPrimaryColor ?? '')
      setLogoUrl(query.data?.brandLogo ?? '')
    }
  }, [query.data])

  useEffect(() => {
    if (!isInitialMount.current) {
      const url = getWidgetUrl()
      if (previewRef.current) {
        previewRef.current.src = url
      }
    }
  }, [brandName, selectedColor, logoUrl, getWidgetUrl])

  if (shouldRedirect) {
    return <Navigate to="/" replace />
  }

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    const newData: UpdateWidgetArgs = {
      id: widgetId,
    }
    newData['brandName'] = data.brandName
    newData['brandPrimaryColor'] = selectedColor
    if (selectedLogo) {
      newData['brandLogo'] = selectedLogo
    }
    mutation.mutate(newData)
  }

  return (
    <div className={styles['container-page']}>
      <Typography tag="p" variant="body-1" typographyColor="gray-6">
        <a href="/widgets" style={{ color: '#2f3a44' }}>
          Widgets
        </a>
        {` > `}
        <a href={`/widgets/${widgetId}`} style={{ color: '#2f3a44' }}>
          {widget?.name}
        </a>
        {` > ${t('widgets:appearancePage.breadcrumb')}`}
      </Typography>
      <Spacer variant={'vertical'} size={'6x'} />
      <div className={styles['container-page-content']}>
        <div className={styles['container-page-left']}>
          <Typography tag="h1" variant="headline-1" typographyColor="gray-8">
            {t('widgets:appearancePage.title')}
          </Typography>
          <Typography tag="p" variant="body-1" typographyColor="gray-6">
            {t('widgets:appearancePage.description')}
          </Typography>
          <Spacer variant={'vertical'} size={'6x'} />
          <form onSubmit={handleSubmit(onSubmit)} className={styles['form']}>
            <div className={styles['card']}>
              <Typography tag="p" variant="strong-1" typographyColor="gray-4">
                {t('widgets:appearancePage.sections.branding')}
              </Typography>
              <div>
                <Controller
                  name="brandName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type={'text'}
                      label={t(
                        'widgets:appearancePage.forms.updateWidget.fields.brandName.label'
                      )}
                      placeholder={t(
                        'widgets:appearancePage.forms.updateWidget.fields.brandName.placeholder'
                      )}
                      fullWidth
                      caption={errors.brandName ? errors.brandName : null}
                      {...field}
                    />
                  )}
                />
                <Spacer variant={'horizontal'} size={'4x'} />
                <Controller
                  name="brandLogoUrl"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FileField
                        logoUrl={preview || logoUrl}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          field.onChange(onChangeLogoFileInput(e))
                        }}
                        label={t(
                          'widgets:appearancePage.forms.updateWidget.fields.brandLogo.label'
                        )}
                        buttonText={t(
                          'widgets:appearancePage.forms.updateWidget.fields.brandLogo.buttonText'
                        )}
                        defaultCaption={t(
                          'widgets:appearancePage.forms.updateWidget.fields.brandLogo.caption'
                        )}
                        mainDefaultText={t(
                          'widgets:appearancePage.forms.updateWidget.fields.brandLogo.defaultText'
                        )}
                      />
                    )
                  }}
                />
                <Typography tag="p" variant="body-1" typographyColor="red-5">
                  {errorMessages ? errorMessages : null}
                </Typography>
              </div>
              <div className={styles['separator-h']} />
              <Typography tag="p" variant="strong-1" typographyColor="gray-4">
                {t('widgets:appearancePage.sections.colors')}
              </Typography>
              <div>
                <Typography tag="p" variant="body-2" typographyColor="gray-8">
                  {t(
                    'widgets:appearancePage.forms.updateWidget.fields.brandPrimaryColor.label'
                  )}
                </Typography>
                <Spacer variant={'vertical'} size={'4x'} />
                <BlockPicker
                  color={selectedColor || selectedColorUrl}
                  onChangeComplete={(color: ColorResult) => {
                    postIframeMessage('preview-color', {
                      color: color.hex,
                    })
                    setSelectedColor(color.hex)
                  }}
                />
              </div>
            </div>
            <Spacer variant={'vertical'} size={'8x'} />

            {showErrorAlert && renderErrorAlert()}

            <div className={styles['form-button-row']}>
              {mutation.isLoading ? (
                <Button type={'button'} className={'not-allowed'}>
                  <Spinner size={'sm'} color={'white'} />
                </Button>
              ) : (
                <>
                  <Typography
                    tag="p"
                    variant="body-2"
                    typographyColor="red-5"
                    className={styles['pointer']}
                    onClick={() => navigate(`/widgets/${widgetId}`)}
                  >
                    {t(
                      'widgets:appearancePage.forms.updateWidget.buttons.cancel.label'
                    )}
                  </Typography>
                  <Button
                    type={'submit'}
                    disabled={mutation.isLoading}
                    style={{ marginLeft: '1rem' }}
                  >
                    <Typography
                      tag="p"
                      variant="body-2"
                      typographyColor="white"
                    >
                      {t(
                        'widgets:appearancePage.forms.updateWidget.buttons.submit.label'
                      )}
                    </Typography>
                  </Button>
                </>
              )}
            </div>
          </form>
        </div>
        <div className={styles['container-page-right']}>
          <div className={styles['preview-container']}>
            <Typography tag="h3" variant="headline-3" typographyColor="gray-8">
              {t('widgets:configPage.preview')}
            </Typography>
            <Spacer variant={'vertical'} size={'6x'} />
            <iframe
              id={'widget-preview-iframe'}
              width="340"
              height="568"
              style={{ border: 0 }}
              frameBorder="0px"
              loading="lazy"
              scrolling="no"
              allowFullScreen
              src={getWidgetUrl()}
              sandbox="allow-scripts allow-same-origin"
              ref={previewRef}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
