import { UserSettingsUseCases } from 'src/domain'
import { useUserSettingsService } from 'src/infrastructure'
import {
  mapToChangePasswordPayload,
  mapToUserSettings,
  mapToUpdateUserSettingsPayload,
  UseUserSettingsAdapter,
  mapToUpdateExpiredPasswordPayload,
} from 'src/adapters'

export const useUserSettingsAdapter: UseUserSettingsAdapter = () => {
  const userSettingService = useUserSettingsService()

  const getUserSettings: UserSettingsUseCases['getUserSettings'] = async () => {
    const userSettingsResponse = await userSettingService.getUserSettings()
    return mapToUserSettings(userSettingsResponse.data)
  }

  const updateUserSettings: UserSettingsUseCases['updateUserSettings'] = async (
    args
  ) => {
    const userSettingsUpdatePayload = mapToUpdateUserSettingsPayload(args)
    const userSettingsUpdateResponse =
      await userSettingService.updateUserSettings(userSettingsUpdatePayload)
    return mapToUserSettings(userSettingsUpdateResponse.data)
  }

  const changePassword: UserSettingsUseCases['changePassword'] = async (
    args
  ) => {
    const changePasswordPayload = mapToChangePasswordPayload(args)
    const changePasswordResponse = await userSettingService.changePassword(
      changePasswordPayload
    )
    return changePasswordResponse.data
  }

  const updateExpiredPassword: UserSettingsUseCases['updateExpiredPassword'] =
    async (args) => {
      const updateExpiredPasswordPayload =
        mapToUpdateExpiredPasswordPayload(args)
      const updateExpiredPasswordResponse =
        await userSettingService.updateExpiredPassword(
          updateExpiredPasswordPayload
        )
      return updateExpiredPasswordResponse.data
    }

  return {
    getUserSettings,
    updateUserSettings,
    changePassword,
    updateExpiredPassword,
  }
}
