import { PaymentsUseCases } from 'src/features/payments/domain'
import { usePaymentsService } from 'src/features/payments/infrastructure'
import { UsePaymentsUseCasesAdapter } from '../../definition'

export const usePaymentsAdapter: UsePaymentsUseCasesAdapter = () => {
  const paymentsService = usePaymentsService()

  const getPayments: PaymentsUseCases['getPayments'] = async (
    fecthDataOptions: any
  ) => {
    const paymentsResponse = await paymentsService.getPayments(
      fecthDataOptions.pageSize,
      fecthDataOptions.pageIndex + 1
    )
    return paymentsResponse.data
  }

  const getPaymentsFilters: PaymentsUseCases['getPaymentsFilters'] = async (
    fecthDataOptions: any
  ) => {
    const paymentsResponse = await paymentsService.getPaymentsFilters(
      fecthDataOptions.pageSize,
      fecthDataOptions.pageIndex + 1,
      fecthDataOptions
    )
    return paymentsResponse.data
  }

  const downloadPayments: PaymentsUseCases['downloadPayments'] = async () => {
    const paymentsResponse = await paymentsService.downloadPayments()
    return paymentsResponse
  }

  return {
    getPayments,
    getPaymentsFilters,
    downloadPayments,
  }
}
