import { UseConsumptionUseCases } from '../definition'

type MakeConsumptionUseCasesProps = {
  useConsumptionUseCases: UseConsumptionUseCases
}

type MakeConsumptionUseCases = (
  props: MakeConsumptionUseCasesProps
) => UseConsumptionUseCases

export const makeConsumptionUseCases: MakeConsumptionUseCases =
  ({ useConsumptionUseCases }) =>
  () =>
    useConsumptionUseCases()
