import { PaymentsLinkService } from 'src/infrastructure'
import { useMarketplaceClient } from 'src/adapters'
import { PaymentLinksFilters } from './use-payments-link'

export const usePaymentsLinkService = () => {
  const { privateClient } = useMarketplaceClient()

  return {
    listAllWidgets: () => {
      return privateClient.get<PaymentsLinkService.ListAllWidgetsResponse>(
        'api/widget/?no_pagination'
      )
    },
    createLink: (payload: PaymentsLinkService.CreateLinkPayload) => {
      return privateClient.post<PaymentsLinkService.CreateLinkResponse>(
        'dashboard/api/payment-link-gateway/',
        payload
      )
    },
    cancelLink: (payload: PaymentsLinkService.CancelLinkPayload) => {
      return privateClient.post<PaymentsLinkService.CancelLinkResponse>(
        'dashboard/api/payment-link-gateway/cancel',
        payload
      )
    },
    getPaymentLinks: (
      pageSize: number,
      page: number,
      filters: PaymentLinksFilters,
      payload: PaymentsLinkService.WidgetIdLinkPayload
    ) => {
      return privateClient.post<PaymentsLinkService.PaymentLinkTableResponse>(
        `dashboard/api/payment-link-gateway/list?search=${
          filters.search
        }&status=${filters.statusFilter}&created_at__lte=${
          filters.dateTo
        }&created_at__gte=${filters.dateFrom}&offset=${
          (page - 1) * pageSize
        }&amount__gte=${filters.min}&amount__lte=${filters.max}&ordering=${
          filters.ordering
        }&paid=${filters.payment}&limit=${pageSize}`,
        payload
      )
    },
    createBatch: (payload: PaymentsLinkService.CreateBatchPayload) => {
      const config = {
        headers: {
          'Content-Type': payload.format,
        },
      }
      return privateClient.post<PaymentsLinkService.CreateBatchResponse>(
        'dashboard/api/payment-link-gateway/batch/create',
        payload.file,
        config
      )
    },
    getPaymentsBatch: (pageSize: number, page: number) => {
      return privateClient.get<PaymentsLinkService.PaymentBatchTableResponse>(
        `dashboard/api/payment-link-gateway/batch/list?offset=${
          (page - 1) * pageSize
        }&limit=${pageSize}`
      )
    },
    cancelPaymentsBatch: (payload: PaymentsLinkService.CancelLinkPayload) => {
      return privateClient.post<PaymentsLinkService.CancelLinkResponse>(
        'dashboard/api/payment-link-gateway/batch/cancel',
        payload
      )
    },
    downloadPaymentBatch: (id: any) => {
      return privateClient.get<any>(
        `dashboard/api/payment-link-gateway/batch/download/${id}`
      )
    },
    // downloadPaymentLinks: () => {
    //   return privateClient.get<Blob>('/dashboard/api/log-transfer/download')
    // },
  }
}
