import React, { FC } from 'react'

import {
  Typography,
  IconMoon,
  Anchor,
} from '@prometeoapi/afrodita-react-component-lib'
import {
  Wrapper,
  MainNavigationLink,
  CustomIcon,
  ActiveLink,
  MainNavigationLinkDropdown,
} from './nav-bar-navigation-item.styled'
import { NavbarNavigationItemProps } from 'src/presentation'

export const NavbarNavigationItem: FC<NavbarNavigationItemProps> = ({
  dropdownContent,
  label,
  iconName,
  className,
  wrapperClassName,
  hasUrl,
  isActive,
  isRed,
  url = '/',
  iconSize = 'sm',
}: NavbarNavigationItemProps) => {
  const labels = {
    onlyText: (
      <>
        <Typography
          variant="small-1"
          typographyColor={isRed ? 'white' : 'black'}
          withFontFamily
        >
          {label}
        </Typography>
        <CustomIcon name="icon-chevron-down" size="xs" />
      </>
    ),
    textWithUrl: (
      <Anchor underlineOnHover={false} href={url.replace('/en', '')}>
        <Typography
          variant="small-1"
          typographyColor={isRed ? 'white' : 'black'}
        >
          {label}
        </Typography>
      </Anchor>
    ),
    onlyIcon: (
      <>
        <IconMoon name={iconName} size={iconSize} />
        <CustomIcon name="icon-chevron-down" size="xs" />
      </>
    ),
  }

  return (
    <Wrapper isRed={isRed} className={wrapperClassName}>
      <MainNavigationLink className={isActive ? 'active' : ''}>
        {hasUrl && labels['textWithUrl']}
        {iconName && labels['onlyIcon']}
        {!iconName && !hasUrl && labels['onlyText']}
        <ActiveLink isRed={isRed} />
      </MainNavigationLink>
      {dropdownContent && (
        <MainNavigationLinkDropdown className={className}>
          {dropdownContent}
        </MainNavigationLinkDropdown>
      )}
    </Wrapper>
  )
}
