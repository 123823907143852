import { useTranslation } from 'react-i18next'
import { Spacer } from '@lib-components'

import styles from './payments.module.scss'
import {
  PageHeader,
  PageHeaderTitle,
  useHomeQuery,
  useHomeQueryDataHelpers,
} from 'src/presentation'
import { Home } from 'src/domain'
import { PaymentTable } from '../../components'
import { Navigate } from 'react-router-dom'

export const PaymentsPage = () => {
  const { t } = useTranslation(['payments'])
  const { data, isSuccess } = useHomeQuery()
  const { isInProduction } = useHomeQueryDataHelpers()
  const shouldRedirect = isSuccess && !isInProduction(data as Home)

  if (shouldRedirect) {
    return <Navigate to="/" replace />
  }

  return (
    <section className={styles['page-container']}>
      <PageHeader>
        <PageHeaderTitle>{t(`payments:summaryPage.title`)}</PageHeaderTitle>
      </PageHeader>
      <Spacer size={'4x'} />
      <PaymentTable />
      <Spacer size={'10x'} />
    </section>
  )
}
