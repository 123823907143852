import { useMarketplaceClient } from 'src/adapters'
import { TransferLogResponse } from '../../definitions/payments.service'
import { PaymentFilters } from './use-payments'

export const usePaymentsService = () => {
  const { privateClient } = useMarketplaceClient()

  return {
    getPayments: (pageSize: number, page: number) => {
      return privateClient.get<TransferLogResponse>(
        `/dashboard/api/log-transfer/get-transfers?page_size=${pageSize}&page=${page}`
      )
    },
    getPaymentsFilters: (
      pageSize: number,
      page: number,
      filters: PaymentFilters
    ) => {
      return privateClient.get<TransferLogResponse>(
        `/dashboard/api/log-transfer/get-transfers?search=${filters.search}&status=${filters.statusFilter}&date_from=${filters.dateFrom}&date_to=${filters.dateTo}&currency=${filters.currency}&page_size=${pageSize}&page=${page}&amount_min=${filters.min}&amount_max=${filters.max}&ordering=${filters.ordering}`
      )
    },
    downloadPayments: () => {
      return privateClient.get<Blob>('/dashboard/api/log-transfer/download')
    },
  }
}
