import { getStyledElementProps } from '@lib-utils'
import { FC, ReactNode } from 'react'
import classNames from 'classnames'

import {
  BaseTemplateCollapsingNavContainerProps,
  BaseTemplateMainContainerProps,
  BaseTemplateMainProps,
} from 'src/presentation'

import styles from './base-template-layout.module.scss'

export type ChildrenContainerProps = {
  children: ReactNode
}

export const BaseTemplateContainer: FC<ChildrenContainerProps> = ({
  children,
}) => {
  return <div className={styles['wrapper']}>{children}</div>
}

export const BaseTemplateHeaderContainer: FC<ChildrenContainerProps> = ({
  children,
}) => {
  return <header className={styles['header-container']}>{children}</header>
}
export const BaseTemplateHeader: FC<ChildrenContainerProps> = ({
  children,
}) => {
  return <div className={styles['header']}>{children}</div>
}

export const BaseTemplateCollapsingNavContainer: FC<
  BaseTemplateCollapsingNavContainerProps
> = ({ children, expanded }) => {
  const className = classNames(styles['collapsing-nav-container'], {
    [styles['expanded']]: expanded,
  })
  return <div className={className}>{children}</div>
}

export const BaseTemplateMainContainer: FC<BaseTemplateMainContainerProps> = ({
  children,
  ...props
}) => {
  const styledProps = getStyledElementProps(props, styles['main-container'])
  return <div {...styledProps}>{children}</div>
}

export const BaseTemplateLeftSidebarContainer: FC<ChildrenContainerProps> = ({
  children,
}) => {
  return <aside className={styles['left-sidebar-container']}>{children}</aside>
}

export const BaseTemplateMain: FC<BaseTemplateMainProps> = ({
  children,
  ...props
}) => {
  const styledProps = getStyledElementProps(props, styles['main'])
  return <main {...styledProps}>{children}</main>
}
