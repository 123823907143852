import React, { FC, useEffect, useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  SortingState,
  PaginationState,
  useReactTable,
  OnChangeFn,
} from '@tanstack/react-table'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useDebounce } from 'use-debounce'
import {
  InputLabel,
  Typography,
  Button,
  IconMoon,
  Spinner,
} from '@prometeoapi/afrodita-react-component-lib'
import { ConfirmationModal } from 'src/components'
import { usePaymentsLinkUseCases, useWidgetUseCases } from 'src/service-locator'

import { Widget } from 'src/domain'
import { LIST_ALL_WIDGETS_QUERY_KEY } from 'src/config'
import { AxiosError } from 'axios'

import { TableProps } from './table-component.d'
import styles from './table-component.module.scss'

export const TableComponent: FC<TableProps> = ({
  columns,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  query,
  paginationParams,
  startDate,
  endDate,
  currency,
  amountMin,
  amountMax,
  statusFilter,
  payment,
  filterPage,
  setSearchDownload,
}) => {
  const { t } = useTranslation(['payments', 'paymentsLink'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate()
  const { cancelLink, getPaymentLinks } = usePaymentsLinkUseCases()
  const { listAllWidgets } = useWidgetUseCases()
  const [listCancelLinks, setListCancelLinks] = useState<string[]>([])

  const [paymentLinkTableData, setPaymentLinkTableData] = useState<any>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [search, setSearch] = useState(searchParams.get('search') || '')
  const [value] = useDebounce(search, 1000)
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: paginationParams?.pageIndex ?? 0,
      pageSize: paginationParams?.pageSize ?? 10,
    })

  const [rowSelection, setRowSelection] = React.useState({})
  const [ordering, setOrdering] = React.useState<string>(
    searchParams.get('ordering') || ''
  )
  const sortOrdering =
    ordering !== ''
      ? ordering.split(',').map((item: any) => {
          return {
            id: item.replace('-', ''),
            desc: item.substring(0, 1) === '-',
          }
        })
      : []

  const [sorting, setSorting] = React.useState<SortingState>(sortOrdering)
  const [isOpened, setIsOpened] = useState(false)
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)

  const handleChangePage = () => {
    setPagination({
      pageSize: pageSize,
      pageIndex: 0,
    })
  }

  const queryListWidget = useQuery<Widget[], AxiosError>(
    LIST_ALL_WIDGETS_QUERY_KEY,
    listAllWidgets
  )

  const getLinksAction = async (ids: any) => {
    if (ids?.length > 0) {
      try {
        setLoading(true)
        const widgetIdsPayload = {
          widget_ids: ids,
        }
        const response = await getPaymentLinks(
          fetchDataOptions,
          widgetIdsPayload
        )
        const responsePaymentLinkTable = await response
        setLoading(false)
        setPaymentLinkTableData(responsePaymentLinkTable)
      } catch (error: any) {
        return error
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [widgetIdList, setWidgetIdList] = useState<string[] | undefined>([])

  const widgetsList = queryListWidget.data

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let paymentLinksList = null

  useEffect(() => {
    if (widgetsList && widgetsList?.length > 0) {
      const ids = widgetsList?.map((widget: any) => widget.publicKey)
      // eslint-disable-next-line
      paymentLinksList = getLinksAction(ids)
      setWidgetIdList(ids)
    }
  }, [widgetsList])

  const fetchDataOptions = {
    pageSize,
    pageIndex,
    ordering: ordering,
    search: value,
    dateFrom: startDate,
    dateTo: endDate,
    currency: currency,
    min: amountMin,
    max: amountMax,
    statusFilter: statusFilter,
    payment: payment,
  }

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const onSortingChange: OnChangeFn<SortingState> = (updaterOrValue) => {
    let newSort: SortingState

    if (typeof updaterOrValue === 'function') {
      newSort = updaterOrValue(sorting)
    } else {
      newSort = updaterOrValue
    }
    setSorting(newSort)
    const sorterFields: Array<string> = newSort.map((item: any) => {
      const { id, desc } = item
      const orderFieldToStr = desc ? id : `-${id}`
      return orderFieldToStr
    })
    setOrdering(sorterFields.toString())
    if (newSort.length === 0) {
      setOrdering('')
    }
  }

  const table = useReactTable({
    data: paymentLinkTableData?.results ?? [],
    columns,
    pageCount: Math.ceil((paymentLinkTableData.count ?? 0) / pageSize),
    state: {
      sorting,
      pagination,
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange,
    manualPagination: true,
    manualSorting: true,
    debugTable: true,
  })

  useEffect(() => {
    getLinksAction(widgetIdList)
    // eslint-disable-next-line
  }, [pageIndex])

  useEffect(() => {
    const rowToCancel = table
      .getSelectedRowModel()
      .flatRows.filter(
        (d: any) => d.original.status === 'active' && d.original.paid === false
      )

    const rowSelected = rowToCancel.map((d: any) => d.original.id)
    setListCancelLinks(rowSelected)

    // eslint-disable-next-line
  }, [rowSelection])

  useEffect(() => {
    const resetPage = [
      'active',
      'cancelled',
      'expired',
      'delete',
      'date',
      'min',
      'max',
      'payment',
      'search',
    ]
    if (resetPage.includes(filterPage as string)) {
      setPagination({
        pageSize: pageSize,
        pageIndex: 0,
      })
    }
  }, [filterPage, pageSize])

  useEffect(() => {
    const qRawParams = {
      search: search,
      ordering: ordering,
      'date-from': startDate,
      'date-to': endDate,
      currency: currency,
      min: amountMin,
      max: amountMax,
      'status-filter': statusFilter,
      payment: payment,
    }
    const qCleanParams = Object.fromEntries(
      Object.entries(qRawParams).filter(([, v]) => v != '')
    )

    if (widgetsList && widgetsList?.length > 0) {
      const ids = widgetsList?.map((widget: any) => widget.publicKey)
      // eslint-disable-next-line
      paymentLinksList = getLinksAction(ids)
    }

    setSearchParams(qCleanParams)
  }, [
    // search,
    setSearchParams,
    ordering,
    amountMax,
    amountMin,
    currency,
    endDate,
    startDate,
    statusFilter,
    payment,
  ])

  const cancelLinkAction = async () => {
    const payload = {
      ids: listCancelLinks,
    }
    try {
      const response = await cancelLink(payload)
      if (response.payment_links_canceled) {
        setIsOpened(false)
        if (widgetsList && widgetsList?.length > 0) {
          const ids = widgetsList?.map((widget: any) => widget.publicKey)
          paymentLinksList = getLinksAction(ids)
          table.resetRowSelection()
        }
      }
    } catch (error: any) {
      setErrorMessage(t('paymentsLink:createLink.cancelModal.errorCancel'))
      return error
    }
  }
  const handleCancelModalClick = async () => {
    await cancelLinkAction()
  }

  const handleCloseModalClick = () => {
    setIsOpened(false)
  }

  const handleModalClick = () => {
    if (listCancelLinks.length !== 0) {
      setIsOpened(true)
      setErrorMessage('')
    }
  }

  const reloadTable = () => {
    getLinksAction(widgetIdList)
  }

  return (
    <div className="p-2">
      <div className={styles['section-cancel']}>
        <button
          className={
            listCancelLinks.length === 0
              ? styles['cancel-button']
              : styles['cancel-active-button']
          }
          onClick={handleModalClick}
        >
          {t(
            `paymentsLink:paymentLinkPage.paymentLinkList.filters.cancelButton`
          )}
          <IconMoon
            className={styles['icon-button']}
            name="icon-cancel"
            size="sm"
          />
        </button>
      </div>
      <div className={styles['top-table-container']}>
        <div className={styles['top-table-container__left']}>
          <InputLabel>
            {t('paymentsLink:paymentLinkPage.paymentLinkList.listToShow')}
          </InputLabel>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e: any) => {
              table.setPageSize(Number(e.target.value))
              fetchDataOptions['pageSize'] = e.target.value
              getLinksAction(widgetIdList)
            }}
          >
            {[10, 50, 150, 300, 600, 1000].map((pageSize: number) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className={styles['top-table-container__right']}>
          <div className={styles['section-reload']}>
            <div className={styles['section-reload']}>
              <button className={styles['reload-button']} onClick={reloadTable}>
                {t(
                  `paymentsLink:paymentLinkPage.paymentLinkList.filters.reloadButton`
                )}
                <IconMoon
                  className={styles['icon-button']}
                  name="icon-refresh"
                  size="sm"
                />
              </button>
            </div>
          </div>
          <div className={styles['section-search']}>
            <input
              placeholder={t(
                'paymentsLink:paymentLinkPage.paymentLinkList.filters.search'
              )}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setSearch(e.currentTarget.value)
                handleChangePage()
                fetchDataOptions['search'] = e.currentTarget.value
                getLinksAction(widgetIdList)
                setSearchDownload(e.currentTarget.value)
              }}
              value={search}
              type="text"
            />
            {search ? (
              <Button
                onClick={() => {
                  setSearch('')
                  handleChangePage()
                  fetchDataOptions['search'] = ''
                  getLinksAction(widgetIdList)
                  setSearchDownload('')
                }}
              >
                <IconMoon
                  name={'icon-cross'}
                  size="xs"
                  style={{ margin: '4px' }}
                />
              </Button>
            ) : (
              <IconMoon
                name={'icon-search'}
                size="sm"
                className={styles['icon-search']}
              />
            )}
          </div>
        </div>
      </div>
      {loading ? (
        <div className={styles['loading-container']}>
          <Spinner />
        </div>
      ) : (
        table.getRowModel().rows.length === 0 && (
          <span className={styles['message']}>
            {t('paymentsLink:paymentLinkPage.paymentLinkList.noResultFound')}
          </span>
        )
      )}
      {table.getRowModel().rows.length !== 0 && (
        <div className={styles['table-wrapper']}>
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              onClick: header.column.getToggleSortingHandler(),
                              className: header.column.getCanSort()
                                ? styles['is-sort']
                                : '',
                            }}
                          >
                            <Typography
                              tag="span"
                              variant="small-2"
                              typographyColor="typography-secondary"
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </Typography>
                            {{
                              asc: (
                                <IconMoon
                                  style={{ marginLeft: '5px' }}
                                  name={'icon-chevron-up'}
                                  size="xs"
                                />
                              ),
                              desc: (
                                <IconMoon
                                  style={{ marginLeft: '5px' }}
                                  name={'icon-chevron-down'}
                                  size="xs"
                                />
                              ),
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row: any) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell: any) => {
                      const value = `${cell.getValue()}`
                        .toLocaleLowerCase()
                        .replaceAll('_', '-')
                      return (
                        <td key={cell.id}>
                          <Typography
                            tag="p"
                            variant="small-1"
                            className={styles[value]}
                          >
                            {cell.column.columnDef.accessorKey === 'amount' ? (
                              <b>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </b>
                            ) : (
                              flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )
                            )}
                          </Typography>
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
      {table.getRowModel().rows.length !== 0 && (
        <div className={styles['bottom-table-container']}>
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <Typography tag="span" variant="small-1">
            {t('paymentsLink:paymentLinkPage.paymentLinkList.paginateInfo', {
              current: table.getState().pagination.pageIndex + 1,
              total: table.getPageCount(),
            })}
          </Typography>
          <button
            onClick={() => {
              table.nextPage()
            }}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
        </div>
      )}
      <ConfirmationModal
        onClickSubmit={handleCancelModalClick}
        onClickCancel={handleCloseModalClick}
        title={t('paymentsLink:createLink.cancelModal.title')}
        message={t('paymentsLink:createLink.cancelModal.message')}
        cancel={t('paymentsLink:createLink.cancelModal.cancel')}
        submit={t('paymentsLink:createLink.cancelModal.submit')}
        isOpen={isOpened}
        messageError={errorMessage as string}
      />
    </div>
  )
}
