import { AxiosError } from 'axios'

export type MarketplaceAdapterMessageForUser = {
  label?: string
  description: string
}

export class APIError<
  TServiceErrors = Record<string, unknown>,
  TAdapterErrors = Record<string, unknown>
> extends AxiosError {
  constructor(
    private _axiosError: AxiosError,
    private _messageForUser: MarketplaceAdapterMessageForUser,
    private _serviceFormFieldErrors?: TServiceErrors,
    private _adapterFormFieldErrors?: TAdapterErrors
  ) {
    super(
      _axiosError.message,
      _axiosError.code,
      _axiosError.config,
      _axiosError.request,
      _axiosError.response
    )
  }

  get messageForUser() {
    return this._messageForUser
  }

  get formFieldErrors() {
    return this._adapterFormFieldErrors
  }

  cleanFormFieldErrors(fieldName: keyof TAdapterErrors) {
    if (
      this._adapterFormFieldErrors &&
      this._adapterFormFieldErrors[fieldName]
    ) {
      delete this._adapterFormFieldErrors[fieldName]
    }

    if (
      this._adapterFormFieldErrors &&
      !Object.keys(this._adapterFormFieldErrors).length
    ) {
      this._adapterFormFieldErrors = undefined
    }
  }

  setAdapterFormFieldErrors(
    mapper: (serviceErrors: TServiceErrors) => TAdapterErrors
  ): void {
    if (this._serviceFormFieldErrors) {
      this._adapterFormFieldErrors = mapper(this._serviceFormFieldErrors)
    }
  }
}
