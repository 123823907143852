import {
  GetUsagePayload,
  GetUsageGraphPayload,
  GetUsageResponse,
  GetUsageGraphResponse,
} from 'src/infrastructure'
import { useMarketplaceClient } from 'src/adapters'

export const useUsageService = () => {
  const { privateClient } = useMarketplaceClient()

  return {
    getUsage: ({ year, month }: GetUsagePayload) =>
      privateClient.get<GetUsageResponse>(
        `api/dashboard/user/usage?year=${year}&month=${month}`
      ),
  }
}

export const useUsageGraphService = () => {
  const { privateClient } = useMarketplaceClient()

  return {
    getUsageGraph: ({ year, month }: GetUsageGraphPayload) =>
      privateClient.get<GetUsageGraphResponse>(
        `dashboard/filter_requests/?user_id=&month=${month}&year=${year}`
      ),
  }
}
