import React, { FC, useEffect, useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  SortingState,
  PaginationState,
  useReactTable,
  OnChangeFn,
} from '@tanstack/react-table'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import {
  InputLabel,
  Typography,
  Spinner,
  IconMoon,
} from '@prometeoapi/afrodita-react-component-lib'
import { TableProps } from './table-component.d'
import styles from './table-component.module.scss'
import { ConfirmationModal } from 'src/components'
import { usePaymentsLinkUseCases } from 'src/service-locator'

export const TableComponent: FC<TableProps> = ({
  columns,
  query,
  paginationParams,
  startDate,
  endDate,
  currency,
  amountMin,
  amountMax,
  statusFilter,
  filterPage,
  // setSearchDownload,
}) => {
  const { t } = useTranslation(['payments', 'paymentsLink'])
  const navigate = useNavigate()
  const { cancelBatch } = usePaymentsLinkUseCases()
  const [searchParams, setSearchParams] = useSearchParams()
  const [listCancelLinks, setListCancelLinks] = useState<string[]>([])
  const [rowSelection, setRowSelection] = useState({})
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isOpened, setIsOpened] = useState(false)
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: paginationParams?.pageIndex ?? 0,
      pageSize: paginationParams?.pageSize ?? 10,
    })

  const [ordering, setOrdering] = React.useState<string>(
    searchParams.get('ordering') ?? ''
  )
  const sortOrdering =
    ordering !== ''
      ? ordering.split(',').map((item: any) => {
          return {
            id: item.replace('-', ''),
            desc: item.substring(0, 1) === '-',
          }
        })
      : []

  const [sorting, setSorting] = React.useState<SortingState>(sortOrdering)

  const fetchDataOptions = {
    pageSize,
    pageIndex,
    ordering: ordering,
    dateFrom: startDate,
    dateTo: endDate,
    currency: currency,
    min: amountMin,
    max: amountMax,
    statusFilter: statusFilter,
  }

  const queryKey = 'getData'
  const getData = async () => {
    const response = await query(fetchDataOptions)
    return response
  }

  const { data, isFetching, refetch } = useQuery(queryKey, getData, {
    keepPreviousData: true,
  })

  const reloadTable = () => {
    refetch()
  }

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const onSortingChange: OnChangeFn<SortingState> = (updaterOrValue) => {
    let newSort: SortingState

    if (typeof updaterOrValue === 'function') {
      newSort = updaterOrValue(sorting)
    } else {
      newSort = updaterOrValue
    }
    setSorting(newSort)
    const sorterFields: Array<string> = newSort.map((item: any) => {
      const { id, desc } = item
      const orderFieldToStr = desc ? id : `-${id}`
      return orderFieldToStr
    })
    setOrdering(sorterFields.toString())
    if (newSort.length === 0) {
      setOrdering('')
    }
  }

  useEffect(() => {
    const rowSelected = table
      .getSelectedRowModel()
      .flatRows.map((d: any) => d.original.id)
    setListCancelLinks(rowSelected)
    // eslint-disable-next-line
  }, [rowSelection])

  const table = useReactTable({
    data: data?.results ?? [],
    columns,
    pageCount: Math.ceil((data?.count ?? 0) / pageSize),
    state: {
      sorting,
      pagination,
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange,
    manualPagination: true,
    manualSorting: true,
    debugTable: true,
  })

  useEffect(() => {
    const resetPage = ['c', 'o', 'r', 'delete', 'date', 'min', 'max']
    if (resetPage.includes(filterPage as string)) {
      setPagination({
        pageSize: pageSize,
        pageIndex: 0,
      })
    }
  }, [filterPage, pageSize])

  useEffect(() => {
    const qRawParams = {
      ordering: ordering,
      'date-from': startDate,
      'date-to': endDate,
      currency: currency,
      min: amountMin,
      max: amountMax,
      'status-filter': statusFilter,
    }
    const qCleanParams = Object.fromEntries(
      Object.entries(qRawParams).filter(([, v]) => v != '')
    )
    setSearchParams(qCleanParams)
  }, [
    setSearchParams,
    ordering,
    amountMax,
    amountMin,
    currency,
    endDate,
    startDate,
    statusFilter,
  ])

  const cancelBatchAction = async () => {
    const payload = {
      ids: listCancelLinks,
    }
    try {
      const response = await cancelBatch(payload)
      if (response.payment_links_batch_cancelled > 0) {
        setIsOpened(false)
        setRowSelection({})
        await refetch()
        navigate('/payments-link-batch')
      } else {
        setErrorMessage(t('paymentsLink:createLink.cancelModal.errorCancel'))
      }
    } catch (error: any) {
      setErrorMessage(t('paymentsLink:createLink.cancelModal.errorCancel'))
      return error
    }
  }

  const handleCancelModalClick = async () => {
    await cancelBatchAction()
  }

  const handleCloseModalClick = () => {
    setIsOpened(false)
  }

  const handleModalClick = () => {
    if (listCancelLinks.length !== 0) {
      setIsOpened(true)
      setErrorMessage('')
    }
  }

  return (
    <div className="p-2">
      <div className={styles['top-table-container']}>
        <div className={styles['top-table-container__left']}>
          <InputLabel>{t('payments:paymentHistory.listToShow')}</InputLabel>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e: any) => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[10, 50, 150, 300, 600, 1000].map((pageSize: number) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className={styles['top-table-container__right']}>
          <div className={styles['section-reload']}>
            <button className={styles['reload-button']} onClick={reloadTable}>
              {t(
                `paymentsLink:paymentLinkPage.paymentLinkList.filters.reloadButton`
              )}
              <IconMoon
                className={styles['icon-button']}
                name="icon-refresh"
                size="sm"
              />
            </button>
          </div>
          <button
            className={
              listCancelLinks.length === 0
                ? styles['cancel-button']
                : styles['cancel-active-button']
            }
            onClick={handleModalClick}
          >
            {t(
              `paymentsLink:paymentLinkPage.paymentLinkList.filters.cancelButton`
            )}
            <IconMoon
              className={styles['icon-button']}
              name="icon-cancel"
              size="sm"
            />
          </button>
          {/* <input
            className={styles['input-search']}
            placeholder={t('payments:paymentHistory.filters.search')}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setSearch(e.currentTarget.value)
              handleChangePage()
              setSearchDownload(e.currentTarget.value)
            }}
            value={search}
            type="text"
          />
          {search ? (
            <Button
              onClick={() => {
                setSearch('')
                handleChangePage()
                setSearchDownload('')
              }}
            >
              <IconMoon
                className={styles['icon-search']}
                name={'icon-cross'}
                size="xs"
                style={{ margin: '4px' }}
              />
            </Button>
          ) : (
            <IconMoon
              name={'icon-search'}
              size="sm"
              className={styles['icon-search']}
            />
          )} */}
        </div>
      </div>
      {isFetching ? (
        <div className={styles['loading-container']}>
          <Spinner />
        </div>
      ) : (
        table.getRowModel().rows.length === 0 && (
          <span className={styles['message']}>
            {t('payments:paymentHistory.noResultFound')}
          </span>
        )
      )}
      {table.getRowModel().rows.length !== 0 && (
        <div className={styles['table-wrapper']}>
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              onClick: header.column.getToggleSortingHandler(),
                              className: header.column.getCanSort()
                                ? styles['is-sort']
                                : '',
                            }}
                          >
                            <Typography
                              tag="span"
                              variant="small-2"
                              typographyColor="typography-secondary"
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </Typography>
                            {{
                              asc: (
                                <IconMoon name={'icon-chevron-up'} size="md" />
                              ),
                              desc: (
                                <IconMoon
                                  name={'icon-chevron-down'}
                                  size="md"
                                />
                              ),
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row: any) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell: any) => {
                      const value = `${cell.getValue()}`
                        .toLocaleLowerCase()
                        .replaceAll('_', '-')
                      return (
                        <td key={cell.id}>
                          <Typography
                            tag="p"
                            variant="small-1"
                            className={styles[value]}
                          >
                            {cell.column.columnDef.accessorKey === 'amount' ? (
                              <b>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </b>
                            ) : (
                              flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )
                            )}
                          </Typography>
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
      {table.getRowModel().rows.length !== 0 && (
        <div className={styles['bottom-table-container']}>
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <Typography tag="span" variant="small-1">
            {t('payments:paymentHistory.paginateInfo', {
              current: table.getState().pagination.pageIndex + 1,
              total: table.getPageCount(),
            })}
          </Typography>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
        </div>
      )}
      <ConfirmationModal
        onClickSubmit={handleCancelModalClick}
        onClickCancel={handleCloseModalClick}
        title={t('paymentsLink:createLink.cancelModal.title')}
        message={t('paymentsLink:createLink.cancelModal.message')}
        cancel={t('paymentsLink:createLink.cancelModal.cancel')}
        submit={t('paymentsLink:createLink.cancelModal.submit')}
        isOpen={isOpened}
        messageError={errorMessage as string}
      />
    </div>
  )
}
