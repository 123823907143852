import { FC, MouseEventHandler, useRef } from 'react'
import styles from './header-auth.module.scss'
import { IconMoon } from '@prometeoapi/afrodita-react-component-lib'
import { MobileNav, WebMenu, WebNav } from 'src/presentation'
import prometeoLogo from 'src/assets/images/logo-prometeo-black.svg'
import { NavLink } from 'react-router-dom'
import * as settings from 'src/config/settings'

export const HeaderLogo = () => (
  <NavLink to={settings.NEW_SITE} className={styles['header-logo']}>
    <img src={prometeoLogo} alt="logo" />
  </NavLink>
)

export const HeaderAuth: FC = () => {
  const collapsingNavEl = useRef<HTMLDivElement>(null)

  const handleToggleMenuClick: MouseEventHandler<HTMLButtonElement> = () => {
    collapsingNavEl.current?.classList.toggle(styles['expanded'])
  }

  return (
    <div>
      <header className={styles['desktop-header']}>
        <WebMenu>
          <div className={styles['logo-header']}>
            <HeaderLogo />
          </div>
          <WebNav />
        </WebMenu>
      </header>

      <header className={styles['mobile-header']}>
        <div className={styles['top']}>
          <HeaderLogo />
          <button
            className={styles['toggle-nav-btn']}
            onClick={handleToggleMenuClick}
          >
            <IconMoon name={'icon-menu'} size="sm" />
          </button>
        </div>
        <div
          ref={collapsingNavEl}
          className={styles['collapsing-nav-container']}
        >
          <MobileNav />
        </div>
      </header>
    </div>
  )
}
