import { Spacer } from '@prometeoapi/afrodita-react-component-lib'
import { useAuthWithRoles } from 'src/infrastructure'
import {
  HomePageApiKey,
  HomePageApiKeySandbox,
  HomePageQuickstart,
  HomePageStageRequest,
  useHomeQuery,
  useHomeQueryDataHelpers,
} from 'src/presentation'

type HomePageStageInfoProps = {
  checkRoles: boolean
  allowedRoles: Array<any>
}

export const HomePageStageInfo = (props: HomePageStageInfoProps) => {
  const { checkRoles, allowedRoles } = props
  const { hasPermission } = useAuthWithRoles(allowedRoles)
  const { data } = useHomeQuery()
  const { shouldShowQuickstart, shouldShowApiKey, shouldShowApiKeySandbox } =
    useHomeQueryDataHelpers()

  if (!hasPermission && checkRoles) {
    return null
  }

  return (
    <>
      {data && (
        <>
          {shouldShowQuickstart(data) && (
            <>
              <HomePageQuickstart />
              <Spacer variant={'vertical'} size={'8x'} />
            </>
          )}

          {shouldShowApiKey(data) && (
            <>
              <HomePageApiKey />
              <Spacer variant={'vertical'} size={'8x'} />
            </>
          )}

          {shouldShowApiKeySandbox(data) && (
            <>
              <HomePageApiKeySandbox />
              <Spacer variant={'vertical'} size={'8x'} />
            </>
          )}

          <HomePageStageRequest />
        </>
      )}
    </>
  )
}
