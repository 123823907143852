import { MapToConsumption, MapToSummary } from '../definition'

const mapToSummary: MapToSummary = (response) => ({
  name: response.name,
  debt: response.debt,
  requestsQuantity: response.requests_quantity,
})

export const mapToConsumption: MapToConsumption = (response) => ({
  historicalDebt: response.historical_debt,
  higherMonthlyRequestsQuantity: response.historical_debt,
  annualData: {
    total: response.annual_data.total,
    summary: response.annual_data.summary.map(mapToSummary),
  },
  statements: response.statements.map((statement) => ({
    year: statement.year,
    month: statement.month,
    total: statement.total,
    requestsQuantity: statement.requests_quantity,
    summary: statement.summary.map(mapToSummary),
  })),
})
