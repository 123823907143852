export default {
  header: {
    sandbox: {
      title: 'Welcome to Prometeo!',
      subtitle:
        'Here you can view the summary of your consumption and your API Key to access the system.',
    },
    preproduction: {
      title: 'Welcome to your Trial environment!',
      subtitle:
        'Use your API key and access data from real institutions to build with Prometeo.',
    },
    production: {
      title: 'Welcome to Prometeo!',
      subtitle: 'You already have access to the Production environment',
    },
    alert: 'Password expires in {{days}} days',
  },
  quickstart: {
    title: 'How to start?',
    subtitle:
      'We have designed these four steps that will allow you to go through the different stages of your integration. Remember that each step is required to advance to the next.',
    steps: {
      1: {
        tab: '1. Documentation',
        body: {
          p: 'Access the OpenAPI Specification for testing from our Documentation. If you still have doubts about how Prometeo works, we suggest you review our demo.',
          cta1: 'Go to documentation',
          cta2: 'See Demos',
        },
      },
      2: {
        tab: '2. Sandbox',
        body: {
          p: 'Start building your integration in the Sandbox with sample data.',
          requests: 'requests',
        },
      },
      3: {
        tab: '3. Trial',
        body: {
          alert:
            'We suggest making enough requests to familiarize yourself with the platform before moving on to Trial.',
          p: 'If you have already done your integration with sample data and now you want to adjust it with real data, you can fill in the required information and go to Trial; there you will have access to real data for a limited period and in the country you choose.',
          verifying: 'We are verifying your information',
          cta: 'Work with real data',
        },
      },
      4: {
        tab: '4. Production',
        body: {
          p: 'Do you want to start trading? Complete your billing information and migrate to Production.',
          cta: 'Billing information',
        },
      },
    },
  },
  apiKey: {
    stats: {
      requests: { header: 'Requests', footer: 'requests' },
      connections: 'Connections',
      useTime: 'Use time',
      day: 'day',
      days: 'days',
    },
    sandbox: {
      title: 'Sandbox private keys access',
      description:
        'Use your API key and fictitious bank account details to develop and test your Prometeo integration.',
      label: 'Your Sandbox API Key is:',
      bullets: {
        data: 'Fictitious data',
        free: 'Free and enabled forever',
        dashboard: 'Dashboard available',
        requests: 'Unlimited API calls',
      },
    },
    preproduction: {
      title: 'Trial private keys access',
      description:
        'Use your new API key and build with real data in our financial/fiscal institutions.',
      label: 'Your Trial API Key is:',
      stats: {
        title: 'Trial Consumption',
      },
    },
    production: {
      title: {
        production: 'Production private keys access',
        testing: 'Testing private keys access',
      },
      description:
        'Use your new API key and build with real data in our financial/fiscal institutions.',
      label: {
        production: 'Your Production API Key is:',
        testing: 'Your Testing API Key is:',
      },
      stats: {
        title: 'Production Consumption',
      },
    },
  },
  stageRequest: {
    verifyingMessage: 'We are verifying your information',
    cta: 'Contact me',
    sandbox: {
      title: 'Start building',
      description:
        "Let's do it! Request access to Trial to build with real data, expanding on the work previously done in the Sandbox.",
      bullets: {
        requests: '30 API calls',
        access: 'Access to institutions in a country of your choice',
        limit: 'Limited to 15 days',
        free: 'no cost',
      },
      cta: 'Work with real data',
    },
    preproduction: {
      title: 'Do you want unlimited access?',
      description:
        'Keep building! If you want to scale and get unlimited API calls, you can do it in our Production environment.',
      bullets: {
        data: 'Real data',
        access: 'Access to institutions in a country of your choice',
        requests: 'Unlimited requests',
      },
      cta: 'I want unlimited access',
      goToProduction: 'Go to production',
    },
    production: {
      title: 'I want to get unlimited calls and connections!',
      description:
        'If service levels are important to your business and the number of countries or transactions is greater than that mentioned in the Torch Plan, we recommend contacting sales to find out about our Corporate Plans.',
      bullets: {
        support: 'Support during on boarding process',
        priority: 'Prioritization in the development of APIs',
        token: 'Soft token/hard token automation',
      },
      cta: 'Contact me and modify my plan',
    },
  },
}
