import { AxiosRequestConfig } from 'axios'

import { WidgetsService } from 'src/infrastructure'
import { useMarketplaceClient } from 'src/adapters'

export const useWidgetService = () => {
  const { privateClient } = useMarketplaceClient()

  return {
    listAllWidgets: () => {
      return privateClient.get<WidgetsService.ListAllWidgetsResponse>(
        'api/widget/?no_pagination'
      )
    },
    getWidget: (id: number) => {
      return privateClient.get<WidgetsService.GetWidgetResponse>(
        `api/widget/${id}/`
      )
    },
    createWidget: (payload: WidgetsService.CreateWidgetPayload) => {
      return privateClient.post<WidgetsService.CreateWidgetResponse>(
        'api/widget/',
        payload
      )
    },
    updateWidget: (payload: WidgetsService.UpdateWidgetPayload) => {
      if (payload.brand_logo) {
        privateClient.interceptors.request.use((config: AxiosRequestConfig) => {
          if (config && config.headers) {
            config.headers['Content-Type'] = 'multipart/form-data'
          } else {
            config.headers = {
              'Content-Type': 'multipart/form-data',
            }
          }
          return config
        })
      }

      return privateClient.patch<WidgetsService.UpdateWidgetResponse>(
        `api/widget/${payload.id}/`,
        payload
      )
    },
    deleteWidget: (id: number) => {
      return privateClient.delete<WidgetsService.DeleteWidgetResponse>(
        `api/widget/${id}/`
      )
    },
    // TODO: verificar si este servicio (getBankingProviders) es necesario
    getBankingProviders: () => {
      return privateClient.get<WidgetsService.GetBankingProvidersResponse>(
        'api/widget/GetAllProviders'
      )
    },
    getProviders: ({
      countryCode,
      page = 1,
    }: {
      countryCode?: string
      page?: number
    }) => {
      const params = new URLSearchParams()
      countryCode && params.append('country', countryCode)
      page && params.append('page', page.toString())
      let url = 'api/widget/providers'
      if (params) {
        url += `?${params.toString()}`
      }
      return privateClient.get<WidgetsService.GetProvidersResponse>(url)
    },
    getAllProviders: ({
      countryCode,
    }: {
      countryCode?: string
      page?: number
    }) => {
      const params = new URLSearchParams()
      countryCode && params.append('country', countryCode)
      params.append('no_pagination', 'true')
      let url = 'api/widget/providers'
      if (params) {
        url += `?${params.toString()}`
      }
      return privateClient.get<WidgetsService.Provider[]>(url)
    },
    getAllBanks: ({ countryCode }: { countryCode?: string; page?: number }) => {
      const params = new URLSearchParams()
      countryCode && params.append('country', countryCode)
      params.append('no_pagination', 'true')
      let url = 'api/widget/banks'
      if (params) {
        url += `?${params.toString()}`
      }
      return privateClient.get<WidgetsService.Bank[]>(url)
    },

    getCountries: (language = 'es') =>
      privateClient.get<WidgetsService.Country[]>(
        `/api/widget/countries?language=${language}`
      ),
    getDestinationAccount: (id: string) =>
      privateClient.get<WidgetsService.GetDestinationAccountResponse>(
        `/api/widget/destination-account/${id}`
      ),
    createDestinationAccount: ({
      widget,
      ...payload
    }: WidgetsService.CreateDestinationAccountPayload) =>
      privateClient.post<WidgetsService.CreateDestinationAccountResponse>(
        `/api/widget/${widget}/destination-account`,
        payload
      ),
    updateDestinationAccount: ({
      id,
      ...payload
    }: WidgetsService.UpdateDestinationAccountPayload) =>
      privateClient.put<WidgetsService.UpdateDestinationAccountResponse>(
        `/api/widget/destination-account/${id}`,
        payload
      ),
  }
}
