import { FC } from 'react'

import { getStyledElementProps } from '@lib-utils'
import { Icon } from '@lib-components'
import { BulletIconProps } from '@lib-types'

import styles from './bullet-icon.module.scss'

export const BulletIcon: FC<BulletIconProps> = ({
  children,
  iconSize,
  iconPosition = 'start',
  iconName = 'check_circle_outline',
  iconColor = 'green-6',
  containerProps = {},
}) => {
  const styledContainerProps = getStyledElementProps(
    containerProps,
    styles['container']
  )

  const getIcon = () => (
    <Icon name={iconName} color={iconColor} size={iconSize} />
  )
  return (
    <div {...styledContainerProps}>
      {iconPosition === 'start' && getIcon()}
      {children}
      {iconPosition === 'end' && getIcon()}
    </div>
  )
}
