import { WidgetUseCases } from 'src/domain'
import { useWidgetService, WidgetsService } from 'src/infrastructure'
import {
  mapToWidget,
  mapToProviderArray,
  mapToProviderResults,
  UseWidgetAdapter,
  mapToWidgetArray,
  mapToCreateWidgetPayload,
  mapToUpdateWidgetPayload,
  mapToCreateDestinationAccountPayload,
  mapToCreateDestinationAccountReturns,
  mapToUpdateDestinationAccountPayload,
  mapToUpdateDestinationAccountReturns,
  mapToGetDestinationAccountReturns,
  mapToBankArray,
} from 'src/adapters'
import { useLanguage, getProvidersWithDistinctBank } from 'src/utils'

export const useWidgetAdapter: UseWidgetAdapter = () => {
  const widgetService = useWidgetService()
  const { language } = useLanguage()

  const listAllWidgets: WidgetUseCases['listAllWidgets'] = async () => {
    const listAllWidgetResponse = await widgetService.listAllWidgets()
    return mapToWidgetArray(listAllWidgetResponse.data)
  }

  const getWidget: WidgetUseCases['getWidget'] = async (args: any) => {
    const widgetResponse = await widgetService.getWidget(args)
    return mapToWidget(widgetResponse.data)
  }

  const createWidget: WidgetUseCases['createWidget'] = async (args: any) => {
    const widgetCreatePayload = mapToCreateWidgetPayload(args)

    const widgetCreateResponse = await widgetService.createWidget(
      widgetCreatePayload
    )
    return mapToWidget(widgetCreateResponse.data)
  }

  const updateWidget: WidgetUseCases['updateWidget'] = async (args) => {
    const widgetUpdatePayload = mapToUpdateWidgetPayload(args)
    const widgetUpdateResponse = await widgetService.updateWidget(
      widgetUpdatePayload
    )
    return mapToWidget(widgetUpdateResponse.data)
  }

  const deleteWidget: WidgetUseCases['deleteWidget'] = async (args: any) => {
    const widgetUpdateResponse = await widgetService.deleteWidget(args)
    return (
      widgetUpdateResponse.status === 200 || widgetUpdateResponse.status === 204
    )
  }

  const getBankingProviders: WidgetUseCases['getBankingProviders'] =
    async () => {
      const getBankingProvidersResponse =
        await widgetService.getBankingProviders()
      return mapToProviderArray(getBankingProvidersResponse.data)
    }

  const getProviders: WidgetUseCases['getProviders'] = async ({
    countryCode,
    page = 1,
  }) => {
    const getProvidersResponse = await widgetService.getProviders({
      countryCode,
      page,
    })
    const providersResponse: WidgetsService.GetProvidersResponse = {
      ...getProvidersResponse.data,
      results: getProvidersWithDistinctBank(getProvidersResponse.data.results),
    }
    return mapToProviderResults(providersResponse)
  }

  const getAllProviders: WidgetUseCases['getAllProviders'] = async ({
    countryCode,
  }) => {
    const getProvidersResponse = await widgetService.getAllProviders({
      countryCode,
    })
    return mapToProviderArray(
      getProvidersWithDistinctBank(getProvidersResponse.data)
    )
  }

  const getAllBanks: WidgetUseCases['getAllBanks'] = async ({
    countryCode,
  }) => {
    const response = await widgetService.getAllBanks({
      countryCode,
    })
    return mapToBankArray(response.data)
  }

  const getCountries: WidgetUseCases['getCountries'] = async () => {
    const getCountriesResponse = await widgetService.getCountries(language)
    return getCountriesResponse.data
  }

  const getDestinationAccount: WidgetUseCases['getDestinationAccount'] = async (
    id
  ) => {
    const getDestinationAccountResponse =
      await widgetService.getDestinationAccount(id)
    return mapToGetDestinationAccountReturns(getDestinationAccountResponse.data)
  }

  const createDestinationAccount: WidgetUseCases['createDestinationAccount'] =
    async (args) => {
      const createDestinationAccountResponse =
        await widgetService.createDestinationAccount(
          mapToCreateDestinationAccountPayload(args)
        )
      return mapToCreateDestinationAccountReturns(
        createDestinationAccountResponse.data
      )
    }

  const updateDestinationAccount: WidgetUseCases['updateDestinationAccount'] =
    async (args) => {
      const updateDestinationAccountResponse =
        await widgetService.updateDestinationAccount(
          mapToUpdateDestinationAccountPayload(args)
        )
      return mapToUpdateDestinationAccountReturns(
        updateDestinationAccountResponse.data
      )
    }

  return {
    listAllWidgets,
    getWidget,
    createWidget,
    updateWidget,
    deleteWidget,
    getBankingProviders,
    getProviders,
    getAllProviders,
    getCountries,
    createDestinationAccount,
    updateDestinationAccount,
    getDestinationAccount,
    getAllBanks,
  }
}
