import { UseConsumptionServiceAdapter, mapToConsumption } from 'src/adapters'
import { useConsumptionService } from 'src/infrastructure'

export const useConsumptionServiceAdapter: UseConsumptionServiceAdapter =
  () => {
    const consumptionService = useConsumptionService()
    const getConsumption = async () => {
      const consumptionResponse = await consumptionService.getConsumption()
      return mapToConsumption(consumptionResponse.data)
    }

    return { getConsumption }
  }
