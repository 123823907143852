import { createContext } from 'react'

import { DashboardFormMutationStore } from 'src/infrastructure'

const DashboardFormMutationContext = createContext<
  DashboardFormMutationStore | undefined
>(undefined)
DashboardFormMutationContext.displayName = 'DashboardFormMutationContext'

export { DashboardFormMutationContext }
