import { FC } from 'react'
import classNames from 'classnames'

import { getStyledElementProps } from '@lib-utils'
import type { SpacerProps } from '@lib-types'

import styles from './spacer.module.scss'

export const Spacer: FC<SpacerProps> = ({
  size = '4x',
  variant = 'vertical',
  debug = false,
  ...props
}) => {
  const styledProps = getStyledElementProps(
    props,
    classNames(styles[variant], {
      [styles['debug']]: debug,
      [styles[`width-${size}`]]: variant === 'horizontal',
      [styles[`height-${size}`]]: variant === 'vertical',
    })
  )
  return <div {...styledProps} />
}
