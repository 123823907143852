import { UseHomeUseCases } from 'src/domain'

type MakeHomeUseCasesProps = {
  useHomeUseCases: UseHomeUseCases
}

type MakeHomeUseCases = (props: MakeHomeUseCasesProps) => UseHomeUseCases

export const makeHomeUseCases: MakeHomeUseCases =
  ({ useHomeUseCases }) =>
  () =>
    useHomeUseCases()
