import { FC, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import * as settings from 'src/config/settings'
import { useQueryClient } from 'react-query'
import { Navigate, useNavigate } from 'react-router-dom'
import {
  Spinner,
  Button,
  Typography,
  Spacer,
  TextField,
  InputLabel,
} from '@prometeoapi/afrodita-react-component-lib'

import { useWidgetUseCases } from 'src/service-locator'
import {
  CreateWidgetArgs,
  CreateWidgetReturns,
  CreateWidgetValidationErrors,
  Home,
} from 'src/domain'
import { ACTION_ROLES, LIST_ALL_WIDGETS_QUERY_KEY } from 'src/config'
import {
  PageHeader,
  PageHeaderTitle,
  PageHeaderSubtitle,
  WidgetList,
  useHomeQuery,
  useHomeQueryDataHelpers,
} from 'src/presentation'
import {
  mapToCreateWidgetValidationErrors,
  useMarketplaceFormMutation,
} from 'src/adapters'
import { WidgetsService, useAuthWithRoles } from 'src/infrastructure'

import styles from './widgets-page.module.scss'

interface IFormInputs {
  name: string
  description: string
}

export const WidgetsPage: FC = () => {
  const { t } = useTranslation(['widgets', 'shared'])
  const { hasPermission } = useAuthWithRoles(
    ACTION_ROLES['widget_create_action']
  )
  const validationSchema = Yup.object({
    name: Yup.string().required(
      t('shared:form.errors.requiredField', {
        name: t('widgets:listPage.forms.createWidget.fields.name.label'),
      })
    ),
    description: Yup.string().required(
      t('shared:form.errors.requiredField', {
        name: t('widgets:listPage.forms.createWidget.fields.description.label'),
      })
    ),
  })
  const navigate = useNavigate()
  const { data, isSuccess } = useHomeQuery()
  const { isInProduction } = useHomeQueryDataHelpers()
  const shouldRedirect =
    !settings.REACT_APP_WIDGET_SHOW_IN_MENU ||
    (isSuccess && !isInProduction(data as Home))
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
    },
    resolver: yupResolver(validationSchema),
  })
  const widgetUseCases = useWidgetUseCases()

  const modalRef = useRef<HTMLDialogElement>(null)

  const queryClient = useQueryClient()
  const createWidgetMutation = useMarketplaceFormMutation<
    CreateWidgetArgs,
    CreateWidgetReturns,
    WidgetsService.CreateWidgetResponseErrors,
    CreateWidgetValidationErrors
  >({
    requestFunction: widgetUseCases.createWidget,
    errorMapper: mapToCreateWidgetValidationErrors,
    onSuccessCallback: async (createdWidget) => {
      await queryClient.invalidateQueries(LIST_ALL_WIDGETS_QUERY_KEY)
      navigate(`${createdWidget.id}`)
    },
  })

  const {
    mutate: createWidget,
    reset: createWidgetReset,
    error: createWidgetError,
    isLoading: createWidgetIsLoading,
  } = createWidgetMutation

  const handleCreateWidgetButtonClick = () => {
    modalRef.current && modalRef.current.showModal()
  }

  const handleCloseModalClick = () => {
    createWidgetReset()
    modalRef.current && modalRef.current.close()
  }

  const submitIsDisabled = useCallback(() => {
    return createWidgetIsLoading || !!createWidgetError?.formFieldErrors
  }, [createWidgetError?.formFieldErrors, createWidgetIsLoading])

  if (shouldRedirect) {
    return <Navigate to="/" replace />
  }
  const onSubmit: SubmitHandler<IFormInputs> = (data) => createWidget(data)

  return (
    <div className={styles['page-container']}>
      <PageHeader>
        <PageHeaderTitle>{t('widgets:listPage.title')}</PageHeaderTitle>
        <PageHeaderSubtitle variant={'body-1'} typographyColor={'gray-6'}>
          {t('widgets:listPage.subtitle')}
        </PageHeaderSubtitle>
      </PageHeader>

      <Spacer size={'8x'} />

      <div className={styles['list-description-container']}>
        <Typography tag="p" variant="emphasized-2" typographyColor="gray-4">
          {t('widgets:listPage.listDescription')}
        </Typography>

        {hasPermission && (
          <Button
            onClick={handleCreateWidgetButtonClick}
            size={'sm'}
            className={styles['create-widgets-button']}
          >
            {t('widgets:listPage.buttons.createWidget.label')}
          </Button>
        )}
      </div>

      <Spacer size={'6x'} />

      <WidgetList />

      <dialog ref={modalRef} className={styles['modal-container']}>
        <div>
          <Typography tag="h3" variant="headline-3" typographyColor="gray-8">
            {t('widgets:listPage.forms.createWidget.title')}
          </Typography>

          <Spacer />

          <Typography tag="p" variant="body-1" typographyColor="gray-6">
            {t('widgets:listPage.forms.createWidget.description')}
          </Typography>

          <Spacer />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t(
                      'widgets:listPage.forms.createWidget.fields.name.label'
                    )}
                    disabled={createWidgetIsLoading}
                    {...field}
                    fullWidth
                  />
                )}
              />
              {errors.name && (
                <p className={styles['error-text']}>{errors.name.message}</p>
              )}

              <Spacer />
              <div>
                <InputLabel>
                  {t(
                    'widgets:listPage.forms.createWidget.fields.description.label'
                  )}{' '}
                </InputLabel>
                <Spacer />
                <textarea
                  className={styles['textarea']}
                  {...register('description', { required: true })}
                ></textarea>
                {errors.description && (
                  <p className={styles['error-text']}>
                    {errors.description.message}
                  </p>
                )}
              </div>
            </div>
            <Spacer size={'8x'} />

            <div className={styles['modal-button-row']}>
              {createWidgetIsLoading ? (
                <Button type={'button'} size={'sm'} className={'not-allowed'}>
                  <Spinner size={'sm'} color={'white'} />
                </Button>
              ) : (
                <>
                  <Typography
                    className={'clickable'}
                    variant="body-2"
                    typographyColor="red-5"
                    onClick={handleCloseModalClick}
                  >
                    {t(
                      'widgets:listPage.forms.createWidget.buttons.close.label'
                    )}
                  </Typography>
                  <Button
                    type={'submit'}
                    disabled={submitIsDisabled()}
                    size={'sm'}
                  >
                    {t(
                      'widgets:listPage.forms.createWidget.buttons.submit.label'
                    )}
                  </Button>
                </>
              )}
            </div>
          </form>
        </div>
      </dialog>
    </div>
  )
}
