export default {
  header: {
    settings: {
      title: 'Configuración',
      subtitle: 'Datos personales de contacto y facturación.',
    },
    changePassword: {
      title: 'Cambiar Contraseña',
    },
  },
  forms: {
    settings: {
      fields: {
        email: { label: 'E-mail' },
        password: { label: 'Contraseña' },
        isCompany: { label: 'Soy una compañía' },
        firstName: { label: 'Nombre' },
        lastName: { label: 'Apellido' },
        companyName: { label: 'Razón social' },
        taxId: { label: 'Rut' },
      },
      changePasswordCta: 'Cambiar contraseña',
      submitButton: {
        label: 'Guardar',
      },
      successMessage: 'Datos actualizados satisfactoriamente.',
    },
    changePassword: {
      fields: {
        oldPassword: { label: 'Contraseña actual' },
        newPassword: {
          label: 'Contraseña nueva',
          bullets: {
            '1': 'Su contraseña no puede asemejarse tanto a su otra información personal.',
            '2': 'Su contraseña debe contener al menos 8 caracteres.',
            '3': 'Su contraseña no puede ser una clave utilizada comunmente.',
            '4': 'Su contraseña no puede ser completamente numérica.',
          },
        },
        newPasswordConfirm: { label: 'Contraseña nueva (confirmación)' },
      },
      submitButton: {
        label: 'Cambiar',
      },
      successMessage: 'Contraseña actualizada.',
    },
  },
}
