import { UseUsageUseCases } from 'src/domain'

type InitProps = {
  useUsageUseCases: UseUsageUseCases
}

export let useUsageUseCases: UseUsageUseCases

export const init = (props: InitProps) => {
  useUsageUseCases = props.useUsageUseCases
}
