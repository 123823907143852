import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Language, LanguageAbleUrl } from 'src/domain'

export const useLanguage = () => {
  const { i18n } = useTranslation()

  const [language, setLanguage] = useState<Language>(i18n.language as Language)

  useEffect(() => setLanguage(i18n.language as Language), [i18n.language])

  const getUrlByLanguage = (url: LanguageAbleUrl) => {
    return url[language]
  }

  return {
    language,
    setLanguage,
    i18n,
    getUrlByLanguage,
  }
}
