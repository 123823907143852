import { Suspense } from 'react'

import { DashboardRoutes } from './routes'
import { Spinner } from '@prometeoapi/afrodita-react-component-lib'

function App() {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Spinner size="xl" />
        </div>
      }
    >
      <DashboardRoutes />
    </Suspense>
  )
}

export default App
