import { UseWidgetUseCases } from 'src/domain'

type MakeWidgetUseCasesProps = {
  useWidgetUseCases: UseWidgetUseCases
}

type MakeWidgetUseCases = (props: MakeWidgetUseCasesProps) => UseWidgetUseCases

export const makeWidgetUseCases: MakeWidgetUseCases =
  ({ useWidgetUseCases }) =>
  () =>
    useWidgetUseCases()
