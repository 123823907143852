import { FC, MouseEventHandler, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { Outlet } from 'react-router-dom'
import { useAuth } from 'src/infrastructure'
import { MINUTES_INTERVAL } from 'src/config'
import { APIError } from 'src/adapters'
import {
  BaseTemplateContainer,
  BaseTemplateHeader,
  BaseTemplateCollapsingNavContainer,
  BaseTemplateMainContainer,
  BaseTemplateLeftSidebarContainer,
  BaseTemplateMain,
  BaseTemplateHeaderIcon,
  BaseTemplateHeaderLogo,
  BaseTemplateHeaderToggleMenuButton,
  DashboardNav,
  DashboardNavSticky,
  BaseTemplateHeaderContainer,
} from 'src/presentation'
import { useAuthUseCases } from 'src/service-locator'

export const BaseTemplate: FC = () => {
  const { logout, user, setUser } = useAuth()
  const { saveAccessToken, refreshAccessToken } = useAuthUseCases()

  const onIdle = () => {
    logout()
  }

  const onActive = async () => {
    try {
      if (user?.refreshToken) {
        const { accessToken, sesameToken } = await refreshAccessToken(
          user.refreshToken
        )
        saveAccessToken(accessToken)
        setUser((prevUser) => prevUser && { ...prevUser, sesameToken })
      } else {
        logout()
      }
    } catch (e) {
      if (e instanceof APIError) {
        logout()
      } else {
        throw e
      }
    }
  }

  useIdleTimer({
    onIdle,
    onActive,
    timeout: MINUTES_INTERVAL,
    throttle: 500,
  })

  const [expandedMobileNav, setExpandedMobileNav] = useState(false)

  const handleToggleMenuClick: MouseEventHandler<HTMLButtonElement> = () => {
    setExpandedMobileNav(!expandedMobileNav)
  }

  const handleNavLiClick: MouseEventHandler<HTMLLIElement> = () => {
    setExpandedMobileNav(!expandedMobileNav)
  }

  return (
    <BaseTemplateContainer>
      <BaseTemplateHeaderContainer>
        <BaseTemplateHeader>
          <BaseTemplateHeaderLogo />
          <BaseTemplateHeaderIcon />
          <BaseTemplateHeaderToggleMenuButton onClick={handleToggleMenuClick} />
        </BaseTemplateHeader>
      </BaseTemplateHeaderContainer>

      <BaseTemplateCollapsingNavContainer expanded={expandedMobileNav}>
        <DashboardNav onLiClick={handleNavLiClick} />
      </BaseTemplateCollapsingNavContainer>

      <BaseTemplateMainContainer>
        <BaseTemplateLeftSidebarContainer>
          <DashboardNavSticky />
        </BaseTemplateLeftSidebarContainer>

        <BaseTemplateMain>
          <Outlet />
        </BaseTemplateMain>
      </BaseTemplateMainContainer>
    </BaseTemplateContainer>
  )
}
