import { FC, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthWithSesameLogin } from 'src/infrastructure'

type PublicRoutesProps = {
  children: ReactNode
}

export const PublicRoutes: FC<PublicRoutesProps> = ({ children }) => {
  const { user } = useAuthWithSesameLogin()

  if (user) {
    return <Navigate to="/" replace />
  }

  return <>{children}</>
}
