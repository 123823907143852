import { FC, useCallback, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { Link } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import {
  Card,
  Button,
  Spacer,
  CheckboxLabeled,
  Typography,
} from '@prometeoapi/afrodita-react-component-lib'

import {
  UserSettings,
  UpdateUserSettingsArgs,
  UpdateUserSettingsReturns,
  UpdateUserSettingsValidationErrors,
} from 'src/domain'
import {
  UpdateUserSettingsResponseErrors,
  useAuthWithRoles,
} from 'src/infrastructure'

import {
  mapToUpdateUserSettingsValidationErrors,
  useMarketplaceFormMutation,
  useMarketplaceQuery,
} from 'src/adapters'
import {
  PageHeader,
  PageHeaderSubtitle,
  PageHeaderTitle,
  useSuccessMutationAlert,
} from 'src/presentation'
import { useUserSettingsUseCases } from 'src/service-locator'
import * as settings from 'src/config/settings'

import styles from './user-settings.module.scss'
import { ACTION_ROLES } from 'src/config'
import { InputPassword, InputText } from 'src/components'

type LinkProtectedProps = {
  to: string
  checkRoles: boolean
  allowedRoles: Array<any>
}

export const LinkProtected = (props: LinkProtectedProps) => {
  const { to, checkRoles, allowedRoles } = props
  const { hasPermission } = useAuthWithRoles(allowedRoles)
  const { t } = useTranslation(['shared', 'userSettings'])

  if (!hasPermission && checkRoles) {
    return null
  }

  return (
    <Link to={to}>
      <Typography typographyColor={'brand'}>
        {t('userSettings:forms.settings.changePasswordCta')}
      </Typography>
    </Link>
  )
}

export const UserSettingsPage: FC = () => {
  const { t } = useTranslation(['shared', 'userSettings'])

  const userSettingsUseCases = useUserSettingsUseCases()

  const userSettingsQuery = useMarketplaceQuery<UserSettings>({
    queryKey: settings.USER_SETTINGS_QUERY_KEY,
    queryFunction: userSettingsUseCases.getUserSettings,
  })
  const queryClient = useQueryClient()
  const [isCompanyCheck, setIsCompanyCheck] = useState(
    userSettingsQuery.data?.isCompany
  )

  const formSchema = Yup.object({
    email: Yup.string()
      .email(
        t('shared:form.errors.emailField', {
          name: t('userSettings:forms.settings.fields.email.label'),
        })
      )
      .required(
        t('shared:form.errors.requiredField', {
          name: t('userSettings:forms.settings.fields.email.label'),
        })
      ),
    isCompany: Yup.boolean(),
    firstName: Yup.string().max(
      30,
      t('shared:form.errors.maxLength', {
        name: t('userSettings:forms.settings.fields.firstName.label'),
        max: 30,
      })
    ),
    lastName: Yup.string().max(
      100,
      t('shared:form.errors.maxLength', {
        name: t('userSettings:forms.settings.fields.lastName.label'),
        max: 100,
      })
    ),
    companyName: Yup.string().max(
      100,
      t('shared:form.errors.maxLength', {
        name: t('userSettings:forms.settings.fields.companyName.label'),
        max: 100,
      })
    ),
    taxId: Yup.string().max(
      100,
      t('shared:form.errors.maxLength', {
        name: t('userSettings:forms.settings.fields.taxId.label'),
        max: 100,
      })
    ),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: userSettingsQuery.data?.firstName,
      lastName: userSettingsQuery.data?.lastName,
      email: userSettingsQuery.data?.email,
      isCompany: userSettingsQuery.data?.isCompany,
      companyName: userSettingsQuery.data?.companyName,
      taxId: userSettingsQuery.data?.taxId,
      password: '',
    },
    resolver: yupResolver(formSchema),
  })

  const updateUserSettingsMutation = useMarketplaceFormMutation<
    UpdateUserSettingsArgs,
    UpdateUserSettingsReturns,
    UpdateUserSettingsResponseErrors,
    UpdateUserSettingsValidationErrors
  >({
    requestFunction: userSettingsUseCases.updateUserSettings,
    errorMapper: mapToUpdateUserSettingsValidationErrors,
    onSuccessCallback: async () => {
      setShowAlert(true)
      await queryClient.invalidateQueries(settings.USER_SETTINGS_QUERY_KEY)
    },
  })

  const {
    mutate: updateUserSettings,
    error: updateUserSettingsError,
    isSuccess: updateUserSettingsIsSuccess,
    isLoading: updateUserSettingsIsLoading,
  } = updateUserSettingsMutation

  const { renderAlert, showAlert, setShowAlert } = useSuccessMutationAlert({
    isSuccess: updateUserSettingsIsSuccess,
    body: t('userSettings:forms.settings.successMessage'),
    spacerSize: '10x',
  })

  // TODO: DRY this
  const submitIsDisabled = useCallback(() => {
    return (
      updateUserSettingsIsLoading || !!updateUserSettingsError?.formFieldErrors
    )
  }, [updateUserSettingsError?.formFieldErrors, updateUserSettingsIsLoading])

  // TODO: loading page component
  if (userSettingsQuery.isLoading) {
    return <div>Cargando</div>
  }

  const onSubmit = (data: any) => {
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      isCompany: isCompanyCheck,
      companyName: data.companyName,
    }
    updateUserSettings(payload)
  }

  return (
    <div className={styles['page-container']}>
      <PageHeader>
        <PageHeaderTitle>
          {t(`userSettings:header.settings.title`)}
        </PageHeaderTitle>
        <PageHeaderSubtitle>
          {t(`userSettings:header.settings.subtitle`)}
        </PageHeaderSubtitle>
      </PageHeader>
      <Spacer size={'10x'} />
      {showAlert && renderAlert()}

      <div className={styles['container-card']}>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <Card className={styles['container']}>
            <div className={styles['row-fields']}>
              <div className={styles['col-field']}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={userSettingsQuery.data?.email}
                  render={({ field }) => (
                    <InputText
                      {...field}
                      type="email"
                      label={t(
                        'userSettings:forms.settings.fields.email.label'
                      )}
                      error={errors?.email?.message}
                    />
                  )}
                />
              </div>
              <div className={styles['col-field']}>
                <Controller
                  name="password"
                  control={control}
                  // eslint-disable-next-line
                  render={({ field }) => (
                    <InputPassword
                      type="password"
                      label={t(
                        'userSettings:forms.settings.fields.password.label'
                      )}
                      disabled
                    />
                  )}
                />

                <Spacer size={'1x'} />

                <LinkProtected
                  to={'/change-password'}
                  checkRoles={true}
                  allowedRoles={ACTION_ROLES['user_change_password_action']}
                />
              </div>
            </div>
            <div className={styles['row-fields']}>
              <div className={styles['col-field']}>
                <Controller
                  name="isCompany"
                  control={control}
                  defaultValue={userSettingsQuery.data?.isCompany}
                  render={({ field }) => (
                    <CheckboxLabeled
                      checked={isCompanyCheck}
                      label={t(
                        'userSettings:forms.settings.fields.isCompany.label'
                      )}
                      onChange={(e: any) => {
                        field.onChange(setIsCompanyCheck(e.target.checked))
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className={styles['row-fields']}>
              <div className={styles['col-field']}>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue={userSettingsQuery.data?.firstName}
                  render={({ field }) => (
                    <InputText
                      {...field}
                      type="text"
                      label={t(
                        'userSettings:forms.settings.fields.firstName.label'
                      )}
                      error={errors?.firstName?.message}
                    />
                  )}
                />
              </div>
              <div className={styles['col-field']}>
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue={userSettingsQuery.data?.lastName}
                  render={({ field }) => (
                    <InputText
                      {...field}
                      type="text"
                      label={t(
                        'userSettings:forms.settings.fields.lastName.label'
                      )}
                      error={errors?.lastName?.message}
                    />
                  )}
                />
              </div>
            </div>
            <div className={styles['row-fields']}>
              <div className={styles['col-field']}>
                <Controller
                  name="companyName"
                  control={control}
                  defaultValue={userSettingsQuery.data?.companyName}
                  render={({ field }) => (
                    <InputText
                      {...field}
                      type="text"
                      label={t(
                        'userSettings:forms.settings.fields.companyName.label'
                      )}
                      error={errors?.companyName?.message}
                    />
                  )}
                />
              </div>
              <div className={styles['col-field']}>
                <Controller
                  name="taxId"
                  control={control}
                  defaultValue={userSettingsQuery.data?.taxId}
                  render={({ field }) => (
                    <InputText
                      {...field}
                      type="text"
                      label={t(
                        'userSettings:forms.settings.fields.taxId.label'
                      )}
                      error={errors?.taxId?.message}
                    />
                  )}
                />
              </div>
            </div>
          </Card>
          <Spacer size={'5x'} />
          <div className={styles['button-container']}>
            <Button
              className={styles['button-submit']}
              type={'submit'}
              disabled={submitIsDisabled()}
            >
              {t('userSettings:forms.settings.submitButton.label')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
