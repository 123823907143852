import { HomeStageOptions } from 'src/domain'

export const SALES_EMAIL =
  process.env.REACT_APP_SALES_EMAIL ?? 'ventas@prometeoapi.com'

export const DOC_URL =
  process.env.REACT_APP_DOC_URL ?? 'https://docs.prometeoapi.com/docs'

export const DOC_WIDGET_PATH =
  process.env.REACT_APP_DOC_WIDGET_PATH ?? '/integration-widget'

export const DOC_WIDGET_URL = DOC_URL + DOC_WIDGET_PATH

export const DEMO_URL =
  process.env.REACT_APP_DEMO_URL ?? 'https://demo.prometeoapi.com/'

export const SESAME_QUERY_PARAM =
  process.env.REACT_APP_SESAME_QUERY_PARAM ?? 'sesame'

// Marketplace settings

export const MEDIUM_URL =
  process.env.REACT_APP_MEDIUM_URL ?? 'https://prometeoopenbanking.medium.com'

export const MARKETPLACE_HOST =
  process.env.REACT_APP_MARKETPLACE_HOST ?? 'https://registro.prometeoapi.com'

export const NEW_SITE =
  process.env.REACT_APP_NEW_SITE ?? 'https://prometeoapi.com'

export const REQUEST_PREPRODUCTION_FORM_URL = `${MARKETPLACE_HOST}/dashboard/request-preproduction`

export const REQUEST_PRODUCTION_FORM_URL = `${MARKETPLACE_HOST}/dashboard/request-production`

export const SPARK_FORM_URL = `${MARKETPLACE_HOST}/accounts/create-spark`

export const CREATE_ACCOUNT_FORM_URL = `${MARKETPLACE_HOST}/accounts/create`

export const RESET_PASSWORD_FORM_URL = `${MARKETPLACE_HOST}/accounts/reset-password`

export const LEGACY_DASHBOARD_URL = {
  usage: `${MARKETPLACE_HOST}/dashboard/usage`,
  settings: `${MARKETPLACE_HOST}/dashboard/settings`,
  consumption: `${MARKETPLACE_HOST}/dashboard/consumption`,
}

export const WEB_USE_CASES_URL = {
  es: `${MARKETPLACE_HOST}#casos-de-uso`,
  en: `${MARKETPLACE_HOST}/en/#use-cases`,
}

export const WEB_HELP_CENTER_URL = (() => {
  if (navigator.language === 'en') {
    return `${NEW_SITE}/en/help-center`
  } else {
    return `${NEW_SITE}/centro-de-ayuda`
  }
})()

export const WEB_PAYMENT_APIS_URL = (() => {
  if (navigator.language === 'en') {
    return `${NEW_SITE}/api-pagos`
  } else {
    return `${NEW_SITE}/en/payments-api`
  }
})()

export const WEB_BI_ACCESS_URL = (() => {
  if (navigator.language === 'en') {
    return `${NEW_SITE}/en/banking-information-access`
  } else {
    return `${NEW_SITE}/acceso-a-informacion-bancaria`
  }
})()

export type NavbarItem = {
  [key: string]: string
}

export const navbarTranslation: Record<any, NavbarItem> = {
  es: {
    paymentApi: `${NEW_SITE}/pago-transferencia`,
    paymentsQR: `${NEW_SITE}/pagos-cobros-codigo-qr`,
    paymentslink: `${NEW_SITE}/link-de-pagos`,
    cashManagement: `${NEW_SITE}/gestion-tesoreria`,
    validationApi: `${NEW_SITE}/validacion-cuenta-bancaria`,
    banks: `${NEW_SITE}/bancos`,
    credits: `${NEW_SITE}/creditos-prestamos`,
    ecommerce: `${NEW_SITE}/ecommerce`,
    paymentGateway: `${NEW_SITE}/pasarela-de-pago`,
    services: `${NEW_SITE}/servicio-transferencia-bancaria`,
    partnersProgram: `${NEW_SITE}/prometeo-partners-program`,
    helpCenter: `${NEW_SITE}/centro-de-ayuda`,
    security: `${NEW_SITE}/seguridad`,
    glossary: `${NEW_SITE}/glosario`,
    reports: `${NEW_SITE}/reportes`,
    aboutUs: `${NEW_SITE}/sobre-nosotros`,
    workWithUs: `${NEW_SITE}/trabajar-con-nosotros`,
    securityPolicy: `${NEW_SITE}/politica-de-seguridad`,
    privacyPolicy: `${NEW_SITE}/politica-de-privacidad`,
    blog: `${NEW_SITE}/blog`,
  },
  en: {
    paymentApi: `${NEW_SITE}/en/bank-transfer-payments`,
    paymentsQR: `${NEW_SITE}/en/qr-payments`,
    paymentslink: `${NEW_SITE}/en/payment-link`,
    cashManagement: `${NEW_SITE}/en/cash-management`,
    validationApi: `${NEW_SITE}/en/account-validation`,
    banks: `${NEW_SITE}/en/banks`,
    credits: `${NEW_SITE}/en/credits-loans`,
    ecommerce: `${NEW_SITE}/en/ecommerce`,
    paymentGateway: `${NEW_SITE}/en/payment-gateway`,
    services: `${NEW_SITE}/en/payment-bank-wire`,
    partnersProgram: `${NEW_SITE}/en/prometeo-partners-program`,
    documentation: 'Documentation',
    helpCenter: `${NEW_SITE}/en/help-center`,
    security: `${NEW_SITE}/en/security`,
    glossary: `${NEW_SITE}/en/glossary`,
    reports: `${NEW_SITE}/en/reports`,
    aboutUs: `${NEW_SITE}/en/about-us`,
    workWithUs: `${NEW_SITE}/en/work-with-us`,
    securityPolicy: `${NEW_SITE}/en/security-policy`,
    privacyPolicy: `${NEW_SITE}/en/privacy-policy`,
    blog: `${NEW_SITE}/en/blog`,
  },
}
///

export const WEB_PRESS_URL = {
  es: `${MARKETPLACE_HOST}/prensa`,
  en: `${MARKETPLACE_HOST}/en/press`,
}

export const ACCESS_TOKEN_KEY =
  process.env.REACT_APP_LOCAL_STORAGE_ACCESS_TOKEN_KEY ?? '__accessToken__'

export const REFRESH_TOKEN_KEY =
  process.env.REACT_APP_LOCAL_STORAGE_REFRESH_TOKEN_KEY ?? '__refreshToken__'

export const MARKETPLACE_DEFAULT_STAGE: HomeStageOptions = 'sandbox'

// React Query settings

const REACT_QUERY_STALE_TIME_MINUTES = parseInt(
  process.env.REACT_QUERY_STALE_TIME_MINUTES ?? '30'
)

export const QUERY_CLIENT_CONFIG = {
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * REACT_QUERY_STALE_TIME_MINUTES,
    },
  },
}

export const USER_SETTINGS_QUERY_KEY = 'userSettings'

export const HOME_CONTEXT_QUERY_KEY = 'homeContext'

export const USAGE_QUERY_KEY = 'usage'

export const LIST_ALL_WIDGETS_QUERY_KEY = 'listAllWidgets'

export const CONSUMPTION_QUERY_KEY = 'consumption'

export const GET_PAYMENTS_QUERY_KEY = 'getPayments'

export const DOWNLOAD_PAYMENTS_QUERY_KEY = 'downloadPayments'

export const GET_WIDGET_QUERY_KEY = 'getWidget'

export const GET_BANKING_PROVIDERS_QUERY_KEY = 'getBankingProviders'

export const GET_WIDGET_COUNTRIES_QUERY_KEY = 'getWidgetCountries'

export const GET_WIDGET_PROVIDERS_QUERY_KEY = 'getWidgetProviders'

export const GET_WIDGET_DESTINATION_ACCOUNT_QUERY_KEY =
  'getWidgetDestinationAccount'

// Auth Provider
const REFRESH_TOKEN_MINUTES_INTERVAL = parseInt(
  process.env.REACT_APP_REFRESH_TOKEN_MINUTES_INTERVAL ?? '7'
)
export const MINUTES_INTERVAL = REFRESH_TOKEN_MINUTES_INTERVAL * 1000 * 60

// Widget
export const WIDGET_CLOUDFRONT_URL =
  process.env.REACT_APP_WIDGET_CLOUDFRONT_URL ?? 'WIDGET_URL'

export const REACT_APP_WIDGET_SHOW_IN_MENU = process.env
  .REACT_APP_WIDGET_SHOW_IN_MENU
  ? process.env.REACT_APP_WIDGET_SHOW_IN_MENU === 'true'
  : true

// Hubspot tracking settings
export const HUBSPOT_TRACKING_URL =
  process.env.REACT_APP_HUBSPOT_TRACKING_URL ??
  'https://prometeoopenbanking.medium.com'
