import { useAuth } from 'src/infrastructure'
import { getSesameMagicLink } from 'src/utils'

export const useLegacyDashboardLink = () => {
  const { user } = useAuth()

  const getMarketplaceLegacyDashboardUrl = (url: string) => {
    if (user?.sesameToken) {
      return getSesameMagicLink(url, user.sesameToken)
    }
    return url
  }

  return {
    getMarketplaceLegacyDashboardUrl,
  }
}
