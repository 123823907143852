import { RegisterAccountUseCases } from 'src/features/register/domain'
import { useCreateAccountService } from 'src/features/register/infrastructure'
import { UseRegisterAccountUseCasesAdapter } from '../../definition'
import { mapToRegisterAccountPayload, mapToAccount } from 'src/adapters'

export const useRegisterAccountAdapter: UseRegisterAccountUseCasesAdapter =
  () => {
    const createAccountService = useCreateAccountService()

    const createAccount: RegisterAccountUseCases['createAccount'] = async (
      args
    ) => {
      const registerAccountPayload = mapToRegisterAccountPayload(args)
      const registerAccountResponse = await createAccountService.createAccount(
        registerAccountPayload
      )
      return mapToAccount(registerAccountResponse.data)
    }

    return {
      createAccount,
    }
  }
