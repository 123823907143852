import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Navigate, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {
  Button,
  SelectField,
  Spacer,
  Typography,
  TextField,
} from '@prometeoapi/afrodita-react-component-lib'

import { useWidgetUseCases } from 'src/service-locator'
import { WidgetsService } from 'src/infrastructure'
import {
  mapToUpdateDestinationAccountValidationErrors,
  useMarketplaceFormMutation,
  useMarketplaceQuery,
} from 'src/adapters'
import {
  GetDestinationAccountReturns,
  Home,
  UpdateDestinationAccountArgs,
  UpdateDestinationAccountReturns,
  UpdateDestinationAccountValidationErrors,
} from 'src/domain'
import {
  useDestinationAccountFormUtils,
  useSuccessMutationAlert,
  PageHeader,
  PageHeaderTitle,
  PageHeaderBreadCrumbs,
  PageCard,
  PageCardTitle,
  useHomeQuery,
  useHomeQueryDataHelpers,
} from 'src/presentation'
import { getBankAccountBranch, useNonNullableParams } from 'src/utils'
import * as settings from 'src/config/settings'

import styles from '../../widget-destination-accounts-pages.module.scss'

export const WidgetDestinationAccountsEditPage: FC = () => {
  const navigate = useNavigate()

  const { t } = useTranslation(['shared', 'widgets'])
  const { data, isSuccess } = useHomeQuery()
  const { isInProduction: isProd } = useHomeQueryDataHelpers()
  const shouldRedirect =
    !settings.REACT_APP_WIDGET_SHOW_IN_MENU ||
    (isSuccess && !isProd(data as Home))

  const {
    // emptyDestinationAccountFormFields,
    destinationAccountDefaultsFormFieldsValidationSchema,
  } = useDestinationAccountFormUtils()

  const widgetUseCases = useWidgetUseCases()

  const { widgetId, destinationAccountId } = useNonNullableParams(
    'widgetId',
    'destinationAccountId'
  )
  const destinationAccountQueryKey = [
    settings.GET_WIDGET_DESTINATION_ACCOUNT_QUERY_KEY,
    destinationAccountId,
  ]

  const getWidgetQueryKey = [settings.GET_WIDGET_QUERY_KEY, widgetId]

  const destinationAccountQuery =
    useMarketplaceQuery<GetDestinationAccountReturns>({
      queryKey: destinationAccountQueryKey,
      queryFunction: () =>
        widgetUseCases.getDestinationAccount(destinationAccountId),
    })

  const validationSchema = Yup.object({
    ...destinationAccountDefaultsFormFieldsValidationSchema,
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const queryClient = useQueryClient()

  const updateDestinationAccountMutation = useMarketplaceFormMutation<
    UpdateDestinationAccountArgs,
    UpdateDestinationAccountReturns,
    WidgetsService.UpdateDestinationAccountResponseErrors,
    UpdateDestinationAccountValidationErrors
  >({
    requestFunction: widgetUseCases.updateDestinationAccount,
    errorMapper: mapToUpdateDestinationAccountValidationErrors,
    onSuccessCallback: async () => {
      setShowSuccessAlert(true)
      await queryClient.invalidateQueries(destinationAccountQueryKey)
      await queryClient.invalidateQueries(getWidgetQueryKey)
      navigate(-1)
    },
  })

  const {
    mutate: updateDestinationAccount,
    isSuccess: updateDestinationAccountIsSuccess,
    isLoading: updateDestinationAccountIsLoading,
  } = updateDestinationAccountMutation

  const {
    renderAlert: renderSuccessAlert,
    showAlert: showSuccessAlert,
    setShowAlert: setShowSuccessAlert,
  } = useSuccessMutationAlert({
    isSuccess: updateDestinationAccountIsSuccess,
    body: t('widgets:destinationAccountsPages.forms.successMessage.edit'),
    spacerSize: '2x',
  })

  const submitIsDisabled = useCallback(() => {
    return updateDestinationAccountIsLoading
  }, [updateDestinationAccountIsLoading])

  if (destinationAccountQuery.isLoading) {
    return <div>Cargando</div>
  }

  if (shouldRedirect) {
    return <Navigate to="/" replace />
  }

  const onSubmit = (values: any) => {
    updateDestinationAccount({
      ...values,
      bankAccountBranch: getBankAccountBranch(values.bankAccountBranch),
      id: parseInt(destinationAccountId),
    })
  }

  return (
    <div>
      <PageHeader>
        <PageHeaderBreadCrumbs spacerSize={'6x'}>
          <PageHeaderBreadCrumbs spacerSize={'6x'}>
            <Typography tag="p" variant="body-1" typographyColor="gray-6">
              <a href="/widgets" style={{ color: '#2f3a44' }}>
                Widgets
              </a>
              {` > `}
              <a href={`/widgets/${widgetId}`} style={{ color: '#2f3a44' }}>
                {`Widget #${widgetId}`}
              </a>
              {` > ${t(
                'widgets:destinationAccountsPages.header.edit.breadcrumb'
              )}`}
            </Typography>
          </PageHeaderBreadCrumbs>
        </PageHeaderBreadCrumbs>
        <PageHeaderTitle>
          {t('widgets:destinationAccountsPages.header.edit.title')}
        </PageHeaderTitle>
      </PageHeader>

      <Spacer size={'7x'} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <PageCard>
          <PageCardTitle variant={'strong-1'} typographyColor={'gray-6'}>
            {t('widgets:destinationAccountsPages.forms.title')}
          </PageCardTitle>

          {showSuccessAlert && renderSuccessAlert()}

          <div className={styles['selects-container']}>
            <Controller
              name="bankCountry"
              control={control}
              render={({ field }) => (
                <SelectField
                  label={t(
                    'widgets:destinationAccountsPages.forms.fields.bankCountry.label'
                  )}
                  disabled={true}
                  placeholder={destinationAccountQuery?.data?.bank.country.name}
                  {...field}
                />
              )}
            />
            <Controller
              name="bankCode"
              control={control}
              render={({ field }) => (
                <SelectField
                  label={t(
                    'widgets:destinationAccountsPages.forms.fields.bankCode.label'
                  )}
                  disabled={true}
                  placeholder={destinationAccountQuery?.data?.bank.name}
                  {...field}
                />
              )}
            />
          </div>

          <Spacer size={'2x'} />

          <div className={styles['text-fields-container']}>
            <div className={styles['top-text-fields-container']}>
              <Controller
                name="bankAccountOwner"
                control={control}
                defaultValue={
                  destinationAccountQuery.data?.bankAccountOwner ?? ''
                }
                render={({ field }) => (
                  <TextField
                    label={t(
                      'widgets:destinationAccountsPages.forms.fields.bankAccountOwner.label'
                    )}
                    fieldName={'bankAccountOwner'}
                    fullWidth
                    {...field}
                    caption={
                      errors?.bankAccountOwner && (
                        <p className={styles['error-text']}>
                          {errors?.bankAccountOwner?.message as string}
                        </p>
                      )
                    }
                  />
                )}
              />

              <Controller
                name="bankAccount"
                control={control}
                defaultValue={destinationAccountQuery.data?.bankAccount ?? ''}
                render={({ field }) => (
                  <TextField
                    type={'tel'}
                    label={t(
                      'widgets:destinationAccountsPages.forms.fields.bankAccount.label'
                    )}
                    fieldName={'bankAccount'}
                    fullWidth
                    {...field}
                    caption={
                      errors.bankAccount && (
                        <p className={styles['error-text']}>
                          {errors.bankAccount.message as string}
                        </p>
                      )
                    }
                  />
                )}
              />
            </div>
          </div>

          <Spacer size={'2x'} />

          <div className={styles['selects-container']}>
            <Controller
              name="bankAccountBranch"
              control={control}
              defaultValue={
                destinationAccountQuery.data?.bankAccountBranch ?? ''
              }
              render={({ field }) => (
                <TextField
                  type={'tel'}
                  label={t(
                    'widgets:destinationAccountsPages.forms.fields.bankAccountBranch.label'
                  )}
                  fieldName={'bankAccountBranch'}
                  fullWidth
                  {...field}
                  caption={
                    errors.bankAccountBranch && (
                      <p className={styles['error-text']}>
                        {errors.bankAccountBranch.message as string}
                      </p>
                    )
                  }
                />
              )}
            />

            <Controller
              name="destinationAccountType"
              control={control}
              render={({ field }) => (
                <SelectField
                  label={t(
                    'widgets:destinationAccountsPages.forms.fields.destinationAccountType.label'
                  )}
                  disabled={true}
                  {...field}
                  placeholder={
                    destinationAccountQuery?.data?.destinationAccountType
                  }
                />
              )}
            />
          </div>
        </PageCard>

        <Spacer size={'7x'} />

        <div className={styles['buttons-container']}>
          <Button
            type={'button'}
            variant={'secondary'}
            onClick={() => navigate(-1)}
          >
            {t('widgets:destinationAccountsPages.forms.buttons.cancel')}
          </Button>
          <Button type={'submit'} disabled={submitIsDisabled()}>
            {t('widgets:destinationAccountsPages.forms.buttons.submit')}
          </Button>
        </div>
      </form>
    </div>
  )
}
