import {
  UseAuthUseCases,
  UseTokenTransactions,
  UseTokenActions,
  UseUserActions,
} from '../definition'

type MakeUseAuthCasesProps = {
  useTokenTransactions: UseTokenTransactions
  useTokenActions: UseTokenActions
  useUserActions: UseUserActions
}

type MakeUseAuthUseCases = (props: MakeUseAuthCasesProps) => UseAuthUseCases

export const makeUseAuthUseCases: MakeUseAuthUseCases =
  ({ useTokenTransactions, useTokenActions, useUserActions }) =>
  () => {
    return {
      ...useTokenActions(),
      ...useTokenTransactions(),
      ...useUserActions(),
    }
  }
