import { Anchor } from '@lib-components'
import { useTranslation } from 'react-i18next'

import { Typography } from '@prometeoapi/afrodita-react-component-lib'
import * as settings from 'src/config/settings'
import styles from './use-cases.module.scss'
import { IconSprite } from '../../../icon-sprite'

export const UseCases = () => {
  const { t, i18n } = useTranslation(['auth'])
  const locale = i18n.language && i18n.language == 'en' ? 'en' : 'es'
  return (
    <div className={styles['wrapper']}>
      <div className={styles['content-left']}>
        <Typography variant={'strong-1'} typographyColor={'gray-5'}>
          {t(`auth:nav.useCasesNav.ourUseCases`)}
        </Typography>
        <Typography variant="small-1" typographyColor="gray-5">
          {t(`auth:nav.useCasesNav.useCasesDescription`)}
        </Typography>
      </div>
      <div className={styles['content-right']}>
        <Anchor
          href={settings.navbarTranslation[locale]['banks']}
          underlineOnHover={false}
        >
          <IconSprite className="banks" title={t('auth:nav.bank')} />
        </Anchor>
        <Anchor
          href={settings.navbarTranslation[locale]['credits']}
          underlineOnHover={false}
        >
          <IconSprite className="credits" title={t('auth:nav.credits')} />
        </Anchor>
        <Anchor
          href={settings.navbarTranslation[locale]['ecommerce']}
          underlineOnHover={false}
        >
          <IconSprite className="ecommerce" title={t('auth:nav.ecommerce')} />
        </Anchor>
        <Anchor
          href={settings.navbarTranslation[locale]['paymentGateway']}
          underlineOnHover={false}
        >
          <IconSprite
            className="payment-gateway"
            title={t('auth:nav.paymentGateway')}
          />
        </Anchor>
        <Anchor
          href={settings.navbarTranslation[locale]['services']}
          underlineOnHover={false}
        >
          <IconSprite className="services" title={t('auth:nav.services')} />
        </Anchor>
      </div>
    </div>
  )
}
