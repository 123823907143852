import React, { FC, ReactNode } from 'react'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { QUERY_CLIENT_CONFIG } from 'src/config'

export type QueryClientProviderProps = {
  children: ReactNode
}

export const ReactQueryClientProvider: FC<QueryClientProviderProps> = ({
  children,
}) => {
  const client = new QueryClient(QUERY_CLIENT_CONFIG)
  return (
    <QueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
