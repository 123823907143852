import classNames from 'classnames'

import { Styled } from '@lib-types'

export function getStyledElementProps(
  elementProps: Styled,
  defaultClassName: string
) {
  const { className } = elementProps
  return {
    ...elementProps,
    className: classNames(defaultClassName, {
      [`${className}`]: className,
    }),
  }
}

export function callAll(...fns: any[]) {
  return (...args: any) => fns.forEach((fn) => fn && fn(...args))
}

export const makeKeyedObject =
  (counter = 0) =>
  (keyPrefix: string) =>
  (valueKey: string) =>
  (value: any) => {
    const keyValue = `${keyPrefix}-${counter}}`
    counter++
    return {
      key: keyValue,
      [valueKey]: value,
    }
  }
