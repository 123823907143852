import { useMarketplaceClient } from 'src/adapters'
import {
  ResetPasswordPayload,
  ResetPasswordConfirmPayload,
  ResetPasswordResponse,
} from 'src/infrastructure'
export const useResetPasswordService = () => {
  const { privateClient } = useMarketplaceClient()

  return {
    resetPassword: (payload: ResetPasswordPayload) => {
      return privateClient.post<ResetPasswordResponse>(
        'api/dashboard/reset-password',
        payload
      )
    },
    updatePassword: (payload: ResetPasswordConfirmPayload) => {
      return privateClient.post<ResetPasswordResponse>(
        `api/dashboard/reset-password-confirm/${payload.uuid64}/${payload.token}`,
        payload
      )
    },
  }
}
