export default {
  summaryPage: {
    title: 'Pagos',
    monthlyReport: {
      title: 'Pagos mes de {{name}}',
      sections: {
        qtyPaymentLabel: 'Transacciones Totales',
        amountPaymentLabel: 'Dinero Total',
        avgPaymentLabel: 'Dinero promedio por trans.',
        errorPaymentLabel: 'Transacciones con error',
      },
    },
  },
  paymentHistory: {
    loading: 'Cargando pagos...',
    error: 'Ha ocurrido un error cargando sus pagos.',
    empty: 'No has recibido ningun pago todavía.',
    title: 'Historial de Pagos',
    filters: {
      status: 'ESTADO',
      date: 'FECHA',
      amount: 'MONTO',
      min: 'min.',
      max: 'máx.',
      buttons: {
        confirmed: 'Confirmado',
        error: 'Error',
        rejected: 'Rechazado',
        delete: 'Borrar filtros',
      },
      search: 'Buscar Concepto, ID de Operación y de Transacción',
    },
    listToShow: 'Listado de',
    noResultFound: 'No se encontraron resultados',
    downloadLabel: 'DESCARGAR TABLA',
    downloadError: 'Hubo un problema al descargar el fichero',
    paginateInfo: 'Página {{current}} de {{total}}',
    tableHeaders: {
      originAccount: 'Cuenta Origen',
      destinationAccount: 'Cuenta Destino',
      destinationName: 'Nombre Cuenta Destino',
      destinationInstitutionName: 'Nombre Banco Destino',
      concept: 'Concepto',
      status: 'Estado',
      currency: 'Moneda',
      amount: 'Monto',
      createdAt: 'Fecha',
      operationId: 'ID Operacion',
      requestId: 'ID Transaccion',
      externalId: 'ID Externo',
    },
  },
  paymentLinkPage: {
    title: 'Link de pagos',
    batchButton: 'Nuevo batch',
    linkButton: 'Nuevo link',
    monthlyReport: {
      title: 'Pagos mes de {{name}}',
      sections: {
        qtyPaymentLabel: 'Transacciones Totales',
        amountPaymentLabel: 'Dinero Total',
        avgPaymentLabel: 'Dinero promedio por trans.',
        errorPaymentLabel: 'Transacciones con error',
      },
    },
    paymentLinkList: {
      loading: 'Cargando links...',
      error: 'Ha ocurrido un error cargando sus links.',
      empty: 'No has recibido ningun pago todavía.',
      title: 'Listado de links de pago',
      filters: {
        status: 'ESTADO',
        date: 'FECHA',
        amount: 'MONTO',
        payment: 'PAGO',
        min: 'min.',
        max: 'máx.',
        buttons: {
          confirmed: 'Activo',
          error: 'Vencido',
          rejected: 'Cancelado',
          payment: 'Pago',
          noPayment: 'No pago',
          delete: 'Borrar filtros',
        },
        search: 'Buscar Concepto o Id Externo',
        cancelButton: 'CANCELAR',
      },
      listToShow: 'Listado de',
      noResultFound: 'No se encontraron resultados',
      downloadLabel: 'DESCARGAR TABLA',
      downloadError: 'Hubo un problema al descargar el fichero',
      paginateInfo: 'Página {{current}} de {{total}}',
      tableHeaders: {
        createdAt: 'Fecha',
        collectionAccount: 'Cuenta Recaudadora',
        concept: 'Concepto',
        externalId: 'ID Externo',
        paymentId: 'Payment ID',
        link: 'Link',
        payment: 'Pago',
        status: 'Estado',
        currency: 'Moneda',
        amount: 'Monto',
      },
    },
  },
}
