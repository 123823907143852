import { ResetPasswordUseCases } from 'src/features/reset-password/domain'
import { useResetPasswordService } from 'src/features/reset-password/infrastructure'
import { UseResetPasswordUseCasesAdapter } from '../../definition'
import {
  mapToResetPasswordConfirmPayload,
  mapToResetPasswordPayload,
} from 'src/adapters'

export const useResetPasswordAdapter: UseResetPasswordUseCasesAdapter = () => {
  const resetPasswordService = useResetPasswordService()

  const resetPassword: ResetPasswordUseCases['resetPassword'] = async (
    args
  ) => {
    const resetPasswordPayload = mapToResetPasswordPayload(args)
    const changePasswordResponse = await resetPasswordService.resetPassword(
      resetPasswordPayload
    )
    return changePasswordResponse.data
  }

  const updatePassword: ResetPasswordUseCases['updatePassword'] = async (
    args
  ) => {
    const resetPasswordConfirmPayload = mapToResetPasswordConfirmPayload(args)
    const changePasswordResponse = await resetPasswordService.updatePassword(
      resetPasswordConfirmPayload
    )
    return changePasswordResponse.data
  }

  return {
    resetPassword,
    updatePassword,
  }
}
