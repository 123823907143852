export default {
  listPage: {
    title: 'Welcome to our Widget!',
    subtitle:
      'Offer your clients a simple and secure graphical interface to interact with our APIs; and increase the conversion rate of your processes.',
    listDescription:
      'Here you can see your list of available widgets to use and create a new one:',
    loading: 'Loading widget list...',
    error: 'An error occurred loading your widgets.',
    empty: "You haven't created any widgets yet.",
    buttons: {
      createWidget: {
        label: 'Create new widget',
      },
    },
    forms: {
      createWidget: {
        title: 'Create new widget:',
        description: 'To begin complete these fields',
        loading: 'Creating widget...',
        fields: {
          name: {
            label: 'Name',
          },
          description: {
            label: 'Description',
          },
        },
        buttons: {
          close: {
            label: 'Close',
          },
          submit: {
            label: 'Create new widget',
          },
        },
        errors: {
          generic: 'An error occurred creating the widget.',
        },
        successMessage: 'The widget has been created successfully',
        goToWidget: 'Edit Widget {{name}}',
      },
    },
  },
  configPage: {
    loading: 'Loading widget...',
    error: 'An error occurred loading your widget.',
    preview: 'Preview your widget',
    buttons: {
      genericEdit: {
        label: 'Edit',
      },
    },
    steps: {
      one: 'STEP 1',
      two: 'STEP 2',
      three: 'STEP 3',
      four: 'STEP 4',
    },
    appearance: {
      title: 'Appearance',
      description: 'Customize the design of your widget',
      incompleteWarning:
        'You need to enter the name, logo of your company and color of the button',
    },
    modules: {
      title: 'Modules',
      description:
        'Select the type of widget you want to offer to your customers.',
      incompleteWarning: 'You need to select Login or Login + Payments module',
    },
    providers: {
      title: 'Available countries, banks and accounts',
      description:
        'Set up the banks and accounts you need your widget to connect with.',
      incompleteWarning: 'You need to select at least one account',
    },
    account: {
      title: 'Collection account',
      description: 'Select the account where the money will go.',
      selected: 'Selected: ',
      incompleteWarning: 'You need to enter an account',
    },
    snippets: {
      sandbox: {
        title: 'Implementation',
        description: {
          firstPart: 'We leave you the ',
          docLink: ' documentation',
          lastPart: ' for you to read. Next you have your widget-id:',
        },
        widgetId: 'Widget-id:',
      },
      production: {
        title: 'Production',
        description: {
          firstPart: 'We leave you the ',
          docLink: ' documentation',
          lastPart: 'for you to read.',
        },
        option: 'You can enable/disable your widget here',
        destinationAccountWarning:
          'You must complete step 3 in order to access this functionality. Enter your collection account.',
      },
    },
    state: {
      label: 'Status of your widget',
      sandbox: 'Sandbox',
      production: 'Production',
    },
  },
  destinationAccountsPages: {
    header: {
      create: {
        title: 'Select collection account',
        breadcrumb: 'Select the collection accounts and the banks of origin',
      },
      edit: {
        title: 'Edit your collection account',
        breadcrumb: 'Edit fundraising account',
      },
    },
    forms: {
      title: 'Collection account',
      fields: {
        bankCode: {
          label: 'Bank name',
          placeholder: 'Select a bank',
          noOptions: 'First you must select the country of the bank',
        },
        bankCountry: {
          label: 'Country of the bank',
          placeholder: 'Select a country',
          noOptions: 'There was an error loading the countries',
        },
        bankAccountOwner: {
          label: 'Name of the owner of your bank account',
        },
        bankAccount: {
          label: 'Your bank account number',
        },
        bankAccountBranch: {
          label: 'Branch of your bank account (optional)',
        },
        destinationAccountType: {
          label: 'Destination account type',
          placeholder: 'Select a destination account type',
          noOptions: 'There was an error loading the destination account types',
        },
      },
      buttons: {
        submit: 'Save',
        cancel: 'Cancel',
        goBack: 'Back',
      },
      errors: {
        generic: 'An error occurred updating your widget.',
      },
      successMessage: {
        create: 'The collecting account has been successfully created.',
        edit: 'The collecting account has been successfully updated.',
      },
      warningMessage:
        'The country and bank you select cannot be changed later.',
    },
  },
  modulePage: {
    title: "Choose your widget's module",
    description:
      'There are two types of widgets: one for Login and one for payments. Select the function (or functions) that your widget will have.',
    breadcrumb: 'Modules',
    loading: 'Updating configuration...',
    modules: {
      login: {
        title: 'Login',
        description:
          'This feature allows the end user to log into their bank account.',
        details: [
          'Through a simple, safe and friendly interface',
          'Using an encrypted connection',
          'Bank credentials are not stored by Prometeo or shared with third parties.',
        ],
      },
      payments: {
        title: 'Payments',
        description: 'This feature allows the end user to make bank transfers.',
        details: [
          'Automatically, quickly and completely safely',
          'Automating payments between financial institutions in LATAM',
          'Eliminating manual processes and delays',
        ],
        switch: {
          on: 'ON',
          off: 'OFF',
        },
      },
    },
    buttons: {
      submit: {
        label: 'Save',
      },
      cancel: {
        label: 'Cancel',
      },
    },
    errors: {
      generic: 'An error occurred updating your widget.',
    },
  },
  providersPage: {
    title: 'Select the country, bank and accounts',
    subtitle: 'List of countries',
    description:
      'Choose the country, the banks and the bank accounts that you will make available to your clients.',
    breadcrumb: 'Selection of Country, Bank and Accounts',
    loading: 'Updating configuration...',
    empty: 'No active providers found.',
    accountTypes: {
      pers: { title: 'Persons', label: 'Personal Account' },
      corp: { title: 'Companies', label: 'Company account' },
      smes: { title: 'SMEs', label: 'SME Account' },
    },
    misc: {
      bank: 'Bank',
    },
    buttons: {
      submit: {
        label: 'Save',
      },
      cancel: {
        label: 'Cancel',
      },
    },
    errors: {
      generic: 'An error occurred updating your widget.',
      listProviders: 'An error occurred loading bank providers.',
    },
  },
  appearancePage: {
    title: 'Customize the appearance of your widget',
    description: 'Choose how you want your widget to look like.',
    breadcrumb: 'Appearance',
    sections: {
      branding: 'Branding',
      colors: 'Colors',
    },
    forms: {
      updateWidget: {
        loading: 'Updating configuration...',
        fields: {
          brandName: {
            label: 'Your company name',
            placeholder: 'Acme Inc.',
          },
          brandPrimaryColor: {
            label: 'Main Button Color',
            placeholder: 'Select the color',
          },
          brandLogo: {
            label: 'Logo',
            caption: 'Formats: PNG, JPG, WEBP / Maximum weight: 500kb',
            buttonText: 'Browse',
            defaultText: 'Logotipo.png',
          },
          errors: {
            brandLogoUrl: {
              byType:
                'The format is not correct. Only .jpg, .png, .webp extensions are allowed',
              bySize: 'The size cannot exceed 500 kb',
              byDimension:
                'The height should not exceed 28px and the width should not exceed 130px.',
            },
          },
        },
        buttons: {
          submit: {
            label: 'Save',
          },
          cancel: {
            label: 'Cancel',
          },
        },
        errors: {
          generic: 'An error occurred updating your widget.',
        },
        successMessage: 'The widget has been successfully updated.',
      },
    },
  },
}
