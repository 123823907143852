export default {
  summaryPage: {
    title: 'Payments',
    monthlyReport: {
      title: 'Payments month of {{name}}',
      sections: {
        qtyPaymentLabel: 'Total Transactions',
        amountPaymentLabel: 'Total Money',
        avgPaymentLabel: 'Average money per trans.',
        errorPaymentLabel: 'Failed transactions',
      },
    },
  },
  paymentHistory: {
    loading: 'Loading payments...',
    error: 'An error has occurred loading your payments.',
    empty: 'You have not received any payment yet.',
    title: 'Payment history',
    filters: {
      status: 'STATE',
      date: 'DATE',
      amount: 'AMOUNT',
      min: 'min.',
      max: 'max.',
      buttons: {
        confirmed: 'Confirmed',
        error: 'Error',
        rejected: 'Rejected',
        delete: 'Clear filters',
      },
      search: 'Search by concept, operation id or transaction id',
    },
    listToShow: 'List of',
    noResultFound: 'No results found',
    downloadLabel: 'DOWNLOAD TABLE',
    downloadError: 'There was a problem downloading the file',
    paginateInfo: 'Page {{current}} of {{total}}',
    tableHeaders: {
      originAccount: 'Origin account',
      destinationAccount: 'Destination account',
      destinationName: 'Recipient Name',
      destinationInstitutionName: 'Destination Bank Name',
      concept: 'Concept',
      status: 'Status',
      currency: 'Currency',
      amount: 'Amount',
      createdAt: 'Date',
      operationId: 'Operation ID',
      requestId: 'Transaction ID',
      externalId: 'External ID',
    },
  },
  paymentLinkPage: {
    title: 'Payment links',
    batchButton: 'New batch',
    linkButton: 'New link',
    monthlyReport: {
      title: 'Payments month of {{name}}',
      sections: {
        qtyPaymentLabel: 'Total Transactions',
        amountPaymentLabel: 'Total Cash',
        avgPaymentLabel: 'Average money per transfer.',
        errorPaymentLabel: 'Transactions with error',
      },
    },
  },
  paymentLinkList: {
    loading: 'Loading links...',
    error: 'An error has occurred loading your links.',
    empty: 'You have not received any payment yet.',
    title: 'List of payment links',
    filters: {
      status: 'STATUS',
      date: 'DATE',
      amount: 'AMOUNT',
      payment: 'PAYMENT',
      min: 'min.',
      max: 'max.',
      buttons: {
        confirmed: 'Active',
        error: 'Expired',
        rejected: 'Cancelled',
        payment: 'Payment',
        noPayment: 'No payment',
        delete: 'Clear filters',
      },
      search: 'Concept or External Id',
      cancelButton: 'CANCEL',
    },
    listToShow: 'List of',
    noResultFound: 'No results found',
    downloadLabel: 'DOWNLOAD TABLE',
    downloadError: 'There was a problem downloading the file',
    paginateInfo: 'Page {{current}} of {{total}}',
    tableHeaders: {
      createdAt: 'Date',
      collectionAccount: 'Collection Account',
      concept: 'Concept',
      externalId: 'External Id',
      paymentId: 'Payment ID',
      link: 'Link',
      payment: 'Payment',
      status: 'Status',
      currency: 'Currency',
      amount: 'Amount',
    },
  },
}
