import {
  UpdateUserSettingsPayload,
  UpdateUserSettingsResponse,
  ChangePasswordPayload,
  ChangePasswordResponse,
  GetUserSettingsResponse,
  UpdateExpiredPasswordPayload,
} from 'src/infrastructure'
import { useMarketplaceClient } from 'src/adapters'

export const useUserSettingsService = () => {
  const { privateClient, publicClient } = useMarketplaceClient()
  return {
    getUserSettings: () => {
      return privateClient.get<GetUserSettingsResponse>(
        'api/dashboard/user/settings'
      )
    },
    updateUserSettings: (payload: UpdateUserSettingsPayload) => {
      return privateClient.patch<UpdateUserSettingsResponse>(
        'api/dashboard/user/settings',
        payload
      )
    },
    changePassword: (payload: ChangePasswordPayload) => {
      return privateClient.post<ChangePasswordResponse>(
        'api/dashboard/change-password',
        payload
      )
    },
    updateExpiredPassword: (payload: UpdateExpiredPasswordPayload) => {
      return publicClient.post<ChangePasswordResponse>(
        'api/dashboard/update-password',
        payload
      )
    },
  }
}
