import { useQuery } from 'react-query'

import { APIError, useMarketplaceLogoutOnQueryError } from 'src/adapters'

export function useMarketplaceQuery<
  TQueryFnData = unknown,
  TError extends APIError = APIError
>({
  queryKey,
  queryFunction,
}: {
  queryKey: string | any[]
  queryFunction: () => Promise<TQueryFnData>
}) {
  const query = useQuery<TQueryFnData, TError>(queryKey, queryFunction)

  useMarketplaceLogoutOnQueryError<TError>({
    isError: query.isError,
    error: query.error,
  })

  return query
}
