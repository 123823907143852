import { GET_WIDGET_QUERY_KEY } from 'src/config'
import { useWidgetUseCases } from 'src/service-locator'
import { Widget } from 'src/domain'
import { useMarketplaceQuery } from 'src/adapters'
import { useNonNullableParams } from 'src/utils'

export const useWidgetQuery = () => {
  const { widgetId } = useNonNullableParams('widgetId')
  const { getWidget } = useWidgetUseCases()

  return useMarketplaceQuery<Widget>({
    queryKey: [GET_WIDGET_QUERY_KEY, widgetId],
    queryFunction: () => getWidget(parseInt(widgetId)),
  })
}
