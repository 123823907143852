import { useCallback, useState } from 'react'
import {
  DropdownSingleValue,
  DropdownValue,
  GetDropdownOptions,
} from '@lib-types'

import * as settings from 'src/config/settings'
import { useMarketplaceQuery } from 'src/adapters'
import { Country } from 'src/domain'
import { useWidgetUseCases } from 'src/service-locator'

export const useCountrySelectField = () => {
  const { getCountries } = useWidgetUseCases()
  const countriesQuery = useMarketplaceQuery<Country[]>({
    queryKey: settings.GET_WIDGET_COUNTRIES_QUERY_KEY,
    queryFunction: getCountries,
  })
  const [selectedCountry, setSelectedCountry] = useState<DropdownValue>()

  const makeOnChangeSelectList =
    (callback: (newValue: DropdownValue) => void) =>
    (newValue: DropdownValue) =>
      callback(newValue)

  const onChangeSelectedCountry = makeOnChangeSelectList(setSelectedCountry)

  const getCountryOptions: GetDropdownOptions = useCallback(() => {
    const countries = countriesQuery.data ?? []
    return countries.map((country) => ({
      value: country.code,
      label: country.name,
    }))
  }, [countriesQuery.data])

  const getSelectedCountryCode = useCallback(
    () => (selectedCountry as DropdownSingleValue)?.value,
    [selectedCountry]
  )

  return {
    selectedCountry,
    onChangeSelectedCountry,
    countriesQuery,
    getCountryOptions,
    getSelectedCountryCode,
  }
}
