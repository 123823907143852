import { useEffect } from 'react'

import { useAuth } from 'src/infrastructure'
import { APIError } from 'src/adapters'

export function useMarketplaceLogoutOnQueryError<
  TError extends APIError<any, any>
>({ isError, error }: { isError: boolean; error: TError | null }) {
  const { logout } = useAuth()

  useEffect(() => {
    if (isError && error?.response?.status === 401) {
      logout()
    }
  }, [logout, isError, error?.response?.status])
}
