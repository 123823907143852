import { UseResetPasswordUseCases } from '../definition'

type MakeResetPasswordUseCasesProps = {
  useResetPasswordUseCases: UseResetPasswordUseCases
}

type MakeResetPasswordUseCases = (
  props: MakeResetPasswordUseCasesProps
) => UseResetPasswordUseCases

export const makeResetPasswordUseCases: MakeResetPasswordUseCases =
  ({ useResetPasswordUseCases }) =>
  () =>
    useResetPasswordUseCases()
