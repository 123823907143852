import { FC, ReactNode } from 'react'
import { Alert, AlertBody } from '@lib-components'

export type WarningAlertProps = {
  children: ReactNode
}

export const WarningAlert: FC<WarningAlertProps> = ({ children }) => {
  return (
    <Alert variant={'warning'}>
      <AlertBody icon={'warning'} iconColor={'yellow-6'} iconSize={'xl'}>
        {children}
      </AlertBody>
    </Alert>
  )
}
