import { FC, ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthWithRoles } from 'src/infrastructure'

type ProtectedRoutesProps = {
  children: ReactNode
  checkRoles?: boolean
  allowedRoles?: Array<any>
}

export const ProtectedRoutes: FC<ProtectedRoutesProps> = ({
  children,
  checkRoles = false,
  allowedRoles = [],
}) => {
  const { user, hasPermission } = useAuthWithRoles(allowedRoles)
  const location = useLocation()

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  if (!hasPermission && checkRoles) {
    return <Navigate to="/permission-denied" replace />
  }

  return <>{children}</>
}
