import { FC, ReactNode } from 'react'

import { BulletIcon, Typography } from '@lib-components'

import styles from './page-bulleted-text.module.scss'

export type PageBulletedTextProps = {
  children: ReactNode
}

export const PageBulletedText: FC<PageBulletedTextProps> = ({ children }) => {
  return (
    <BulletIcon iconName={'check'}>
      <Typography variant={'body-2'} typographyColor={'gray-4'}>
        {children}
      </Typography>
    </BulletIcon>
  )
}

export type PageBulletedTextInlineGroupProps = {
  children: ReactNode
}

export const PageBulletedTextInlineGroup: FC<
  PageBulletedTextInlineGroupProps
> = ({ children }) => {
  return <div className={styles['container']}>{children}</div>
}
