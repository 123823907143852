import { forwardRef } from 'react'
import styles from './input-text.module.scss'
import { InputTextProps } from './input-text.d'

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (props, ref) => {
    const {
      type,
      label,
      value,
      name,
      placeholder,
      error,
      disabled,
      required,
      autoComplete = 'on',
      onChange,
    } = props

    const errorClass = error ? styles['form-field-error'] : styles['form-field']

    const requireClass = required
      ? `${styles['form-label']} ${styles['form-label--required']}`
      : styles['form-label']

    return (
      <div className={`${styles['form-group']}`}>
        <input
          type={type}
          id={name}
          defaultValue={value}
          className={errorClass}
          placeholder={placeholder || label}
          onChange={onChange}
          disabled={disabled}
          autoComplete={autoComplete}
          ref={ref}
        />
        <label htmlFor={name} className={requireClass}>
          {label}
        </label>
        {error && (
          <span
            className={`${styles['form-message']} ${styles['form-message--visible']} ${styles['form-message--error']}`}
          >
            {error}
          </span>
        )}
      </div>
    )
  }
)

InputText.displayName = 'InputText'
