import { FC } from 'react'

import {
  DashboardFormikFormProps,
  DashboardMutationFormProvider,
} from 'src/presentation'

export const DashboardFormikForm: FC<DashboardFormikFormProps> = ({
  formik,
  mutation,
  children,
}) => {
  return (
    <DashboardMutationFormProvider formik={formik} mutation={mutation}>
      <form onSubmit={formik.handleSubmit}>{children}</form>
    </DashboardMutationFormProvider>
  )
}
