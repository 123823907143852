import { FC, ReactNode } from 'react'

import {
  Card,
  Spacer,
  Typography,
} from '@prometeoapi/afrodita-react-component-lib'

import { TypographyProps } from '@lib-types'

export const PageCardDescription: FC<TypographyProps> = ({
  children,
  variant = 'body-1',
  typographyColor = 'gray-6',
  ...props
}) => {
  return (
    <Typography variant={variant} typographyColor={typographyColor} {...props}>
      {children}
    </Typography>
  )
}

export const PageCardTitle: FC<TypographyProps> = ({
  variant = 'featured-3',
  children,
  ...props
}) => {
  return (
    <>
      <Typography variant={variant} {...props}>
        {children}
      </Typography>
      <Spacer size={'2x'} />
    </>
  )
}

export type PageCardProps = {
  children: ReactNode
}

export const PageCard: FC<PageCardProps> = ({ children }) => {
  return <Card>{children}</Card>
}
