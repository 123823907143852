import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { DestinationAccountFormFields } from 'src/presentation'

export const useDestinationAccountFormUtils = () => {
  const { t } = useTranslation(['shared', 'widgets'])

  const emptyDestinationAccountFormFields: DestinationAccountFormFields = {
    bankAccount: '',
    bankAccountOwner: '',
    bankAccountBranch: '',
  }

  const destinationAccountDefaultsFormFieldsValidationSchema = {
    bankAccount: Yup.number()
      .required(
        t('shared:form.errors.requiredField', {
          name: t(
            'widgets:destinationAccountsPages.forms.fields.bankAccount.label'
          ),
        })
      )
      .typeError(
        t('shared:form.errors.numberField', {
          name: t(
            'widgets:destinationAccountsPages.forms.fields.bankAccountBranch.label'
          ),
        })
      ),
    bankAccountOwner: Yup.string().required(
      t('shared:form.errors.requiredField', {
        name: t(
          'widgets:destinationAccountsPages.forms.fields.bankAccountOwner.label'
        ),
      })
    ),
    bankAccountBranch: Yup.number().typeError(
      t('shared:form.errors.numberField', {
        name: t(
          'widgets:destinationAccountsPages.forms.fields.bankAccountBranch.label'
        ),
      })
    ),
  }

  return {
    emptyDestinationAccountFormFields,
    destinationAccountDefaultsFormFieldsValidationSchema,
  }
}
