import { useTranslation } from 'react-i18next'
import {
  HighlightedText,
  Spacer,
} from '@prometeoapi/afrodita-react-component-lib'

import {
  PageCard,
  PageCardTitle,
  PageCardDescription,
  PageBulletedTextInlineGroup,
  PageBulletedText,
  useHomeQuery,
} from 'src/presentation'

export const HomePageApiKeySandbox = () => {
  const { t } = useTranslation(['home'])
  const { data } = useHomeQuery()
  return (
    <>
      {data && (
        <PageCard>
          <PageCardTitle>{t('home:apiKey.sandbox.title')}</PageCardTitle>

          <PageCardDescription>
            {t('home:apiKey.sandbox.description')}
          </PageCardDescription>

          <Spacer />

          <HighlightedText label={t('home:apiKey.sandbox.label')}>
            {data.user.sandboxApiKey}
          </HighlightedText>

          <Spacer />

          <PageBulletedTextInlineGroup>
            <PageBulletedText>
              {t('home:apiKey.sandbox.bullets.data')}
            </PageBulletedText>
            <PageBulletedText>
              {t('home:apiKey.sandbox.bullets.free')}
            </PageBulletedText>
            <PageBulletedText>
              {t('home:apiKey.sandbox.bullets.dashboard')}
            </PageBulletedText>
            <PageBulletedText>
              {t('home:apiKey.sandbox.bullets.requests')}
            </PageBulletedText>
          </PageBulletedTextInlineGroup>
        </PageCard>
      )}
    </>
  )
}
