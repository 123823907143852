import { useContext, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { UseAuth, AuthContext } from 'src/infrastructure'
import * as settings from 'src/config/settings'

export const useAuth: UseAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export const useAuthWithSesameLogin = () => {
  const context = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const sesame = searchParams.get(settings.SESAME_QUERY_PARAM)
    if (sesame) {
      searchParams.delete(settings.SESAME_QUERY_PARAM)
      setSearchParams(searchParams)
      context.loginWithSesame(sesame).then((user) => context.setUser(user))
    }
  }, [context, searchParams, setSearchParams])

  return context
}

export const useAuthWithRoles = (allowedRoles: Array<any>) => {
  const { user } = useAuthWithSesameLogin()
  const roles = user?.roles

  const hasPermission = useMemo(() => {
    const permit = () =>
      allowedRoles?.every((item: any) =>
        roles?.find((inner: any) => {
          if (JSON.stringify(inner) === JSON.stringify(item)) {
            return true
          }

          return false
        })
      )

    return permit()
  }, [allowedRoles, roles])

  return {
    user,
    hasPermission,
  }
}
