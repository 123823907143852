export default {
  header: {
    settings: {
      title: 'Settings',
      subtitle: 'Personal contact and billing information.',
    },
    changePassword: {
      title: 'Change Password',
    },
  },
  forms: {
    settings: {
      fields: {
        email: { label: 'E-mail' },
        password: { label: 'Password' },
        isCompany: { label: "I'm a company" },
        firstName: { label: 'Name' },
        lastName: { label: 'Lastname' },
        companyName: { label: 'Company’s business name' },
        taxId: { label: 'Rut' },
      },
      changePasswordCta: 'Change password',
      submitButton: {
        label: 'Save',
      },
      successMessage: 'Data successfully updated.',
    },
    changePassword: {
      fields: {
        oldPassword: { label: 'Current password' },
        newPassword: {
          label: 'New password',
          bullets: {
            '1': 'Your password may not closely resemble your other personal information.',
            '2': 'Your password must contain at least 8 characters.',
            '3': 'Your password cannot be a commonly used password.',
            '4': 'Your password cannot be completely numeric.',
          },
        },
        newPasswordConfirm: { label: 'New password (confirmation)' },
      },
      submitButton: {
        label: 'Change',
      },
      successMessage: 'Password successfully updated.',
    },
  },
}
