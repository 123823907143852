import { UsePaymentsUseCases } from 'src/domain'

type InitProps = {
  usePaymentsUseCases: UsePaymentsUseCases
}

export let usePaymentsUseCases: UsePaymentsUseCases

export const init = (props: InitProps) => {
  usePaymentsUseCases = props.usePaymentsUseCases
}
