import { useCallback } from 'react'
import {
  DropdownSingleValue,
  DropdownValue,
  GetDropdownOptions,
} from '@lib-types'

import * as settings from 'src/config/settings'
import { useMarketplaceQuery } from 'src/adapters'
import { Country, Bank } from 'src/domain'
import { useWidgetUseCases } from 'src/service-locator'

export const useBankSelectField = (selectedCountryCode?: Country['code']) => {
  const { getAllBanks } = useWidgetUseCases()
  const banksQuery = useMarketplaceQuery<Bank[]>({
    queryKey: [settings.GET_WIDGET_COUNTRIES_QUERY_KEY, selectedCountryCode],
    queryFunction: () =>
      selectedCountryCode
        ? getAllBanks({ countryCode: selectedCountryCode })
        : Promise.resolve([]),
  })

  const getBankOptions: GetDropdownOptions = useCallback(() => {
    if (!selectedCountryCode) {
      return []
    }
    const banks = banksQuery.data ?? []
    return banks.map((bank) => ({
      value: bank.code,
      label: bank.name,
    }))
  }, [banksQuery.data, selectedCountryCode])

  const makeGetSelectBankValue = useCallback(
    (bankCode?: string) => () => {
      const options = getBankOptions()
      return options
        ? (options.find(
            (option) => (option as DropdownSingleValue)?.value == bankCode
          ) as DropdownValue | undefined)
        : undefined
    },
    [getBankOptions]
  )

  return {
    banksQuery: banksQuery,
    getBankOptions: getBankOptions,
    makeGetSelectProviderValue: makeGetSelectBankValue,
  }
}
