import { useTranslation } from 'react-i18next'

import { BulletIconProps } from '@lib-types'
import { makeKeyedObject } from '@lib-utils'

import { Home } from 'src/domain'
import { HomePageApiKeyStatsItem } from 'src/presentation'

export const useHomeQueryDataHelpers = () => {
  const { t } = useTranslation(['home'])

  const isInSandbox = (data: Home) => data?.user?.stage === 'sandbox'

  const isInPreproduction = (data: Home) =>
    data?.user?.stage === 'preproduction'

  const isInProduction = (data: Home) => data?.user?.stage === 'production'

  const isCompanyDataBeingVerified = (data: Home) =>
    data.companyDataStatus === 'pending'

  const isBillingInformationBeingVerified = (data: Home) =>
    data.billingInformationStatus === 'pending'

  const companyDataHasNotBeenFilled = (data: Home) =>
    data.companyDataStatus === 'blank'

  const billingInformationHasNotBeenFilled = (data: Home) =>
    data.billingInformationStatus === 'blank'

  const didEnoughRequests = (data: Home) =>
    data.requestsData.totalRequestsSandbox >=
    data.minimumRequestsForPreproduction

  const shouldShowIcon = (data: Home) => (step?: number) => {
    switch (step) {
      case 2:
        return !isInSandbox(data) || didEnoughRequests(data)
      case 3:
        return (
          (isInSandbox(data) && isCompanyDataBeingVerified(data)) ||
          !isInSandbox(data)
        )
      case 4:
        return isInProduction(data)
      default:
        return false
    }
  }

  const getIconName =
    (data: Home) =>
    (step?: number): BulletIconProps['iconName'] => {
      if (step === 3 && isInSandbox(data) && isCompanyDataBeingVerified(data)) {
        return 'icon-calendar-today'
      }
      return 'icon-check-circle'
    }

  const shouldShowAlert = (data: Home) => (step?: number) => {
    if (step === 3) {
      return (
        isInSandbox(data) &&
        !didEnoughRequests(data) &&
        !isCompanyDataBeingVerified(data)
      )
    }

    return false
  }

  const shouldShowQuickstart = (data: Home) => !isInProduction(data)

  const shouldShowApiKey = (data: Home) => {
    return !isInSandbox(data)
  }

  const shouldShowApiKeySandbox = (data: Home) => {
    return (
      isInSandbox(data) ||
      isInPreproduction(data) ||
      (isInProduction(data) && data.isInTestingServer)
    )
  }

  const getApiKeyCardTitle = (data: Home) => {
    if (isInProduction(data)) {
      return data.isInTestingServer
        ? t('home:apiKey.production.title.testing')
        : t('home:apiKey.production.title.production')
    }
    return t('home:apiKey.preproduction.title')
  }

  const getApiKeyCardDescription = (data: Home) => {
    return t(`home:apiKey.${data.user.stage}.description`)
  }

  const getApiKeyCardLabel = (data: Home) => {
    if (isInProduction(data)) {
      return data.isInTestingServer
        ? t('home:apiKey.production.label.testing')
        : t('home:apiKey.production.label.production')
    }
    return t('home:apiKey.preproduction.label')
  }

  const getApiKeyCardStatsContainerTitle = (data: Home) => {
    if (isInProduction(data)) {
      return t(`home:apiKey.production.stats.title`)
    }
    return t(`home:apiKey.preproduction.stats.title`)
  }

  const getStatsItemHeader = (item: HomePageApiKeyStatsItem) => {
    switch (item) {
      case 'requests':
        return t('home:apiKey.stats.requests.header')
      case 'connections':
        return t('home:apiKey.stats.connections')
      case 'useTime':
        return t('home:apiKey.stats.useTime')
    }
  }

  const getStatsItemFooter =
    (data: Home) => (item: HomePageApiKeyStatsItem) => {
      const maxProdRequests = data.pricingData.maxProdRequests ?? '∞'
      const maxDaysAvailable = data.stageData.maxDaysAvailable ?? '∞'
      const maxCountriesConnections =
        data.stageData.maxCountriesConnections ?? 1

      switch (item) {
        case 'requests':
          return `${maxProdRequests} ${t('home:apiKey.stats.requests.footer')}`
        case 'connections':
          return `${data.connectionCount}/${maxCountriesConnections}`
        case 'useTime':
          return `${maxDaysAvailable} ${t('home:apiKey.stats.days')}`
      }
    }

  const getStatsItemBody = (data: Home) => (item: HomePageApiKeyStatsItem) => {
    const useTime = `${data.daysOfUse ?? '∞'} ${
      data.daysOfUse === 1
        ? t('home:apiKey.stats.day')
        : t('home:apiKey.stats.days')
    }`

    switch (item) {
      case 'requests':
        return `${data.requestsData.totalRequests}`
      case 'connections':
        return `${data.connectionCount}`
      case 'useTime':
        return useTime
    }
  }

  const getStageRequestTitle = (data: Home) => {
    return t(`home:stageRequest.${data.user.stage}.title`)
  }

  const getStageRequestDescription = (data: Home) => {
    return t(`home:stageRequest.${data.user.stage}.description`)
  }

  const getStateRequestBullets = (data: Home) => {
    if (!data.user.stage) {
      return []
    }
    const getBullet = makeKeyedObject(1)(data.user.stage)('bulletText')
    switch (data.user.stage) {
      case 'production':
        return [
          getBullet(t('home:stageRequest.production.bullets.support')),
          getBullet(t('home:stageRequest.production.bullets.priority')),
          getBullet(t('home:stageRequest.production.bullets.token')),
        ]
      case 'preproduction':
        return [
          getBullet(t('home:stageRequest.preproduction.bullets.data')),
          getBullet(t('home:stageRequest.preproduction.bullets.access')),
          getBullet(t('home:stageRequest.preproduction.bullets.requests')),
        ]
      case 'sandbox':
        return [
          getBullet(t('home:stageRequest.sandbox.bullets.requests')),
          getBullet(t('home:stageRequest.sandbox.bullets.access')),
          getBullet(t('home:stageRequest.sandbox.bullets.limit')),
          getBullet(t('home:stageRequest.sandbox.bullets.free')),
        ]
    }
  }

  const shouldShowStageRequestCta = (data: Home) => {
    switch (data.user.stage) {
      case 'preproduction':
        return billingInformationHasNotBeenFilled(data)
      case 'sandbox':
        return companyDataHasNotBeenFilled(data)
      default:
        return true
    }
  }

  const getStageRequestsCta = (data: Home) => {
    if (data.user.stage) {
      return t(`home:stageRequest.${data.user.stage}.cta`)
    }
    return t('home:stageRequest.cta')
  }

  const shouldShowStageRequestVerifyingMessage = (data: Home) => {
    switch (data.user.stage) {
      case 'preproduction':
        return isBillingInformationBeingVerified(data)
      case 'sandbox':
        return isCompanyDataBeingVerified(data)
      default:
        return false
    }
  }

  return {
    isInProduction,
    isInPreproduction,
    isInSandbox,
    shouldShowApiKey,
    shouldShowApiKeySandbox,
    shouldShowQuickstart,
    shouldShowIcon,
    isCompanyDataBeingVerified,
    companyDataHasNotBeenFilled,
    isBillingInformationBeingVerified,
    billingInformationHasNotBeenFilled,
    didEnoughRequests,
    getIconName,
    shouldShowAlert,
    getApiKeyCardTitle,
    getApiKeyCardDescription,
    getApiKeyCardLabel,
    getApiKeyCardStatsContainerTitle,
    getStatsItemHeader,
    getStatsItemFooter,
    getStatsItemBody,
    getStageRequestTitle,
    getStageRequestDescription,
    getStateRequestBullets,
    shouldShowStageRequestCta,
    getStageRequestsCta,
    shouldShowStageRequestVerifyingMessage,
  }
}
