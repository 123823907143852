export default {
  createLink: {
    title: 'Genera el link de pagos',
    breadcumb: 'Link de Pagos',
    breadcumb1: 'Nuevo link',
    formTitle: 'Completa estos campos para crear un nuevo link de pagos',
    form: {
      fields: {
        widget: {
          label: 'Widget',
          placeholder: 'El link está enlazado a',
          helpText: 'El link está enlazado a',
        },
        concept: {
          label: 'Concepto',
          helpText: 'Aparecerá en el historial bancario del cliente',
          caption: 'Máx. 20 caracteres',
        },
        amount: {
          label: 'Monto & Moneda',
          labelError: 'Monto',
          caption: 'Solo números y 2 decimales como máximo',
        },
        currency: {
          label: 'Moneda',
        },
        external_id: {
          label: 'ID externo',
          caption: 'Máx. 50 caracteres',
        },
        dueDate: {
          label: 'Fecha de vencimiento',
        },
        email: {
          label: 'Correo electrónico',
        },
      },
      button: 'Generar Link',
    },
    modal: {
      title: 'Link de pagos generado',
      description:
        'Corresponde al widget {{widget_account}}, con el concepto {{concept}}, monto {{amount}}$ y moneda {{currency}}, ID externo {{externalID}}, dura hasta {{expired}}.',
      label: 'Link de pago',
      helpText: 'Link copiado en el portapapeles',
      exit: 'Salir',
    },
    loadingMessage: 'Cargando ...',
    cancelModal: {
      title: '¿Estas seguro que deseas cancelar?',
      message:
        'Si cancelas los links dejarán de funcionar y no podrás volver a activarlos. Deberás volver a crear uno nuevo.',

      cancel: 'No, mantener',
      submit: 'Si, cancelar',
      errorCancel: 'No se pudieron cancelar los link de pagos seleccionados',
      errorEmptyLinks: 'Tiene que seleccionar al menos un payment link',
    },
  },
  paymentLinkPage: {
    title: 'Link de pagos',
    batchButton: 'Nuevo batch',
    linkButton: 'Nuevo link',
    paymentLinkList: {
      loading: 'Cargando links...',
      error: 'Ha ocurrido un error cargando sus links.',
      empty: 'No has recibido ningun pago todavía.',
      title: ' ',
      filters: {
        status: 'ESTADO',
        date: 'FECHA',
        amount: 'MONTO',
        payment: 'PAGO',
        min: 'min.',
        max: 'máx.',
        buttons: {
          confirmed: 'Activo',
          error: 'Vencido',
          rejected: 'Cancelado',
          payment: 'Pago',
          noPayment: 'No pago',
          delete: 'Borrar filtros',
        },
        search: 'Buscar Concepto o Id Externo',
        cancelButton: 'CANCELAR',
        reloadButton: 'ACTUALIZAR',
      },
      listToShow: 'Listado de',
      noResultFound: 'No se encontraron resultados',
      downloadLabel: 'DESCARGAR TABLA',
      downloadError: 'Hubo un problema al descargar el fichero',
      paginateInfo: 'Página {{current}} de {{total}}',
      tableHeaders: {
        createdAt: 'Fecha',
        collectionAccount: 'Cuenta Recaudadora',
        concept: 'Concepto',
        externalId: 'ID Externo',
        paymentId: 'Payment ID',
        link: 'Link',
        payment: 'Pago',
        status: 'Estado',
        currency: 'Moneda',
        amount: 'Monto',
        email: 'Correo',
      },
    },
  },
  createBatch: {
    title: 'Generación masiva',
    breadcumb: 'Carga Masiva',
    breadcumb1: 'Subir Archivo',
    formTitle: 'Completa estos campos para crear nuevos links de pagos',
    form: {
      fields: {
        archivo: {
          label: 'Listado de clientes',
          placeholder: 'Archivo.csv',
          button: 'Examinar',
          helpText: 'Prometeo solo acepta archivos en formato JSON y CSV. ',
          helpText1: 'Cómo convertir archivo a JSON/CSV?',
          helpText2:
            'El archivo deberá tener las siguientes columnas: product_id, amount ,currency, concept external_id, email, expires_at. ',
          helpText3: 'Ver formato de columnas.',
          download: 'Descargar template',
        },
      },
      button: 'Generar Archivo',
    },
    modal: {
      title: 'Cargando archivo',
      title1: 'Archivo Cargado',
      description: 'Dura 24h en base al archivo {{filename}}',
      label: 'Archivo',
      exit: 'Confirmar',
    },
    loadingMessage: 'Cargando ...',
    errorModal: {
      title: 'Archivo no generado',
      description:
        'Revisa los errores correspondientes para poder generar el archivo correctamente',
      helpText:
        'Los valores marcados contienen un error y no pudimos generar el archivo',
      invalidFile:
        'Por favor revisar el fichero. No se puede cargar este archivo. El formato no es compatible. ',
      cancel: 'Cerrar',
    },
    helpModal: {
      title: 'Conversión de archivos',
      csvTitle: 'Para convertir a formato CSV:',
      csvStep1: 'Abre el archivo en tu programa de hojas de cálculo.',
      csvStep2:
        'Asegúrate de que los datos estén organizados en columnas y filas.',
      csvStep3:
        'Guarda el archivo en formato CSV (Valores Separados por Comas).',
      csvStep4:
        'Si el programa te solicita opciones de configuración al guardar, elige las opciones por defecto o las que mejor se adapten a tus datos.',
      jsonTitle: 'Para convertir a formato JSON:',
      jsonStep1: 'Abre el archivo en un editor de texto.',
      jsonStep2:
        'Asegúrate de que los datos estén estructurados en formato de objeto o matriz JSON.',
      jsonStep3: 'Guarda el archivo con extensión .json.',
    },
    colFormatModal: {
      title: 'Formato de columnas de archivo',
      helpText: '(Por favor respeta los títulos de las columnas)',
      titleProduct: 'product_id: ',
      descriptionProduct:
        'Es el ID de tu widget al que el link estará enlazada. Si no sabes cuál es el ID de tu widget, puedes solicitarlo a nuestro equipo de soporte a través de soporte@prometeoapi.com.',
      titleAmount: 'amount: ',
      descriptionAmount:
        'Solo admite formato numérico con un máximo de 2 decimales.',
      titleCurrency: 'currency: ',
      descriptionCurrency:
        'Para pesos uruguayos, completar con “UYU”; para soles, completar con “PEN”; para dólares, completar con “USD”.',
      titleConcept: 'concept: ',
      descriptionConcept:
        'Campo alfanumérico de hasta 20 caracteres. Este campo aparecerá en el Home Banking de tu cliente indicando el concepto de la transferencia.',
      titleExternalId: 'external_id: ',
      descriptionExternalID:
        'Campo alfanumérico con un máximo de 50 caracteres.  Puedes utilizar este campo para identificar internamente tus links (por ejemplo, número de factura, número de cliente, etc.)',
      titleEmail: 'email: ',
      descriptionEmail:
        'Correo electrónico del cliente al que le deseas cobrar.',
      titleExpireAt: 'expires_at: ',
      descriptionExpireAt:
        'Es la fecha en la que el link expirará. Asegúrate de seguir el formato AAAA-MM-DD.',
    },
  },
  paymentLinkBatchPage: {
    title: 'Link de pagos por carga masiva',
    batchButton: 'Cargar archivo',
    paymentLinkList: {
      loading: 'Cargando links...',
      error: 'Ha ocurrido un error cargando sus links.',
      empty: 'No has recibido ningun pago todavía.',
      title: ' ',
      listToShow: 'Listado de',
      noResultFound: 'No se encontraron resultados',
      downloadLabel: 'DESCARGAR TABLA',
      downloadError: 'Hubo un problema al descargar el fichero',
      paginateInfo: 'Página {{current}} de {{total}}',
      tableHeaders: {
        id: 'ID',
        createdAt: 'Fecha',
        status: 'Estado',
        link: 'Descargar',
        expired_at: 'Fecha Vencimiento',
      },
    },
  },
}
