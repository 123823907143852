import { memo } from 'react'
import { PasswordRequirementProps } from './password-requirement.d'
import styles from './password-requirement.module.scss'
import { useTranslation } from 'react-i18next'

export const PasswordRequirement = memo((props: PasswordRequirementProps) => {
  const { t } = useTranslation(['shared'])
  const {
    upperLetterCheck,
    lowerLetterCheck,
    numberCheck,
    pwdLengthCheck,
    specialCharCheck,
  } = props
  const classNames = {
    pwdLengthCheck: pwdLengthCheck
      ? styles['password-checks--valid']
      : styles['password-checks--invalid'],
    upperLetterCheck: upperLetterCheck
      ? styles['password-checks--valid']
      : styles['password-checks--invalid'],
    lowerLetterCheck: lowerLetterCheck
      ? styles['password-checks--valid']
      : styles['password-checks--invalid'],
    numberCheck: numberCheck
      ? styles['password-checks--valid']
      : styles['password-checks--invalid'],
    specialCharCheck: specialCharCheck
      ? styles['password-checks--valid']
      : styles['password-checks--invalid'],
  }

  return (
    <div className={styles['password-checks']}>
      <h2>{t('shared:form.passwordMust')}</h2>
      <p className={classNames?.pwdLengthCheck}>
        {t('shared:form.requirements.pwdLengthCheck')}
      </p>
      <p className={classNames?.upperLetterCheck}>
        {t('shared:form.requirements.upperLetterCheck')}
      </p>
      <p className={classNames?.lowerLetterCheck}>
        {t('shared:form.requirements.lowerLetterCheck')}
      </p>
      <p className={classNames?.numberCheck}>
        {t('shared:form.requirements.numberCheck')}
      </p>
      <p className={classNames?.specialCharCheck}>
        {t('shared:form.requirements.specialCharCheck')}
      </p>
    </div>
  )
})

PasswordRequirement.displayName = 'PasswordRequirement'
