import { FC, ReactNode } from 'react'
import classNames from 'classnames'

import { Anchor } from '@lib-components'
import { AnchorProps } from '@lib-types'

import styles from './dropdown-nav.module.scss'
import animate from './dropdown-nav-animationes.module.scss'

export type DropdownNavLiContainerProps = {
  children: ReactNode
}

export const DropdownNavLiContainer: FC<DropdownNavLiContainerProps> = ({
  children,
}) => {
  return <li className={styles['container']}>{children}</li>
}

export type DropdownNavItemLinkProps = {
  to: AnchorProps['href']
  children: ReactNode
}

export const DropdownNavItemLink: FC<DropdownNavItemLinkProps> = ({
  to,
  children,
}) => {
  return (
    <Anchor
      href={to}
      underlineOnHover={false}
      typographyProps={{ className: styles['nav-item'] }}
      target="_blank"
    >
      {children}
    </Anchor>
  )
}

export type DropdownNavProps = {
  children: ReactNode
  show?: boolean
}

export const DropdownNav: FC<DropdownNavProps> = ({
  show = false,
  children,
}) => {
  const className = classNames(styles['nav'], animate['slide-in'], {
    [styles['show']]: show,
  })
  return <nav className={className}>{children}</nav>
}
