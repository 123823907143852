import { FC, MouseEventHandler, ReactNode, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { NavLink, To, useLocation } from 'react-router-dom'
import { getStyledElementProps } from '@lib-utils'
import { Typography } from '@lib-components'
import { TypographyProps } from '@lib-types'
import { useHomeQueryDataHelpers, useHomeQuery } from 'src/presentation'
import { useAuth, useAuthMarketplaceService } from 'src/infrastructure'
// eslint-disable-next-line
import { useLegacyDashboardLink } from 'src/utils'
import * as settings from 'src/config/settings'

import styles from './dashboard-nav.module.scss'
import { ACTION_ROLES } from 'src/config'
import { Li } from './dashboard-nav-li'

export const DashboardNavItem: FC<
  TypographyProps & { disabled?: boolean; children: ReactNode }
> = ({ variant = 'body-1', tag = 'span', disabled, children, ...props }) => {
  const styledProps = getStyledElementProps(
    props,
    classNames(styles['nav-item'], { [styles['disabled']]: disabled })
  )
  return (
    <Typography variant={variant} tag={tag} {...styledProps}>
      {children}
    </Typography>
  )
}

export const DashboardNavItemLink: FC<{ to: To; children: ReactNode }> = ({
  to,
  children,
}) => {
  return (
    <NavLink to={to}>
      <DashboardNavItem>{children}</DashboardNavItem>
    </NavLink>
  )
}

export const LegacyDashboardNavItemLink: FC<{
  url: string
  children: ReactNode
}> = ({ url, children }) => {
  const { getMarketplaceLegacyDashboardUrl } = useLegacyDashboardLink()
  return (
    <a href={getMarketplaceLegacyDashboardUrl(url)}>
      <DashboardNavItem>{children}</DashboardNavItem>
    </a>
  )
}

export const DashboardNav: FC<{
  onLiClick?: MouseEventHandler<HTMLLIElement>
}> = ({ onLiClick }) => {
  const { t } = useTranslation(['shared'])
  const location = useLocation()
  const { logoutUser } = useAuthMarketplaceService()
  const { logout } = useAuth()
  const { data, isSuccess } = useHomeQuery()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isInProduction } = useHomeQueryDataHelpers()

  const onClickLogout = async () => {
    if (location.pathname === '/payments' && location.search != null) {
      location.search = ''
    }
    const response = await logoutUser()
    if (response) {
      logout()
    }
  }

  const pathNameLink =
    location.pathname === '/payments-link' ||
    location.pathname === '/payments-link/create-link' ||
    location.pathname === '/payments-link-batch' ||
    location.pathname === '/payments-link-batch/create-batch'

  const [showLink, setShowLink] = useState(false)
  const handleLinkOpen = () => {
    setShowLink(!showLink)
  }

  return (
    <>
      {isSuccess && data && (
        <nav>
          <ul className={styles['mainmenu']}>
            <nav className={styles['nav']}>
              <ul>
                <Li
                  onLiClick={onLiClick}
                  checkRoles={true}
                  allowedRoles={ACTION_ROLES['summary_view_action']}
                >
                  <DashboardNavItemLink to={'/'}>
                    {t('shared:nav.summary')}
                  </DashboardNavItemLink>
                </Li>
                {/* <Li
              onLiClick={onLiClick}
              checkRoles={true}
              allowedRoles={ACTION_ROLES["history_view_action"]}
            >
              <DashboardNavItemLink to={'/usage'}>
                {t('shared:nav.usage')}
              </DashboardNavItemLink>
            </Li> */}
                {/* {!isInSandbox(data) && (
              <Li
                onLiClick={onLiClick}
                checkRoles={true}
                allowedRoles={ACTION_ROLES["consumption_view_action"]}
              >
                <DashboardNavItemLink to={'/consumption'}>
                  {t('shared:nav.consumption')}
                </DashboardNavItemLink>
              </Li>
            )} */}
                {isInProduction(data) &&
                  settings.REACT_APP_WIDGET_SHOW_IN_MENU && (
                    <Li
                      onLiClick={onLiClick}
                      checkRoles={true}
                      allowedRoles={ACTION_ROLES['widgets_view_action']}
                    >
                      <DashboardNavItemLink to={'/widgets'}>
                        {t('shared:nav.widgets')}
                      </DashboardNavItemLink>
                    </Li>
                  )}
                {isInProduction(data) && (
                  <Li
                    onLiClick={onLiClick}
                    checkRoles={true}
                    allowedRoles={ACTION_ROLES['payments_view_action']}
                  >
                    <DashboardNavItemLink to={'/payments'}>
                      {t('shared:nav.payments')}
                    </DashboardNavItemLink>
                  </Li>
                )}
                {isInProduction(data) && (
                  <Li
                    onLiClick={undefined}
                    checkRoles={true}
                    allowedRoles={ACTION_ROLES['payment_links_view_action']}
                  >
                    <DashboardNavItem onClick={handleLinkOpen}>
                      {t('shared:nav.paymentsLink')}
                    </DashboardNavItem>
                    <ul
                      className={
                        showLink || pathNameLink
                          ? `${styles['submenu']} ${styles['open-link']}`
                          : styles['submenu']
                      }
                    >
                      <Li
                        checkRoles={true}
                        allowedRoles={ACTION_ROLES['payment_links_view_action']}
                        onLiClick={onLiClick}
                      >
                        <DashboardNavItemLink to={'/payments-link'}>
                          {t('shared:nav.listPaymentLink')}
                        </DashboardNavItemLink>
                      </Li>
                      <Li
                        checkRoles={true}
                        allowedRoles={ACTION_ROLES['payment_links_view_action']}
                        onLiClick={onLiClick}
                      >
                        <DashboardNavItemLink to={'/payments-link/create-link'}>
                          {t('shared:nav.individualLoad')}
                        </DashboardNavItemLink>
                      </Li>
                      <Li
                        checkRoles={true}
                        allowedRoles={ACTION_ROLES['payment_links_view_action']}
                        onLiClick={onLiClick}
                      >
                        <DashboardNavItemLink to={'/payments-link-batch'}>
                          {t('shared:nav.massiveLoad')}
                        </DashboardNavItemLink>
                      </Li>
                    </ul>
                  </Li>
                )}
                <Li
                  onLiClick={onLiClick}
                  checkRoles={true}
                  allowedRoles={ACTION_ROLES['documentation_view_action']}
                >
                  <a
                    href={settings.DOC_URL}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <DashboardNavItem>
                      {t('shared:nav.documentation')}
                    </DashboardNavItem>
                  </a>
                </Li>
                <Li
                  onLiClick={onLiClick}
                  checkRoles={true}
                  allowedRoles={ACTION_ROLES['help_center_view_action']}
                >
                  <a
                    href={settings.WEB_HELP_CENTER_URL}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <DashboardNavItem>
                      {t('shared:nav.helpCenter')}
                    </DashboardNavItem>
                  </a>
                </Li>
                <Li
                  onLiClick={onLiClick}
                  checkRoles={true}
                  allowedRoles={ACTION_ROLES['user_settings_edit_action']}
                >
                  <DashboardNavItemLink to={'/settings'}>
                    {t('shared:nav.settings')}
                  </DashboardNavItemLink>
                </Li>
                <Li onLiClick={onLiClick} checkRoles={false} allowedRoles={[]}>
                  <DashboardNavItem onClick={onClickLogout}>
                    {t('shared:nav.logOut')}
                  </DashboardNavItem>
                </Li>
              </ul>
            </nav>
          </ul>
        </nav>
      )}
    </>
  )
}

export const DashboardNavSticky = () => (
  <div className={styles['sticky']}>
    <DashboardNav />
  </div>
)
