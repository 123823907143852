import styled from 'styled-components'
import { NavbarProps } from 'src/presentation'

export const WrapperNavbar = styled.div``

export const NavbarStart = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-flow: row nowrap;

  > a {
    text-decoration: none;
  }
`
export const NavbarEnd = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-flow: row nowrap;
  width: 300px;
  height: 120px;
  gap: 20px;

  > a {
    text-decoration: none;
  }
`
export const MainNavigationBoxesExtras = styled.div`
  display: flex;
  flex-flow: row nowrap;
`

export const MainNavigationLogoLink = styled.div`
  display: flex;
  height: 100%;
  padding-right: 45px;

  :hover {
    cursor: pointer;
  }
  > img {
    margin: auto;
  }
`

export const MainNavigation = styled.nav<NavbarProps>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  height: 84px;
  left: 0px;
  top: 0px;
  box-shadow: 0px 4px 8px rgba(47, 58, 68, 0.08);
  border-bottom: ${(navProps: NavbarProps) =>
    navProps.isRed ? '1px solid #F86F66' : '1px solid #E7EDEF'};
  background: ${(navProps: NavbarProps) =>
    navProps.isRed
      ? 'linear-gradient(95.27deg, #E6210A 0%, #F25353 74.06%)'
      : '#FFFFFF'};

  @media (max-width: 1199px) {
    height: 60px;
    border-bottom: 0;
  }
`
