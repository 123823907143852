import { useCallback } from 'react'

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'src/config'
import {
  useAuthStorageServiceAdapter,
  useMarketplaceClient,
} from 'src/adapters'
import {
  GetTokensPayload,
  GetTokensResponse,
  RefreshTokenPayload,
  RefreshTokenResponse,
} from 'src/infrastructure'

export const useAuthMarketplaceService = () => {
  const { publicClient, privateClient } = useMarketplaceClient()
  const { getAccessToken } = useAuthStorageServiceAdapter()

  return {
    getTokensWithSesame: (sesame: string) => {
      return publicClient.get<GetTokensResponse>(
        `/api/dashboard/user/tokens/${sesame}`
      )
    },
    getTokens: (payload: GetTokensPayload) => {
      return publicClient.post<GetTokensResponse>('/api/auth/token', payload)
    },
    refreshAccessToken: (payload: RefreshTokenPayload) => {
      return publicClient.post<RefreshTokenResponse>(
        '/api/auth/token/refresh',
        payload
      )
    },
    logoutUser: () => {
      const token = getAccessToken()
      if (token) {
        privateClient.post<any>('/api/auth/logout')
        return true
      }
      return false
    },
  }
}

export const useAuthStorageService = () => {
  return {
    saveAccessToken: useCallback((token: string) => {
      return localStorage.setItem(ACCESS_TOKEN_KEY, token)
    }, []),
    getAccessToken: useCallback((): ReturnType<typeof localStorage.getItem> => {
      return localStorage.getItem(ACCESS_TOKEN_KEY)
    }, []),
    removeAccessToken: useCallback(() => {
      return localStorage.removeItem(ACCESS_TOKEN_KEY)
    }, []),
    saveRefreshToken: useCallback((token: string) => {
      return localStorage.setItem(REFRESH_TOKEN_KEY, token)
    }, []),
    getRefreshToken: useCallback((): ReturnType<
      typeof localStorage.getItem
    > => {
      return localStorage.getItem(REFRESH_TOKEN_KEY)
    }, []),
    removeRefreshToken: useCallback(() => {
      return localStorage.removeItem(REFRESH_TOKEN_KEY)
    }, []),
  }
}
