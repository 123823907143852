import { FC } from 'react'
import {
  HighlightedText,
  Spacer,
  Typography,
} from '@prometeoapi/afrodita-react-component-lib'

import {
  PageCard,
  PageCardTitle,
  PageCardDescription,
  StatsContainerProps,
  StatsItemProps,
  useHomeQuery,
  useHomeQueryDataHelpers,
} from 'src/presentation'

import styles from './home-page-api-key.module.scss'

const StatsItem: FC<StatsItemProps> = ({ header, footer, children }) => {
  return (
    <div>
      <Typography variant={'body-1'} typographyColor={'gray-6'}>
        {header}
      </Typography>

      <Typography variant={'featured-1'}>{children}</Typography>

      <Typography variant={'body-2'} typographyColor={'gray-4'}>
        {footer}
      </Typography>
    </div>
  )
}

const StatsContainer: FC<StatsContainerProps> = ({ header, children }) => {
  return (
    <div>
      <Typography variant={'emphasized-1'}>{header}</Typography>

      <Spacer />

      <div className={styles['stats-items-container']}>{children}</div>
    </div>
  )
}

export const HomePageApiKey = () => {
  const { data } = useHomeQuery()
  const {
    getApiKeyCardLabel,
    getApiKeyCardDescription,
    getApiKeyCardTitle,
    getApiKeyCardStatsContainerTitle,
    getStatsItemHeader,
    getStatsItemFooter,
    getStatsItemBody,
  } = useHomeQueryDataHelpers()
  return (
    <>
      {data && (
        <PageCard>
          <PageCardTitle>{getApiKeyCardTitle(data)}</PageCardTitle>

          <PageCardDescription>
            {getApiKeyCardDescription(data)}
          </PageCardDescription>

          <Spacer />

          <HighlightedText label={getApiKeyCardLabel(data)}>
            {data.user.apiKey}
          </HighlightedText>

          <Spacer />

          <StatsContainer header={getApiKeyCardStatsContainerTitle(data)}>
            <StatsItem
              header={getStatsItemHeader('requests')}
              footer={getStatsItemFooter(data)('requests')}
            >
              {getStatsItemBody(data)('requests')}
            </StatsItem>

            <StatsItem
              header={getStatsItemHeader('connections')}
              footer={getStatsItemFooter(data)('connections')}
            >
              {getStatsItemBody(data)('connections')}
            </StatsItem>

            <StatsItem
              header={getStatsItemHeader('useTime')}
              footer={getStatsItemFooter(data)('useTime')}
            >
              {getStatsItemBody(data)('useTime')}
            </StatsItem>
          </StatsContainer>
        </PageCard>
      )}
    </>
  )
}
