import { useMarketplaceClient } from 'src/adapters'
import {
  RegisterAccountPayload,
  RegisterAccountResponse,
} from 'src/infrastructure'
export const useCreateAccountService = () => {
  const { privateClient } = useMarketplaceClient()

  return {
    createAccount: (payload: RegisterAccountPayload) => {
      return privateClient.post<RegisterAccountResponse>(
        'api/dashboard/register/',
        payload
      )
    },
  }
}
