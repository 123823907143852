import React, { FC } from 'react'
import { Typography } from '@prometeoapi/afrodita-react-component-lib'
import { IconSpriteProps } from 'src/presentation'
import styles from './icon-sprite.module.scss'

export const IconSprite: FC<IconSpriteProps> = (props: IconSpriteProps) => {
  const { className, title } = props
  if (title) {
    return (
      <div className={styles['wrapper-icon']}>
        <i className={styles[className]} />
        <Typography
          variant="strong-1"
          withFontFamily
          typographyColor="gray-8"
          tag="span"
        >
          {title}
        </Typography>
      </div>
    )
  }

  return <i className={className} />
}
