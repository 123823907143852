import { Anchor } from '@lib-components'
import { useTranslation } from 'react-i18next'
import * as settings from 'src/config/settings'
import styles from './about.module.scss'
import { IconSprite } from '../../../icon-sprite'

export const About = () => {
  const { t, i18n } = useTranslation(['auth'])
  const locale = i18n.language && i18n.language == 'en' ? 'en' : 'es'

  return (
    <div className={styles['wrapper']}>
      <Anchor
        href={settings.navbarTranslation[locale]['aboutUs']}
        underlineOnHover={false}
      >
        <IconSprite className="about-us" title={t('auth:nav.aboutUs')} />
      </Anchor>
      <Anchor
        href={settings.navbarTranslation[locale]['workWithUs']}
        underlineOnHover={false}
      >
        <IconSprite className="work-with-us" title={t('auth:nav.workWithUs')} />
      </Anchor>
      <Anchor
        href={settings.navbarTranslation[locale]['securityPolicy']}
        underlineOnHover={false}
      >
        <IconSprite
          className="security-policy"
          title={t('auth:nav.securityPolicy')}
        />
      </Anchor>
      <Anchor
        href={settings.navbarTranslation[locale]['privacyPolicy']}
        underlineOnHover={false}
      >
        <IconSprite
          className="privacy-policy"
          title={t('auth:nav.privacyPolicy')}
        />
      </Anchor>
    </div>
  )
}
