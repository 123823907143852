export default {
  header: {
    sandbox: {
      title: '¡Te damos la bienvenida a Prometeo!',
      subtitle:
        'Aquí podrás visualizar el resumen de tu consumo y tu API Key para acceder al sistema.',
    },
    preproduction: {
      title: '¡Te damos la bienvenida a tu ambiente de Trial!',
      subtitle:
        'Utiliza tu API key y accede a datos de instituciones reales para construir con Prometeo.',
    },
    production: {
      title: '¡Te damos la bienvenida a Prometeo!',
      subtitle: 'Ya tienes acceso al ambiente de Producción.',
    },
    alert: 'La contraseña vence en {{days}} días',
  },
  quickstart: {
    title: '¿Cómo empezar?',
    subtitle:
      'Hemos diseñado estos cuatro pasos que te permitirán recorrer las diferentes etapas de tu integración. Recuerda que cada paso es requisito para avanzar al siguiente.',
    steps: {
      '1': {
        tab: '1. Documentación',
        body: {
          p: 'Accede a Especificación OpenAPI para realizar pruebas desde nuestra Documentación. Si aún te quedan dudas de cómo funciona Prometeo, te sugerimos revisar nuestra demo.',
          cta1: 'Ir a la documentación',
          cta2: 'Ver Demo',
        },
      },
      '2': {
        tab: '2. Sandbox',
        body: {
          p: 'Comienza a desarrollar tu integración en el Sandbox con datos de muestra.',
          requests: 'requests',
        },
      },
      '3': {
        tab: '3. Trial',
        body: {
          alert:
            'Te sugerimos hacer suficientes request para familiarizarte con la plataforma antes de pasar a Trial.',
          p: 'Si ya realizaste tu integración con datos de muestra y ahora quieres ajustarla con datos reales, puedes completar la información requerida y pasar a Trial; allí tendrás acceso a datos reales por un periodo limitado y en el país que elijas.',
          verifying: 'Estamos verificando tu información',
          cta: 'Trabajar con datos reales',
        },
      },
      '4': {
        tab: '4. Producción',
        body: {
          p: '¿Quieres comenzar a operar? Completa tus datos de facturación y migra a Producción.',
          cta: 'Datos de facturación',
        },
      },
    },
  },
  apiKey: {
    stats: {
      requests: { header: 'Requests', footer: 'requests' },
      connections: 'Conexiones',
      useTime: 'Tiempo de uso',
      day: 'día',
      days: 'días',
    },
    production: {
      title: {
        production: 'Acceso a keys privadas de Producción',
        testing: 'Acceso a keys privadas de Testing',
      },
      description:
        'Utiliza tu nuevo API key y construye con datos reales en nuestras instituciones financieras/fiscales.',
      label: {
        production: 'Tu API Key de Producción es:',
        testing: 'Tu API Key de Testing es:',
      },
      stats: {
        title: 'Consumo de Producción',
      },
    },
    preproduction: {
      title: 'Acceso a keys privadas de Trial',
      description:
        'Utiliza tu nuevo API key y construye con datos reales en nuestras instituciones financieras/fiscales.',
      label: 'Tu API Key de Trial es:',
      stats: {
        title: 'Consumo de Trial',
      },
    },
    sandbox: {
      title: 'Acceso a keys privadas de Sandbox',
      description:
        'Utiliza tu API key y datos de cuentas bancarias ficticias para desarrollar y probar tu integración a Prometeo.',
      label: 'Tu API Key de Sandbox es:',
      bullets: {
        data: 'Datos ficticios',
        free: 'Gratis y habilitado para siempre',
        dashboard: 'Dashboard disponible',
        requests: 'Llamadas ilimitadas',
      },
    },
  },
  stageRequest: {
    cta: 'Contactarme',
    verifyingMessage: 'Estamos verificando tu información',
    sandbox: {
      title: 'Comienza a construir',
      description:
        '¡Manos a la obra! Solicita el acceso a Trial para construir con datos reales, expandiendo el trabajo previamente realizado en Sandbox.',
      bullets: {
        requests: '30 llamadas a la API',
        access: 'Acceso a instituciones en un país a elección',
        limit: 'Limitado a 15 días',
        free: 'Sin costo',
      },
      cta: 'Trabajar con datos reales',
    },
    preproduction: {
      title: '¿Quieres acceso ilimitado?',
      description:
        '¡Continúa construyendo! Si quieres escalar y obtener llamadas a la API ilimitadas, lo puedes hacer en nuestro ambiente de Producción.',
      bullets: {
        data: 'Datos reales',
        access: 'Acceso a instituciones en un país a elección',
        requests: 'Requests ilimitados',
      },
      cta: 'Quiero acceso ilimitado',
      goToProduction: 'Ir a producción',
    },
    production: {
      title: '¡Quiero obtener llamadas y conexiones ilimitadas!',
      description:
        'Si los niveles de servicio son importantes para tu negocio y la cantidad de países o transacciones es superior a la mencionada en el Plan Torch te recomendamos contactar a ventas para conocer nuestros Planes Corporativos.',
      bullets: {
        support: 'Soporte en el proceso de on boarding',
        priority: 'Priorización en el desarrollo de APIs',
        token: 'Automatización de soft token/hard token',
      },
      cta: 'Contactarme y modificar mi Plan',
    },
  },
}
