import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'

import { MARKETPLACE_HOST } from 'src/config'
import { MarketplaceServiceFormValidationErrorResponse } from 'src/infrastructure'

import {
  APIError,
  MarketplaceAdapterMessageForUser,
  useAuthStorageServiceAdapter,
} from 'src/adapters'

const baseConfig = {
  baseURL: MARKETPLACE_HOST,
}

const useMarketPlaceAPIErrorsHelpers = () => {
  const { t } = useTranslation(['shared'])
  let data: any

  const getErrorMessage = (
    error: AxiosError
  ): MarketplaceAdapterMessageForUser => {
    const statusCode = error.response?.status
    data = error.response?.data

    switch (statusCode) {
      case 403:
        return {
          description: t('shared:api.errors.403'),
        }
      case 401:
        return {
          label: t('shared:api.errors.401.label'),
          description: t('shared:api.errors.401.description'),
        }
      case 400:
        if (data && Object.keys(data).includes('token')) {
          return {
            description: t(`shared:api.errors.${data?.token}`),
          }
        }
        if (data.message && data.message.includes('Account locked'))
          return {
            label: t('shared:api.errors.400.label'),
            description: t('shared:api.errors.400.description'),
          }
        else
          return {
            description: t('shared:api.errors.tryAgain'),
          }
      default:
        return {
          description: t('shared:api.errors.tryAgain'),
        }
    }
  }

  const getFieldErrors = (error: AxiosError) => {
    const statusCode = error.response?.status
    const data = error.response?.data
    if (data && statusCode === 400) {
      return (data as MarketplaceServiceFormValidationErrorResponse)
        .field_errors
    }
  }

  return {
    getErrorMessage,
    getFieldErrors,
  }
}

const useMarketplaceAxiosInterceptors = () => {
  const { getAccessToken } = useAuthStorageServiceAdapter()
  const { getFieldErrors, getErrorMessage } = useMarketPlaceAPIErrorsHelpers()

  const errorHandler = (error: AxiosError) => {
    const messageForUser = getErrorMessage(error)
    const fieldErrors = getFieldErrors(error)
    throw new APIError(error, messageForUser, fieldErrors)
  }

  const privateRequestHandler = async (config: AxiosRequestConfig) => {
    if (!config.headers) {
      config.headers = {}
    }

    const token = getAccessToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  }

  // TODO
  const responseHandler = (response: AxiosResponse) => {
    return response
  }

  return {
    errorHandler,
    privateRequestHandler,
    responseHandler,
  }
}

export const useMarketplaceClient = () => {
  const { errorHandler, privateRequestHandler, responseHandler } =
    useMarketplaceAxiosInterceptors()

  // Client instances
  const publicClient = axios.create({
    ...baseConfig,
  })
  const privateClient = axios.create({
    ...baseConfig,
  })

  // Interceptors
  publicClient.interceptors.response.use(responseHandler, errorHandler)

  privateClient.interceptors.request.use(privateRequestHandler, errorHandler)
  privateClient.interceptors.response.use(responseHandler, errorHandler)

  return { publicClient, privateClient }
}
