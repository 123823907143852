import { useTranslation } from 'react-i18next'

import styles from './payments-links-page.module.scss'
import {
  PageHeader,
  PageHeaderTitle,
  useHomeQuery,
  useHomeQueryDataHelpers,
} from 'src/presentation'
import { Home } from 'src/domain'
import { Navigate } from 'react-router-dom'
import { PaymentTableLink } from './links/table-link/payment-link-table'
import { Spacer } from '@prometeoapi/afrodita-react-component-lib'

export const PaymentsLinkPage = () => {
  const { t } = useTranslation(['paymentsLink'])

  // eslint-disable-next-line
  const { data, isSuccess } = useHomeQuery()
  const { isInProduction } = useHomeQueryDataHelpers()
  const shouldRedirect = isSuccess && !isInProduction(data as Home)

  if (shouldRedirect) {
    return <Navigate to="/" replace />
  }

  return (
    <section className={styles['page-container']}>
      <PageHeader>
        <div className={styles['page-header']}>
          <div>
            <PageHeaderTitle>
              {t(`payments:paymentLinkPage.title`)}
            </PageHeaderTitle>
          </div>
        </div>
      </PageHeader>
      <Spacer size={'4x'} />
      <PaymentTableLink />
      <Spacer size={'10x'} />
    </section>
  )
}
