import { FC } from 'react'

import {
  DashboardFormMutationStore,
  DashboardFormMutationContext,
} from 'src/infrastructure'

export const DashboardMutationFormProvider: FC<DashboardFormMutationStore> = ({
  formik,
  mutation,
  children,
}) => {
  return (
    <DashboardFormMutationContext.Provider value={{ formik, mutation }}>
      {children}
    </DashboardFormMutationContext.Provider>
  )
}
