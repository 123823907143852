import { MapToUsage, MapToUsageGraph } from '../definition'

export const mapToUsage: MapToUsage = (response) => ({
  monthlyLogins: response.monthly_logins,
  usageTable: response.usage_table,
  totalSuccess: response.total_success,
  totalErrors: response.total_errors,
})

export const mapToUsageGraph: MapToUsageGraph = (response) => ({
  usageTimeseries: response.usage_timeseries,
  usageTable: response.usage_table,
})
