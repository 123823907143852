export enum WidgetEnvs {
  SANDBOX = 'SA',
  PREPRODUCTION = 'PP',
  PRODUCTION = 'PR',
}

export enum BankAccountTypes {
  PERSONAL = 'pers',
  CORPORATE = 'corp',
  SMES = 'smes',
}

export enum WidgetModules {
  LOGIN = 1,
  PAYMENTS = 2,
}
