import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import {
  Typography,
  Spacer,
  Card,
  TextCardTitle,
  SelectField,
  Dropdown,
  InputLabel,
  Button,
  TextField,
  IconMoon,
  InputCaption,
} from '@prometeoapi/afrodita-react-component-lib'
import { usePaymentsLinkUseCases, useWidgetUseCases } from 'src/service-locator'
import { Widget } from 'src/domain'
import DatePicker from 'react-datepicker'

import './react-datepicker.css'
import { LIST_ALL_WIDGETS_QUERY_KEY } from 'src/config'

import styles from './create-link-page.module.scss'
import { AxiosError } from 'axios'

type IFormInput = {
  product_id: string
  concept: string
  currency: object
  amount: number
  external_id: string
  expires_at: Date
  email: string
}

export const CreateLinkPage: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['widgets', 'shared', 'paymentsLink', 'auth'])
  const { listAllWidgets } = useWidgetUseCases()
  const { createLink } = usePaymentsLinkUseCases()

  const query = useQuery<Widget[], AxiosError>(
    LIST_ALL_WIDGETS_QUERY_KEY,
    listAllWidgets
  )
  const widgets = query.data
  const modalRef = useRef<HTMLDialogElement>(null)

  const [startDate, setStartDate] = useState(null)
  const [url, setUrl] = useState('')
  const [amount, setAmount] = useState('')
  const [currency, setCurrency] = useState('')
  const [concept, setConcept] = useState('')
  const [external_id, setExternal_id] = useState('')
  const [widgetAccount, setWidgetAccount] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [errorsDate, setErrorsDate] = useState('')

  const formSchema = Yup.object({
    amount: Yup.number()
      .required(
        t('shared:form.errors.requiredField', {
          name: t(`paymentsLink:createLink.form.fields.amount.labelError`),
        })
      )
      .typeError(
        t('shared:form.errors.numberField', {
          name: t(`paymentsLink:createLink.form.fields.amount.labelError`),
        })
      ),
    external_id: Yup.string()
      .required(
        t('shared:form.errors.requiredField', {
          name: t(`paymentsLink:createLink.form.fields.external_id.label`),
        })
      )
      .max(
        50,
        t('shared:form.errors.maxLength', {
          name: t(`paymentsLink:createLink.form.fields.external_id.label`),
          max: 50,
        })
      ),
    concept: Yup.string().max(
      20,
      t('shared:form.errors.maxLength', {
        name: t('paymentsLink:createLink.form.fields.concept.label'),
        max: 20,
      })
    ),
    product_id: Yup.object()
      .shape({
        label: Yup.string().required(
          t('shared:form.errors.requiredField', {
            name: t(`paymentsLink:createLink.form.fields.widget.label`),
          })
        ),
        value: Yup.string().required(
          t('shared:form.errors.requiredField', {
            name: t(`paymentsLink:createLink.form.fields.widget.label`),
          })
        ),
      })
      .nullable()
      .required(
        t('shared:form.errors.requiredField', {
          name: t(`paymentsLink:createLink.form.fields.widget.label`),
        })
      ),
    email: Yup.string().email(
      t('shared:form.errors.emailField', {
        name: t(`paymentsLink:createLink.form.fields.email.label`),
      })
    ),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(formSchema),
  })

  const onChangeDate = (date: any) => {
    setStartDate(date)
  }

  const createLinkAction = async (data: any) => {
    const amount = data.amount
    const payload = {
      product_id: data.product_id.value,
      concept: data.concept,
      amount: amount.toFixed(2),
      currency: data.currency.value,
      external_id: data.external_id,
      email: data.email,
      expires_at: startDate,
    }
    setAmount(amount.toFixed(2))
    setConcept(data.concept)
    setCurrency(data.currency.value)
    setExternal_id(data.external_id)
    setWidgetAccount(data.product_id.label)
    try {
      const response = await createLink(payload)
      if (response.url) {
        setUrl(response.url)
        handleCreateLinkButtonClick()
        navigator.clipboard.writeText(response.url)
      }
    } catch (error: any) {
      if (error.response.status == 401) {
        navigate('/login')
      }
      setErrorMessage(error._messageForUser.description)
      return error
    }
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    try {
      startDate
        ? createLinkAction(data)
        : setErrorsDate(
            t('shared:form.errors.requiredField', {
              name: t(`paymentsLink:createLink.form.fields.dueDate.label`),
            })
          )
    } catch (error: any) {
      return error
    }
  }

  const handleCreateLinkButtonClick = () => {
    modalRef.current && modalRef.current.showModal()
  }

  const handleCloseModalClick = () => {
    modalRef.current && modalRef.current.close()
    navigate('/payments-link')
  }

  const optionCurrency = [
    {
      value: 'UYU',
      label: (
        <div className={styles['icon-flag']}>
          <IconMoon name="icon-uy" size="xl" />{' '}
          <InputCaption className={styles['icon-label']}>UYU</InputCaption>
        </div>
      ),
    },
    {
      value: 'USD',
      label: (
        <div className={styles['icon-flag']}>
          <IconMoon name="icon-us" size="xl" />
          <InputCaption className={styles['icon-label']}>USD</InputCaption>
        </div>
      ),
    },
    {
      value: 'PEN',
      label: (
        <div className={styles['icon-flag']}>
          <IconMoon name="icon-pe" size="xl" />{' '}
          <InputCaption className={styles['icon-label']}>PEN</InputCaption>
        </div>
      ),
    },
  ]

  return (
    <div className={styles['container-page']}>
      <Typography tag="p" variant="body-1" typographyColor="gray-6">
        <a href="/payments-link" style={{ color: '#2f3a44' }}>
          {t('paymentsLink:createLink.breadcumb')}
        </a>

        {` > ${t('paymentsLink:createLink.breadcumb1')}`}
      </Typography>
      <Spacer variant={'vertical'} size={'6x'} />
      <Typography tag="p" variant="headline-1">
        {t('paymentsLink:createLink.title')}
      </Typography>

      <Spacer variant={'vertical'} size={'6x'} />

      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <TextCardTitle>
              <Typography tag="p" variant="strong-1">
                {t('paymentsLink:createLink.formTitle')}
              </Typography>{' '}
            </TextCardTitle>

            <div className={styles['row-fields']}>
              <div className={styles['row-col']}>
                <InputLabel>
                  {t('paymentsLink:createLink.form.fields.widget.label')}
                </InputLabel>
                <Spacer size={'1x'} />

                <Typography
                  typographyColor="gray-3"
                  variant="small-1"
                  withFontFamily
                >
                  {t('paymentsLink:createLink.form.fields.widget.helpText')}
                </Typography>
                <Controller
                  name="product_id"
                  defaultValue={''}
                  control={control}
                  render={({ field }) => (
                    <SelectField
                      loadingMessage={t(
                        'paymentsLink:createLink.loadingMessage'
                      )}
                      options={widgets?.map((widget, index) => ({
                        index: index,
                        label: widget.name,
                        value: widget.publicKey,
                      }))}
                      placeholder=""
                      {...field}
                    />
                  )}
                />

                {errors.product_id ? (
                  <p className={styles['error-text']}>
                    {errors.product_id?.message}{' '}
                  </p>
                ) : (
                  ''
                )}
              </div>
              <div className={styles['row-col']}>
                <InputLabel>
                  {t('paymentsLink:createLink.form.fields.concept.label')}
                </InputLabel>
                <Spacer size={'1x'} />

                <Typography
                  typographyColor="gray-3"
                  variant="small-1"
                  withFontFamily
                >
                  {t('paymentsLink:createLink.form.fields.concept.helpText')}{' '}
                </Typography>
                <Controller
                  name="concept"
                  control={control}
                  render={({ field }) => <TextField fullWidth {...field} />}
                />
                <Typography
                  typographyColor="gray-3"
                  variant="small-1"
                  withFontFamily
                >
                  {t('paymentsLink:createLink.form.fields.concept.caption')}{' '}
                </Typography>
                {errors.concept ? (
                  <p className={styles['error-text']}>
                    {errors.concept?.message}{' '}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className={styles['row-fields']}>
              <div className={styles['row-col']}>
                <div className={styles['input-group']}>
                  <div className={styles['row-amount']}>
                    <Controller
                      name="amount"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label={t(
                            'paymentsLink:createLink.form.fields.amount.label'
                          )}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className={styles['row-currency']}>
                    <Controller
                      name="currency"
                      control={control}
                      defaultValue={{
                        value: 'UYU',
                        label: (
                          <div className={styles['icon-flag']}>
                            <IconMoon name="icon-uy" size="xl" />{' '}
                            <InputCaption className={styles['icon-label']}>
                              UYU
                            </InputCaption>
                          </div>
                        ),
                      }}
                      render={({ field }) => (
                        <Dropdown
                          options={optionCurrency}
                          placeholder={<IconMoon name="icon-uy" size="xl" />}
                          {...field}
                        />
                      )}
                    />
                  </div>
                </div>
                <Typography
                  typographyColor="gray-3"
                  variant="small-1"
                  withFontFamily
                >
                  {t('paymentsLink:createLink.form.fields.amount.caption')}{' '}
                </Typography>
                {errors.amount ? (
                  <p className={styles['error-text']}>
                    {errors.amount?.message}{' '}
                  </p>
                ) : (
                  ''
                )}
                {errors.currency ? (
                  <p className={styles['error-text']}>
                    {errors.currency?.message}{' '}
                  </p>
                ) : (
                  ''
                )}
              </div>
              <div className={styles['row-col']}>
                <Controller
                  name="external_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t(
                        'paymentsLink:createLink.form.fields.external_id.label'
                      )}
                      fullWidth
                      {...field}
                    />
                  )}
                />
                <Typography
                  typographyColor="gray-3"
                  variant="small-1"
                  withFontFamily
                >
                  {t('paymentsLink:createLink.form.fields.external_id.caption')}{' '}
                </Typography>
                {errors.external_id ? (
                  <p className={styles['error-text']}>
                    {errors.external_id?.message}{' '}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className={styles['row-fields']}>
              <div className={styles['row-col']}>
                <InputLabel>
                  {t('paymentsLink:createLink.form.fields.dueDate.label')}
                </InputLabel>

                <Controller
                  name="expires_at"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      weekLabel="expires_at"
                      dateFormat="dd/MM/yy 00:01"
                      selected={startDate}
                      startDate={startDate}
                      onChange={(e) => field.onChange(onChangeDate(e))}
                      className={styles['calendar-input']}
                      disabledKeyboardNavigation
                    />
                  )}
                />

                {errorsDate ? (
                  <p className={styles['error-text']}>{errorsDate} </p>
                ) : (
                  ''
                )}
              </div>
              <div className={styles['row-col']}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="email"
                      label={t(
                        'paymentsLink:createLink.form.fields.email.label'
                      )}
                      fullWidth
                      {...field}
                    />
                  )}
                />
                {errors.email ? (
                  <p className={styles['error-text']}>
                    {errors.email?.message}{' '}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
            {errorMessage ? (
              <div>
                <InputCaption
                  className={styles['error-text']}
                  error
                  id="input-caption"
                >
                  {errorMessage}
                </InputCaption>
              </div>
            ) : (
              ''
            )}
          </Card>
          <Spacer size={'10x'} />
          <div className={styles['btn-create']}>
            <Button id="button" type="submit" size="md" variant="primary-light">
              {t('paymentsLink:createLink.form.button')}
            </Button>
          </div>
        </form>
      </div>
      <dialog ref={modalRef} className={styles['modal-container']}>
        <div>
          <Typography tag="h3" variant="headline-3" typographyColor="gray-8">
            {t('paymentsLink:createLink.modal.title')}
          </Typography>

          <Spacer />

          <Typography tag="p" variant="body-1" typographyColor="gray-6">
            {t('paymentsLink:createLink.modal.description', {
              widget_account: widgetAccount,
              concept: concept,
              amount: amount,
              currency: currency,
              externalID: external_id,
              expired: startDate,
            })}
          </Typography>

          <Spacer size={'8x'} />

          <TextField
            label={t('paymentsLink:createLink.modal.label')}
            onChange={() => {}}
            type="text"
            fullWidth
            value={url}
          />

          <Typography tag="p" variant="body-2" typographyColor="green-6">
            {t('paymentsLink:createLink.modal.helpText')}
          </Typography>

          <Spacer size={'8x'} />

          <div className={styles['modal-button-row']}>
            <>
              <Button
                size="md"
                variant="primary-light"
                onClick={handleCloseModalClick}
              >
                {t('paymentsLink:createLink.modal.exit')}
              </Button>
            </>
          </div>
        </div>
      </dialog>
    </div>
  )
}
