import {
  makeUseAuthUseCases,
  makeUserSettingsUseCases,
  makeHomeUseCases,
  makeWidgetUseCases,
  makePaymentsUseCases,
  makeRegisterAccountUseCases,
  makePaymentsLinkUseCases,
  makeResetPasswordUseCases,
  makeConsumptionUseCases,
  makeUsageUseCases,
} from 'src/domain'
import {
  useConsumptionServiceAdapter,
  useUserSettingsAdapter,
  useAuthMarketplaceServiceAdapter,
  useAuthStorageServiceAdapter,
  useUserActionsAdapter,
  useHomeServiceAdapter,
  useWidgetAdapter,
  usePaymentsAdapter,
  useRegisterAccountAdapter,
  usePaymentsLinkAdapter,
  useResetPasswordAdapter,
  useUsageServiceAdapter,
} from 'src/adapters'
import { init as initAuthServiceLocator } from 'src/features/auth/service-locator'
import { init as initUserSettingsServiceLocator } from 'src/features/user-settings/service-locator'
import { init as initHomeServiceLocator } from 'src/features/home/service-locator'
import { init as initWidgetServiceLocator } from 'src/features/widgets/service-locator'
import { init as initPaymentsServiceLocator } from 'src/features/payments/service-locator'
import { init as initRegisterAccountLocator } from 'src/features/register/service-locator'
import { init as initPaymentsLinkServiceLocator } from 'src/features/payments-link/service-locator'
import { init as initResetPasswordServiceLocator } from 'src/features/reset-password/service-locator'
import { init as initConsumtionServiceLocator } from 'src/features/consumption/service-locator'
import { init as initUsageServiceLocator } from 'src/features/usage/service-locator'

export const initAllServiceLocator = () => {
  // Auth feature
  const useAuthUseCases = makeUseAuthUseCases({
    useTokenTransactions: useAuthMarketplaceServiceAdapter,
    useTokenActions: useAuthStorageServiceAdapter,
    useUserActions: useUserActionsAdapter,
  })
  initAuthServiceLocator({
    useAuthUseCases,
  })

  // Register account
  const useRegisterAccountUseCases = makeRegisterAccountUseCases({
    useRegisterAccountUseCases: useRegisterAccountAdapter,
  })
  initRegisterAccountLocator({ useRegisterAccountUseCases })

  // HomePage feature
  const useHomeUseCases = makeHomeUseCases({
    useHomeUseCases: useHomeServiceAdapter,
  })
  initHomeServiceLocator({ useHomeUseCases })

  // User settings feature
  const useUserSettingsUseCases = makeUserSettingsUseCases({
    useUserSettingsUseCases: useUserSettingsAdapter,
  })
  initUserSettingsServiceLocator({
    useUserSettingsUseCases,
  })

  // Widget feature
  const useWidgetUseCases = makeWidgetUseCases({
    useWidgetUseCases: useWidgetAdapter,
  })
  initWidgetServiceLocator({
    useWidgetUseCases,
  })

  // Payments feature
  const usePaymentsUseCases = makePaymentsUseCases({
    usePaymentsUseCases: usePaymentsAdapter,
  })
  initPaymentsServiceLocator({
    usePaymentsUseCases,
  })

  //Payments-Link feature
  const usePaymentsLinkUseCases = makePaymentsLinkUseCases({
    usePaymentsLinkUseCases: usePaymentsLinkAdapter,
  })
  initPaymentsLinkServiceLocator({
    usePaymentsLinkUseCases,
  })

  // Reset Password feature
  const useResetPasswordUseCases = makeResetPasswordUseCases({
    useResetPasswordUseCases: useResetPasswordAdapter,
  })
  initResetPasswordServiceLocator({
    useResetPasswordUseCases,
  })

  // Consumption feature
  const useConsumptionUseCases = makeConsumptionUseCases({
    useConsumptionUseCases: useConsumptionServiceAdapter,
  })
  initConsumtionServiceLocator({
    useConsumptionUseCases,
  })

  // Usage feature
  const useUsageUseCases = makeUsageUseCases({
    useUsageUseCases: useUsageServiceAdapter,
  })
  initUsageServiceLocator({
    useUsageUseCases,
  })
}
