export const ACTION_ROLES: { [key: string]: Array<unknown> } = {
  summary_view_action: [{ role: 'summary_view_action', assignment: {} }],
  summary_view_welcome_action: [
    { role: 'summary_view_welcome_action', assignment: {} },
  ],
  summary_view_stage_info_action: [
    { role: 'summary_view_stage_info_action', assignment: {} },
  ],
  history_view_action: [{ role: 'history_view_action', assignment: {} }],
  consumption_view_action: [
    { role: 'consumption_view_action', assignment: {} },
  ],
  widgets_view_action: [{ role: 'widgets_view_action', assignment: {} }],
  widget_create_action: [{ role: 'widget_create_action', assignment: {} }],
  widget_detail_action: [{ role: 'widget_detail_action', assignment: {} }],
  widget_edit_apperance_action: [
    { role: 'widget_edit_apperance_action', assignment: {} },
  ],
  widget_create_collection_account_action: [
    {
      role: 'widget_create_collection_account_action',
      assignment: {},
    },
  ],
  widget_edit_collection_account_action: [
    {
      role: 'widget_edit_collection_account_action',
      assignment: {},
    },
  ],
  widget_edit_production_enable_action: [
    { role: 'widget_edit_production_enable_action', assignment: {} },
  ],
  documentation_view_action: [
    { role: 'documentation_view_action', assignment: {} },
  ],
  help_center_view_action: [
    { role: 'help_center_view_action', assignment: {} },
  ],
  payment_links_view_action: [
    { role: 'payment_links_view_action', assignment: {} },
  ],
  payment_links_create_one_action: [
    { role: 'payment_links_create_one_action', assignment: {} },
  ],
  payment_links_create_bulk_action: [
    { role: 'payment_links_create_bulk_action', assignment: {} },
  ],
  payment_links_cancel_links_action: [
    { role: 'payment_links_cancel_links_action', assignment: {} },
  ],
  payment_links_export_action: [
    { role: 'payment_links_export_action', assignment: {} },
  ],
  payments_view_action: [{ role: 'payments_view_action', assignment: {} }],
  payments_export_action: [{ role: 'payments_export_action', assignment: {} }],
  user_change_password_action: [
    { role: 'user_change_password_action', assignment: {} },
  ],
  user_settings_edit_action: [
    { role: 'user_settings_edit_action', assignment: {} },
  ],
}
