import { PaymentsLinkUseCases } from 'src/domain'
import { usePaymentsLinkService } from 'src/infrastructure'
import {
  mapToPaymentLink,
  UsePaymentsLinkAdapter,
  mapToWidgetPaymentLinkArray,
  mapToCreateLinkPayload,
  mapToCancelLinkPayload,
  mapToCreateBatchPayload,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mapToWidgetIdLinkPayload,
} from 'src/adapters'

export const usePaymentsLinkAdapter: UsePaymentsLinkAdapter = () => {
  const paymentsLinkService = usePaymentsLinkService()

  const listAllWidgets: PaymentsLinkUseCases['listAllWidgets'] = async () => {
    const listAllWidgetResponse = await paymentsLinkService.listAllWidgets()
    return mapToWidgetPaymentLinkArray(listAllWidgetResponse.data)
  }

  const createLink: PaymentsLinkUseCases['createLink'] = async (args: any) => {
    const linkCreatePayload = mapToCreateLinkPayload(args)

    const linkCreateResponse = await paymentsLinkService.createLink(
      linkCreatePayload
    )
    return mapToPaymentLink(linkCreateResponse.data)
  }

  const cancelLink: PaymentsLinkUseCases['cancelLink'] = async (args: any) => {
    const linkCancelPayload = mapToCancelLinkPayload(args)

    const linkCancelResponse = await paymentsLinkService.cancelLink(
      linkCancelPayload
    )
    return linkCancelResponse.data
  }

  const getPaymentLinks: PaymentsLinkUseCases['getPaymentLinks'] = async (
    fecthDataOptions: any,
    widgetIds: any
  ) => {
    // const widgetIdsPayload = mapToWidgetIdLinkPayload(fecthDataOptions)
    const widgetIdsPayload = widgetIds
    const paymentsLinksResponse = await paymentsLinkService.getPaymentLinks(
      fecthDataOptions.pageSize,
      fecthDataOptions.pageIndex + 1,
      fecthDataOptions,
      widgetIdsPayload
    )
    return paymentsLinksResponse.data
  }

  const createBatch: PaymentsLinkUseCases['createBatch'] = async (
    args: any
  ) => {
    const linkBatchPayload = mapToCreateBatchPayload(args)

    const linkBatchResponse = await paymentsLinkService.createBatch(
      linkBatchPayload
    )
    return linkBatchResponse
  }

  const getPaymentsBatch: PaymentsLinkUseCases['getPaymentsBatch'] = async (
    fecthDataOptions: any
  ) => {
    const paymentsBatchResponse = await paymentsLinkService.getPaymentsBatch(
      fecthDataOptions.pageSize,
      fecthDataOptions.pageIndex + 1
    )
    return paymentsBatchResponse.data
  }

  const cancelBatch: PaymentsLinkUseCases['cancelLink'] = async (args: any) => {
    const linkCancelPayload = mapToCancelLinkPayload(args)

    const linkCancelResponse = await paymentsLinkService.cancelPaymentsBatch(
      linkCancelPayload
    )
    return linkCancelResponse.data
  }

  const downloadBatch: PaymentsLinkUseCases['downloadBatch'] = async (id) => {
    const paymentsDownloadResponse =
      await paymentsLinkService.downloadPaymentBatch(id)
    return paymentsDownloadResponse.data
  }

  // const downloadPaymentLinks: PaymentsLinkUseCases['downloadPayments'] = async () => {
  //   const paymentsLinkResponse = await paymentsLinkService.downloadPaymentLinks()
  //   return paymentsLinkResponse
  // }

  return {
    listAllWidgets,
    createLink,
    cancelLink,
    getPaymentLinks,
    createBatch,
    getPaymentsBatch,
    cancelBatch,
    downloadBatch,
    //downloadPaymentLinks,
  }
}
