import React, { FC, HTMLProps, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { format } from 'date-fns'
import { ColumnDef } from '@tanstack/react-table'
import { useQuery } from 'react-query'
import { usePaymentsLinkUseCases, useWidgetUseCases } from 'src/service-locator'
import {
  Card,
  IconMoon,
  Typography,
  Anchor,
} from '@prometeoapi/afrodita-react-component-lib'
import { capitalizeWords } from 'src/utils'

import { Widget } from 'src/domain'
import { LIST_ALL_WIDGETS_QUERY_KEY } from 'src/config'
import { AxiosError } from 'axios'

import DatePicker, { registerLocale } from 'react-datepicker'

import './react-datepicker.css'
import styles from './payment-link-table.module.scss'
import { es, enUS } from 'date-fns/locale'
import { TableComponent } from 'src/components/table'
import { DownloadButton } from 'src/components/table/download-button'

export const PaymentTableLink: FC = () => {
  const { t } = useTranslation(['payments', 'paymentsLink'])
  const navigate = useNavigate()
  const { listAllWidgets } = useWidgetUseCases()
  const { getPaymentLinks } = usePaymentsLinkUseCases()
  const [searchParams] = useSearchParams()

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchDownload, setSearchDownload] = useState('')

  const [formatStartDate, serFormatStartDate] = useState(
    searchParams.get('date-from') ?? ''
  )
  const [formatEndDate, serFormatEndDate] = useState(
    searchParams.get('date-to') ?? ''
  )
  const [filterStatus, setFilterStatus] = useState(
    searchParams.get('status-filter') ?? ''
  )
  const [min, setMin] = useState(searchParams.get('min') ?? '')
  const [max, setMax] = useState(searchParams.get('max') ?? '')
  const [isActiveConfirmed, setIsActiveConfirmed] = useState(
    filterStatus === 'active'
  )
  const [isActiveError, setIsActiveError] = useState(
    filterStatus === 'cancelled'
  )
  const [isActiveRejected, setIsActiveRejected] = useState(
    filterStatus === 'expired'
  )
  const [isPay, setIsPay] = useState(false)
  const [isNotPay, setNotIsPay] = useState(false)

  const [filterPage, setFilterPage] = useState('')
  const [paid, setPaid] = useState<boolean | null>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [widgetIdList, setWidgetIdList] = useState<string[] | undefined>([])

  const queryListWidget = useQuery<Widget[], AxiosError>(
    LIST_ALL_WIDGETS_QUERY_KEY,
    listAllWidgets
  )
  const widgetsList = queryListWidget.data

  useEffect(() => {
    if (widgetsList?.length !== 0) {
      const ids = widgetsList?.map((widget: any) => widget.publicKey)
      setWidgetIdList(ids)
    }
  }, [widgetsList])

  registerLocale('es', es)
  registerLocale('enUS', enUS)

  const onClickStatus = (e: any) => {
    const typeStatus = e.target?.value
    setFilterStatus(typeStatus)
    setActiveButtons(typeStatus)
  }

  // eslint-disable-next-line
  const onClickStatusPayment = (e: any) => {
    const typeStatus = e.target?.value
    setPaymentButtons(typeStatus)
  }

  const setPaymentButtons = (typeStatus: string) => {
    if (typeStatus === 'p') {
      setIsPay(true)
      setNotIsPay(false)
      setPaid(true)
    } else if (typeStatus === 'np') {
      setIsPay(false)
      setNotIsPay(true)
      setPaid(!true)
    }
  }

  const setActiveButtons = (typeStatus: string) => {
    setFilterPage(typeStatus)
    if (typeStatus === 'active') {
      setIsActiveConfirmed(true)
      setIsActiveError(false)
      setIsActiveRejected(false)
    } else if (typeStatus === 'cancelled') {
      setIsActiveConfirmed(false)
      setIsActiveError(true)
      setIsActiveRejected(false)
    } else if (typeStatus === 'expired') {
      setIsActiveConfirmed(false)
      setIsActiveError(false)
      setIsActiveRejected(true)
    }
  }

  const deleteFilters = () => {
    setFilterPage('delete')
    setFilterStatus('')
    setMin('')
    setMax('')
    setStartDate(null)
    setEndDate(null)
    serFormatStartDate('')
    serFormatEndDate('')
    setIsActiveConfirmed(false)
    setIsActiveError(false)
    setIsActiveRejected(false)
    setIsPay(false)
    setNotIsPay(false)
    setPaid(null)
    navigate('/payments-link')
  }

  const onChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    const dateStart = format(new Date(start), 'yyyy-MM-dd')
    const dateEnd = format(new Date(end), 'yyyy-MM-dd')
    serFormatStartDate(dateStart)
    serFormatEndDate(dateEnd)
    setFilterPage('date')
  }

  function IndeterminateCheckbox({
    indeterminate,
    ...rest
  }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const ref = useRef<HTMLInputElement>(null!)
    useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, indeterminate])
    if (rest.disabled) {
      return <span ref={ref} {...rest} />
    } else {
      return <input type="checkbox" ref={ref} {...rest} />
    }
  }
  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <span
            className={
              row.original.status === 'cancelled' ||
              row.original.status === 'expired' ||
              row.original.paid === true
                ? styles['disabled']
                : styles['px-1']
            }
          >
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled:
                  row.original.status === 'cancelled' ||
                  row.original.status === 'expired' ||
                  (row.original.paid === true && row.getCanSelect()),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </span>
        ),
      },
      {
        header: t(
          'paymentsLink:paymentLinkPage.paymentLinkList.tableHeaders.createdAt'
        ).toUpperCase(),
        accessorKey: 'created_at',
        enableSorting: true,
        cell: (c: any) => {
          return format(new Date(c.getValue()), 'dd/MM/yyyy')
        },
      },
      {
        header: t(
          'paymentsLink:paymentLinkPage.paymentLinkList.tableHeaders.concept'
        ).toUpperCase(),
        accessorKey: 'payment_data.concept',
        enableSorting: false,
        cell: (c: any) => {
          return c.getValue() ? `${c.getValue()}` : ''
        },
      },
      {
        header: t(
          'paymentsLink:paymentLinkPage.paymentLinkList.tableHeaders.externalId'
        ).toUpperCase(),
        accessorKey: 'payment_data.external_id',
        enableSorting: false,
        cell: (c: any) => {
          return c.getValue() ? `${c.getValue()}` : ''
        },
      },
      {
        header: t(
          'paymentsLink:paymentLinkPage.paymentLinkList.tableHeaders.paymentId'
        ).toUpperCase(),
        accessorKey: 'id',
        enableSorting: true,
        cell: (c: any) => {
          return c.getValue() ? `${c.getValue()}` : ''
        },
      },
      {
        header: t(
          'paymentsLink:paymentLinkPage.paymentLinkList.tableHeaders.link'
        ).toUpperCase(),
        accessorKey: 'url',
        enableSorting: false,
        cell: (c: any) => {
          return (
            <Anchor
              href={c.getValue()}
              typographyColor="typography-primary"
              typographyVariant="small-1"
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              {c.getValue() ? `${c.getValue()}` : ''}
            </Anchor>
          )
        },
      },
      {
        header: t(
          'paymentsLink:paymentLinkPage.paymentLinkList.tableHeaders.payment'
        ).toUpperCase(),
        accessorKey: 'paid',
        enableSorting: true,
        cell: (c: any) => {
          if (c.getValue() === true) {
            return (
              <span className={styles['pago']}>
                {t(
                  'paymentsLink:paymentLinkPage.paymentLinkList.filters.buttons.payment'
                )}
              </span>
            )
          } else {
            return (
              <span className={styles['expired']}>
                {t(
                  'paymentsLink:paymentLinkPage.paymentLinkList.filters.buttons.noPayment'
                )}
              </span>
            )
          }
        },
      },
      {
        header: t(
          'paymentsLink:paymentLinkPage.paymentLinkList.tableHeaders.status'
        ).toUpperCase(),
        accessorKey: 'status',
        enableSorting: false,
        cell: (c: any) => {
          if (c.getValue() === 'active') {
            return (
              <span className={styles['active']}>
                {capitalizeWords(c.getValue() || '')}
              </span>
            )
          } else if (c.getValue() === 'cancelled') {
            return (
              <span className={styles['error']}>
                {capitalizeWords(c.getValue() || '')}
              </span>
            )
          } else if (c.getValue() === 'expired') {
            return (
              <span className={styles['expired']}>
                {capitalizeWords(c.getValue() || '')}
              </span>
            )
          }
        },
      },
      {
        header: t(
          'paymentsLink:paymentLinkPage.paymentLinkList.tableHeaders.currency'
        ).toUpperCase(),
        accessorKey: 'payment_data.currency',
        enableSorting: true,
      },
      {
        header: t(
          'paymentsLink:paymentLinkPage.paymentLinkList.tableHeaders.amount'
        ).toUpperCase(),
        accessorKey: 'payment_data.amount',
        enableSorting: true,
        cell: (c: any) => {
          const amount = new Intl.NumberFormat('en', {
            minimumFractionDigits: 2,
          }).format(c.getValue())
          return <span className={styles['center']}>{amount}</span>
        },
      },
    ],
    [t]
  )
  const checkAmountKey = (key: string) => {
    return (
      (key >= '0' && key <= '9') ||
      ['.', 'ArrowLeft', 'ArrowRight', 'Delete', 'Backspace'].includes(key)
    )
  }
  return (
    <Card>
      <div className={styles['card-header']}>
        <Typography tag="h2" variant="strong-1">
          {t('paymentsLink:paymentLinkPage.paymentLinkList.title')}
        </Typography>

        <div className={styles['download-button']}>
          <DownloadButton
            startDate={formatStartDate}
            endDate={formatEndDate}
            statusFilter={filterStatus}
            amountMin={min}
            amountMax={max}
            search={searchDownload}
            widgetIds={widgetIdList}
            payment={paid}
            currency={''}
          />
        </div>
      </div>
      <div className={styles['filter-header']}>
        <div className={styles['filter-section-border']}>
          <span className={styles['filter-title']}>
            {t('paymentsLink:paymentLinkPage.paymentLinkList.filters.status')}
          </span>
          <div className={styles['filter-button']}>
            <button
              value="active"
              className={`${styles.active} ${
                isActiveConfirmed ? styles.selected : ''
              }`}
              onClick={onClickStatus}
            >
              {t(
                'paymentsLink:paymentLinkPage.paymentLinkList.filters.buttons.confirmed'
              )}
            </button>
            <button
              value="expired"
              className={`${styles.expired} ${
                isActiveRejected ? styles.selected : ''
              }`}
              onClick={onClickStatus}
            >
              {t(
                'paymentsLink:paymentLinkPage.paymentLinkList.filters.buttons.error'
              )}
            </button>
            <button
              value="cancelled"
              className={`${styles.error} ${
                isActiveError ? styles.selected : ''
              }`}
              onClick={onClickStatus}
            >
              {t(
                'paymentsLink:paymentLinkPage.paymentLinkList.filters.buttons.rejected'
              )}
            </button>
          </div>
        </div>
        <div className={styles['filter-section-border']}>
          <span className={styles['filter-title']}>
            {t('paymentsLink:paymentLinkPage.paymentLinkList.filters.payment')}
          </span>
          <div className={styles['filter-button']}>
            <button
              value="p"
              className={`${styles.pago} ${isPay ? styles.selected : ''}`}
              onClick={onClickStatusPayment}
            >
              {t(
                'paymentsLink:paymentLinkPage.paymentLinkList.filters.buttons.payment'
              )}
            </button>
            <button
              value="np"
              className={`${styles.expired} ${isNotPay ? styles.selected : ''}`}
              onClick={onClickStatusPayment}
            >
              {t(
                'paymentsLink:paymentLinkPage.paymentLinkList.filters.buttons.noPayment'
              )}
            </button>
          </div>
        </div>
        <div className={styles['filter-section-border']}>
          <span className={styles['filter-title']}>
            {t('paymentsLink:paymentLinkPage.paymentLinkList.filters.date')}
          </span>
          <div className={styles['calendar-section']}>
            <IconMoon
              className={styles['icon']}
              name="icon-calendar-today"
              size="sm"
            ></IconMoon>
            <DatePicker
              dateFormat="dd/MM/yy"
              selected={startDate}
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={onChange}
              locale="es"
              className={styles['calendar-input']}
              disabledKeyboardNavigation
            />
          </div>
        </div>
        <div className={styles['filter-section-border']}>
          <span className={styles['filter-title']}>
            {t('paymentsLink:paymentLinkPage.paymentLinkList.filters.amount')}
          </span>
          <div className={styles['filter-input']}>
            <input
              name="min"
              type="text"
              placeholder={t(
                'paymentsLink:paymentLinkPage.paymentLinkList.filters.min'
              )}
              value={min}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setMin(e.currentTarget.value)
                setFilterPage('min')
              }}
              onKeyDown={(e) => {
                if (checkAmountKey(e.key) === false) {
                  e.preventDefault()
                }
              }}
            ></input>
            <span className={styles['filter-hyphen']}>{'-'}</span>
            <input
              name="max"
              type="text"
              placeholder={t(
                'paymentsLink:paymentLinkPage.paymentLinkList.filters.max'
              )}
              value={max}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setMax(e.currentTarget.value)
                setFilterPage('max')
              }}
              onKeyDown={(e) => {
                if (checkAmountKey(e.key) === false) {
                  e.preventDefault()
                }
              }}
            ></input>
          </div>
        </div>
        <div className={styles['filter-section']}>
          <div className={styles['filter-button']}>
            <button className={styles['delete-filter']} onClick={deleteFilters}>
              {t(
                'paymentsLink:paymentLinkPage.paymentLinkList.filters.buttons.delete'
              )}
            </button>
          </div>
        </div>
      </div>

      <TableComponent
        columns={columns}
        query={getPaymentLinks}
        startDate={formatStartDate}
        endDate={formatEndDate}
        statusFilter={filterStatus}
        amountMin={min}
        amountMax={max}
        currency={''}
        payment={paid}
        filterPage={filterPage}
        setSearchDownload={setSearchDownload}
      />
    </Card>
  )
}
