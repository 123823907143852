export default {
  consumption: {
    header: {
      title: 'Consumo de Prometeo',
      subtitle: 'Aquí podrás visualizar el resumen de tu consumo.',
    },
  },
  consumptionGraph: {
    header: {
      title: 'Historial de consumo',
      subtitle:
        'Haz clic sobre el mes del que quieres conocer el consumo y en la parte inferior observarás el total del mes y el total histórico.',
    },
    footer: {
      totalMonth: 'Total usado en',
      historicalTotal: 'Total histórico',
    },
  },
  consumptionSummary: {
    header: {
      title: 'Resumen de tu consumo por API',
      subtitle1:
        'Elige el mes del que quieras conocer el consumo y a continuación',
      subtitle2: 'encontrarás el resumen detallado.',
    },
    investedTotal: 'Total invertido',
    breakdown: 'Desgloce',
    movements: 'Movimientos',
    requests: 'requests',
    requestsUser: 'requests / usuarios',
    transfer: 'Transferencias',
    apiValidation: 'API Validación',
    apiSandbox: 'API Sandbox',
  },
}
