import { StageOptions, StatusOptions } from 'src/infrastructure'
import {
  MapToHome,
  MapToHomeStageOptions,
  MapToHomeStatusOptions,
  MapToHomeUser,
  MapToHomeUserPricingData,
  MapToHomeUserRequestsData,
  MapToHomeUserStageData,
} from 'src/adapters'
import * as settings from 'src/config/settings'

export const mapToHome: MapToHome = (response) => ({
  user: mapToHomeUser(response.user),
  requestsData: mapToHomeUserRequestsData(response.requests_data),
  stageData: mapToHomeUserStageData(response.stage_data),
  pricingData: mapToHomeUserPricingData(response.pricing_data),
  billingInformationStatus: mapToHomeStatusOptions(
    response.billing_information_status
  ),
  companyDataStatus: mapToHomeStatusOptions(response.company_data_status),
  isInTestingServer: response.is_in_testing_server,
  connectionCount: response.connection_count,
  minimumRequestsForPreproduction: response.minimum_requests_for_preproduction,
  daysOfUse: response.days_of_use,
  daysResetPassword: response.days_reset_password,
})

const mapToHomeUser: MapToHomeUser = (response) => ({
  stage: mapToHomeStageOptions(response.stage),
  pricing: response.pricing,
  apiKey: response.api_key,
  sandboxApiKey: response.sandbox_api_key,
})

const mapToHomeStageOptions: MapToHomeStageOptions = (option) => {
  if (!option) {
    return settings.MARKETPLACE_DEFAULT_STAGE
  }
  switch (option) {
    case StageOptions.preproduction:
      return 'preproduction'
    case StageOptions.production:
      return 'production'
    case StageOptions.sandbox:
      return 'sandbox'
  }
}

const mapToHomeStatusOptions: MapToHomeStatusOptions = (option) => {
  switch (option) {
    case StatusOptions.blank:
      return 'blank'
    case StatusOptions.pending:
      return 'pending'
    case StatusOptions.approved:
      return 'approved'
  }
}

const mapToHomeUserRequestsData: MapToHomeUserRequestsData = (response) => {
  return {
    totalRequests: response.total_requests,
    totalRequestsSandbox: response.total_requests_sandbox,
  }
}

const mapToHomeUserStageData: MapToHomeUserStageData = (response) => {
  return {
    maxCountriesConnections: response?.max_countries_connections,
    maxDaysAvailable: response?.max_days_available,
  }
}

const mapToHomeUserPricingData: MapToHomeUserPricingData = (response) => {
  return {
    maxProdRequests: response?.max_prod_requests,
  }
}
