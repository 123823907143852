import { FC } from 'react'
import classNames from 'classnames'

import { Icon, Typography } from '@lib-components'
import { AlertTitleProps, AlertBodyProps, AlertProps } from '@lib-types'
import { getStyledElementProps } from '@lib-utils'

import styles from './alert.module.scss'

export const AlertTitle: FC<AlertTitleProps> = ({
  icon,
  children,
  iconColor = 'gray-6',
  iconSize = 'xl',
  ...props
}) => {
  return (
    <div className={styles['title']}>
      {icon && <Icon name={icon} color={iconColor} size={iconSize} />}
      <Typography
        variant={'featured-3'}
        tag={'span'}
        typographyColor={'gray-8'}
        {...props}
      >
        {children}
      </Typography>
    </div>
  )
}

export const AlertBody: FC<AlertBodyProps> = ({
  icon,
  children,
  iconColor = 'gray-6',
  iconSize = 'xl',
  ...props
}) => {
  return (
    <div className={styles['body']}>
      {icon && <Icon name={icon} color={iconColor} size={iconSize} />}
      <Typography
        variant={'body-1'}
        tag={'span'}
        typographyColor={'gray-6'}
        {...props}
      >
        {children}
      </Typography>
    </div>
  )
}

export const Alert: FC<AlertProps> = ({
  children,
  variant = 'default',
  onClose,
  ...props
}) => {
  const styledProps = getStyledElementProps(
    props,
    classNames(styles['root'], styles[variant])
  )
  return (
    <div {...styledProps}>
      {onClose && (
        <Icon
          name={'close'}
          className={styles['close-icon']}
          onClick={() => onClose()}
        />
      )}
      {children}
    </div>
  )
}
