import { Link } from 'react-router-dom'
import { Typography } from '@lib-components'
import { useAuth } from 'src/infrastructure'

export function PermissionDeniedPage() {
  const { logout } = useAuth()

  const onClickLogout = () => {
    logout()
  }

  return (
    <div id="notfound">
      <div className="notfound-bg" />
      <div className="notfound">
        <div className="notfound-404">
          <Typography tag="h1">403</Typography>
        </div>
        <Typography tag="h2">
          Oops, You don&apos;t have permission to access this page
        </Typography>
        <Link to="/" className="btn home-btn" onClick={onClickLogout}>
          Logout
        </Link>
      </div>
    </div>
  )
}
