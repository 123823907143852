import {
  Dispatch,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react'

export function useSafeDispatch<TState>(
  dispatch: Dispatch<SetStateAction<TState>>
) {
  const mounted = useRef(false)
  useLayoutEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])
  return useCallback(
    (args: SetStateAction<TState>) => {
      if (mounted.current) return dispatch(args)
    },
    [dispatch]
  )
}
