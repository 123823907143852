export default {
  hello: 'hello from en:shared',
  nav: {
    summary: 'Summary',
    usage: 'Usage',
    consumption: 'Consumption',
    documentation: 'Documentation',
    helpCenter: 'Help center',
    settings: 'Settings',
    logOut: 'Log out',
    widgets: 'Widgets',
    payments: 'Payments',
    paymentsLink: 'Payments Link',
    listPaymentLink: 'List of Payment Links',
    massiveLoad: 'Massive link upload',
    individualLoad: 'Individual link upload',
    paymentsLinkBatch: 'Payments Link Batch',
  },
  api: {
    errors: {
      tryAgain: 'An error has occurred, please try again',
      '403': "You don't have enough permissions to see this content",
      '401': {
        label: 'Your account is invalid',
        description:
          'The email or password is invalid, please try again or reset your password',
      },
      '400': {
        label: 'Your account is locked',
        description:
          'Account Locked Out - Too many login attempts. Contact support to unlock your account.',
      },
      tokenDoesNotExist: 'The token was used, you need a new one',
      tokenIsNotValid: 'The token is not valid',
    },
  },
  form: {
    errors: {
      emailField: '{{name}} must be a valid email',
      numberField: '{{name}} must be a number',
      requiredField: '{{name}} is required',
      maxLength: '{{name}} must be {{max}} characters or less',
      minLength: '{{name}} must be {{min}} characters or more',
    },
    passwordMust: 'Password must',
    requirements: {
      upperLetterCheck: 'contain at least 1 uppercase letter',
      lowerLetterCheck: 'contain at least 1 lowercase letter',
      numberCheck: 'contain at least 1 number',
      specialCharCheck:
        'contain at least 1 special characters, for example: !,@,#,$,^,&,*',
      pwdLengthCheck: 'be at least 8 characters in length',
    },
  },
}
