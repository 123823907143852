import { ChangeEvent, FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import {
  Typography,
  Spacer,
  Card,
  TextCardTitle,
  InputLabel,
  Button,
  IconMoon,
  Spinner,
  Anchor,
} from '@prometeoapi/afrodita-react-component-lib'
import { usePaymentsLinkUseCases } from 'src/service-locator'
import styles from './create-batch.module.scss'

type IFormInput = {
  archivo: File
}

export const CreateBatchPage: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['shared', 'paymentsLink'])
  const { createBatch } = usePaymentsLinkUseCases()

  const modalRef = useRef<HTMLDialogElement>(null)
  const modalHelpRef = useRef<HTMLDialogElement>(null)

  const [fileName, setFileName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [stringData, setStringData] = useState<any>(null)
  const [success, setSuccess] = useState(false)
  const [errorResponse, setErrorResponse] = useState(false)
  const [loading, setLoading] = useState(true)
  const [helpConvert, setHelpConvert] = useState(false)
  const [colFormat, setColFormat] = useState(false)
  const [fileFormat, setFileFormat] = useState('')

  const fileReader = new FileReader()
  const formSchema = Yup.object().shape({
    archivo: Yup.mixed().required(
      t('shared:form.errors.requiredField', {
        name: t(`paymentsLink:createBatch.form.fields.archivo.placeholder`),
      })
    ),
  })

  const { register, handleSubmit } = useForm<IFormInput>({
    defaultValues: {},
    resolver: yupResolver(formSchema),
  })

  const createBatchAction = async () => {
    handleCreateLinkButtonClick()
    const payload = {
      file: stringData,
      format: fileFormat,
    }
    try {
      setLoading(true)
      const response = await createBatch(payload)
      setLoading(false)
      if (response.status == 201) {
        setSuccess(true)
      }
    } catch (error: any) {
      if (error.response.status == 401) {
        navigate('/login')
      }
      setErrorResponse(true)
      setErrorMessage(error._messageForUser.description)
    }
  }

  const onSubmit: SubmitHandler<IFormInput> = async () => {
    {
      fileName
        ? await createBatchAction()
        : setErrorMessage(
            t('shared:form.errors.requiredField', {
              name: t(
                `paymentsLink:createBatch.form.fields.archivo.placeholder`
              ),
            })
          )
    }
  }

  const handleCreateLinkButtonClick = () => {
    if (modalRef.current) {
      modalRef.current.showModal()
    }
  }

  const handleCloseModalClick = () => {
    if (modalRef.current) {
      modalRef.current.close()
      navigate('/payments-link-batch')
    }
  }

  const handleHelpButton = () => {
    setHelpConvert(true)
    if (modalHelpRef.current) {
      modalHelpRef.current.showModal()
    }
  }
  const handleColFormatButton = () => {
    setColFormat(true)
    if (modalHelpRef.current) {
      modalHelpRef.current.showModal()
    }
  }

  const handleCloseHelpModal = () => {
    if (modalHelpRef.current) {
      modalHelpRef.current.close()
      setColFormat(false)
      setHelpConvert(false)
    }
  }

  const onChangeFileInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      setFileFormat(file.type)
      if (file) {
        setFileName(file.name)
        fileReader.readAsText(file)
        fileReader.onload = function (event) {
          const csvdata = event.target?.result
          setStringData(csvdata)
        }
      } else {
        setErrorMessage(
          t('shared:form.errors.requiredField', {
            name: t(`paymentsLink:createBatch.form.fields.archivo.placeholder`),
          })
        )
      }
    }
  }

  return (
    <div className={styles['container-page']}>
      <Typography tag="p" variant="body-1" typographyColor="gray-6">
        <a href="/payments-link-batch" style={{ color: '#2f3a44' }}>
          {t('paymentsLink:createBatch.breadcumb')}
        </a>

        {` > ${t('paymentsLink:createBatch.breadcumb1')}`}
      </Typography>
      <Spacer variant={'vertical'} size={'6x'} />
      <Typography tag="p" variant="headline-1">
        {t('paymentsLink:createBatch.title')}
      </Typography>

      <Spacer variant={'vertical'} size={'6x'} />

      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <TextCardTitle>
              <div className={styles['card-header']}>
                <div>
                  <Typography tag="p" variant="strong-1">
                    {t('paymentsLink:createBatch.formTitle')}
                  </Typography>{' '}
                </div>
              </div>
            </TextCardTitle>

            <div className={styles['row-fields']}>
              <InputLabel>
                {t('paymentsLink:createBatch.form.fields.archivo.label')}
              </InputLabel>
              <Spacer size={'1x'} />
              <div className={styles['container-info']}>
                <Typography variant="small-1" withFontFamily>
                  {t('paymentsLink:createBatch.form.fields.archivo.helpText')}
                </Typography>
                <span className={styles['pointer']} onClick={handleHelpButton}>
                  <Typography
                    variant="small-1"
                    withFontFamily
                    typographyColor="red-5"
                  >
                    {t(
                      'paymentsLink:createBatch.form.fields.archivo.helpText1'
                    )}
                  </Typography>{' '}
                </span>
                <Typography variant="small-1" withFontFamily>
                  {t('paymentsLink:createBatch.form.fields.archivo.helpText2')}
                </Typography>
                <span
                  className={styles['pointer']}
                  onClick={handleColFormatButton}
                >
                  <Typography
                    variant="small-1"
                    withFontFamily
                    typographyColor="red-5"
                  >
                    {t(
                      'paymentsLink:createBatch.form.fields.archivo.helpText3'
                    )}
                  </Typography>{' '}
                </span>
                <Spacer size={'1x'} />
                <Typography
                  variant="small-1"
                  withFontFamily
                  typographyColor="red-5"
                >
                  <Anchor
                    href="/docs/template_masive_links_batch.xlsx"
                    typographyColor="red-5"
                    typographyTag="span"
                    typographyVariant="small-1"
                    underlineOnHover
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('paymentsLink:createBatch.form.fields.archivo.download')}
                  </Anchor>
                </Typography>{' '}
              </div>
              <Spacer size={'5x'} />
              <span className={styles['input-container']}>
                <input
                  {...register('archivo')}
                  className={styles['file-input']}
                  type="file"
                  name="paymentslinkfile"
                  accept=".csv, .json, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeFileInput(e)
                  }}
                />
                <Typography variant={'body-1'} typographyColor={'gray-4'}>
                  {fileName
                    ? fileName
                    : t(
                        'paymentsLink:createBatch.form.fields.archivo.placeholder'
                      )}
                </Typography>
                <button className={styles['button-file']}>
                  <Typography variant={'small-1'} typographyColor={'gray-6'}>
                    {t('paymentsLink:createBatch.form.fields.archivo.button')}
                  </Typography>
                </button>
              </span>

              {errorMessage ? (
                <p className={styles['error-text']}>{errorMessage} </p>
              ) : (
                ''
              )}
            </div>
          </Card>
          <Spacer size={'10x'} />
          <div className={styles['btn-create']}>
            <Button id="button" type="submit" size="md" variant="primary-light">
              {t('paymentsLink:createBatch.form.button')}
            </Button>
          </div>
        </form>
      </div>
      <dialog ref={modalRef} className={styles['modal-container']}>
        {loading ? (
          <div className={styles['loading-container']}>
            <Spinner />
          </div>
        ) : (
          ''
        )}{' '}
        {success ? (
          <div className={styles[`success-response`]}>
            <Typography tag="h3" variant="headline-3" typographyColor="gray-8">
              {t('paymentsLink:createBatch.modal.title1')}
            </Typography>

            <Spacer />

            <Typography tag="p" variant="body-1" typographyColor="gray-6">
              {t('paymentsLink:createBatch.modal.description', {
                filename: fileName,
              })}
            </Typography>

            <Spacer size={'8x'} />
            <InputLabel>{t('paymentsLink:createBatch.modal.label')}</InputLabel>
            <span
              className={styles['input-container']}
              style={{ border: 'solid #12C75D' }}
            >
              <Typography tag="p" variant="body-1" typographyColor="gray-6">
                {fileName}
              </Typography>
              <IconMoon name="icon-check-green" size="md" />
            </span>

            <Spacer size={'8x'} />

            <div className={styles['modal-button-row']}>
              <>
                <Button
                  size="md"
                  variant="primary-light"
                  onClick={handleCloseModalClick}
                >
                  {t('paymentsLink:createBatch.modal.exit')}
                </Button>
              </>
            </div>
          </div>
        ) : (
          ''
        )}
        {errorResponse ? (
          <div className={styles['error-response']}>
            <Typography tag="h3" variant="headline-3" typographyColor="gray-8">
              {t('paymentsLink:createBatch.errorModal.title')}
            </Typography>

            <Spacer />

            <Typography tag="p" variant="body-1" typographyColor="gray-6">
              {t('paymentsLink:createBatch.errorModal.description', {
                filename: fileName,
              })}
            </Typography>
            <Typography tag="p" variant="small-1" typographyColor="red-5">
              <Spacer />
              {t('paymentsLink:createBatch.errorModal.helpText')}
            </Typography>
            <Spacer size={'5x'} />

            <div className={styles['modal-button-row']}>
              <>
                <Button
                  size="md"
                  variant="primary-light"
                  onClick={handleCloseModalClick}
                >
                  {t('paymentsLink:createBatch.errorModal.cancel')}
                </Button>
              </>
            </div>
          </div>
        ) : (
          ''
        )}
      </dialog>
      <dialog ref={modalHelpRef} className={styles['modal-help-container']}>
        {helpConvert ? (
          <>
            <Typography tag="h3" variant="headline-3" typographyColor="gray-8">
              {t('paymentsLink:createBatch.helpModal.title')}
            </Typography>

            <Spacer />

            <Typography tag="p" variant="body-1" typographyColor="gray-6">
              {t('paymentsLink:createBatch.helpModal.csvTitle')}
            </Typography>
            <ul className={styles['modal-list']}>
              <li>
                <Typography tag="p" variant="body-1" typographyColor="gray-6">
                  {t('paymentsLink:createBatch.helpModal.csvStep1')}
                </Typography>
              </li>
              <li>
                <Typography tag="p" variant="body-1" typographyColor="gray-6">
                  {t('paymentsLink:createBatch.helpModal.csvStep2')}
                </Typography>
              </li>
              <li>
                <Typography tag="p" variant="body-1" typographyColor="gray-6">
                  {t('paymentsLink:createBatch.helpModal.csvStep3')}
                </Typography>
              </li>
              <li>
                <Typography tag="p" variant="body-1" typographyColor="gray-6">
                  {t('paymentsLink:createBatch.helpModal.csvStep4')}
                </Typography>
              </li>
            </ul>
            <Spacer />
            <Typography tag="p" variant="body-1" typographyColor="gray-6">
              {t('paymentsLink:createBatch.helpModal.jsonTitle')}
            </Typography>
            <ul className={styles['modal-list']}>
              <li>
                <Typography tag="p" variant="body-1" typographyColor="gray-6">
                  {t('paymentsLink:createBatch.helpModal.jsonStep1')}
                </Typography>
              </li>
              <li>
                <Typography tag="p" variant="body-1" typographyColor="gray-6">
                  {t('paymentsLink:createBatch.helpModal.jsonStep2')}
                </Typography>
              </li>
              <li>
                <Typography tag="p" variant="body-1" typographyColor="gray-6">
                  {t('paymentsLink:createBatch.helpModal.jsonStep3')}
                </Typography>
              </li>
            </ul>
          </>
        ) : (
          ''
        )}

        {colFormat ? (
          <>
            <Typography tag="h3" variant="headline-3" typographyColor="gray-8">
              {t('paymentsLink:createBatch.colFormatModal.title')}
            </Typography>
            <Typography tag="p" variant="body-2" typographyColor="gray-8">
              {t('paymentsLink:createBatch.colFormatModal.helpText')}
            </Typography>

            <Spacer />

            <ul className={styles['modal-list']}>
              <li>
                <Typography
                  className={styles['option-list']}
                  variant="body-2"
                  typographyColor="gray-6"
                >
                  <span className={styles['title-list']}>
                    {t('paymentsLink:createBatch.colFormatModal.titleProduct')}
                  </span>

                  {t(
                    'paymentsLink:createBatch.colFormatModal.descriptionProduct'
                  )}
                </Typography>
              </li>
              <li>
                <Typography
                  className={styles['option-list']}
                  variant="body-2"
                  typographyColor="gray-6"
                >
                  <span className={styles['title-list']}>
                    {t('paymentsLink:createBatch.colFormatModal.titleAmount')}
                  </span>

                  {t(
                    'paymentsLink:createBatch.colFormatModal.descriptionAmount'
                  )}
                </Typography>
              </li>
              <li>
                <Typography
                  className={styles['option-list']}
                  variant="body-2"
                  typographyColor="gray-6"
                >
                  <span className={styles['title-list']}>
                    {t('paymentsLink:createBatch.colFormatModal.titleCurrency')}
                  </span>

                  {t(
                    'paymentsLink:createBatch.colFormatModal.descriptionCurrency'
                  )}
                </Typography>
              </li>
              <li>
                <Typography
                  className={styles['option-list']}
                  variant="body-2"
                  typographyColor="gray-6"
                >
                  <span className={styles['title-list']}>
                    {t('paymentsLink:createBatch.colFormatModal.titleConcept')}
                  </span>

                  {t(
                    'paymentsLink:createBatch.colFormatModal.descriptionConcept'
                  )}
                </Typography>
              </li>
              <li>
                <Typography
                  className={styles['option-list']}
                  variant="body-2"
                  typographyColor="gray-6"
                >
                  <span className={styles['title-list']}>
                    {t(
                      'paymentsLink:createBatch.colFormatModal.titleExternalId'
                    )}
                  </span>

                  {t(
                    'paymentsLink:createBatch.colFormatModal.descriptionExternalID'
                  )}
                </Typography>
              </li>
              <li>
                <Typography
                  className={styles['option-list']}
                  variant="body-2"
                  typographyColor="gray-6"
                >
                  <span className={styles['title-list']}>
                    {t('paymentsLink:createBatch.colFormatModal.titleEmail')}
                  </span>

                  {t(
                    'paymentsLink:createBatch.colFormatModal.descriptionEmail'
                  )}
                </Typography>
              </li>
              <li>
                <Typography
                  className={styles['option-list']}
                  variant="body-2"
                  typographyColor="gray-6"
                >
                  <span className={styles['title-list']}>
                    {t('paymentsLink:createBatch.colFormatModal.titleExpireAt')}
                  </span>

                  {t(
                    'paymentsLink:createBatch.colFormatModal.descriptionExpireAt'
                  )}
                </Typography>
              </li>
            </ul>
          </>
        ) : (
          ''
        )}

        <div className={styles['modal-button-row']}>
          <>
            <Button
              size="md"
              variant="primary-light"
              onClick={handleCloseHelpModal}
            >
              {t('paymentsLink:createBatch.errorModal.cancel')}
            </Button>
          </>
        </div>
      </dialog>
    </div>
  )
}
