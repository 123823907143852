import {
  CreateWidgetArgs,
  UpdateWidgetArgs,
  Widget,
  Provider,
  PaginatedResults,
  Domain,
  DestinationAccountRule,
  CreateDestinationAccountReturns,
  CreateDestinationAccountArgs,
  UpdateDestinationAccountReturns,
  UpdateDestinationAccountArgs,
  WidgetDestinationAccount,
  CreateDestinationAccountValidationErrors,
  GetDestinationAccountReturns,
  UpdateDestinationAccountValidationErrors,
  CreateWidgetValidationErrors,
  Bank,
} from 'src/domain'
import { WidgetsService } from 'src/infrastructure'
import { removeEmptyErrors } from 'src/utils'

export const mapToWidgetDTO = (
  args: UpdateWidgetArgs
): WidgetsService.UpdateWidgetPayload => ({
  id: args.id,
  name: args.name,
  description: args.description,
  show_prometeo_logo: args.showPrometeoLogo,
  brand_logo: args.brandLogo,
  brand_name: args.brandName,
  brand_primary_color: args.brandPrimaryColor,
  allowed_feature_level: args.allowedFeatureLevel,
  environment: args.environment,
})

export const mapToProviderDTO = (
  provider: Provider
): WidgetsService.Provider => ({
  id: provider.id,
  provider_code: provider.providerCode,
  bank_code: provider.bankCode,
  name: provider.name,
  logo: provider.logo,
  account_type: provider.accountType,
  country: provider.country,
})

export const mapWidgetProvidersToDTO = (
  providers: Provider[]
): WidgetsService.Provider[] => {
  return providers.map(mapToProviderDTO)
}

export const mapWidgetProvidersIdList = (
  providers: Provider[]
): number[] | undefined => {
  if (providers.length) {
    return providers.map((provider) => provider.id)
  }
}

export const mapToDomain = (dto: WidgetsService.Domain): Domain => ({
  whitelistedDomains: dto.whitelisted_domains,
  environment: dto.environment,
})

export const mapToDomainArray = (domains: WidgetsService.Domain[]): Domain[] =>
  domains.map<Domain>(mapToDomain)

export const mapToWidgetDestinationAccount = (
  account: WidgetsService.WidgetDestinationAccount
): WidgetDestinationAccount => ({
  id: account.id,
  bankAccount: account.bank_account,
  bankAccountBranch: account.bank_account_branch,
  bankAccountOwner: account.bank_account_owner,
  bank: mapToBank(account.bank),
})

export const mapToGetDestinationAccountReturns = (
  account: WidgetsService.GetDestinationAccountResponse
): GetDestinationAccountReturns => ({
  widget: account.widget,
  bankAccount: account.bank_account,
  bankAccountBranch: account.bank_account_branch,
  bankAccountOwner: account.bank_account_owner,
  destinationAccountType: account.bank_destination_account_type,
  bank: mapToBank(account.bank),
})

export const mapToCreateDestinationAccountPayload = (
  args: CreateDestinationAccountArgs
): WidgetsService.CreateDestinationAccountPayload => ({
  widget: args.widget,
  bank_account: args.bankAccount,
  bank_account_branch: args.bankAccountBranch,
  bank_account_owner: args.bankAccountOwner,
  bank_code: args.bankCode,
  bank_destination_account_type: args.destinationAccountType,
})

export const mapToCreateDestinationAccountReturns = (
  account: WidgetsService.CreateDestinationAccountResponse
): CreateDestinationAccountReturns => ({
  id: account.id,
  bankAccount: account.bank_account,
  bankAccountBranch: account.bank_account_branch,
  bankAccountOwner: account.bank_account_owner,
  bankCode: account.bank_code,
})

export const mapToCreateDestinationAccountValidationErrors = (
  responseErrors: WidgetsService.CreateDestinationAccountResponseErrors
): CreateDestinationAccountValidationErrors => ({
  widget: responseErrors.widget,
  bankAccount: responseErrors.bank_account,
  bankAccountBranch: responseErrors.bank_account_branch,
  bankAccountOwner: responseErrors.bank_account_owner,
  bankCode: responseErrors.bank_code,
  destinationAccountType: responseErrors.bank_destination_account_type,
})

export const mapToUpdateDestinationAccountValidationErrors = (
  responseErrors: WidgetsService.UpdateDestinationAccountResponseErrors
): UpdateDestinationAccountValidationErrors => ({
  id: responseErrors.id,
  bankAccount: responseErrors.bank_account,
  bankAccountBranch: responseErrors.bank_account_branch,
  bankAccountOwner: responseErrors.bank_account_owner,
})

export const mapToUpdateDestinationAccountPayload = (
  args: UpdateDestinationAccountArgs
): WidgetsService.UpdateDestinationAccountPayload => ({
  id: args.id,
  bank_account: args.bankAccount,
  bank_account_branch: args.bankAccountBranch,
  bank_account_owner: args.bankAccountOwner,
})

export const mapToUpdateDestinationAccountReturns = (
  account: WidgetsService.UpdateDestinationAccountResponse
): UpdateDestinationAccountReturns => ({
  bankAccount: account.bank_account,
  bankAccountBranch: account.bank_account_branch,
  bankAccountOwner: account.bank_account_owner,
  widget: account.widget,
  bank: mapToBank(account.bank),
})

export const mapToWidgetDestinationAccountArray = (
  accounts: WidgetsService.WidgetDestinationAccount[]
): WidgetDestinationAccount[] =>
  accounts.map<WidgetDestinationAccount>(mapToWidgetDestinationAccount)

export const mapToWidgetDestinationAccountRule = (
  dto: WidgetsService.DestinationAccountRule
): DestinationAccountRule => ({
  sourceProviderCode: dto.source_provider_code,
  destinationBankCode: dto.destination_bank_code,
  destinationInstitution: dto.destination_institution,
})

export const mapToWidgetDestinationAccountRuleArray = (
  rules: WidgetsService.DestinationAccountRule[]
): DestinationAccountRule[] =>
  rules.map<DestinationAccountRule>(mapToWidgetDestinationAccountRule)

export const mapToWidget = (dto: WidgetsService.Widget): Widget => ({
  id: dto.id,
  domains: mapToDomainArray(dto.domains ?? []),
  destinationAccounts: mapToWidgetDestinationAccountArray(
    dto.destination_accounts ?? []
  ),
  name: dto.name,
  description: dto.description,
  environment: dto.environment,
  publicKey: dto.public_key,
  allowedFeatureLevel: dto.allowed_feature_level,
  brandName: dto.brand_name,
  brandLogo: dto.brand_logo,
  brandPrimaryColor: dto.brand_primary_color,
  showPrometeoLogo: dto.show_prometeo_logo,
  isActive: dto.is_active,
  createdOn: dto.created_on,
  modifiedOn: dto.modified_on,
  client: dto.client,
  providers: mapToProviderArray(dto.providers),
  destinationAccountRules: mapToWidgetDestinationAccountRuleArray(
    dto.destination_account_rules ?? []
  ),
})

export const mapToCreateWidgetPayload = (
  args: CreateWidgetArgs
): WidgetsService.CreateWidgetPayload => {
  return {
    name: args.name,
    description: args.description,
  }
}

export const mapToUpdateWidgetPayload = (
  args: UpdateWidgetArgs
): WidgetsService.UpdateWidgetPayload => {
  return {
    id: args.id,
    name: args.name,
    description: args.description,
    show_prometeo_logo: args.showPrometeoLogo,
    brand_logo: args.brandLogo,
    brand_name: args.brandName,
    brand_primary_color: args.brandPrimaryColor,
    allowed_feature_level: args.allowedFeatureLevel,
    environment: args.environment,
    providers: mapWidgetProvidersIdList(args.providers ?? []),
  }
}

export const mapToWidgetArray = (data: WidgetsService.Widget[]): Widget[] =>
  data.map(mapToWidget)

export const mapToProvider = (dto: WidgetsService.Provider): Provider => ({
  id: dto.id,
  providerCode: dto.provider_code,
  bankCode: dto.bank_code,
  name: dto.name,
  logo: dto.logo,
  accountType: dto.account_type,
  country: dto.country,
})

export const mapToBank = (dto: WidgetsService.Bank): Bank => ({
  id: dto.id,
  name: dto.name,
  logo: dto.logo,
  country: dto.country,
  code: dto.code,
})

export const mapToBankArray = (results: WidgetsService.Bank[]): Bank[] =>
  results.map(mapToBank)

export const mapToProviderArray = (
  results:
    | WidgetsService.Provider[]
    | WidgetsService.GetBankingProvidersResponse
): Provider[] => {
  return results.map(mapToProvider)
}

export const mapToProviderResults = (
  response: WidgetsService.GetProvidersResponse
): PaginatedResults<Provider> => {
  return {
    message: response.message,
    count: response.count,
    next: response.next,
    previous: response.previous,
    results: mapToProviderArray(response.results),
  }
}

export const mapToCreateWidgetValidationErrors = (
  responseErrors: WidgetsService.CreateWidgetResponseErrors
): CreateWidgetValidationErrors => {
  const mappedErrors = {
    name: responseErrors.name,
    description: responseErrors.description,
  }
  return removeEmptyErrors<CreateWidgetValidationErrors>(mappedErrors)
}
