import { UseAuthUseCases } from 'src/domain'

type initProps = {
  useAuthUseCases: UseAuthUseCases
}

export let useAuthUseCases: UseAuthUseCases

export const init = (props: initProps) => {
  useAuthUseCases = props.useAuthUseCases
}
