import jwtDecode from 'jwt-decode'
import { useCallback } from 'react'

import { TokenTransactions, TokenActions, UserActions } from 'src/domain'
import {
  DecodedAccessToken,
  useAuthMarketplaceService,
  useAuthStorageService,
} from 'src/infrastructure'
import {
  mapToAuthTokens,
  UseUserActionsAdapter,
  mapToUser,
  mapToRefreshTokens,
  UseAuthStorageServiceAdapter,
  UseAuthMarketplaceServiceAdapter,
} from 'src/adapters'

export const useAuthMarketplaceServiceAdapter: UseAuthMarketplaceServiceAdapter =
  () => {
    const authService = useAuthMarketplaceService()

    const getTokens: TokenTransactions['getTokens'] = useCallback(
      async (args) => {
        const getTokensResponse = await authService.getTokens(args)
        return mapToAuthTokens(getTokensResponse.data)
      },
      [authService]
    )

    const getTokensWithSesame: TokenTransactions['getTokensWithSesame'] =
      useCallback(
        async (sesame) => {
          const getTokensWithSesameResponse =
            await authService.getTokensWithSesame(sesame)
          return mapToAuthTokens(getTokensWithSesameResponse.data)
        },
        [authService]
      )

    const refreshAccessToken: TokenTransactions['refreshAccessToken'] =
      useCallback(
        async (refreshToken) => {
          const refreshTokenResponse = await authService.refreshAccessToken({
            refresh: refreshToken,
          })
          return mapToRefreshTokens(refreshTokenResponse.data)
        },
        [authService]
      )

    return {
      getTokens,
      getTokensWithSesame,
      refreshAccessToken,
    }
  }

export const useAuthStorageServiceAdapter: UseAuthStorageServiceAdapter =
  () => {
    const {
      getAccessToken: authServiceGetAccessToken,
      removeAccessToken: authServiceRemoveAccessToken,
      saveAccessToken: authServiceSaveAccessToken,
      getRefreshToken: authServiceGetRefreshToken,
      removeRefreshToken: authServiceRemoveRefreshToken,
      saveRefreshToken: authServiceSaveRefreshToken,
    } = useAuthStorageService()

    const saveAccessToken: TokenActions['saveAccessToken'] = useCallback(
      (token) => {
        authServiceSaveAccessToken(token)
      },
      [authServiceSaveAccessToken]
    )

    const getAccessToken: TokenActions['getAccessToken'] = useCallback(() => {
      return authServiceGetAccessToken()
    }, [authServiceGetAccessToken])

    const removeAccessToken: TokenActions['removeAccessToken'] =
      useCallback(() => {
        authServiceRemoveAccessToken()
      }, [authServiceRemoveAccessToken])

    const saveRefreshToken: TokenActions['saveRefreshToken'] = useCallback(
      (token) => {
        authServiceSaveRefreshToken(token)
      },
      [authServiceSaveRefreshToken]
    )

    const getRefreshToken: TokenActions['getRefreshToken'] = useCallback(() => {
      return authServiceGetRefreshToken()
    }, [authServiceGetRefreshToken])

    const removeRefreshToken: TokenActions['removeRefreshToken'] =
      useCallback(() => {
        authServiceRemoveRefreshToken()
      }, [authServiceRemoveRefreshToken])

    return {
      getAccessToken,
      saveAccessToken,
      removeAccessToken,
      getRefreshToken,
      saveRefreshToken,
      removeRefreshToken,
    }
  }

export const useUserActionsAdapter: UseUserActionsAdapter = () => {
  const { getAccessToken } = useAuthStorageServiceAdapter()
  const authService = useAuthMarketplaceService()

  const getUserFromAccessToken: UserActions['getUserFromAccessToken'] = (
    accessToken
  ) => {
    return mapToUser(jwtDecode<DecodedAccessToken>(accessToken))
  }

  const getUser: UserActions['getUser'] = useCallback(() => {
    const accessToken = getAccessToken()
    if (accessToken) {
      return getUserFromAccessToken(accessToken)
    }
    return null
  }, [getAccessToken])

  const getUserFromTokens: UserActions['getUserFromTokens'] = useCallback(
    (tokens) => {
      const { refreshToken, accessToken, sesameToken } = tokens
      const user = getUserFromAccessToken(accessToken)
      user.refreshToken = refreshToken
      user.sesameToken = sesameToken
      return user
    },
    []
  )

  const logoutUser: any = useCallback(async () => {
    return await authService.logoutUser()
  }, [authService])

  return {
    getUser,
    getUserFromTokens,
    getUserFromAccessToken,
    logoutUser,
  }
}
