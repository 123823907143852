import { FC } from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Spacer, Button } from '@prometeoapi/afrodita-react-component-lib'

import {
  ChangePasswordArgs,
  ChangePasswordFormValidationErrors,
  ChangePasswordReturns,
} from 'src/domain'
import { useUserSettingsUseCases } from 'src/service-locator'
import { ChangePasswordResponseErrors } from 'src/infrastructure'
import {
  mapToChangePasswordFormValidationErrors,
  useMarketplaceFormMutation,
} from 'src/adapters'
import {
  DashboardFormikForm,
  PageHeader,
  PageHeaderTitle,
  useSuccessMutationAlert,
} from 'src/presentation'

import styles from './change-password.module.scss'
import { InputPassword } from 'src/components'

export const ChangePasswordPage: FC = () => {
  const { t } = useTranslation(['shared', 'userSettings'])

  const userSettingsUseCases = useUserSettingsUseCases()

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .required(
          t('shared:form.errors.requiredField', {
            name: t(
              'userSettings:forms.changePassword.fields.oldPassword.label'
            ),
          })
        )
        .min(
          8,
          t('auth:error.length', {
            name: t(`register:registerPage.password`),
          })
        )
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
          t('auth:error.regex', {
            name: t(`register:registerPage.password`),
          })
        ),
      newPassword: Yup.string()
        .required(
          t('shared:form.errors.requiredField', {
            name: t(
              'userSettings:forms.changePassword.fields.newPassword.label'
            ),
          })
        )
        .min(
          8,
          t('auth:error.length', {
            name: t(`register:registerPage.password`),
          })
        )
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
          t('auth:error.regex', {
            name: t(`register:registerPage.password`),
          })
        ),
      newPasswordConfirm: Yup.string()
        .required(
          t('shared:form.errors.requiredField', {
            name: t(
              'userSettings:forms.changePassword.fields.newPasswordConfirm.label'
            ),
          })
        )
        .min(
          8,
          t('auth:error.length', {
            name: t(`register:registerPage.confirmPassword`),
          })
        )
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
          t('auth:error.regex', {
            name: t(`register:registerPage.confirmPassword`),
          })
        )
        .oneOf(
          [Yup.ref('newPassword')],
          t('resetpage:resetPasswordConfirm.error.match', {
            name: t(`register:registerPage.confirmPassword`),
          })
        ),
    }),
    onSubmit: (values) => {
      changePassword(values)
    },
  })

  const changePasswordMutation = useMarketplaceFormMutation<
    ChangePasswordArgs,
    ChangePasswordReturns,
    ChangePasswordResponseErrors,
    ChangePasswordFormValidationErrors
  >({
    requestFunction: userSettingsUseCases.changePassword,
    errorMapper: mapToChangePasswordFormValidationErrors,
    onSuccessCallback: () => {
      setShowAlert(true)
      formik.resetForm()
    },
  })

  const {
    mutate: changePassword,
    error: changePasswordError,
    isSuccess: changePasswordIsSuccess,
  } = changePasswordMutation

  const { renderAlert, showAlert, setShowAlert } = useSuccessMutationAlert({
    isSuccess: changePasswordIsSuccess,
    body: t('userSettings:forms.changePassword.successMessage'),
  })

  return (
    <section className={styles['page-container']}>
      <PageHeader>
        <PageHeaderTitle>
          {t(`userSettings:header.changePassword.title`)}
        </PageHeaderTitle>
      </PageHeader>

      <Spacer size={'10x'} />

      {showAlert && (
        <>
          {renderAlert()}

          <Spacer size={'10x'} />
        </>
      )}

      <DashboardFormikForm formik={formik} mutation={changePasswordMutation}>
        <InputPassword
          {...formik.getFieldProps('oldPassword')}
          type="password"
          label={t(
            'userSettings:forms.changePassword.fields.oldPassword.label'
          )}
          error={
            formik?.errors?.oldPassword ||
            changePasswordError?.formFieldErrors?.oldPassword
          }
        />

        <Spacer />

        <InputPassword
          {...formik.getFieldProps('newPassword')}
          type="password"
          label={t(
            'userSettings:forms.changePassword.fields.newPassword.label'
          )}
          error={
            formik?.errors?.newPassword ||
            changePasswordError?.formFieldErrors?.newPassword
          }
        />

        <Spacer />

        <InputPassword
          {...formik.getFieldProps('newPasswordConfirm')}
          type="password"
          label={t(
            'userSettings:forms.changePassword.fields.newPasswordConfirm.label'
          )}
          error={
            formik.errors.newPasswordConfirm ||
            changePasswordError?.formFieldErrors?.newPasswordConfirm
          }
        />

        <Spacer />

        <Button type={'submit'} disabled={!(formik.isValid && formik.dirty)}>
          {t('userSettings:forms.changePassword.submitButton.label')}
        </Button>
      </DashboardFormikForm>
    </section>
  )
}
