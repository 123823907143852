export default {
  listPage: {
    title: '¡Te damos la bienvenida a nuestro Widget!',
    subtitle:
      'Ofrece a tus clientes una interfaz gráfica simple y segura para que interactúen con nuestras APIs; y aumenta la tasa de conversión de tus procesos.',
    listDescription:
      'Aquí puedes ver tu listado de widgets disponibles para usar y crear uno nuevo:',
    loading: 'Cargando listado de widgets...',
    error: 'Ha ocurrido un error cargando sus widgets.',
    empty: 'No ha creado ningún widget todavía.',
    buttons: {
      createWidget: {
        label: 'Crear nuevo widget',
      },
    },
    forms: {
      createWidget: {
        title: 'Creación de nuevo widget:',
        description: 'Para comenzar completa estos campos',
        loading: 'Creando widget...',
        fields: {
          name: {
            label: 'Nombre',
          },
          description: {
            label: 'Descripción',
          },
        },
        buttons: {
          close: {
            label: 'Cerrar',
          },
          submit: {
            label: 'Crear nuevo widget',
          },
        },
        errors: {
          generic: 'Ha ocurrido un error creando el widget.',
        },
        successMessage: 'El widget se ha creado satisfactoriamente',
        goToWidget: 'Editar Widget {{name}}',
      },
    },
  },
  configPage: {
    loading: 'Cargando widget...',
    error: 'Ha ocurrido un error cargando su widget.',
    preview: 'Vista previa de tu widget',
    buttons: {
      genericEdit: {
        label: 'Editar',
      },
    },
    steps: {
      one: 'PASO 1',
      two: 'PASO 2',
      three: 'PASO 3',
      four: 'PASO 4',
    },
    appearance: {
      title: 'Apariencia',
      description: 'Personaliza el diseño de tu widget',
      incompleteWarning:
        'Necesitas ingresar el nombre, logo de tu empresa y color del botón',
    },
    modules: {
      title: 'Módulos',
      description:
        'Selecciona el tipo de widget que quieres ofrecer a tus clientes.',
      incompleteWarning: 'Necesitas seleccionar módulo Login o Login + Pagos',
    },
    providers: {
      title: 'Países, bancos y cuentas disponibles',
      description:
        'Configura los bancos y cuentas con los que necesitas que tu widget se conecte.',
      incompleteWarning: 'Necesitas seleccionar al menos una cuenta',
    },
    account: {
      title: 'Cuenta recaudadora',
      description: 'Selecciona la cuenta a donde llegará el dinero.',
      selected: 'Seleccionado: ',
      incompleteWarning: 'Necesitas ingresar una cuenta',
    },
    snippets: {
      sandbox: {
        title: 'Implementación',
        description: {
          firstPart: 'Te dejamos la ',
          docLink: ' documentación',
          lastPart: ' para que leas. A continuación tienes tu widget-id:',
        },
        widgetId: 'Widget-id:',
      },
      production: {
        title: 'Producción',
        description: {
          firstPart: 'Te dejamos la ',
          docLink: ' documentación',
          lastPart: ' para que leas.',
        },
        option: 'Puedes activar/desactivar tu widget aquí',
        destinationAccountWarning:
          'Debes completar el paso 3 para poder tener acceso a esta funcionalidad. Ingresa tu cuenta recaudadora.',
      },
    },
    state: {
      label: 'Estado de tu widget',
      sandbox: 'Sandbox',
      production: 'Producción',
    },
  },
  destinationAccountsPages: {
    header: {
      create: {
        title: 'Selecciona cuenta recaudadora',
        breadcrumb:
          'Selecciona las cuentas recaudadoras y los bancos de origen',
      },
      edit: {
        title: 'Edita tu cuenta recaudadora',
        breadcrumb: 'Editar cuenta recaudadora',
      },
    },
    forms: {
      title: 'Cuenta recaudadora',
      fields: {
        bankCode: {
          label: 'Nombre del banco',
          placeholder: 'Selecciona un banco',
          noOptions: 'Primero debes seleccionar el país del banco',
        },
        bankCountry: {
          label: 'País del banco',
          placeholder: 'Selecciona un país',
          noOptions: 'Hubo un error al cargar los países',
        },
        bankAccountOwner: {
          label: 'Nombre del titular de tu cuenta bancaria',
        },
        bankAccount: {
          label: 'Número de tu cuenta bancaria',
        },
        bankAccountBranch: {
          label: 'Sucursal de tu cuenta bancaria (opcional)',
        },
        destinationAccountType: {
          label: 'Tipo de cuenta de destino',
          placeholder: 'Selecciona un tipo de cuenta de destino',
          noOptions: 'Hubo un error al cargar los tipos de cuenta de destino',
        },
      },
      buttons: {
        submit: 'Guardar',
        cancel: 'Cancelar',
        goBack: 'Volver',
      },
      errors: {
        generic: 'Ha ocurrido un error actualizando su widget.',
      },
      successMessage: {
        create: 'La cuenta recaudadora se ha creado satisfactoriamente.',
        edit: 'La cuenta recaudadora se ha actualizado satisfactoriamente.',
      },
      warningMessage:
        'El país y el banco que selecciones no los podrás cambiar luego.',
    },
  },
  modulePage: {
    title: 'Elige el módulo de tu widget',
    description:
      'Existen dos tipos de widgets: uno para Login y otro para pagos. Selecciona la función (o las funciones) que tendrá tu widget.',
    breadcrumb: 'Módulos',
    loading: 'Actualizando configuración...',
    modules: {
      login: {
        title: 'Login',
        description:
          'Esta función permite que el usuario final inicie sesión en su cuenta bancaria.',
        details: [
          'Por medio de una interfaz sencilla, segura y amigable',
          'Mediante una conexión encriptada',
          'Las credenciales bancarias no son almacenadas por Prometeo ni compartidas con terceros.',
        ],
      },
      payments: {
        title: 'Pagos',
        description:
          'Esta función permite que el usuario final realice transferencias bancarias.',
        details: [
          'De forma automática, rápida y completamente segura',
          'Automatizando pagos entre instituciones financieras en LATAM',
          'Eliminando procesos manuales y retrasos',
        ],
        switch: {
          on: 'ON',
          off: 'OFF',
        },
      },
    },
    buttons: {
      submit: {
        label: 'Guardar',
      },
      cancel: {
        label: 'Cancelar',
      },
    },
    errors: {
      generic: 'Ha ocurrido un error actualizando su widget.',
    },
  },
  providersPage: {
    title: 'Selecciona el país, banco y cuentas',
    subtitle: 'Listado de países',
    description:
      'Elige el país, los bancos y las cuentas bancarias que disponibilizarás a tus clientes.',
    breadcrumb: 'Selección de País, Banco y Cuentas',
    loading: 'Actualizando configuración...',
    empty: 'No se han encontrado proveedores activos.',
    accountTypes: {
      pers: { title: 'Personas', label: 'Cuenta personal' },
      corp: { title: 'Empresas', label: 'Cuenta empresa' },
      smes: { title: 'Pymes', label: 'Cuenta PyME' },
    },
    misc: {
      bank: 'Banco',
    },
    buttons: {
      submit: {
        label: 'Guardar',
      },
      cancel: {
        label: 'Cancelar',
      },
    },
    errors: {
      generic: 'Ha ocurrido un error actualizando su widget.',
      listProviders: 'Ha ocurrido un error cargando los proveedores bancarios.',
    },
  },
  appearancePage: {
    title: 'Personaliza la apariencia de tu widget',
    description: 'Elige cómo quieres que se vea tu widget.',
    breadcrumb: 'Apariencia',
    sections: {
      branding: 'Branding',
      colors: 'Colores',
    },
    forms: {
      updateWidget: {
        loading: 'Actualizando configuración...',
        fields: {
          brandName: {
            label: 'Nombre de tu empresa',
            placeholder: 'Acme Inc.',
          },
          brandPrimaryColor: {
            label: 'Color del botón principal',
            placeholder: 'Seleccioná el color',
          },
          brandLogo: {
            label: 'Logo',
            caption: 'Formatos: PNG, JPG, WEBP / Peso máximo: 500kb',
            buttonText: 'Examinar',
            defaultText: 'Logotipo.png',
          },
          errors: {
            brandLogoUrl: {
              byType:
                'El formato no es correcto. Solo se permiten extensiones .jpg, .png, .webp',
              bySize: 'El tamaño no puede exceder los 500 kb',
              byDimension:
                'La altura no debe exceder los 28 px y el ancho no debe exceder los 130 px.',
            },
          },
        },
        buttons: {
          submit: {
            label: 'Guardar',
          },
          cancel: {
            label: 'Cancelar',
          },
        },
        errors: {
          generic: 'Ha ocurrido un error actualizando su widget.',
        },
        successMessage: 'El widget se ha actualizado satisfactoriamente.',
      },
    },
  },
}
