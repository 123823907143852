import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { usePaymentsLinkUseCases } from 'src/service-locator'
import {
  Card,
  Typography,
  Spacer,
} from '@prometeoapi/afrodita-react-component-lib'
import styles from './table-payments-link.module.scss'
import { TableComponent } from './table-component'
import { TableProps } from './table-payments-link.d'

export const PaymentBatchTable: FC<TableProps> = ({ columns }) => {
  const { t } = useTranslation(['payments', 'paymentsLink'])
  const { getPaymentsBatch } = usePaymentsLinkUseCases()

  return (
    <Card>
      <div className={styles['card-header']}>
        <Typography tag="h2" variant="strong-1">
          {t('paymentsLink:paymentLinkBatchPage.paymentLinkList.title')}
        </Typography>
      </div>
      <Spacer size={'4x'} />

      <TableComponent
        columns={columns}
        query={getPaymentsBatch}
        startDate={''}
        endDate={''}
        statusFilter={''}
        amountMin={''}
        amountMax={''}
        currency={''}
        payment={null}
        filterPage={''}
        // setSearchDownload={setSearchDownload}
      />
    </Card>
  )
}
