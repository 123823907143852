import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { useSafeDispatch } from 'src/utils'
import { LoginArgs, User } from 'src/domain'
import { useAuthUseCases } from 'src/service-locator'
import { AuthContext } from 'src/infrastructure'

export type AuthProviderProps = {
  children: ReactNode
}

const AuthProvider: FC<AuthProviderProps> = (props) => {
  const [user, unsafeSetUser] = useState<User>()
  const setUser = useSafeDispatch(unsafeSetUser)

  const {
    saveAccessToken,
    removeAccessToken,
    getUser,
    getUserFromTokens,
    getTokens,
    getTokensWithSesame,
    getRefreshToken,
    saveRefreshToken,
    removeRefreshToken,
  } = useAuthUseCases()

  const login = useCallback(
    async (args: LoginArgs) => {
      const tokens = await getTokens(args)
      saveAccessToken(tokens.accessToken)
      saveRefreshToken(tokens.refreshToken)
      return getUserFromTokens(tokens)
    },
    [getTokens, getUserFromTokens, saveAccessToken, saveRefreshToken]
  )

  const loginWithSesame = useCallback(
    async (sesame: string) => {
      const tokens = await getTokensWithSesame(sesame)
      saveAccessToken(tokens.accessToken)
      saveRefreshToken(tokens.refreshToken)
      return getUserFromTokens(tokens)
    },
    [getTokensWithSesame, getUserFromTokens, saveAccessToken, saveRefreshToken]
  )

  const logout = useCallback(() => {
    removeAccessToken()
    removeRefreshToken()
    setUser(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRefreshToken, removeAccessToken, removeRefreshToken, setUser])

  useEffect(() => {
    const existingUser = getUser()
    if (existingUser) {
      setUser(existingUser)
    } else {
      logout()
    }
  }, [getUser, logout, removeAccessToken, setUser])

  const value = useMemo(
    () => ({
      user,
      setUser,
      login,
      loginWithSesame,
      logout,
    }),
    [login, loginWithSesame, logout, setUser, user]
  )

  return <AuthContext.Provider value={value} {...props} />
}

export default AuthProvider
