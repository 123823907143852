import { Link } from 'react-router-dom'
import { Typography } from '@lib-components'

export function NotFoundPage() {
  return (
    <div id="notfound">
      <div className="notfound-bg"></div>
      <div className="notfound">
        <div className="notfound-404">
          <Typography tag="h1">404</Typography>
        </div>
        <Typography tag="h2">
          Oops, We are sorry, but the page you requested was not found
        </Typography>
        <Link to="/" className="btn home-btn">
          Go Home
        </Link>
      </div>
    </div>
  )
}
