import { useTranslation } from 'react-i18next'
import { useAuthWithRoles } from 'src/infrastructure'

import {
  PageHeader,
  PageHeaderSubtitle,
  PageHeaderTitle,
  useHomeQuery,
} from 'src/presentation'

type HomePageHeaderProps = {
  checkRoles: boolean
  allowedRoles: Array<any>
}

export const HomePageHeader = (props: HomePageHeaderProps) => {
  const { checkRoles, allowedRoles } = props
  const { t } = useTranslation(['home'])
  const { data } = useHomeQuery()
  const { hasPermission } = useAuthWithRoles(allowedRoles)

  if (!hasPermission && checkRoles) {
    return null
  }

  return (
    <>
      {data && (
        <PageHeader>
          <PageHeaderTitle>
            {t(`home:header.${data.user.stage}.title`)}
          </PageHeaderTitle>
          <PageHeaderSubtitle>
            {t(`home:header.${data.user.stage}.subtitle`)}
          </PageHeaderSubtitle>
        </PageHeader>
      )}
    </>
  )
}
