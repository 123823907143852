import classNames from 'classnames'
import { FC } from 'react'
import { getStyledElementProps } from '@lib-utils'

import { Icon } from '@lib-components'
import { SpinnerProps } from '@lib-types'

import styles from './spinner.module.scss'

export const Spinner: FC<SpinnerProps> = ({ size, color, ...props }) => {
  const styledProps = getStyledElementProps(props, classNames(styles['spin']))
  return <Icon name={'loop'} size={size} color={color} {...styledProps} />
}
