import { MarketplaceResponse } from 'src/services'
import {
  MarketplaceErrorListFields,
  MarketplacePaginatorResponse,
} from 'src/infrastructure'

export namespace WidgetsService {
  export type Widget = {
    id: number
    domains?: Domain[]
    destination_accounts?: WidgetDestinationAccount[]
    name: string
    description: string
    environment: string
    public_key: string
    allowed_feature_level: number
    brand_name?: string
    brand_logo?: string
    brand_primary_color?: string
    show_prometeo_logo: boolean
    is_active: boolean
    created_on: string
    modified_on: string
    client: number
    providers: Provider[]
    destination_account_rules?: DestinationAccountRule[]
  }

  export type WidgetDestinationAccount = Omit<DestinationAccount, 'widget'>

  export type Domain = {
    whitelisted_domains: string
    environment: string
  }

  export type Bank = {
    id: number
    name: string
    logo: string
    country: Country
    code: string
  }

  export type Provider = {
    id: number
    provider_code: string
    bank_code: string
    name: string
    logo: string
    account_type: string
    country: Country
  }

  export type Country = {
    code: string
    name: string
  }

  export type DestinationAccount = {
    id: number
    bank_account: string
    bank_account_branch: number
    bank_account_owner: string
    widget: number
    bank: Bank
  }

  export type DestinationAccountRule = {
    source_provider_code: string
    destination_bank_code: string
    destination_institution: string
  }

  export type GetWidgetResponse = Widget

  export type ListAllWidgetsResponse = Widget[]

  export type CreateWidgetPayload = Pick<Widget, 'name' | 'description'>

  export type CreateWidgetErrorResponseField = keyof CreateWidgetPayload

  export type CreateWidgetResponseErrors =
    MarketplaceErrorListFields<CreateWidgetErrorResponseField>

  export type CreateWidgetResponse = Widget

  export type UpdateWidgetPayload = Omit<
    Partial<Widget>,
    'providers',
    'brand_logo'
  > & {
    providers?: number[]
    brand_logo?: File
  }

  export type UpdateWidgetResponse = Widget

  export type DeleteWidgetResponse = MarketplaceResponse

  export type GetBankingProvidersResponse = Provider[]

  export type GetProvidersResponse = MarketplacePaginatorResponse<Provider>

  export type CreateDestinationAccountDTO = Omit<DestinationAccount, 'bank'> & {
    bank_code: string
    bank_destination_account_type: string | undefined
  }

  export type CreateDestinationAccountPayloadDTO = Omit<
    CreateDestinationAccountDTO,
    'id'
  >

  export type UpdateDestinationAccountPayloadDTO = Omit<
    DestinationAccount,
    'widget' | 'bank'
  >

  export type GetDestinationAccountDTO = Omit<DestinationAccount, 'id'> & {
    bank_destination_account_type: string | undefined
  }

  export type GetDestinationAccountResponse = GetDestinationAccountDTO

  export type CreateDestinationAccountPayload =
    CreateDestinationAccountPayloadDTO

  export type CreateDestinationAccountResponse = Omit<
    CreateDestinationAccountDTO,
    'widget'
  >

  export type CreateDestinationAccountErrorResponseField =
    keyof CreateDestinationAccountPayload

  export type CreateDestinationAccountResponseErrors =
    MarketplaceErrorListFields<CreateDestinationAccountErrorResponseField>

  export type UpdateDestinationAccountPayload =
    UpdateDestinationAccountPayloadDTO

  export type UpdateDestinationAccountResponse = Omit<DestinationAccount, 'id'>

  export type UpdateDestinationAccountErrorResponseField =
    keyof UpdateDestinationAccountPayload

  export type UpdateDestinationAccountResponseErrors =
    MarketplaceErrorListFields<UpdateDestinationAccountErrorResponseField>
}
