import { AxiosResponse } from 'axios'
import { useRef, useState } from 'react'

interface DownloadFileProps {
  readonly apiDefinition: () => Promise<AxiosResponse<Blob>>
  readonly preDownloading: () => void
  readonly postDownloading: () => void
  readonly onError: () => void
  readonly getFileName: () => string
}

interface DownloadedFileInfo {
  readonly download: () => Promise<void>
  readonly ref: React.MutableRefObject<HTMLAnchorElement | null>
  readonly name: string | undefined
  readonly url: string | undefined
}

export const useDownloadFile = ({
  apiDefinition,
  preDownloading,
  postDownloading,
  onError,
  getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
  const ref = useRef<HTMLAnchorElement | null>(null)
  const [url, setFileUrl] = useState<string>()
  const [name, setFileName] = useState<string>()

  const download = async () => {
    try {
      preDownloading()
      setFileName(getFileName())
      const { data } = await apiDefinition()
      const url = window.URL.createObjectURL(new Blob([data]))
      setFileUrl(url)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${getFileName()}`)
      document.body.appendChild(link)
      link.click()
      URL.revokeObjectURL(url)
      postDownloading()
    } catch (error) {
      onError()
    }
  }

  return { download, ref, url, name }
}
