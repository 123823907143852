import { FC, ReactNode } from 'react'
import { Typography, Spacer } from '@prometeoapi/afrodita-react-component-lib'
import { TypographyProps } from '@lib-types'

import { TypographyWithSpacer } from 'src/presentation'

import styles from './page-header.module.scss'

export const PageHeaderSubtitle: FC<TypographyProps> = ({
  children,
  variant = 'body-1',
  typographyColor = 'gray-4',
  ...typographyProps
}) => {
  return (
    <Typography
      variant={variant}
      typographyColor={typographyColor}
      {...typographyProps}
    >
      {children}
    </Typography>
  )
}

export const PageHeaderTitle: FC<TypographyWithSpacer> = ({
  children,
  variant = 'headline-1',
  spacerSize = '2x',
  ...typographyProps
}) => {
  return (
    <>
      <Typography variant={variant} {...typographyProps}>
        {children}
      </Typography>
      <Spacer size={spacerSize} />
    </>
  )
}

export type PageHeaderProps = {
  children: ReactNode
}

export const PageHeader: FC<PageHeaderProps> = ({ children }) => {
  return <div className={styles['page-header']}>{children}</div>
}

export const PageHeaderBreadCrumbs: FC<TypographyWithSpacer> = ({
  children,
  variant = 'body-1',
  typographyColor = 'gray-6',
  spacerSize = '2x',
  ...typographyProps
}) => {
  return (
    <>
      <Typography
        variant={variant}
        typographyColor={typographyColor}
        {...typographyProps}
      >
        {children}
      </Typography>
      <Spacer size={spacerSize} />
    </>
  )
}
