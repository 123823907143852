export default {
  hello: 'hola desde es:shared',
  nav: {
    summary: 'Resumen',
    usage: 'Historial',
    consumption: 'Consumo',
    documentation: 'Documentación',
    helpCenter: 'Centro de ayuda',
    settings: 'Configuración',
    logOut: 'Salir',
    widgets: 'Widgets',
    payments: 'Pagos',
    paymentsLink: 'Link de pagos',
    listPaymentLink: 'Listado de link de pagos',
    massiveLoad: 'Carga masiva de links',
    individualLoad: 'Carga individual de links',
    paymentsLinkBatch: 'Link de pagos Batch',
  },
  api: {
    errors: {
      tryAgain: 'Ha ocurrido un error, por favor intentalo de nuevo',
      '403': 'No estás autorizado para ver este contenido',
      '401': {
        label: 'Tu cuenta es inválida',
        description:
          'El correo electrónico o la contraseña son inválidas, prueba nuevamente o restablece tu contraseña',
      },
      '400': {
        label: 'Tu cuenta esta bloqueada',
        description:
          'Cuenta bloqueada: demasiados intentos de inicio de sesión. Póngase en contacto con soporte para desbloquear su cuenta.',
      },
      tokenDoesNotExist: 'El token fue usado, necesitas uno nuevo.',
      tokenIsNotValid: 'El token no es válido',
    },
  },
  form: {
    errors: {
      emailField: '{{name}} debe ser un correo válido',
      numberField: '{{name}} debe ser un número',
      requiredField: '{{name}} es requerido',
      maxLength: '{{name}} debe de ser de {{max}} caracteres o menos',
      minLength: '{{name}} debe de ser de {{min}} caracteres o más',
    },
    passwordMust: 'La contraseña debe',
    requirements: {
      upperLetterCheck: 'contener al menos 1 letra mayúscula',
      lowerLetterCheck: 'contener al menos 1 letra minúscula',
      numberCheck: 'contener al menos 1 número',
      specialCharCheck:
        'contener al menos 1 carácter especial, por ejemplo: !,@,#,$,^,&,*',
      pwdLengthCheck: 'tener al menos 8 caracteres de longitud',
    },
  },
}
