import { UseConsumptionUseCases } from 'src/domain'

type InitProps = {
  useConsumptionUseCases: UseConsumptionUseCases
}

export let useConsumptionUseCases: UseConsumptionUseCases

export const init = (props: InitProps) => {
  useConsumptionUseCases = props.useConsumptionUseCases
}
