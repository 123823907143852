import { NavLink } from 'react-router-dom'
import { BaseTemplateHeaderToggleMenuButtonProps } from 'src/presentation'

import logo from 'src/assets/images/logo-prometeo-black.svg'
import styles from './base-template-components.module.scss'
import { IconMoon } from '@prometeoapi/afrodita-react-component-lib'
import { useAuthWithRoles } from 'src/infrastructure'
import { ACTION_ROLES } from 'src/config'

export const BaseTemplateHeaderLogo = () => (
  <NavLink to={'/'} className={styles['header-logo']}>
    <img src={logo} alt="logo" />
  </NavLink>
)

type NavLinkProtectedProps = {
  to: string
  classN?: string
  checkRoles: boolean
  allowedRoles: Array<any>
}

export const NavLinkProtected = (props: NavLinkProtectedProps) => {
  const { to, checkRoles, allowedRoles, classN } = props
  const { hasPermission } = useAuthWithRoles(allowedRoles)
  if (!hasPermission && checkRoles) {
    return null
  }

  return (
    <NavLink to={to} className={classN}>
      <IconMoon name={'icon-profile'} size="md" />
    </NavLink>
  )
}

export const BaseTemplateHeaderIcon = () => (
  <div className={styles['container-icons']}>
    {/* <NavLink to={'/'} className={styles['header-icons']}>
      <IconMoon name={'icon-bell'} size="md" />
    </NavLink>
    <NavLink to={'/'} className={styles['header-icons']}>
      <IconMoon name={'icon-help'} size="md" />
    </NavLink> */}
    <NavLinkProtected
      checkRoles={true}
      allowedRoles={ACTION_ROLES['user_settings_edit_action']}
      to={'/settings'}
      classN={styles['header-icons']}
    />
  </div>
)

export const BaseTemplateHeaderToggleMenuButton = ({
  onClick,
}: BaseTemplateHeaderToggleMenuButtonProps) => {
  return (
    <button className={styles['toggle-nav-btn']} onClick={onClick}>
      <IconMoon name={'icon-menu'} size="sm" />
    </button>
  )
}
