import { UseRegisterAccountUseCases } from 'src/domain'

type InitProps = {
  useRegisterAccountUseCases: UseRegisterAccountUseCases
}

export let useRegisterAccountUseCases: UseRegisterAccountUseCases

export const init = (props: InitProps) => {
  useRegisterAccountUseCases = props.useRegisterAccountUseCases
}
