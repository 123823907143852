import { UseRegisterAccountUseCases } from '../definition'

type MakeRegisterAccountUseCasesProps = {
  useRegisterAccountUseCases: UseRegisterAccountUseCases
}

type MakeRegisterAccountUseCases = (
  props: MakeRegisterAccountUseCasesProps
) => UseRegisterAccountUseCases

export const makeRegisterAccountUseCases: MakeRegisterAccountUseCases =
  ({ useRegisterAccountUseCases }) =>
  () =>
    useRegisterAccountUseCases()
