import { useTranslation } from 'react-i18next'
import { Spacer, Button } from '@prometeoapi/afrodita-react-component-lib'
import {
  PageCard,
  PageCardTitle,
  PageCardDescription,
  PageBulletedTextInlineGroup,
  PageBulletedText,
  useHomeQuery,
  useHomeQueryDataHelpers,
} from 'src/presentation'
import * as settings from 'src/config/settings'

export const HomePageStageRequest = () => {
  const { t } = useTranslation(['home'])
  const { data } = useHomeQuery()
  const {
    getStageRequestTitle,
    getStageRequestDescription,
    getStateRequestBullets,
    shouldShowStageRequestCta,
    getStageRequestsCta,
    shouldShowStageRequestVerifyingMessage,
  } = useHomeQueryDataHelpers()
  return (
    <>
      {data && (
        <PageCard>
          <PageCardTitle>{getStageRequestTitle(data)}</PageCardTitle>

          <PageCardDescription>
            {getStageRequestDescription(data)}
          </PageCardDescription>

          <Spacer variant={'vertical'} size={'2x'} />

          <PageBulletedTextInlineGroup>
            {getStateRequestBullets(data).map(({ key, bulletText }) => {
              return <PageBulletedText key={key}>{bulletText}</PageBulletedText>
            })}
          </PageBulletedTextInlineGroup>

          {shouldShowStageRequestCta(data) && (
            <>
              <Spacer variant={'vertical'} size={'6x'} />
              <a href={`mailto:${settings.SALES_EMAIL}`}>
                <Button>{getStageRequestsCta(data)}</Button>
              </a>
            </>
          )}

          {shouldShowStageRequestVerifyingMessage(data) && (
            <>
              <Spacer variant={'vertical'} size={'6x'} />
              <Button disabled>
                {t('home:stageRequest.verifyingMessage')}
              </Button>
            </>
          )}
        </PageCard>
      )}
    </>
  )
}
