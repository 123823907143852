import { HOME_CONTEXT_QUERY_KEY } from 'src/config'
import { useMarketplaceQuery } from 'src/adapters'
import { Home } from 'src/domain'
import { useHomeUseCases } from 'src/service-locator'

export const useHomeQuery = () => {
  const { getHome } = useHomeUseCases()
  const { isLoading, isError, isSuccess, data } = useMarketplaceQuery<Home>({
    queryKey: HOME_CONTEXT_QUERY_KEY,
    queryFunction: getHome,
  })

  return {
    isError,
    isLoading,
    isSuccess,
    data,
  }
}
