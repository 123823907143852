import { ChangeEvent, FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import * as settings from 'src/config/settings'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import {
  IconMoon,
  Spacer,
  Switch,
  Typography,
  Anchor,
  Spinner,
} from '@prometeoapi/afrodita-react-component-lib'
import {
  WIDGET_CLOUDFRONT_URL,
  DOC_WIDGET_URL,
  GET_WIDGET_QUERY_KEY,
  ACTION_ROLES,
} from 'src/config'
import {
  Home,
  UpdateWidgetArgs,
  Widget,
  WidgetDestinationAccount,
  WidgetEnvs,
} from 'src/domain'
import { APIError } from 'src/adapters'
import {
  useHomeQuery,
  useHomeQueryDataHelpers,
  useWidgetQuery,
} from 'src/presentation'
import { useNonNullableParams } from 'src/utils'
import { useWidgetUseCases } from 'src/service-locator'

import styles from './widget-page.module.scss'
import { useAuthWithRoles } from 'src/infrastructure'

// TODO: refactorizar todo este componente por amor al creador
// Esto está funcionando solo por obra y gracia del espíritu santo
export const WidgetPage: FC = () => {
  const { hasPermission: hasEditApperance } = useAuthWithRoles(
    ACTION_ROLES['widget_edit_apperance_action']
  )
  const { hasPermission: hasEditCollectionAccount } = useAuthWithRoles(
    ACTION_ROLES['widget_edit_collection_account_action']
  )
  const { hasPermission: hasCreateCollectionAccount } = useAuthWithRoles(
    ACTION_ROLES['widget_create_collection_account_action']
  )
  const { hasPermission: hasEditProduction } = useAuthWithRoles(
    ACTION_ROLES['widget_edit_production_enable_action']
  )

  const navigate = useNavigate()
  const { t } = useTranslation(['widgets', 'shared'])
  const { data, isSuccess } = useHomeQuery()
  const { isInProduction: isProd } = useHomeQueryDataHelpers()
  const shouldRedirect =
    !settings.REACT_APP_WIDGET_SHOW_IN_MENU ||
    (isSuccess && !isProd(data as Home))
  const query = useWidgetQuery()
  const widgetUseCases = useWidgetUseCases()
  const queryClient = useQueryClient()
  const mutation = useMutation<Widget, APIError, UpdateWidgetArgs>(
    widgetUseCases.updateWidget,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([GET_WIDGET_QUERY_KEY, widgetId])
      },
    }
  )
  const { widgetId } = useNonNullableParams('widgetId')

  const isAppearanceCompleted = (widget?: Widget) =>
    widget?.brandName && widget?.brandLogo && widget?.brandPrimaryColor

  const hasAtLeastOneDestinationAccount = (widget?: Widget) => {
    if (widget?.destinationAccounts?.length)
      return !!widget?.destinationAccounts[0].bankAccount
    return false
  }

  const isInProduction = (widget?: Widget) => {
    return (
      hasAtLeastOneDestinationAccount(widget) &&
      widget?.environment === WidgetEnvs.PRODUCTION
    )
  }

  const getFirstDestinationAccount = (
    widget?: Widget
  ): WidgetDestinationAccount | undefined => {
    if (widget?.destinationAccounts?.length) {
      return widget.destinationAccounts[0]
    }
  }

  const getFirstDestinationAccountId = (
    widget?: Widget
  ): WidgetDestinationAccount['id'] | undefined => {
    const destinationAccount = getFirstDestinationAccount(widget)
    if (destinationAccount) {
      return destinationAccount.id
    }
  }

  const handleProductionToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const environment = e.target.checked
      ? WidgetEnvs.PRODUCTION
      : WidgetEnvs.SANDBOX
    mutation.mutate({ id: parseInt(widgetId), environment })
  }

  const getWidgetUrl = useCallback(() => {
    if (query.isSuccess) {
      const params: Record<string, string> = {
        isPreview: 'true',
        widgetKey: query.data?.publicKey,
      }

      if (query.data?.brandLogo) {
        params['logoUrl'] = query.data?.brandLogo
      }
      if (query.data?.brandName) {
        params['brandName'] = query.data?.brandName
      }
      if (query.data?.brandPrimaryColor) {
        params['primaryColor'] = query.data?.brandPrimaryColor
      }

      params['concept'] = 'Concept'

      const queryParams = new URLSearchParams([...Object.entries(params)])

      const iframeUrl = new URL(`${WIDGET_CLOUDFRONT_URL}?${queryParams}`)

      return iframeUrl.href
    }
  }, [query.isSuccess, query.data])

  if (query.isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Spinner size="xl" />
      </div>
    )
  }

  if (query.isError) {
    return (
      <Typography tag="p" variant="body-2" color="red-5">
        {t('widgets:configPage.error')}
      </Typography>
    )
  }

  if (shouldRedirect) {
    return <Navigate to="/" replace />
  }

  return (
    <>
      <div className={styles['container-page']}>
        <Typography tag="p" variant="body-1" typographyColor="gray-6">
          <a href="/widgets" style={{ color: '#2f3a44' }}>
            Widgets
          </a>
          {` > ${query.data?.name}`}
        </Typography>
        <Spacer size={'6x'} />
        <div className={styles['container-page-content']}>
          <div className={styles['container-page-left']}>
            <Typography tag="h3" variant="headline-3" typographyColor="gray-8">
              {`Widget: ${query.data?.name}`}
            </Typography>
            <Typography tag="p" variant="body-1" typographyColor="gray-6">
              {`${query.data?.description}`}
            </Typography>
            <Spacer size={'6x'} />

            {/* STEP 1 */}
            <Typography
              tag="p"
              variant="strong-2"
              typographyColor="gray-4"
              className={styles['step-typography']}
            >
              {t('widgets:configPage.steps.one')}{' '}
              {isAppearanceCompleted(query.data) && (
                <IconMoon
                  name={'icon-check-circle'}
                  color={'green-6'}
                  size={'lg'}
                />
              )}
            </Typography>
            <Spacer size={'3x'} />
            <div
              className={`${styles['card']} ${styles['section-container-h']}`}
            >
              <div className={styles['section-container-left']}>
                <Typography
                  tag="p"
                  variant="emphasized-1"
                  typographyColor="gray-8"
                >
                  {t('widgets:configPage.appearance.title')}
                </Typography>
                <Typography tag="p" variant="body-2" typographyColor="gray-6">
                  {t('widgets:configPage.appearance.description')}
                </Typography>
                {!isAppearanceCompleted(query.data) && (
                  <div>
                    <Spacer />
                    <Typography
                      tag="p"
                      variant="small-1"
                      typographyColor="yellow-7"
                    >
                      {t('widgets:configPage.appearance.incompleteWarning')}
                    </Typography>
                  </div>
                )}
              </div>
              {hasEditApperance && (
                <div className={styles['section-container-right']}>
                  <Typography
                    tag="p"
                    variant="body-2"
                    typographyColor="red-5"
                    className={styles['pointer']}
                    onClick={() =>
                      navigate('appearance', {
                        state: { widget: query.data },
                      })
                    }
                  >
                    {t('widgets:configPage.buttons.genericEdit.label')}
                  </Typography>
                </div>
              )}
            </div>
            <Spacer size={'8x'} />

            {/* STEP 2 */}
            <Typography tag="p" variant="strong-2" typographyColor="gray-4">
              {t('widgets:configPage.steps.two')}
            </Typography>
            <Spacer size={'3x'} />
            <div
              className={`${styles['card']} ${styles['section-container-v']}`}
            >
              <Typography
                tag="p"
                variant="emphasized-1"
                typographyColor="gray-8"
              >
                {t('widgets:configPage.snippets.sandbox.title')}
              </Typography>
              <Spacer />
              <Typography tag="p" variant="body-2" typographyColor="gray-6">
                {t('widgets:configPage.snippets.sandbox.description.firstPart')}
                <Anchor
                  typographyVariant={'body-2'}
                  href={DOC_WIDGET_URL}
                  target={'_blank'}
                >
                  {t('widgets:configPage.snippets.sandbox.description.docLink')}
                </Anchor>
                {t('widgets:configPage.snippets.sandbox.description.lastPart')}
              </Typography>
              <Spacer />
              <Typography tag="p" variant="body-2" typographyColor="gray-4">
                {t('widgets:configPage.snippets.sandbox.widgetId')}
              </Typography>
              <Spacer size={'2x'} />
              <div className={styles['snippet-container']}>
                <Typography tag="p" variant="body-2" typographyColor="gray-0">
                  {query.data?.publicKey}
                </Typography>
              </div>
            </div>
            <Spacer size={'8x'} />

            {/* STEP 3 */}
            <Typography
              tag="p"
              variant="strong-2"
              typographyColor="gray-4"
              className={styles['step-typography']}
            >
              {t('widgets:configPage.steps.three')}
              {hasAtLeastOneDestinationAccount(query.data) && (
                <IconMoon
                  name={'icon-check-circle'}
                  color={'green-6'}
                  size={'lg'}
                />
              )}
            </Typography>
            <Spacer size={'3x'} />
            <div
              className={`${styles['card']} ${styles['section-container-v']}`}
            >
              <div className={`${styles['section-container-h']}`}>
                <div className={styles['section-container-left']}>
                  <Typography
                    tag="p"
                    variant="emphasized-1"
                    typographyColor="gray-8"
                  >
                    {t('widgets:configPage.account.title')}
                  </Typography>

                  {!hasAtLeastOneDestinationAccount(query.data) && (
                    <>
                      <Typography
                        tag="p"
                        variant="body-2"
                        typographyColor="gray-6"
                      >
                        {t('widgets:configPage.account.description')}
                      </Typography>
                      <div>
                        <Spacer size={'2x'} />
                        <Typography
                          tag="p"
                          variant="small-1"
                          typographyColor="yellow-7"
                        >
                          {t('widgets:configPage.account.incompleteWarning')}
                        </Typography>
                      </div>
                    </>
                  )}

                  {hasAtLeastOneDestinationAccount(query.data) && (
                    <>
                      <Typography
                        tag="p"
                        variant="body-2"
                        typographyColor="gray-6"
                      >
                        {`${t('widgets:configPage.account.selected')} ${
                          getFirstDestinationAccount(query.data)?.bank.name
                        } *${getFirstDestinationAccount(
                          query.data
                        )?.bankAccount.slice(-4)}`}
                      </Typography>
                    </>
                  )}
                  {/* </div> */}
                  <div className={styles['section-container-right']}>
                    {!hasAtLeastOneDestinationAccount(query.data) &&
                      hasCreateCollectionAccount && (
                        <Link to={'destination-accounts/create'}>
                          <Typography
                            tag="p"
                            variant="body-2"
                            typographyColor="red-5"
                            className={styles['pointer']}
                          >
                            Crear
                          </Typography>
                        </Link>
                      )}

                    {hasAtLeastOneDestinationAccount(query.data) &&
                      hasEditCollectionAccount && (
                        <Link
                          to={`destination-accounts/${getFirstDestinationAccountId(
                            query.data
                          )}/edit`}
                        >
                          <Typography
                            tag="p"
                            variant="body-2"
                            typographyColor="red-5"
                            className={styles['pointer']}
                          >
                            {t('widgets:configPage.buttons.genericEdit.label')}
                          </Typography>
                        </Link>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <Spacer size={'8x'} />

            {/* STEP 4 */}
            <Typography
              tag="p"
              variant="strong-2"
              typographyColor="gray-4"
              className={styles['step-typography']}
            >
              {t('widgets:configPage.steps.four')}
              {isInProduction(query.data) && (
                <IconMoon
                  name={'icon-check-circle'}
                  color={'green-6'}
                  size={'lg'}
                />
              )}
            </Typography>
            <Spacer size={'3x'} />
            <div
              className={`${styles['card']} ${styles['section-container-v']}`}
            >
              <Typography
                tag="p"
                variant="emphasized-1"
                typographyColor="gray-8"
              >
                {t('widgets:configPage.snippets.production.title')}
              </Typography>
              <Spacer />
              <Typography tag="p" variant="body-2" typographyColor="gray-6">
                {t(
                  'widgets:configPage.snippets.production.description.firstPart'
                )}
                <Anchor
                  typographyVariant={'body-2'}
                  href={DOC_WIDGET_URL}
                  target={'_blank'}
                >
                  {t(
                    'widgets:configPage.snippets.production.description.docLink'
                  )}
                </Anchor>
                {t(
                  'widgets:configPage.snippets.production.description.lastPart'
                )}
              </Typography>
              {hasAtLeastOneDestinationAccount(query.data) ? (
                <div className={styles['production-toggle-container']}>
                  {hasEditProduction && (
                    <>
                      <Typography
                        tag="p"
                        variant="body-2"
                        typographyColor="gray-8"
                        className={styles['pointer']}
                      >
                        {t('widgets:configPage.snippets.production.option')}
                      </Typography>
                      <Switch
                        name="productionSwitch"
                        defaultChecked={isInProduction(query.data)}
                        onChange={handleProductionToggleChange}
                      />
                    </>
                  )}
                </div>
              ) : (
                <>
                  <Spacer size={'2x'} />
                  <Typography
                    tag="p"
                    variant="small-1"
                    typographyColor="yellow-7"
                  >
                    {t(
                      'widgets:configPage.snippets.production.destinationAccountWarning'
                    )}
                  </Typography>
                </>
              )}
            </div>

            <Spacer size={'8x'} />
          </div>

          <div className={styles['container-page-right']}>
            <div
              className={`${styles['card']} ${styles['status-outer-container']}`}
            >
              <Typography tag="p" variant="strong-2" typographyColor="gray-4">
                {t('widgets:configPage.state.label')}
              </Typography>
              <Spacer />
              <div className={styles['status-container']}>
                <div className={styles['text-icon-container']}>
                  {isInProduction(query.data) ? (
                    <IconMoon
                      name="icon-check-circle-outline"
                      style={{ color: '#12C75D' }}
                    />
                  ) : (
                    <IconMoon
                      name="icon-check-circle-outline"
                      color={'gray-4'}
                    />
                  )}
                  <Spacer variant={'horizontal'} size={'1x'} />
                  <Typography
                    tag="p"
                    variant="emphasized-1"
                    typographyColor="gray-5"
                  >
                    {t('widgets:configPage.state.production')}
                  </Typography>
                </div>
              </div>
            </div>
            <Spacer size={'8x'} />
            <div className={styles['preview-container']}>
              <Typography
                tag="h3"
                variant="headline-3"
                typographyColor="gray-8"
              >
                {t('widgets:configPage.preview')}
              </Typography>
              <Spacer size={'6x'} />
              <iframe
                width="340"
                height="568"
                style={{ border: 0 }}
                frameBorder="0px"
                loading="lazy"
                scrolling="no"
                allowFullScreen
                src={getWidgetUrl()}
                sandbox="allow-scripts allow-same-origin"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
