import { UseUserSettingsUseCases } from '../definition'

type MakeUserSettingUseCasesProps = {
  useUserSettingsUseCases: UseUserSettingsUseCases
}

type MakeUserSettingUseCases = (
  props: MakeUserSettingUseCasesProps
) => UseUserSettingsUseCases

export const makeUserSettingsUseCases: MakeUserSettingUseCases =
  ({ useUserSettingsUseCases }) =>
  () =>
    useUserSettingsUseCases()
