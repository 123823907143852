import { UseUserSettingsUseCases } from 'src/domain'

type InitProps = {
  useUserSettingsUseCases: UseUserSettingsUseCases
}

export let useUserSettingsUseCases: UseUserSettingsUseCases

export const init = (props: InitProps) => {
  useUserSettingsUseCases = props.useUserSettingsUseCases
}
