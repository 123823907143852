export default {
  createLink: {
    title: 'Generate the payments link',
    breadcumb: 'Payments Link',
    breadcumb1: 'New link',
    formTitle: 'Complete these fields to create a new payment link',
    form: {
      fields: {
        widget: {
          label: 'Widget',
          placeholder: 'The link is attached to',
          helpText: 'The link is linked to',
        },
        concept: {
          label: 'Concept',
          helpText: 'It will appear in the customers bank history',
          caption: 'Max. 20 characters',
        },
        amount: {
          label: 'Amount & Currency',
          labelError: 'Amount',
          caption: 'Only numbers and 2 decimal places maximum',
        },
        currency: {
          label: 'Currency',
        },
        external_id: {
          label: 'external ID',
          caption: 'Max. 50 characters',
        },
        dueDate: {
          label: 'Expiry Date',
        },
        email: {
          label: 'Email',
        },
      },
      button: 'Generate Link',
    },
    modal: {
      title: 'Generated payment link',
      description:
        'Corresponds to widget {{widget_account}}, with concept {{concept}}, amount {{amount}}$ and currency {{currency}}, external ID {{externalID}}, lasts until {{expired}}. ',
      label: 'Payment link',
      helpText: 'Link copied to clipboard',
      exit: 'Exit',
    },
    loadingMessage: 'Loading...',
    monthlyReport: {
      title: 'Payments month of {{name}}',
      sections: {
        qtyPaymentLabel: 'Total Transactions',
        amountPaymentLabel: 'Total Money',
        avgPaymentLabel: 'Average money per transaction',
        errorPaymentLabel: 'Transactions with error',
      },
    },
  },
  paymentLinkPage: {
    title: 'Payments Link',
    batchButton: 'New batch',
    linkButton: 'New link',
    paymentLinkList: {
      loading: 'Loading links...',
      error: 'An error has occurred loading your links.',
      empty: 'You have not received any payment yet.',
      title: ' ',
      filters: {
        status: 'STATUS',
        date: 'DATE',
        amount: 'AMOUNT',
        payment: 'PAYMENT',
        min: 'min.',
        max: 'max.',
        buttons: {
          confirmed: 'Active',
          error: 'Expired',
          rejected: 'Cancelled',
          payment: 'Payment',
          noPayment: 'No payment',
          delete: 'Clear filters',
        },
        search: 'Concept or External Id',
        cancelButton: 'CANCEL',
        reloadButton: 'RELOAD',
      },
      listToShow: 'List of',
      noResultFound: 'No results found',
      downloadLabel: 'DOWNLOAD TABLE',
      downloadError: 'There was a problem downloading the file',
      paginateInfo: 'Page {{current}} of {{total}}',
      tableHeaders: {
        createdAt: 'Date',
        collectionAccount: 'Collection Account',
        concept: 'Concept',
        externalId: 'External Id',
        paymentId: 'Payment ID',
        link: 'Link',
        payment: 'Payment',
        status: 'Status',
        currency: 'Currency',
        amount: 'Amount',
        email: 'Email',
      },
    },
  },
  createBatch: {
    title: 'Batch payment links',
    breadcumb: 'Massive link',
    breadcumb1: 'Upload file',
    formTitle: 'Complete these fields to create new payment links',
    form: {
      fields: {
        archivo: {
          label: 'Customer list',
          placeholder: 'File.csv',
          button: 'Browse',
          helpText: 'Prometeo only accepts files in JSON and CSV format. ',
          helpText1: 'How to convert file to JSON/CSV?',
          helpText2:
            'The file should have the following columns: product_id, amount ,currency, concept external_id, email, expires_at. ',
          helpText3: 'See column format.',
          download: 'Download template',
        },
      },
      button: 'Generate File',
    },
    modal: {
      title: 'Loading file',
      title1: 'Uploaded File',
      description: ' Lasts 24h based on file {{filename}}',
      label: 'File',
      exit: 'Confirm',
    },
    loadingMessage: 'Loading...',
    errorModal: {
      title: 'File not generated',
      description:
        'Check the corresponding errors to be able to generate the file correctly',
      helpText:
        'The checked values contain an error and we were unable to generate the file',
      invalidFile:
        'Please review the file. Cannot load this file. The format is not supported.',
      cancel: 'Close',
    },
    helpModal: {
      title: 'Conversion of files',
      csvTitle: 'To convert to CSV format:',
      csvStep1: 'Open the file in your spreadsheet program.',
      csvStep2: 'Make sure the data is organized in columns and rows.',
      csvStep3: 'Save the file in CSV (Comma Separated Values) format.',
      csvStep4:
        'If the program prompts you for configuration options when saving, choose the default options or those that best suit your data.',
      jsonTitle: 'To convert to JSON format:',
      jsonStep1: 'Open the file in a text editor.',
      jsonStep2:
        'Make sure that the data is structured in object or JSON array format.',
      jsonStep3: 'Save the file with .json extension.',
    },
    colFormatModal: {
      title: 'File Column Format',
      helpText: '(Please respect the column titles)',
      titleProduct: 'product_id: ',
      descriptionProduct:
        'Is the ID of your widget that the link will link to. If you do not know the ID of your widget, you can request it from our support team through soporte@prometeoapi.com.',
      titleAmount: 'amount: ',
      descriptionAmount:
        'Only supports numeric format with a maximum of 2 decimal places.',
      titleCurrency: 'currency: ',
      descriptionCurrency:
        'For Uruguayan pesos, complete with “UYU”; for soles, complete with “PEN”; for dollars, complete with “USD”.',
      titleConcept: 'concept: ',
      descriptionConcept:
        ' Alphanumeric field up to 20 characters. This field will appear in your client`s Home Banking indicating the concept of the transfer.',
      titleExternalId: 'external_id: ',
      descriptionExternalID:
        'Alphanumeric field with a maximum of 50 characters. You can use this field to internally identify your links (for example, invoice number, customer number, etc.)',
      titleEmail: 'email: ',
      descriptionEmail: 'Email address of the customer you want to charge.',
      titleExpireAt: 'expires_at: ',
      descriptionExpireAt:
        'Is the date the link will expire. Be sure to follow the format YYYY-MM-DD.',
    },
  },
  paymentLinkBatchPage: {
    title: 'Batch payment link',
    batchButton: 'Load file',
    paymentLinkList: {
      loading: 'Loading links...',
      error: 'An error occurred loading your links.',
      empty: 'You have not received any payment yet.',
      title: ' ',
      listToShow: 'List of',
      noResultFound: 'No results found',
      downloadLabel: 'DOWNLOAD TABLE',
      downloadError: 'There was a problem downloading the file',
      paginateInfo: 'Page {{current}} of {{total}}',
      tableHeaders: {
        id: 'ID',
        createdAt: 'Date',
        status: 'Status',
        link: 'Download',
        expired_at: 'Date Expired',
      },
    },
  },
}
