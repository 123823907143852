import { FC, ReactNode, useState } from 'react'
import { Anchor, Icon } from '@lib-components'
import { AnchorProps } from '@lib-types'
import { useTranslation } from 'react-i18next'

import * as settings from 'src/config/settings'
import { Button, Typography } from '@prometeoapi/afrodita-react-component-lib'
import { MainNavigationBoxesExtras } from './prometeo-web-nav.styled'
import styles from './prometeo-web-nav.module.scss'
import { Product } from './nav-bar-menu-card/product'
import { NavbarNavigationItem } from './nav-bar-navigation-item/nav-bar-navigation-item'
import { About } from './nav-bar-menu-card/about'
import { Resources } from './nav-bar-menu-card/resources'
import { UseCases } from './nav-bar-menu-card/use-cases'
import {
  DropdownNav,
  DropdownNavItemLink,
  DropdownNavLiContainer,
} from '../dropdown-nav'

export type WebMenuProps = {
  children: ReactNode
}

export const WebMenu: FC<WebMenuProps> = ({ children }) => {
  const { t, i18n } = useTranslation(['auth'])

  const renderElement: any = {
    en: (
      <div className={styles['wrapper-lang']}>
        <Button
          fullWidth
          size="lg"
          variant="primary-dark"
          onClick={() => i18n.changeLanguage('es')}
        >
          <Typography variant="body-1">{t('auth:langEs')}</Typography>
        </Button>
      </div>
    ),
    es: (
      <div className={styles['wrapper-lang']}>
        <Button
          fullWidth
          className=""
          size="lg"
          variant="primary-dark"
          onClick={() => i18n.changeLanguage('en')}
        >
          <Typography variant="body-1">{t('auth:langEn')}</Typography>
        </Button>
      </div>
    ),
  }

  return (
    <div className={styles['web-menu']}>
      <div className={styles['navbar-start']}>{children}</div>
      <div className={styles['navbar-end']}>
        <Anchor
          href={'/login'}
          underlineOnHover={false}
          typographyProps={{ className: styles['nav-item'] }}
        >
          <Typography variant="small-1" typographyColor={'black'}>
            {t('auth:logIn')}
          </Typography>
        </Anchor>

        <Anchor
          href={'/register-account'}
          underlineOnHover={false}
          typographyProps={{ className: styles['nav-item'] }}
        >
          <Button variant={'secondary-light'} style={{ borderStyle: 'solid' }}>
            <Typography variant="small-1" typographyColor={'black'}>
              {t('auth:nav.start')}
            </Typography>
          </Button>
        </Anchor>
      </div>
      <div className={styles['navbar-language']}>
        <NavbarNavigationItem
          iconName={'icon-language'}
          iconSize={'xl'}
          isRed={false}
          dropdownContent={
            <MainNavigationBoxesExtras>
              <div>{renderElement[i18n.language == 'en' ? 'en' : 'es']}</div>
            </MainNavigationBoxesExtras>
          }
        />
      </div>
    </div>
  )
}

export type WebNavItemLinkProps = {
  to: AnchorProps['href']
  children: ReactNode
}

export const WebNavItemLink: FC<WebNavItemLinkProps> = ({ to, children }) => {
  return (
    <Anchor
      href={to}
      underlineOnHover={false}
      typographyProps={{ className: styles['nav-item'] }}
    >
      {children}
    </Anchor>
  )
}

export const MobileNav = () => {
  const { t, i18n } = useTranslation(['auth'])
  const locale = i18n.language && i18n.language == 'en' ? 'es' : 'es'

  const renderElementMobile: any = {
    en: (
      <div>
        <Button
          size="sm"
          variant="primary-dark"
          onClick={() => i18n.changeLanguage('es')}
        >
          <Typography variant="body-1">{t('auth:langEs')}</Typography>
        </Button>
      </div>
    ),
    es: (
      <div>
        <Button
          className=""
          size="sm"
          variant="primary-dark"
          onClick={() => i18n.changeLanguage('en')}
        >
          <Typography variant="body-1">{t('auth:langEn')}</Typography>
        </Button>
      </div>
    ),
  }

  const [showResources, setShowResources] = useState(false)
  const handleResourcesClick = () => {
    setShowResources(!showResources)
  }

  const [showUseCases, setShowUseCases] = useState(false)
  const handleUseCasesClick = () => {
    setShowUseCases(!showUseCases)
  }

  const [showProduct, setShowProduct] = useState(false)
  const handleProductClick = () => {
    setShowProduct(!showProduct)
  }

  const [showAboutUs, setShowAboutUs] = useState(false)
  const handleAboutUsClick = () => {
    setShowAboutUs(!showAboutUs)
  }
  return (
    <nav className={styles['nav']}>
      <ul>
        <DropdownNavLiContainer>
          <span className={styles['nav-item']} onClick={handleProductClick}>
            {t('auth:nav.products')} <Icon name={'keyboard_arrow_down'} />
          </span>
          <DropdownNav show={showProduct}>
            <ul>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['paymentApi']}
                >
                  {t('auth:nav.paymentApi')}
                </DropdownNavItemLink>
              </li>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['paymentsQR']}
                >
                  {t('auth:nav.paymentsQR')}
                </DropdownNavItemLink>
              </li>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['paymentslink']}
                >
                  {t('auth:nav.paymentslink')}
                </DropdownNavItemLink>
              </li>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['validationApi']}
                >
                  {t('auth:nav.validationApi')}
                </DropdownNavItemLink>
              </li>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['cashManagement']}
                >
                  {t('auth:nav.dataApi')}
                </DropdownNavItemLink>
              </li>
            </ul>
          </DropdownNav>
        </DropdownNavLiContainer>
        <DropdownNavLiContainer>
          <span className={styles['nav-item']} onClick={handleUseCasesClick}>
            {t('auth:nav.useCases')} <Icon name={'keyboard_arrow_down'} />
          </span>
          <DropdownNav show={showUseCases}>
            <ul>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['banks']}
                >
                  {t('auth:nav.bank')}
                </DropdownNavItemLink>
              </li>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['credits']}
                >
                  {t('auth:nav.credits')}
                </DropdownNavItemLink>
              </li>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['ecommerce']}
                >
                  {t('auth:nav.ecommerce')}
                </DropdownNavItemLink>
              </li>
              <li>
                <DropdownNavItemLink to={settings.WEB_BI_ACCESS_URL}>
                  {t('auth:nav.bankingInformationAccess')}
                </DropdownNavItemLink>
              </li>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['paymentGateway']}
                >
                  {t('auth:nav.paymentGateway')}
                </DropdownNavItemLink>
              </li>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['services']}
                >
                  {t('auth:nav.services')}
                </DropdownNavItemLink>
              </li>
            </ul>
          </DropdownNav>
        </DropdownNavLiContainer>
        <DropdownNavLiContainer>
          <span className={styles['nav-item']} onClick={handleResourcesClick}>
            {t('auth:nav.resources')} <Icon name={'keyboard_arrow_down'} />
          </span>
          <DropdownNav show={showResources}>
            <ul>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['partnersProgram']}
                >
                  {t('auth:nav.partnersProgram')}
                </DropdownNavItemLink>
              </li>
              <li>
                <DropdownNavItemLink to={settings.DOC_URL}>
                  {t('auth:nav.documentation')}
                </DropdownNavItemLink>
              </li>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['security']}
                >
                  {t('auth:nav.security')}
                </DropdownNavItemLink>
              </li>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['helpCenter']}
                >
                  {t('auth:nav.helpCenter')}
                </DropdownNavItemLink>
              </li>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['glossary']}
                >
                  {t('auth:nav.glossary')}
                </DropdownNavItemLink>
              </li>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['reports']}
                >
                  {t('auth:nav.reports')}
                </DropdownNavItemLink>
              </li>
            </ul>
          </DropdownNav>
        </DropdownNavLiContainer>
        <DropdownNavLiContainer>
          <span className={styles['nav-item']} onClick={handleAboutUsClick}>
            {t('auth:nav.aboutPrometeo')} <Icon name={'keyboard_arrow_down'} />
          </span>
          <DropdownNav show={showAboutUs}>
            <ul>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['aboutUs']}
                >
                  {t('auth:nav.aboutUs')}
                </DropdownNavItemLink>
              </li>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['workWithUs']}
                >
                  {t('auth:nav.workWithUs')}
                </DropdownNavItemLink>
              </li>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['securityPolicy']}
                >
                  {t('auth:nav.securityPolicy')}
                </DropdownNavItemLink>
              </li>
              <li>
                <DropdownNavItemLink
                  to={settings.navbarTranslation[locale]['privacyPolicy']}
                >
                  {t('auth:nav.privacyPolicy')}
                </DropdownNavItemLink>
              </li>
            </ul>
          </DropdownNav>
        </DropdownNavLiContainer>
        <DropdownNavLiContainer>
          <li>
            <Anchor
              href={settings.navbarTranslation[locale]['blog']}
              underlineOnHover={false}
              typographyProps={{ className: styles['nav-item'] }}
              target="_blank"
            >
              {t('auth:nav.blog')}
            </Anchor>
          </li>
        </DropdownNavLiContainer>
        <li>
          <Anchor
            href={'/login'}
            underlineOnHover={false}
            typographyProps={{ className: styles['nav-item'] }}
          >
            <Typography variant="body-2" typographyColor={'black'}>
              {t('auth:logIn')}
            </Typography>
          </Anchor>
        </li>
        <li>
          <Anchor
            href={'/register-account'}
            underlineOnHover={false}
            typographyProps={{ className: styles['nav-item'] }}
          >
            <Button
              variant={'secondary-light'}
              style={{ borderStyle: 'solid' }}
            >
              <Typography variant="body-2" typographyColor={'black'}>
                {t('auth:nav.start')}
              </Typography>
            </Button>
          </Anchor>
        </li>
        <li>{renderElementMobile[i18n.language == 'en' ? 'en' : 'es']}</li>
      </ul>
    </nav>
  )
}

export const WebNav = () => {
  const { t, i18n } = useTranslation(['auth'])
  const locale = i18n.language && i18n.language == 'en' ? 'en' : 'es'

  return (
    <>
      <NavbarNavigationItem
        label={t('auth:nav.products')}
        isRed={false}
        dropdownContent={
          <MainNavigationBoxesExtras>
            <Product />
          </MainNavigationBoxesExtras>
        }
      />
      <NavbarNavigationItem
        label={t('auth:nav.useCases')}
        isRed={false}
        dropdownContent={
          <MainNavigationBoxesExtras>
            <UseCases />
          </MainNavigationBoxesExtras>
        }
      />
      <NavbarNavigationItem
        label={t('auth:nav.resources')}
        isRed={false}
        dropdownContent={
          <MainNavigationBoxesExtras>
            <Resources />
          </MainNavigationBoxesExtras>
        }
      />
      <NavbarNavigationItem
        label={t('auth:nav.aboutUs')}
        isRed={false}
        dropdownContent={
          <MainNavigationBoxesExtras>
            <About />
          </MainNavigationBoxesExtras>
        }
      />
      <NavbarNavigationItem
        label={t('auth:nav.blog')}
        isRed={false}
        wrapperClassName="custom-link"
        hasUrl
        url={settings.navbarTranslation[locale]['blog']}
      />
    </>
  )
}
