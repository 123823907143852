import { FC } from 'react'
import { Spacer, Spinner } from '@prometeoapi/afrodita-react-component-lib'
import {
  HomePageHeader,
  HomePageStageInfo,
  useHomeQuery,
  Alert,
} from 'src/presentation'
import { ACTION_ROLES } from 'src/config'

export const HomePage: FC = () => {
  const { isLoading, isError, isSuccess } = useHomeQuery()

  // TODO: loading page component
  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Spinner style={{ color: '#677782' }} size="xl" />
      </div>
    )
  }

  // TODO: error page component
  if (isError) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <div style={{ color: '#E6210A' }}>Error</div>
      </div>
    )
  }

  return (
    <>
      {isSuccess && (
        <div>
          <Alert />

          <Spacer size={'6x'} />

          <HomePageHeader
            checkRoles={true}
            allowedRoles={ACTION_ROLES['summary_view_welcome_action']}
          />

          <Spacer size={'10x'} />

          <HomePageStageInfo
            checkRoles={true}
            allowedRoles={ACTION_ROLES['summary_view_stage_info_action']}
          />
        </div>
      )}
    </>
  )
}
