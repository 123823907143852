import * as settings from 'src/config/settings'

export const getSesameMagicLink = (url: string, sesame_token: string) => {
  if (url.includes('?')) {
    url += '&'
  } else {
    url += '?'
  }
  return `${url}${settings.SESAME_QUERY_PARAM}=${sesame_token}`
}

export function removeEmptyErrors<TErrors>(
  errors: Record<string, string[]>
): TErrors {
  const entries = Object.entries(errors)
  const entriesFiltered = entries.filter((fieldErrors) => fieldErrors[1])
  return Object.fromEntries(entriesFiltered) as TErrors
}

export const scrollToTop = () =>
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
