import { Anchor } from '@lib-components'
import { useTranslation } from 'react-i18next'

import { Typography } from '@prometeoapi/afrodita-react-component-lib'
import * as settings from 'src/config/settings'
import styles from './product.module.scss'
import { IconSprite } from '../../../icon-sprite'

export const Product = () => {
  const { t, i18n } = useTranslation(['auth'])
  const locale = i18n.language && i18n.language == 'en' ? 'en' : 'es'

  return (
    <div className={styles['wrapper']}>
      <div className={styles['content-left']}>
        <Typography variant={'strong-1'} typographyColor={'gray-5'}>
          {t(`auth:nav.product.ourProducts`)}
        </Typography>
        <Typography variant="small-1" typographyColor="gray-5">
          {t(`auth:nav.product.ourProductsDescription`)}
        </Typography>
      </div>
      <div className={styles['content-right']}>
        <Anchor
          href={settings.navbarTranslation[locale]['paymentApi']}
          underlineOnHover={false}
        >
          <IconSprite className="web-pay" title={t(`auth:nav.paymentApi`)} />
        </Anchor>
        <Anchor
          href={settings.navbarTranslation[locale]['paymentsQR']}
          underlineOnHover={false}
        >
          <IconSprite className="qr-pay" title={t('auth:nav.paymentsQR')} />
        </Anchor>
        <Anchor
          href={settings.navbarTranslation[locale]['paymentslink']}
          underlineOnHover={false}
        >
          <IconSprite className="link-pay" title={t('auth:nav.paymentslink')} />
        </Anchor>
        <Anchor
          href={settings.navbarTranslation[locale]['validationApi']}
          underlineOnHover={false}
        >
          <IconSprite
            className="validation-account"
            title={t('auth:nav.validationApi')}
          />
        </Anchor>
        <Anchor
          href={settings.navbarTranslation[locale]['cashManagement']}
          underlineOnHover={false}
        >
          <IconSprite className="data-api" title={t('auth:nav.dataApi')} />
        </Anchor>
      </div>
    </div>
  )
}
