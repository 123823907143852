import { useHomeService } from 'src/infrastructure'
import { mapToHome, UseHomeServiceAdapter } from 'src/adapters'

export const useHomeServiceAdapter: UseHomeServiceAdapter = () => {
  const homeService = useHomeService()
  const getHome = async () => {
    const homeResponse = await homeService.getHome()
    return mapToHome(homeResponse.data)
  }
  return { getHome }
}
