import { UseHomeUseCases } from 'src/domain'

type InitProps = {
  useHomeUseCases: UseHomeUseCases
}

export let useHomeUseCases: UseHomeUseCases

export const init = (props: InitProps) => {
  useHomeUseCases = props.useHomeUseCases
}
