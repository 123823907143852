import { useMarketplaceClient } from 'src/adapters'
import {
  RegisterAccountConfirmPayload,
  RegisterAccountConfirmResponse,
} from 'src/infrastructure'

export const useAccountConfirmService = () => {
  const { privateClient } = useMarketplaceClient()

  return {
    accountConfirm: (payload: RegisterAccountConfirmPayload) => {
      return privateClient.post<RegisterAccountConfirmResponse>(
        'api/dashboard/register/account/confirm',
        payload
      )
    },
  }
}
