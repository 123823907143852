import { GetDestinationAccountReturns } from 'src/domain'
import { WidgetsService } from 'src/infrastructure'
import { DestinationAccountFormFields } from 'src/presentation'

export const getProvidersWithDistinctBank = (
  providers: WidgetsService.Provider[]
): WidgetsService.Provider[] =>
  providers.filter(
    (provider, index, self) =>
      index ===
      self.findIndex((_provider) => _provider.bank_code === provider.bank_code)
  )

export const getBankAccountBranch = (
  bankAccountBranchInputValue: string
): number => {
  const bankAccountBranchInt = parseInt(bankAccountBranchInputValue)
  return isNaN(bankAccountBranchInt) ? 0 : bankAccountBranchInt
}

export const getFormikInitialValues = (
  emptyData: DestinationAccountFormFields,
  data?: GetDestinationAccountReturns
) => {
  if (data) {
    return {
      bankAccount: data.bankAccount,
      bankAccountOwner: data.bankAccountOwner,
      bankAccountBranch:
        data.bankAccountBranch !== 0 ? data.bankAccountBranch.toString() : '',
    }
  }
  return emptyData
}
