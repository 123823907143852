import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { resources, defaultNS } from './resources'

const options = {
  // order and from where user language should be detected
  order: [
    'sessionStorage',
    'localStorage',
    'navigator',
    'htmlTag',
    'querystring',
    'cookie',
    'path',
    'subdomain',
  ],
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    fallbackLng: 'es',
    debug: process.env.NODE_ENV !== 'production',
    ns: Object.keys(resources.es),
    defaultNS,
    resources,
  })

export default i18n
