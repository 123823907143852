import { useTranslation } from 'react-i18next'
import {
  Accordion,
  AccordionBody,
  Alert,
  AlertBody,
  Anchor,
  Button,
  Card,
  Spacer,
  Tab,
  TabBody,
  TabBodyList,
  TabList,
  TabsProvider,
  Typography,
} from '@prometeoapi/afrodita-react-component-lib'

import { useLegacyDashboardLink } from 'src/utils'
import { useHomeQuery, useHomeQueryDataHelpers } from 'src/presentation'
import * as settings from 'src/config/settings'

const Step1Tab = () => {
  const { t } = useTranslation(['home'])

  return (
    <Tab step={1} showIcon iconName="icon-check-circle">
      {t('home:quickstart.steps.1.tab')}
    </Tab>
  )
}

const Step2Tab = () => {
  const { t } = useTranslation(['home'])
  const { data } = useHomeQuery()
  const { shouldShowIcon } = useHomeQueryDataHelpers()

  return (
    <>
      {data && (
        <Tab
          step={2}
          showIcon={shouldShowIcon(data)(2)}
          iconName="icon-check-circle"
        >
          {t('home:quickstart.steps.2.tab')}
        </Tab>
      )}
    </>
  )
}

const Step3Tab = () => {
  const { t } = useTranslation(['home'])
  const { data } = useHomeQuery()
  const { shouldShowIcon, getIconName } = useHomeQueryDataHelpers()

  return (
    <>
      {data && (
        <Tab
          step={3}
          showIcon={shouldShowIcon(data)(3)}
          iconName={getIconName(data)(3)}
        >
          {t('home:quickstart.steps.3.tab')}
        </Tab>
      )}
    </>
  )
}

const Step4Tab = () => {
  const { t } = useTranslation(['home'])
  const { data } = useHomeQuery()
  const { shouldShowIcon } = useHomeQueryDataHelpers()

  return (
    <>
      {data && (
        <Tab step={4} showIcon={shouldShowIcon(data)(4)}>
          {t('home:quickstart.steps.4.tab')}
        </Tab>
      )}
    </>
  )
}

const Step1TabBody = () => {
  const { t } = useTranslation(['home'])

  return (
    <TabBody step={1}>
      <Typography typographyColor="gray-6" variant="body-1">
        {t('home:quickstart.steps.1.body.p')}
      </Typography>

      <Spacer variant={'vertical'} size={'2x'} />

      <p>
        <Anchor href={settings.DOC_URL} target={'_blank'}>
          {t('home:quickstart.steps.1.body.cta1')}
        </Anchor>
      </p>

      <p>
        <Anchor href={settings.DEMO_URL} target={'_blank'}>
          {t('home:quickstart.steps.1.body.cta2')}
        </Anchor>
      </p>
    </TabBody>
  )
}

const Step2TabBody = () => {
  const { t } = useTranslation(['home'])
  const { data } = useHomeQuery()

  return (
    <>
      {data && (
        <TabBody step={2}>
          <Typography variant={'body-1'} typographyColor={'gray-6'}>
            {t('home:quickstart.steps.2.body.p')}
          </Typography>

          <Spacer variant={'vertical'} size={'4x'} />

          <Typography variant={'body-2'} typographyColor={'gray-4'}>
            {`${data.requestsData.totalRequestsSandbox} ${t(
              'home:quickstart.steps.2.body.requests'
            )}`}
          </Typography>
        </TabBody>
      )}
    </>
  )
}

const Step3TabBody = () => {
  const { t } = useTranslation(['home'])
  const { data } = useHomeQuery()
  const {
    shouldShowAlert,
    isInSandbox,
    shouldShowStageRequestCta,
    getStageRequestsCta,
  } = useHomeQueryDataHelpers()

  return (
    <>
      {data && (
        <TabBody step={3}>
          {shouldShowAlert(data)(3) && (
            <>
              <Alert variant={'info'}>
                <AlertBody>{t('home:quickstart.steps.3.body.alert')}</AlertBody>
              </Alert>

              <Spacer variant={'vertical'} size={'4x'} />
            </>
          )}
          <Typography variant={'body-1'} typographyColor={'gray-6'}>
            {t('home:quickstart.steps.3.body.p')}
          </Typography>

          {isInSandbox(data) && (
            <>
              <Spacer variant={'vertical'} size={'4x'} />

              {shouldShowStageRequestCta(data) && (
                <>
                  <Spacer variant={'vertical'} size={'6x'} />
                  <a href={`mailto:${settings.SALES_EMAIL}`}>
                    <Button>{getStageRequestsCta(data)}</Button>
                  </a>
                </>
              )}
            </>
          )}
        </TabBody>
      )}
    </>
  )
}

const Step4TabBody = () => {
  const { t } = useTranslation(['home'])
  const { data } = useHomeQuery()
  const { isInPreproduction } = useHomeQueryDataHelpers()
  const { getMarketplaceLegacyDashboardUrl } = useLegacyDashboardLink()

  return (
    <>
      {data && (
        <TabBody step={4}>
          <Typography variant={'body-1'} typographyColor={'gray-6'}>
            {t('home:quickstart.steps.4.body.p')}
          </Typography>
          {isInPreproduction(data) && (
            <>
              <Spacer variant={'vertical'} size={'4x'} />
              <Anchor
                href={getMarketplaceLegacyDashboardUrl(
                  settings.REQUEST_PRODUCTION_FORM_URL
                )}
              >
                {t('home:quickstart.steps.4.body.cta')}
              </Anchor>
            </>
          )}
        </TabBody>
      )}
    </>
  )
}

export const HomePageQuickstart = () => {
  const { t } = useTranslation(['home'])
  return (
    <Card>
      <Accordion
        title={t('home:quickstart.title')}
        subtitle={t('home:quickstart.subtitle')}
        titleVariant={'featured-3'}
        subtitleColor={'gray-6'}
        headerWithBorder={false}
        expanded={true}
      >
        <AccordionBody>
          <TabsProvider>
            <TabList>
              <Step1Tab />
              <Step2Tab />
              <Step3Tab />
              <Step4Tab />
            </TabList>

            <TabBodyList>
              <Step1TabBody />
              <Step2TabBody />
              <Step3TabBody />
              <Step4TabBody />
            </TabBodyList>
          </TabsProvider>
        </AccordionBody>
      </Accordion>
    </Card>
  )
}
