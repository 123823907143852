import React from 'react'
import styles from './button-csv.module.scss'
import { Spinner } from '@lib-components'
import { ButtonCsvProps, ButtonState } from './button-csv.d'

export const ButtonCsv = ({ buttonState, onClick, label }: ButtonCsvProps) => {
  const isLoading = buttonState === ButtonState.Loading
  return (
    <button className={styles['wrapper']} type="button" onClick={onClick}>
      {isLoading && <Spinner />}
      {!isLoading && label}
    </button>
  )
}
