import { UseResetPasswordUseCases } from 'src/domain'

type InitProps = {
  useResetPasswordUseCases: UseResetPasswordUseCases
}

export let useResetPasswordUseCases: UseResetPasswordUseCases

export const init = (props: InitProps) => {
  useResetPasswordUseCases = props.useResetPasswordUseCases
}
