import { HomeResponse } from 'src/infrastructure'
import { useMarketplaceClient } from 'src/adapters'

export const useHomeService = () => {
  const { privateClient } = useMarketplaceClient()

  return {
    getHome: () => privateClient.get<HomeResponse>('api/dashboard/home'),
  }
}
