import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  Card,
  InputLabel,
  Typography,
} from '@prometeoapi/afrodita-react-component-lib'
import { DataTableProps } from './error-table.d'

import styles from './error-table.module.scss'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ErrorTableBatch: FC<DataTableProps> = ({ data }) => {
  const { t } = useTranslation(['payments', 'paymentsLink'])

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: t(
          'paymentsLink:paymentLinkPage.paymentLinkList.tableHeaders.paymentId'
        ).toUpperCase(),
        accessorKey: 'data.product_id',
        enableSorting: true,
        cell: (c: any) => {
          if (c.row.original.product_id) {
            return (
              <>
                <code className={styles['tooltip']}>
                  {c.row.original.data.product_id}
                  <br></br>
                  <code className={styles['tooltiptext']}>
                    {c.row.original.product_id}
                  </code>
                </code>
              </>
            )
          }
        },
      },
      {
        header: t(
          'paymentsLink:paymentLinkPage.paymentLinkList.tableHeaders.concept'
        ).toUpperCase(),
        accessorKey: 'data.concept',
        enableSorting: false,
        cell: (c: any) => {
          return c.getValue() ? `${c.getValue()}` : ''
        },
      },
      {
        header: t(
          'paymentsLink:paymentLinkPage.paymentLinkList.tableHeaders.externalId'
        ).toUpperCase(),
        accessorKey: 'data.external_id',
        enableSorting: false,
        cell: (c: any) => {
          return c.getValue() ? `${c.getValue()}` : ''
        },
      },

      {
        header: t(
          'paymentsLink:paymentLinkPage.paymentLinkList.tableHeaders.currency'
        ).toUpperCase(),
        accessorKey: 'data.currency',
        enableSorting: true,
        cell: (c: any) => {
          if (c.row.original.currency) {
            return (
              <>
                <code className={styles['tooltip']}>
                  {c.row.original.data.currency}
                  <br></br>
                  <code className={styles['tooltiptext']}>
                    {c.row.original.currency}
                  </code>
                </code>
              </>
            )
          }
        },
      },
      {
        header: t(
          'paymentsLink:paymentLinkPage.paymentLinkList.tableHeaders.amount'
        ).toUpperCase(),
        accessorKey: 'data.amount',
        enableSorting: true,
        cell: (c: any) => {
          const amount = new Intl.NumberFormat('en', {
            minimumFractionDigits: 2,
          }).format(c.getValue())
          return <span className={styles['center']}>{amount}</span>
        },
      },
      {
        header: t(
          'paymentsLink:paymentLinkPage.paymentLinkList.tableHeaders.email'
        ).toUpperCase(),
        accessorKey: 'data.email',
        enableSorting: false,
        cell: (c: any) => {
          if (c.row.original.currency) {
            return (
              <>
                <code className={styles['tooltip']}>
                  {c.row.original.data.email}
                  <br></br>
                  <code className={styles['tooltiptext']}>
                    {c.row.original.email}
                  </code>
                </code>
              </>
            )
          }
        },
      },
      {
        header: t(
          'paymentsLink:paymentLinkBatchPage.paymentLinkList.tableHeaders.expired_at'
        ).toUpperCase(),
        accessorKey: 'data.expires_at',
        enableSorting: true,
        cell: (c: any) => {
          return (
            <>
              <code className={styles['tooltip']}>
                {c.row.original.data.expires_at}
                <br></br>
                <code className={styles['tooltiptext']}>
                  {c.row.original.expires_at}
                </code>
              </code>
            </>
          )
          // return format(new Date(c.getValue()), 'dd/MM/yyyy ')
        },
      },
    ],
    [t]
  )

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  return (
    <Card>
      <div className={styles['top-table-container__left']}>
        <InputLabel>{t('payments:paymentHistory.listToShow')}</InputLabel>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e: any) => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 50, 150, 300, 600, 1000].map((pageSize: number) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div className={styles['table-wrapper']}>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            onClick: header.column.getToggleSortingHandler(),
                            className: header.column.getCanSort()
                              ? styles['is-sort']
                              : '',
                          }}
                        >
                          <Typography
                            tag="span"
                            variant="small-2"
                            typographyColor="typography-secondary"
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </Typography>
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row: any) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell: any) => (
                  <td key={cell.id}>
                    <Typography tag="p" variant="small-1">
                      {cell.column.columnDef.accessorKey === 'amount' ? (
                        <b>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </b>
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      )}
                    </Typography>{' '}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {table.getRowModel().rows.length !== 0 && (
        <div className={styles['bottom-table-container']}>
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <Typography tag="span" variant="small-1">
            {t('payments:paymentHistory.paginateInfo', {
              current: table.getState().pagination.pageIndex + 1,
              total: table.getPageCount(),
            })}
          </Typography>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
        </div>
      )}
    </Card>
  )
}
