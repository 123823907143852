import { PuiColor } from '@lib-types'

export const puiColors: PuiColor[] = [
  'orange-1',
  'orange-0',
  'white',
  'gray-1-5',
  'gray-0-5',
  'gray-8',
  'gray-7',
  'gray-6',
  'gray-5',
  'gray-4',
  'gray-3',
  'gray-2',
  'gray-1',
  'gray-0',
  'yellow-1-5',
  'yellow-0-5',
  'yellow-8',
  'yellow-7',
  'yellow-6',
  'yellow-5',
  'yellow-4',
  'yellow-3',
  'yellow-2',
  'yellow-1',
  'yellow-0',
  'green-1-5',
  'green-0-5',
  'green-8',
  'green-7',
  'green-6',
  'green-5',
  'green-4',
  'green-3',
  'green-2',
  'green-1',
  'green-0',
  'blue-1-5',
  'blue-0-5',
  'blue-8',
  'blue-7',
  'blue-6',
  'blue-5',
  'blue-4',
  'blue-3',
  'blue-2',
  'blue-1',
  'blue-0',
  'red-1-5',
  'red-0-5',
  'red-8',
  'red-7',
  'red-6',
  'red-5',
  'red-4',
  'red-3',
  'red-2',
  'red-1',
  'red-0',
  'accent-orange',
  'accent-yellow',
  'celebration',
  'action',
  'error',
  'brand',
  'black',
]
