import React, { HTMLProps, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './payments-batch-page.module.scss'
import {
  PageHeader,
  PageHeaderTitle,
  useHomeQuery,
  useHomeQueryDataHelpers,
} from 'src/presentation'
import { Home } from 'src/domain'
import { Navigate, useNavigate } from 'react-router-dom'
import { ErrorTableBatch, PaymentBatchTable } from '../../components'
import {
  Button,
  IconMoon,
  Typography,
  Spacer,
} from '@prometeoapi/afrodita-react-component-lib'
import { format } from 'date-fns'
import { ColumnDef } from '@tanstack/react-table'
import { useAuthWithRoles } from 'src/infrastructure'
import { ACTION_ROLES } from 'src/config'
import { capitalizeWords } from 'src/utils'
import { usePaymentsLinkUseCases } from 'src/service-locator'

export const PaymentsLinkBatchPage = () => {
  const { t } = useTranslation(['paymentsLink'])
  const navigate = useNavigate()
  const { downloadBatch } = usePaymentsLinkUseCases()

  const { hasPermission: canCreatePL } = useAuthWithRoles(
    ACTION_ROLES['payment_links_create_one_action']
  )
  const modalRef = useRef<HTMLDialogElement>(null)

  // eslint-disable-next-line
  const { data, isSuccess } = useHomeQuery()
  const { isInProduction } = useHomeQueryDataHelpers()
  const shouldRedirect = isSuccess && !isInProduction(data as Home)
  const [isErrorData, setIsErrorData] = useState(false)
  const [isInvalidData, setIsInvalidData] = useState(false)
  const [errorDataResponse, setErrorDataResponse] = useState<{
    [key: string]: any
  }>({})

  const checkboxRef = (ref: any, rest: any) => {
    return <input type="checkbox" ref={ref} {...rest} />
  }

  function IndeterminateCheckbox({
    indeterminate,
    ...rest
  }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const ref = useRef<HTMLInputElement>(null!)

    useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, indeterminate])

    return checkboxRef(ref, rest)
  }

  const tableCheckbox = (table: any) => {
    return (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    )
  }

  const anchorCell = (cell: any) => {
    return (
      <Button
        variant="terciary-light"
        size="xs"
        onClick={() => handleDownload(cell.row.original.id)}
      >
        <IconMoon name="icon-link" size="xs" style={{ color: '#e6210a' }} />
      </Button>
    )
  }

  const rowCheckbox = (row: any) => {
    return (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: row.original.status !== 'created',
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    )
  }

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => tableCheckbox(table),
        cell: ({ row }) => rowCheckbox(row),
      },
      {
        header: t(
          'paymentsLink:paymentLinkBatchPage.paymentLinkList.tableHeaders.id'
        ).toUpperCase(),
        accessorKey: 'id',
        enableSorting: false,
        cell: (c: any) => {
          return c.getValue() ? `${c.getValue()}` : ''
        },
      },
      {
        header: t(
          'paymentsLink:paymentLinkBatchPage.paymentLinkList.tableHeaders.createdAt'
        ).toUpperCase(),
        accessorKey: 'created_at',
        enableSorting: false,
        cell: (c: any) => {
          return format(new Date(c.getValue()), 'dd/MM/yyyy HH:mm')
        },
      },
      {
        header: t(
          'paymentsLink:paymentLinkBatchPage.paymentLinkList.tableHeaders.status'
        ).toUpperCase(),
        accessorKey: 'status',
        enableSorting: false,
        cell: (c: any) => {
          if (c.getValue() === 'created') {
            return (
              <span className={styles['active']}>
                {capitalizeWords(c.getValue() || '')}
              </span>
            )
          } else if (c.getValue() === 'error') {
            return (
              <span className={styles['error']}>
                {capitalizeWords(c.getValue() || '')}
              </span>
            )
          } else if (c.getValue() === 'processing') {
            return (
              <span className={styles['processing']}>
                {capitalizeWords(c.getValue() || '')}
              </span>
            )
          } else if (c.getValue() === 'cancelled') {
            return (
              <span className={styles['cancelled']}>
                {capitalizeWords(c.getValue() || '')}
              </span>
            )
          }
        },
      },
      {
        header: t(
          'paymentsLink:paymentLinkBatchPage.paymentLinkList.tableHeaders.link'
        ).toUpperCase(),
        accessorKey: 'url',
        enableSorting: false,
        cell: (c: any) => {
          if (c.row.original.status === 'processing') {
            return <>...</>
          } else {
            return anchorCell(c)
          }
        },
      },
    ],
    // eslint-disable-next-line
    [t]
  )

  const downloadBatchAction = async (id: any) => {
    try {
      const response = await downloadBatch(id)
      if (typeof response === 'string') {
        download(response)
      }
      if (response?.error_details.length > 0) {
        const error_details = response.error_details
        setIsErrorData(true)
        const listArray: Array<object> = []
        error_details.forEach((data: any) => {
          if (Object.keys(data).length) {
            listArray.push(data)
          }
        })
        setErrorDataResponse(listArray)
        handleOpenModalClick()
      } else if (response.error_details.file) {
        setIsInvalidData(true)
        handleOpenModalClick()
      }
    } catch (error: any) {
      if (error.response.status == 401) {
        navigate('/login')
      }
    }
  }

  const download = (response: any) => {
    try {
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${getFileName()}`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      // onError()
    }
  }

  const getFileName = () => {
    const current = new Date()
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`

    return date + '.csv'
  }

  const handleDownload = async (id: any) => {
    await downloadBatchAction(id)
  }

  const handleOpenModalClick = () => {
    if (modalRef.current) {
      modalRef.current.showModal()
    }
  }
  const handleCloseModalClick = () => {
    if (modalRef.current) {
      modalRef.current.close()
    }
  }

  if (shouldRedirect) {
    return <Navigate to="/" replace />
  }

  return (
    <section className={styles['page-container']}>
      <PageHeader>
        <div className={styles['page-header']}>
          <div>
            <PageHeaderTitle>
              {t(`paymentsLink:paymentLinkBatchPage.title`)}
            </PageHeaderTitle>
          </div>
          <div className={styles['section-buttons']}>
            {canCreatePL && (
              <Button
                className={styles['button']}
                size="md"
                variant="terciary-light"
                onClick={() => {
                  navigate('create-batch')
                }}
              >
                <Typography
                  variant="body-2"
                  withFontFamily
                  typographyColor="red-5"
                >
                  {t(`paymentsLink:paymentLinkBatchPage.batchButton`)}
                </Typography>{' '}
                <IconMoon
                  className={styles['icon-button']}
                  style={{ color: '#e6210a' }}
                  name="icon-file"
                  size="xs"
                />
              </Button>
            )}
          </div>
        </div>
      </PageHeader>
      <Spacer size={'4x'} />
      <PaymentBatchTable columns={columns} />
      <Spacer size={'10x'} />

      <dialog ref={modalRef} className={styles['modal-container']}>
        <div className={styles['error-response']}>
          <Typography tag="h3" variant="headline-3" typographyColor="gray-8">
            {t('paymentsLink:createBatch.errorModal.title')}
          </Typography>
          {isErrorData ? (
            <div>
              <Typography tag="p" variant="small-1" typographyColor="red-5">
                <Spacer />
                {t('paymentsLink:createBatch.errorModal.helpText')}
              </Typography>
              <Spacer size={'5x'} />
              <ErrorTableBatch data={errorDataResponse} />
            </div>
          ) : (
            <div>
              {isInvalidData ? (
                <Typography tag="p" variant="body-1" typographyColor="red-5">
                  <Spacer />
                  {t('paymentsLink:createBatch.errorModal.invalidFile')}
                </Typography>
              ) : (
                ' '
              )}
            </div>
          )}

          <Spacer size={'8x'} />

          <div className={styles['modal-button-row']}>
            <>
              <Button
                size="md"
                variant="primary-light"
                onClick={handleCloseModalClick}
              >
                {t('paymentsLink:createBatch.errorModal.cancel')}
              </Button>
            </>
          </div>
        </div>
      </dialog>
    </section>
  )
}
