import { UsePaymentsLinkUseCases } from 'src/domain'

type MakePaymentsLinkUseCasesProps = {
  usePaymentsLinkUseCases: UsePaymentsLinkUseCases
}

type MakePaymentsLinkUseCases = (
  props: MakePaymentsLinkUseCasesProps
) => UsePaymentsLinkUseCases

export const makePaymentsLinkUseCases: MakePaymentsLinkUseCases =
  ({ usePaymentsLinkUseCases }) =>
  () =>
    usePaymentsLinkUseCases()
