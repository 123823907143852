import { Usage, UsageGraph, UseUsageUseCases } from '../definition'

type MakeUsageUseCasesProps = {
  useUsageUseCases: UseUsageUseCases
}

type MakeUsageUseCases = (props: MakeUsageUseCasesProps) => UseUsageUseCases

export const makeUsageUseCases: MakeUsageUseCases =
  ({ useUsageUseCases }) =>
  () => {
    const today = new Date()
    const usageUseCases = useUsageUseCases()

    const getUsage = (
      year: number = today.getFullYear(),
      month: number = today.getMonth() + 1
    ): Promise<Usage> => {
      if (month > 12) throw new Error('Mes invalido')

      return usageUseCases.getUsage(year, month)
    }

    const getUsageGraphData = (
      year: number = today.getFullYear(),
      month: number = today.getMonth() + 1
    ): Promise<UsageGraph> => {
      if (month > 12) throw new Error('Mes invalido')

      return usageUseCases.getUsageGraphData(year, month)
    }
    return {
      getUsage,
      getUsageGraphData,
    }
  }
