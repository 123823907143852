import { forwardRef, useState } from 'react'
import { PasswordRequirement } from './password-requirement/password-requirement'
import { InputPasswordProps } from './input-password.d'
import styles from './input-password.module.scss'
import { Icon } from '@lib-components'

export const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>(
  (props, ref) => {
    const {
      label,
      name,
      placeholder,
      error,
      disabled,
      required,
      autoComplete = 'off',
      showRequirement = false,
      onChange,
    } = props
    const errorClass = error ? styles['form-field-error'] : styles['form-field']

    const requireClass = required
      ? `${styles['form-label']} ${styles['form-label--required']}`
      : styles['form-label']
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [pwdRequirement, setPwdRequirement] = useState<boolean>(false)
    const [checks, setChecks] = useState<Record<string, boolean>>({
      upperLetterCheck: false,
      lowerLetterCheck: false,
      numberCheck: false,
      pwdLengthCheck: false,
      specialCharCheck: false,
    })

    const handleOnMouseEnter = () => {
      setPwdRequirement(true)
    }

    const handleOnMouseLeave = () => {
      setPwdRequirement(false)
    }

    const handleOnShowPassword = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      event.preventDefault()
      setShowPassword((prevState) => !prevState)
    }

    const handleOnKeyup = (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.target as HTMLInputElement
      const upperLetterCheck = /[A-Z]/.test(value)
      const lowerLetterCheck = /[a-z]/.test(value)
      const numberCheck = /[0-9]/.test(value)
      const pwdLengthCheck = value.length > 8
      const specialCharCheck = /[!@#$%^*]/.test(value)
      setChecks({
        upperLetterCheck,
        lowerLetterCheck,
        numberCheck,
        pwdLengthCheck,
        specialCharCheck,
      })
    }

    return (
      <>
        <div className={`${styles['form-group']}`}>
          <button
            aria-label="show/hide password"
            className={styles['form-icon']}
            tabIndex={-1}
            disabled={disabled}
            type="button"
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleOnShowPassword}
            data-testid="change-input-type"
          >
            <Icon name={`${showPassword ? 'visibility_off' : 'visibility'}`} />
          </button>
          <input
            type={showPassword ? 'text' : 'password'}
            id={name}
            className={errorClass}
            placeholder={placeholder || label}
            disabled={disabled}
            autoComplete={autoComplete}
            onChange={onChange}
            onFocus={handleOnMouseEnter}
            onMouseOut={handleOnMouseLeave}
            onKeyUp={handleOnKeyup}
            ref={ref}
          />
          <label htmlFor={name} className={requireClass}>
            {label}
          </label>
          {error && (
            <span
              className={`${styles['form-message']} ${styles['form-message--visible']} ${styles['form-message--error']}`}
            >
              {error}
            </span>
          )}
        </div>
        {pwdRequirement && showRequirement ? (
          <PasswordRequirement
            upperLetterCheck={checks.upperLetterCheck}
            lowerLetterCheck={checks.lowerLetterCheck}
            numberCheck={checks.numberCheck}
            pwdLengthCheck={checks.pwdLengthCheck}
            specialCharCheck={checks.specialCharCheck}
          />
        ) : null}
      </>
    )
  }
)

InputPassword.displayName = 'InputPassword'
