import styled from 'styled-components'
import { IconMoon } from '@prometeoapi/afrodita-react-component-lib'
import { NavbarNavigationItemProps } from 'src/presentation'

export const MainNavigationLink = styled.div<NavbarNavigationItemProps>`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  width: 100%;
  padding: 0 10px;

  a {
    text-decoration: none;
    display: block;
    height: 100%;
    width: 100%;
    text-align: center;
    padding: 27px 10px;
  }
`

export const MainNavigationLinkDropdown = styled.div<NavbarNavigationItemProps>`
  background-color: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: none;
  position: absolute;
  left: 0;
  top: 100%;

  &.languages {
    left: -70px;
  }
`

export const CustomIcon = styled(IconMoon)`
  padding: 4px;
  margin-left: 4px;
  transition: all 1s;
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
`

export const ActiveLink = styled.hr<NavbarNavigationItemProps>`
  position: absolute;
  width: 100%;
  height: 4px;
  left: 0px;
  bottom: 18px;
  display: none;
  border: 0;
  background: ${(props: NavbarNavigationItemProps) =>
    props.isRed ? '#ffffff' : '#e6210a'};
`

export const Wrapper = styled.div<NavbarNavigationItemProps>`
  height: 100%;
  padding: 50px 12px 50px 12px;
  position: relative;
  cursor: pointer;
  z-index: 2;

  &.custom-link {
    padding: 0;

    ${ActiveLink} {
      bottom: -5px;
    }
  }

  :hover ${MainNavigationLinkDropdown} {
    display: block;
  }

  ${CustomIcon} {
    color: ${(props: NavbarNavigationItemProps) =>
      props.isRed ? '#ffffff' : 'inherit'};
  }

  :hover ${CustomIcon} {
    transition: all 1s;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }

  :hover ${ActiveLink}, .active ${ActiveLink} {
    display: block;
  }
`
