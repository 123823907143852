import {
  ChangePasswordFormValidationErrors,
  UpdateUserSettingsValidationErrors,
} from 'src/domain'
import {
  MapToChangePasswordFormValidationErrors,
  MapToChangePasswordPayload,
  MapToUpdateExpiredPasswordPayload,
  MapToUpdateUserSettingsPayload,
  MapToUpdateUserSettingsValidationErrors,
  MapToUserSettings,
} from 'src/adapters'
import { removeEmptyErrors } from 'src/utils'

export const mapToChangePasswordPayload: MapToChangePasswordPayload = (
  args
) => ({
  old_password: args.oldPassword,
  new_password1: args.newPassword,
  new_password2: args.newPasswordConfirm,
})

export const mapToUpdateExpiredPasswordPayload: MapToUpdateExpiredPasswordPayload =
  (args) => ({
    password: args.password,
    token: args.token,
  })

export const mapToChangePasswordFormValidationErrors: MapToChangePasswordFormValidationErrors =
  (responseErrors) => {
    const mappedErrors = {
      oldPassword: responseErrors.old_password,
      newPassword: responseErrors.new_password1,
      newPasswordConfirm: responseErrors.new_password2,
    }

    return removeEmptyErrors<ChangePasswordFormValidationErrors>(mappedErrors)
  }

export const mapToUpdateUserSettingsValidationErrors: MapToUpdateUserSettingsValidationErrors =
  (responseErrors) => {
    const mappedErrors = {
      email: responseErrors.email,
      isCompany: responseErrors.is_company,
      firstName: responseErrors.first_name,
      lastName: responseErrors.last_name,
      companyName: responseErrors.reason,
      taxId: responseErrors.rut,
    }

    return removeEmptyErrors<UpdateUserSettingsValidationErrors>(mappedErrors)
  }

export const mapToUpdateUserSettingsPayload: MapToUpdateUserSettingsPayload = (
  args
) => ({
  email: args.email,
  is_company: args.isCompany,
  first_name: args.firstName,
  last_name: args.lastName,
  reason: args.companyName,
  rut: args.taxId,
})

export const mapToUserSettings: MapToUserSettings = (response) => ({
  email: response.email,
  isCompany: response.is_company,
  firstName: response.first_name,
  lastName: response.last_name,
  companyName: response.reason ?? '',
  taxId: response.rut ?? '',
})
