import { User } from 'src/domain'
import { MapToAuthTokens, MapToRefreshTokens, MapToUser } from 'src/adapters'

export const mapToAuthTokens: MapToAuthTokens = (response) => ({
  accessToken: response.access,
  refreshToken: response.refresh,
  sesameToken: response.sesame,
})

export const mapToRefreshTokens: MapToRefreshTokens = (response) => ({
  accessToken: response.access,
  sesameToken: response.sesame,
})

export const mapToUser: MapToUser = (decodedAccessToken): User => {
  return {
    email: decodedAccessToken.email,
    roles: decodedAccessToken.roles,
  }
}
