import { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import { ACTION_ROLES } from 'src/config'
import { LoginFormPage, ProtectedRoutes, PublicRoutes } from 'src/presentation'

const BaseTemplatePage = lazy(() => import('src/routes/templates/base'))

const HomePage = lazy(() => import('src/routes/pages/home'))

const UserSettingsPage = lazy(() => import('src/routes/pages/user-settings'))

const ChangePasswordPage = lazy(
  () => import('src/routes/pages/change-password')
)

const RenewCredentialsPage = lazy(
  () => import('src/routes/pages/renew-credentials')
)

const RegisterPage = lazy(() => import('src/routes/pages/register-account'))
const RegisterAccountConfirmPage = lazy(
  () => import('src/routes/pages/register-account-confirm')
)
const ResetPasswordPage = lazy(() => import('src/routes/pages/reset-password'))
const ResetPasswordEmailPage = lazy(
  () => import('src/routes/pages/reset-password-email')
)
const ResetPasswordConfirmPage = lazy(
  () => import('src/routes/pages/reset-password-confirm')
)

const PaymentsPage = lazy(() => import('src/routes/pages/payments'))

const PaymentsLinkPage = lazy(() => import('src/routes/pages/payments-link'))

const PaymentsLinkBatchPage = lazy(
  () => import('src/routes/pages/payments-link-batch')
)

const ConsumptionPage = lazy(() => import('src/routes/pages/consumption'))
const UsagePage = lazy(() => import('src/routes/pages/usage'))

const WidgetsPage = lazy(() => import('src/routes/pages/widgets'))

const WidgetPage = lazy(() => import('src/routes/pages/widgets/[widget-id]'))

const WidgetDestinationAccountsCreatePage = lazy(
  () => import('src/routes/pages/widgets/destination-accounts/create')
)

const WidgetDestinationAccountsEditPage = lazy(
  () =>
    import(
      'src/routes/pages/widgets/destination-accounts/[destination-account-id]/edit'
    )
)

const WidgetAppearancePage = lazy(
  () => import('src/routes/pages/widgets/[widget-id]/appearance')
)

const CreateLinkPage = lazy(
  () => import('src/routes/pages/payments-link/links/create-link')
)
const CreateBatchPage = lazy(
  () => import('src/routes/pages/payments-link/links/create-batch')
)
const NotFoundPage = lazy(() => import('src/routes/pages/not-found'))
const PermissionDeniedPage = lazy(
  () => import('src/routes/pages/permission-denied')
)

export const DashboardRoutes = () => {
  return (
    <Routes>
      <Route element={<BaseTemplatePage />}>
        <Route
          path="/"
          element={
            <ProtectedRoutes
              checkRoles={true}
              allowedRoles={ACTION_ROLES['summary_view_action']}
            >
              <HomePage />
            </ProtectedRoutes>
          }
        />
        <Route
          path={'/settings'}
          element={
            <ProtectedRoutes
              checkRoles={true}
              allowedRoles={ACTION_ROLES['user_settings_edit_action']}
            >
              <UserSettingsPage />
            </ProtectedRoutes>
          }
        />
        <Route
          path={'/consumption'}
          element={
            <ProtectedRoutes
              checkRoles={true}
              allowedRoles={ACTION_ROLES['consumption_view_action']}
            >
              <ConsumptionPage />
            </ProtectedRoutes>
          }
        />
        <Route
          path={'/usage'}
          element={
            <ProtectedRoutes
              checkRoles={true}
              allowedRoles={ACTION_ROLES['history_view_action']}
            >
              <UsagePage />
            </ProtectedRoutes>
          }
        />
        <Route
          path={'/change-password'}
          element={
            <ProtectedRoutes
              checkRoles={true}
              allowedRoles={ACTION_ROLES['user_change_password_action']}
            >
              <ChangePasswordPage />
            </ProtectedRoutes>
          }
        />
        <Route
          path={'/payments'}
          element={
            <ProtectedRoutes
              checkRoles={true}
              allowedRoles={ACTION_ROLES['payments_view_action']}
            >
              <PaymentsPage />
            </ProtectedRoutes>
          }
        />
        <Route path={'/widgets'}>
          <Route
            index
            element={
              <ProtectedRoutes
                checkRoles={true}
                allowedRoles={ACTION_ROLES['widgets_view_action']}
              >
                <WidgetsPage />
              </ProtectedRoutes>
            }
          />
          <Route path={':widgetId'}>
            <Route
              index
              element={
                <ProtectedRoutes
                  checkRoles={true}
                  allowedRoles={ACTION_ROLES['widget_detail_action']}
                >
                  <WidgetPage />
                </ProtectedRoutes>
              }
            />
            <Route path={'destination-accounts'}>
              <Route
                path={'create'}
                element={
                  <ProtectedRoutes
                    checkRoles={true}
                    allowedRoles={
                      ACTION_ROLES['widget_create_collection_account_action']
                    }
                  >
                    <WidgetDestinationAccountsCreatePage />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={':destinationAccountId/edit'}
                element={
                  <ProtectedRoutes
                    checkRoles={true}
                    allowedRoles={
                      ACTION_ROLES['widget_edit_collection_account_action']
                    }
                  >
                    <WidgetDestinationAccountsEditPage />
                  </ProtectedRoutes>
                }
              />
            </Route>
            <Route
              path={'appearance'}
              element={
                <ProtectedRoutes
                  checkRoles={true}
                  allowedRoles={ACTION_ROLES['widget_edit_apperance_action']}
                >
                  <WidgetAppearancePage />
                </ProtectedRoutes>
              }
            />
          </Route>
        </Route>
        <Route path={'/payments-link'}>
          <Route
            index
            element={
              <ProtectedRoutes
                checkRoles={true}
                allowedRoles={ACTION_ROLES['payment_links_view_action']}
              >
                <PaymentsLinkPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={'create-link'}
            element={
              <ProtectedRoutes
                checkRoles={true}
                allowedRoles={ACTION_ROLES['payment_links_create_one_action']}
              >
                <CreateLinkPage />
              </ProtectedRoutes>
            }
          />
        </Route>
        <Route path={'/payments-link-batch'}>
          <Route
            index
            element={
              <ProtectedRoutes>
                <PaymentsLinkBatchPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={'create-batch'}
            element={
              <ProtectedRoutes
                checkRoles={true}
                allowedRoles={ACTION_ROLES['payment_links_create_one_action']}
              >
                <CreateBatchPage />
              </ProtectedRoutes>
            }
          />
        </Route>
      </Route>
      <Route
        path="/login"
        element={
          <PublicRoutes>
            <LoginFormPage />
          </PublicRoutes>
        }
      />
      <Route
        path="/renew-credentials/:token"
        element={
          <PublicRoutes>
            <RenewCredentialsPage />
          </PublicRoutes>
        }
      />
      <Route
        path={'/register-account'}
        element={
          <PublicRoutes>
            <RegisterPage />
          </PublicRoutes>
        }
      />
      <Route
        path="/accounts/confirm/:uuid/:token"
        element={
          <PublicRoutes>
            <RegisterAccountConfirmPage />
          </PublicRoutes>
        }
      />
      <Route
        path={'/reset-password'}
        element={
          <PublicRoutes>
            <ResetPasswordPage />
          </PublicRoutes>
        }
      />
      <Route
        path={'/reset-password-email'}
        element={
          <PublicRoutes>
            <ResetPasswordEmailPage />
          </PublicRoutes>
        }
      />
      <Route path={'/accounts/reset-password-confirm'}>
        <Route
          index
          element={
            <PublicRoutes>
              <ResetPasswordConfirmPage />
            </PublicRoutes>
          }
        />
        <Route
          path=":uuid64/:token"
          element={
            <PublicRoutes>
              <ResetPasswordConfirmPage />
            </PublicRoutes>
          }
        />
      </Route>
      <Route path="/permission-denied" element={<PermissionDeniedPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}
