import { FC } from 'react'
import classNames from 'classnames'

import { getStyledElementProps } from '@lib-utils'
import type { IconProps } from '@lib-types'

import styles from './icon.module.scss'

export const Icon: FC<IconProps> = ({
  name,
  color = 'black',
  size = 'xl',
  ...props
}) => {
  const styledProps = getStyledElementProps(
    props,
    classNames(styles['root'], styles[`size-${size}`], styles[color])
  )
  return <span {...styledProps}>{name}</span>
}
