import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {
  Button,
  SelectField,
  Spacer,
  Typography,
  TextField,
} from '@prometeoapi/afrodita-react-component-lib'

import * as settings from 'src/config/settings'
import {
  useMarketplaceFormMutation,
  mapToCreateDestinationAccountValidationErrors,
} from 'src/adapters'
import {
  PageCard,
  PageCardTitle,
  PageHeader,
  PageHeaderBreadCrumbs,
  PageHeaderTitle,
  useDangerMutationAlert,
  WarningAlert,
  useCountrySelectField,
  useDestinationAccountFormUtils,
  useBankSelectField,
  useHomeQuery,
  useHomeQueryDataHelpers,
} from 'src/presentation'
import {
  CreateDestinationAccountArgs,
  CreateDestinationAccountReturns,
  CreateDestinationAccountValidationErrors,
  Home,
} from 'src/domain'
import { WidgetsService } from 'src/infrastructure'
import { getBankAccountBranch, useNonNullableParams } from 'src/utils'
import { useWidgetUseCases } from 'src/service-locator'

import styles from '../widget-destination-accounts-pages.module.scss'

export const WidgetDestinationAccountsCreatePage: FC = () => {
  const navigate = useNavigate()

  const { t } = useTranslation(['shared', 'widgets'])
  const { data, isSuccess } = useHomeQuery()
  const { isInProduction: isProd } = useHomeQueryDataHelpers()
  const shouldRedirect =
    !settings.REACT_APP_WIDGET_SHOW_IN_MENU ||
    (isSuccess && !isProd(data as Home))

  const {
    // emptyDestinationAccountFormFields,
    destinationAccountDefaultsFormFieldsValidationSchema,
  } = useDestinationAccountFormUtils()
  const [country, setCountry] = useState('')

  const { widgetId } = useNonNullableParams('widgetId')

  const validationSchema = Yup.object({
    ...destinationAccountDefaultsFormFieldsValidationSchema,
    bankCode: Yup.string().required(
      t('shared:form.errors.requiredField', {
        name: t('widgets:destinationAccountsPages.forms.fields.bankCode.label'),
      })
    ),
    destinationAccountType: Yup.string().when('bankCode', {
      is: (bankCode: string) =>
        bankCode === 'scotia_pe' || bankCode === 'interbank_pe',
      then: Yup.string().required('Field is required'),
      otherwise: Yup.string(),
    }),
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { countriesQuery, getCountryOptions } = useCountrySelectField()

  const { banksQuery, getBankOptions, makeGetSelectProviderValue } =
    useBankSelectField(country)

  const widgetUseCases = useWidgetUseCases()

  const queryClient = useQueryClient()

  const createDestinationAccountMutation = useMarketplaceFormMutation<
    CreateDestinationAccountArgs,
    CreateDestinationAccountReturns,
    WidgetsService.CreateDestinationAccountResponseErrors,
    CreateDestinationAccountValidationErrors
  >({
    requestFunction: widgetUseCases.createDestinationAccount,
    errorMapper: mapToCreateDestinationAccountValidationErrors,
    onSuccessCallback: async () => {
      await queryClient.invalidateQueries([
        settings.GET_WIDGET_QUERY_KEY,
        widgetId,
      ])
      navigate(-1)
    },
  })

  const {
    mutate: createDestinationAccount,
    isSuccess: createDestinationAccountIsSuccess,
    isError: createDestinationAccountIsError,
    error: createDestinationAccountError,
    isLoading: createDestinationAccountIsLoading,
  } = createDestinationAccountMutation

  const { renderAlert: renderErrorAlert, showAlert: showErrorAlert } =
    useDangerMutationAlert({
      isError: createDestinationAccountIsError,
      body: t('widgets:modulePage.errors.generic'),
      spacerSize: '2x',
    })

  const submitIsDisabled = useCallback(() => {
    return (
      createDestinationAccountIsLoading ||
      !!createDestinationAccountError?.formFieldErrors ||
      createDestinationAccountIsSuccess
    )
  }, [
    createDestinationAccountError?.formFieldErrors,
    createDestinationAccountIsLoading,
    createDestinationAccountIsSuccess,
  ])

  if (shouldRedirect) {
    return <Navigate to="/" replace />
  }

  const onSubmit = (values: any) => {
    createDestinationAccount({
      ...values,
      bankAccountBranch: getBankAccountBranch(values.bankAccountBranch),
      widget: parseInt(widgetId),
    })
  }

  return (
    <div>
      <PageHeader>
        <PageHeaderBreadCrumbs spacerSize={'6x'}>
          <Typography tag="p" variant="body-1" typographyColor="gray-6">
            <a href="/widgets" style={{ color: '#2f3a44' }}>
              Widgets
            </a>
            {` > `}
            <a href={`/widgets/${widgetId}`} style={{ color: '#2f3a44' }}>
              {`Widget #${widgetId}`}
            </a>
            {` > ${t(
              'widgets:destinationAccountsPages.header.create.breadcrumb'
            )}`}
          </Typography>
        </PageHeaderBreadCrumbs>
        <PageHeaderTitle>
          {t('widgets:destinationAccountsPages.header.create.title')}
        </PageHeaderTitle>
      </PageHeader>

      <Spacer size={'7x'} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <PageCard>
          <PageCardTitle variant={'strong-1'} typographyColor={'gray-6'}>
            {t('widgets:destinationAccountsPages.forms.title')}
          </PageCardTitle>

          {showErrorAlert ? (
            renderErrorAlert()
          ) : (
            <>
              <WarningAlert>
                {t('widgets:destinationAccountsPages.forms.warningMessage')}
              </WarningAlert>
              <Spacer />
            </>
          )}

          <div className={styles['selects-container']}>
            <Controller
              name="countries"
              control={control}
              render={({ field }) => {
                return (
                  <SelectField
                    label={t(
                      'widgets:destinationAccountsPages.forms.fields.bankCountry.label'
                    )}
                    placeholder={t(
                      'widgets:destinationAccountsPages.forms.fields.bankCountry.placeholder'
                    )}
                    onChange={(e: any) => {
                      field.onChange(e)
                      setCountry(e.value)
                    }}
                    isLoading={countriesQuery.isLoading}
                    options={getCountryOptions()}
                    disabled={createDestinationAccountIsSuccess}
                  />
                )
              }}
            />
            <Controller
              name="bankCode"
              control={control}
              render={({ field }) => (
                <SelectField
                  label={t(
                    'widgets:destinationAccountsPages.forms.fields.bankCode.label'
                  )}
                  placeholder={t(
                    'widgets:destinationAccountsPages.forms.fields.bankCode.placeholder'
                  )}
                  fieldName={'bankCode'}
                  isLoading={banksQuery.isLoading}
                  options={getBankOptions()}
                  makeGetSelectValue={makeGetSelectProviderValue}
                  noOptionsMessage={t(
                    'widgets:destinationAccountsPages.forms.fields.bankCode.noOptions'
                  )}
                  disabled={createDestinationAccountIsSuccess}
                  {...field}
                />
              )}
            />
          </div>

          <Spacer size={'2x'} />

          <div className={styles['text-fields-container']}>
            <div className={styles['top-text-fields-container']}>
              <Controller
                name="bankAccountOwner"
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t(
                      'widgets:destinationAccountsPages.forms.fields.bankAccountOwner.label'
                    )}
                    disabled={createDestinationAccountIsSuccess}
                    fullWidth
                    {...field}
                    caption={
                      errors.bankAccountOwner && (
                        <p className={styles['error-text']}>
                          {errors.bankAccountOwner.message as string}
                        </p>
                      )
                    }
                  />
                )}
              />
              <Controller
                name="bankAccount"
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t(
                      'widgets:destinationAccountsPages.forms.fields.bankAccount.label'
                    )}
                    fieldName={'bankAccount'}
                    fullWidth
                    disabled={createDestinationAccountIsSuccess}
                    {...field}
                    caption={
                      errors.bankAccount && (
                        <p className={styles['error-text']}>
                          {errors.bankAccount.message as string}
                        </p>
                      )
                    }
                  />
                )}
              />
            </div>
          </div>

          <Spacer size={'2x'} />

          <div className={styles['selects-container']}>
            <Controller
              name="bankAccountBranch"
              control={control}
              render={({ field }) => (
                <TextField
                  type={'tel'}
                  label={t(
                    'widgets:destinationAccountsPages.forms.fields.bankAccountBranch.label'
                  )}
                  disabled={createDestinationAccountIsSuccess}
                  fullWidth
                  {...field}
                  caption={
                    errors.bankAccountBranch && (
                      <p className={styles['error-text']}>
                        {errors.bankAccountBranch.message as string}
                      </p>
                    )
                  }
                />
              )}
            />
            <Controller
              name="destinationAccountType"
              control={control}
              render={({ field }) => (
                <SelectField
                  label={t(
                    'widgets:destinationAccountsPages.forms.fields.destinationAccountType.label'
                  )}
                  placeholder={t(
                    'widgets:destinationAccountsPages.forms.fields.destinationAccountType.placeholder'
                  )}
                  isLoading={false}
                  makeGetSelectValue={makeGetSelectProviderValue}
                  noOptionsMessage={t(
                    'widgets:destinationAccountsPages.forms.fields.destinationAccountType.noOptions'
                  )}
                  disabled={createDestinationAccountIsSuccess}
                  {...field}
                />
              )}
            />
          </div>
        </PageCard>

        <Spacer size={'7x'} />

        <div className={styles['buttons-container']}>
          {createDestinationAccountIsSuccess ? (
            <Link to={`/widgets/${widgetId}`}>
              <Button type={'button'}>
                {t('widgets:destinationAccountsPages.forms.buttons.goBack')}
              </Button>
            </Link>
          ) : (
            <>
              <Button
                type={'button'}
                variant={'secondary'}
                onClick={() => navigate(-1)}
              >
                {t('widgets:destinationAccountsPages.forms.buttons.cancel')}
              </Button>
              <Button type={'submit'} disabled={submitIsDisabled()}>
                {t('widgets:destinationAccountsPages.forms.buttons.submit')}
              </Button>
            </>
          )}
        </div>
      </form>
    </div>
  )
}
