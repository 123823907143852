export default {
  consumption: {
    header: {
      title: 'Consumption',
      subtitle: 'Here you can view a summary of your consumption.',
    },
  },
  consumptionGraph: {
    header: {
      title: 'Consumption History',
      subtitle:
        'Click on the month you want to know the consumption of and you will see the total for the month and the historical total at the bottom.',
    },
  },
  consumptionSummary: {
    header: {
      title: 'Summary of Your Consumption by API',
      subtitle1:
        'Choose the month you want to know the consumption of and then',
      subtitle2: 'you will find the detailed summary.',
    },
  },
}
