import { Anchor } from '@lib-components'
import { useTranslation } from 'react-i18next'

import * as settings from 'src/config/settings'
import styles from './resources.module.scss'
import { IconSprite } from '../../../icon-sprite'

export const Resources = () => {
  const { t, i18n } = useTranslation(['auth'])
  const locale = i18n.language && i18n.language == 'en' ? 'en' : 'es'

  return (
    <div className={styles['wrapper']}>
      <Anchor
        href={settings.navbarTranslation[locale]['partnersProgram']}
        underlineOnHover={false}
      >
        <IconSprite
          className="partners"
          title={t('auth:nav.partnersProgram')}
        />
      </Anchor>
      <Anchor href={settings.DOC_URL} underlineOnHover={false}>
        <IconSprite
          className="documentation"
          title={t('auth:nav.documentation')}
        />
      </Anchor>
      <Anchor
        href={settings.navbarTranslation[locale]['security']}
        underlineOnHover={false}
      >
        <IconSprite className="security" title={t('auth:nav.security')} />
      </Anchor>
      <Anchor
        href={settings.navbarTranslation[locale]['helpCenter']}
        underlineOnHover={false}
      >
        <IconSprite className="help-center" title={t('auth:nav.helpCenter')} />
      </Anchor>
      <Anchor
        href={settings.navbarTranslation[locale]['glossary']}
        underlineOnHover={false}
      >
        <IconSprite className="glossary" title={t('auth:nav.glossary')} />
      </Anchor>
      <Anchor
        href={settings.navbarTranslation[locale]['reports']}
        underlineOnHover={false}
      >
        <IconSprite className="reports" title={t('auth:nav.reports')} />
      </Anchor>
    </div>
  )
}
