import { UsePaymentsUseCases } from '../definition'

type MakePaymentsUseCasesProps = {
  usePaymentsUseCases: UsePaymentsUseCases
}

type MakePaymentsUseCases = (
  props: MakePaymentsUseCasesProps
) => UsePaymentsUseCases

export const makePaymentsUseCases: MakePaymentsUseCases =
  ({ usePaymentsUseCases }) =>
  () =>
    usePaymentsUseCases()
